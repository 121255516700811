import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature los-subscription
import { getMasterEnterpriseListRequest } from '../actions';
import { makeSelectMasterEnterpriseOptions } from '../selectors';
//////////////////////////////////////////////////

const defaultFields = {
  [GC.FIELD_MASTER_ENTERPRISE_GUID]: null,
  [GC.FIELD_USE_EXISTENT_ENTERPRISE]: false,
};

const getFieldSettings = (disabled: boolean) => [
  {
    disabled,
    type: 'toggle',
    inputWrapperStyles: { mb: 25 },
    fieldName: GC.FIELD_USE_EXISTENT_ENTERPRISE,
    label: ['titles:use-existent-enterprise', 'Use Existent Enterprise'],
  },
  {
    disabled,
    isRequired: true,
    type: 'reactSelect',
    options: 'masterEnterpriseOptions',
    fieldName: GC.FIELD_MASTER_ENTERPRISE_GUID,
    inputWrapperStyles: { mb: 25, width: 270 },
    label: ['titles:master-enterprise', 'Master Enterprise'],
  },
];

const getValidationSchemaObject = ({ useExistentEnterprise }: Object) => ({
  [GC.FIELD_USE_EXISTENT_ENTERPRISE]: G.yupStringNotRequired,
  [GC.FIELD_MASTER_ENTERPRISE_GUID]: G.ifElse(useExistentEnterprise, G.yupStringRequired, G.yupStringNotRequired),
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  masterEnterpriseOptions: makeSelectMasterEnterpriseOptions(state),
});

const enhance = compose(
  connect(mapStateToProps, { getMasterEnterpriseListRequest }),
  withFormik({
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultFields, initialValues),
    validationSchema: () => Yup.lazy((values: Object) => Yup.object().shape(getValidationSchemaObject(values))),
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => {
      const { useExistentEnterprise } = values;

      let data = values;

      if (G.isFalse(useExistentEnterprise)) {
        data = { ...values, [GC.FIELD_MASTER_ENTERPRISE_GUID]: null };
      }

      submitAction(data);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { masterEnterpriseOptions, getMasterEnterpriseListRequest } = this.props;

      if (R.isEmpty(masterEnterpriseOptions)) getMasterEnterpriseListRequest();
    },
  }),
  pure,
);

export const AcceptForm = enhance((props: Object) => {
  const {
    handleSubmit,
    initialValues,
    handleCustomChange,
    masterEnterpriseOptions,
    values: { useExistentEnterprise },
  } = props;

  const fieldSettings = getFieldSettings(R.pathOr(false, [GC.FIELD_USE_EXISTENT_ENTERPRISE], initialValues));

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        handleCustomChange={handleCustomChange}
        masterEnterpriseOptions={masterEnterpriseOptions}
        fieldsWrapperStyles={{ justifyContent: 'center' }}
        fields={G.ifElse(useExistentEnterprise, fieldSettings, R.init(fieldSettings))}
      />
      <FormFooter2 />
    </form>
  );
});
