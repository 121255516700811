import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure } from 'react-recompose';
// components
import { EmptyList } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
// feature loadboard virtual table
import VirtualizedTable from '../../../components/virtualized-table';
//////////////////////////////////////////////////

const LBResultsListComponent = pure(({
  report,
  itemList,
  resultHeight,
  tableSettings,
  columnSettings,
  titleSortValues,
  titleFilterValues,
  handleTableTitleFilter,
  pinnedReportsArePresent,
} : Object) => {
  const minTableHeight = 200;
  const toSubtract = G.ifElse(pinnedReportsArePresent, 96, 64);
  const tableHeight = G.ifElse(resultHeight, R.subtract(resultHeight, toSubtract), minTableHeight);

  return (
    R.or(G.isNotNilAndNotEmpty(titleFilterValues), G.isNotNilAndNotEmpty(itemList)) ?
    (
      <VirtualizedTable
        report={report}
        listData={itemList}
        withLazyLoading={false}
        tableHeight={tableHeight}
        columnSettings={columnSettings}
        titleSortValues={titleSortValues}
        titleFilterValues={titleFilterValues}
        handleTableTitleFilter={handleTableTitleFilter}
        {...tableSettings}
      />
    ) : <EmptyList>{G.getWindowLocale('titles:empty-list', 'Empty List')}</EmptyList> //TODO: maybe move to virtual list

  );
});

export default connect(null, {})(LBResultsListComponent);
