import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = ({ loadPlannerTemplate }: Object) => loadPlannerTemplate;

const makeSelectItemList = () => createSelector(
  store,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['itemList']),
  ),
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectCrossDockLocationList = () => createSelector(
  store,
  ({ locations }: Object) => locations,
);

export {
  makeSelectItemList,
  makeSelectListLoading,
  makeSelectCrossDockLocationList,
};
