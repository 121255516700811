import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// common
import {
  sendInvoiceToBCByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../common/actions';
// components
import { Table } from '../../../components/table';
import { EditReport } from '../../../components/edit-report';
import { TitlePanel } from '../../../components/title-panel';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { makeSelectOpenedSidebar } from '../../navbar-menu/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { makeSelectBranchesTree, makeSelectCurrentBranchGuid } from '../../branch/selectors';
// forms
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { withMassActionSubmit } from '../../../hocs/with-mass-action-submit';
// icons
import * as I from '../../../svgs';
// ui
import { Box, AbsoluteBox, ZOrderWrapper } from '../../../ui';
// feature invoice
import ActionForm from '../components/action-form';
import { getChangeInvoicesTitle, getGuidsFromStringOrSelectedList } from '../helpers';
// feature invoice/carrier
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import SelectedListTotals from './components/selected-list-totals';
import {
  makeSelectConfigs,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  printByReportRequest,
  changeInvoicesRequest,
  getXMLByReportRequest,
  resetListAndPagination,
  exportReportDataRequest,
  getCarrierInvoiceDetails,
  changeDefaultReportRequest,
  deleteCarrierInvoicesRequest,
  exportCarrierInvoiceToQuickBooksRequest,
} from './actions';
//////////////////////////////////////////////////

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const enhance = compose(
  withFixedPopover,
  withMassActionSubmit,
  withHandlers({
    handleListRequest: ({ getItemListRequest }: Object) => (isInitial: boolean = false) => (
      getItemListRequest(isInitial)
    ),
    getQuickFilteredListRequest: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getItemListRequest(true);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getItemListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);
      setUsedReport(selectedReport);
      getItemListRequest(true);
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) => (
      setReportFromPrompt(data)
    ),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
          G.isNotNilAndNotEmpty(reportFromPrompt),
          G.notEquals(selectedReport, reportFromPrompt),
        )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest(true);
      }

      setPromptStatus(false);
    },
    handleCleanFilter: ({ cleanQuickFilter, getItemListRequest }: Object) => () => {
      cleanQuickFilter();
      getItemListRequest(true);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleExportToQuickBooks: (props: Object) => () => {
      const { itemList, handleMassActionSubmit, exportCarrierInvoiceToQuickBooksRequest } = props;

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      handleMassActionSubmit({
        noSelected,
        formValues: { guids },
        submitAction: exportCarrierInvoiceToQuickBooksRequest,
      });
    },
    handleChangeInvoices: (props: Object) => (invoiceGuid: string) => {
      const {
        configs,
        itemList,
        openModal,
        closeModal,
        branchGuid,
        changeInvoicesRequest,
        handleMassActionSubmit,
      } = props;

      const invoiceGuids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);
      const title = getChangeInvoicesTitle(invoiceGuid);

      const noSelected = R.isEmpty(invoiceGuids);
      const submitMassAction = (data: Object) => changeInvoicesRequest(data);

      let submitAction;

      if (G.isString(invoiceGuid)) {
        submitAction = changeInvoicesRequest;
      } else {
        submitAction = (values: Object) => handleMassActionSubmit({
          noSelected,
          formValues: values,
          submitAction: (data: Object) => submitMassAction(data),
        });
      }


      const component = (
        <ActionForm
          configs={configs}
          branchGuid={branchGuid}
          closeModal={closeModal}
          invoiceGuids={invoiceGuids}
          submitAction={submitAction}
          statusConfigName='CarrierIStatusConfig'
        />
      );

      const modal = G.getDefaultModalOptions(component, title);

      openModal(modal);
    },
    handleOpenSubmitDeleteInvoices: (props: Object) => () => {
      const {
        itemList,
        openModal,
        closeModal,
        handleMassActionSubmit,
        deleteCarrierInvoicesRequest,
      } = props;

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitMassAction = (data: Object) => {
        const { excludedGuids } = data;
        let name;
        const carrierInvoicesText = G.getWindowLocale('titles:carrier-invoices', 'Carrier Invoice(s)');

        if (noSelected) {
          name = `${G.getWindowLocale('titles:all', 'All')} ${carrierInvoicesText}`;
        } else if (G.isNotNilAndNotEmpty(excludedGuids)) {
          name = `${G.getWindowLocale('titles:all-except-selected', 'All Except Selected')} ${carrierInvoicesText}`;
        } else {
          name = `${G.getWindowLocale('titles:selected', 'Selected')} ${carrierInvoicesText}`;
        }

        const modalContent = getConfirmModal({
          name,
          cancelAction: closeModal,
          text: deleteConfirmationLocaleTxt,
          cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
          submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
          submitAction: () => {
            deleteCarrierInvoicesRequest(data);
            closeModal();
          },
        });

        openModal(modalContent);
      };
      handleMassActionSubmit({
        noSelected,
        formValues: { guids },
        submitAction: (data: Object) => submitMassAction(data),
      });
    },
    handleDeleteInvoice: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        deleteCarrierInvoicesRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: G.getWindowLocale('titles:carrier-invoices', 'Carrier Invoice(s)'),
        submitAction: () => {
          deleteCarrierInvoicesRequest({
            guids: R.of(Array, guid),
            [GC.FIELD_CURRENT_BRANCH]: G.getAmousCurrentBranchGuidFromWindow(),
          });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handlePrintInvoicesByReport: ({ openModal, printByReportRequest }: Object) => () => {
      const component = (
        <SelectPrintTemplateForm
          submitAction={(values: Object) => printByReportRequest(values)}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE_REPORT}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-invoice', 'Print Invoice'));

      openModal(modal);
    },
    handleQBIIFImportList: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        filterParams,
        selectedReport,
        handleMassActionSubmit,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      const guids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = {
          ...data,
          type: 'carrier',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };
          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        createQBIIFImportByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
    handleToggleDetails: ({ getCarrierInvoiceDetails }: Object) => (invoice: Object) => (
      getCarrierInvoiceDetails(invoice)
    ),
    handleSendCarrierInvoiceListToBC: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        filterParams,
        selectedReport,
        closeFixedPopup,
        handleMassActionSubmit,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      const guids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);
      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = {
          ...data,
          invoiceType: 'carrierInvoice',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };
          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        sendInvoiceToBCByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
    handleSendCarrierInvoiceListToSageIntacct: (props: Object) => () => {
      const {
        itemList,
        filterParams,
        selectedReport,
        additionalFilters,
        handleMassActionSubmit,
        sendInvoiceToSageByInvoiceTypeRequest,
      } = props;

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;
        let dataToUse = {
          ...data,
          invoiceType: 'carrierInvoice',
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };
          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        sendInvoiceToSageByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
  }),
  withPromptModal(FILTER_PARAMS),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    openedSidebar,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleToggleDetails,
    handleDeleteInvoice,
    handleChangeInvoices,
    handleTableTitleFilter,
  } = props;

  const allChecked = G.isAllChecked(itemList);
  let enhancedItemList = itemList;

  if (G.isNotNil(enhancedItemList)) {
    enhancedItemList = R.map(
      (entity: Object) => R.mergeRight(
        entity,
        {
          detailsWidth: `calc(100vw - ${
            G.ifElse(
              openedSidebar,
              R.add(GC.SIDEBAR_CONTAINER_WIDTH, 32),
              32,
            )
            }px)`,
        },
      ),
      enhancedItemList,
    );
  }

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleChangeInvoices,
      permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      iconName: 'trash',
      action: handleDeleteInvoice,
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
    },
  ];

  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    itemList: enhancedItemList,
    useSearchableColumns: true,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const CarrierInvoiceComponent = (props: Object) => {
  const {
    itemList,
    handleChangeInvoices,
    getXMLByReportRequest,
    handleQBIIFImportList,
    handleExportToQuickBooks,
    handlePrintInvoicesByReport,
    handleOpenSubmitDeleteInvoices,
    handleSendCarrierInvoiceListToBC,
    handleSendCarrierInvoiceListToSageIntacct,
  } = props;

  const mainLightColor = G.getTheme('colors.white');

  const actionOptions = [
    {
      action: getXMLByReportRequest,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      type: 'massAction',
      action: handleChangeInvoices,
      icon: I.pencil(mainLightColor, 20, 20),
      text: G.getWindowLocale('actions:change-invoices', 'Change Invoices'),
    },
    {
      type: 'massAction',
      action: handleExportToQuickBooks,
      icon: I.quickbook('transparent', 25, 25),
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      type: 'massAction',
      action: handleQBIIFImportList,
      icon: I.quickbook('transparent', 25, 25),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
      permissions: [PC.TEL_CARRIER_INVOICE_READ, PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    },
    {
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: handleSendCarrierInvoiceListToBC,
      permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
    },
    {
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: handleSendCarrierInvoiceListToSageIntacct,
      permissions: [PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
    },
    {
      type: 'massAction',
      icon: I.trash(mainLightColor, 20, 20),
      action: handleOpenSubmitDeleteInvoices,
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];

  const selectedList = R.filter(R.prop('selected'), R.or(itemList, []));

  return (
    <Box p={15}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          type={GC.CARRIER_INVOICE_REPORT}
          title={G.getWindowLocale('titles:carrier:reconciliation', 'Carrier: Reconciliation')}
        />
      </ZOrderWrapper>
      {
        G.isNotEmpty(selectedList) &&
        <AbsoluteBox
          left={65}
          bottom='5px'
          overflow='auto'
          maxWidth='calc(100vw - 420px)'
        >
          <SelectedListTotals list={selectedList} />
        </AbsoluteBox>
      }
      <PageActions
        listActions={actionOptions}
        shadowColor={G.getTheme('createButton.shadowColor')}
        count={R.length(R.filter(R.prop('selected'), R.or(itemList, [])))}
        mainAction={{
          action: handlePrintInvoicesByReport,
          text: G.getWindowLocale('actions:print', 'Print'),
        }}
      />
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  configs: makeSelectConfigs(state),
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  branchesTree: makeSelectBranchesTree(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  openedSidebar: makeSelectOpenedSidebar(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  openLoader,
  closeModal,
  selectItem,
  setReports,
  closeLoader,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  printByReportRequest,
  changeInvoicesRequest,
  getXMLByReportRequest,
  resetListAndPagination,
  exportReportDataRequest,
  getCarrierInvoiceDetails,
  changeDefaultReportRequest,
  deleteCarrierInvoicesRequest,
  sendInvoiceToBCByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
  exportCarrierInvoiceToQuickBooksRequest,
})(enhance(CarrierInvoiceComponent));
