import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { Table, EmptyList } from '../../../components';
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature load-board
import ColumnActions from './column-actions';
import SearchFilterForm from './search-filter-form';
import { getValuesFromTemplate } from '../settings/search-filter-form';
import { makeSelectTemplates, makeSelectAvailableLoadBoards } from '../selectors';
import { templatesReport, columnSettings, templateTableSettings } from '../settings/filter-list';
import { saveTemplateRequest, deleteTemplateRequest, createSearchFilterRequest } from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleEditTemplate: (props: Object) => (guid: string, data: Object) => {
      const { name } = data;

      const {
        openModal,
        closeModal,
        saveTemplateRequest,
        availableLoadBoards,
        createSearchFilterRequest,
      } = props;

      const templates = R.of(Array, data);
      const templateOptions = R.map(
        ({ name, guid }: Object) => ({ label: name, value: guid }),
      )(templates);

      const loadBoardOptions = R.filter(
        ({ value }: Object) => G.isNotNil(R.find(
          R.propEq(value, GC.FIELD_TYPE),
          availableLoadBoards,
        )),
        GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
      );

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width: 490 },
        title: G.getWindowLocale('actions:post-equipment', 'Post Equipment'),
        component: (
          <SearchFilterForm
            isTemplateEdit={true}
            cancelAction={closeModal}
            templateOptions={templateOptions}
            loadBoardOptions={loadBoardOptions}
            templates={G.indexByGuid(templates)}
            handleSave={createSearchFilterRequest}
            initialValues={getValuesFromTemplate(data)}
            saveTemplate={(values: Object) => saveTemplateRequest({
              ...values,
              name,
              guid,
            })}
          />
        ),
        options: {
          minWidth: 490,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width: 490, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
    handleDeleteTemplate: (props: Object) => (guid: string, data: Object) => {
      const { openModal, closeModal, deleteTemplateRequest } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteTemplateRequest(guid),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:template', 'Template')} - ${data.name}?`,
      });

      openModal(modalContent);
    },
    handleResetAlternative: () => (tableProps: Object, currentTarget: Object) => {
      const {
        report,
        openFixedPopup,
        closeFixedPopup,
        handleClickResetByGuid,
      } = tableProps;

      return openFixedPopup({
        zIndex: 1300,
        position: 'right',
        el: currentTarget,
        content: (
          <ColumnActions
            closeFixedPopup={closeFixedPopup}
            reportType={R.prop(GC.FIELD_TYPE, report)}
            handleClickResetByGuid={handleClickResetByGuid}
          />
        ),
      });
    },
  }),
  pure,
);

const TemplateList = enhance(({
  itemList,
  handleEditTemplate,
  handleDeleteTemplate,
  handleResetAlternative,
}: Object) => {
  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEditTemplate,
    },
    {
      iconName: 'trash',
      action: handleDeleteTemplate,
    },
  ];

  return (
      G.isNotNilAndNotEmpty(itemList) ? (
        <Table
          loading={false}
          itemList={itemList}
          hasSelectable={false}
          report={templatesReport}
          actionButtons={actionButtons}
          totalCount={R.length(itemList)}
          columnSettings={columnSettings}
          tableSettings={templateTableSettings}
          handleResetAlternative={handleResetAlternative}
          withResizableColumns={templateTableSettings.withResizableColumns}
        />
      ) : <EmptyList>{G.getWindowLocale('titles:empty-list', 'Empty List')}</EmptyList>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectTemplates(state),
  availableLoadBoards: makeSelectAvailableLoadBoards(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  saveTemplateRequest,
  deleteTemplateRequest,
  createSearchFilterRequest,
})(TemplateList);
