import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
// feature master-invoice
import { addMasterInvoices } from './actions';
import { validateCreateMasterInvoice } from './helpers';
//////////////////////////////////////////////////

const handleAddMasterInvoices = createLogic({
  type: addMasterInvoices,
  validate({ getState, action }: Object, allow: void, reject: void) {
    if (R.not(validateCreateMasterInvoice(action.payload))) {
      G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:master-invoice-number-required', 'Master invoice number is required'),
      );
      return reject();
    }
    allow(action);
  },
});

export default [
  handleAddMasterInvoices,
];
