import React from 'react';
import * as R from 'ramda';
// components
import { EmptyList } from '../../../components/list';
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, StickedBox } from '../../../ui';
// feature
import InvoiceDetails from './invoice-details';
//////////////////////////////////////////////////

const renderInvoices = (props: Object) => {
  if (G.isNilOrEmpty(props.data)) {
    return (
      <EmptyList width='100%'>
        {G.getWindowLocale('titles:empty-list', 'Empty List')}
      </EmptyList>
    );
  }
  return props.data.map((invoice: Object, i: number) => (
    <InvoiceDetails
      {...invoice}
      closeModal={props.closeModal}
      documentTypes={props.parentProps.documentTypes}
      key={`master-invoice-invoice-${invoice.guid}-${i}`}
      actionsDisabled={R.pathOr(false, ['actionsDisabled'], props)}
      remove={(
        invoiceGuid: string,
        invoiceNumber: string,
      ) => props.parentProps.removeInvoice(
        invoiceGuid,
        invoiceNumber,
        props.parentProps.guid,
      )}
      edit={() => props.parentProps.editInvoice(invoice, props.parentProps.guid)}
      addDocument={() => props.parentProps.addDocument(invoice, props.parentProps.guid)}
    />
  ));
};

const MasterInvoiceInvoices = (props: Object) => (
  <StickedBox
    left='0px'
    display='inline-block'
    bg={G.getTheme('colors.white')}
    width={props.parentProps.detailsWidth}
  >
    <LocalLoader localLoaderOpen={props.parentProps.detailsLoading}>
      <Flex
        p={15}
        width='100%'
        flexWrap='wrap'
        border='1px solid'
        alignItems='baseline'
        borderColor={G.getTheme('tables.rows.borderColor')}
      >
        {renderInvoices(props)}
      </Flex>
    </LocalLoader>
  </StickedBox>
);

export default MasterInvoiceInvoices;
