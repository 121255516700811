import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const setFilterProps = createAction('setFilterProps');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const qbIIFImportRequest = createAction('qbIIFImportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const updateReportRequest = createAction('updateReportRequest');
export const createReportRequest = createAction('createReportRequest');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const printByReportRequest = createAction('printByReportRequest');
export const getXMLByReportRequest = createAction('getXMLByReportRequest');
export const changeInvoicesRequest = createAction('changeInvoicesRequest');
export const changeInvoicesSuccess = createAction('changeInvoicesSuccess');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const getCarrierInvoiceDetails = createAction('getCarrierInvoiceDetails');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const printCarrierInvoiceRequest = createAction('printCarrierInvoiceRequest');
export const toggleCarrierInvoiceDetails = createAction('toggleCarrierInvoiceDetails');
export const deleteCarrierInvoicesRequest = createAction('deleteCarrierInvoicesRequest');
export const getCarrierInvoiceDetailsError = createAction('getCarrierInvoiceDetailsError');
export const updateCIReconciliationRequest = createAction('updateCIReconciliationRequest');
export const updateCIReconciliationSuccess = createAction('updateCIReconciliationSuccess');
export const getCarrierInvoiceDetailsSuccess = createAction('getCarrierInvoiceDetailsSuccess');
export const collapseAndResetCarrierInvoices = createAction('collapseAndResetCarrierInvoices');
export const setCarrierInvoiceDetailsLoading = createAction('setCarrierInvoiceDetailsLoading');
export const getCarrierInvoiceStatusesSuccess = createAction('getCarrierInvoiceStatusesSuccess');
export const approveOrRejectCarrierInvoiceRequest = createAction('approveOrRejectCarrierInvoiceRequest');
export const approveOrRejectCarrierInvoiceSuccess = createAction('approveOrRejectCarrierInvoiceSuccess');
export const exportCarrierInvoiceToQuickBooksRequest = createAction('exportCarrierInvoiceToQuickBooksRequest');
