import { $all, $set } from 'plow-js';
import { createReducer } from 'redux-act';
// constants
import * as GC from '../../constants';
// feature locale
import * as A from './actions';
// import { LOCALE_DE, LOCALE_EN } from './constants';
//////////////////////////////////////////////////

const initialState = {
  currentLng: GC.LANG_TYPE_ENGLISH,
  changeLngPending: false,
  initialLoadPending: true,
  lngs: {
    [GC.LANG_TYPE_ENGLISH]: {},
    [GC.LANG_TYPE_GERMAN]: null,
  },
};

const changeLngFail = (state: Object) => (
  $set('changeLngPending', false, state)
);

const initialLoadLngFail = (state: Object) => (
  $set('initialLoadPending', false, state)
);

const changeLngRequest = (state: Object) => (
  $set('changeLngPending', true, state)
);

const initialLoadLngRequest = (state: Object) => (
  $set('initialLoadPending', true, state)
);

const changeLngSuccess = (state: Object, payload: Object) => (
  $all(
    $set('changeLngPending', false),
    $set('currentLng', payload.lng),
    $set(`lngs.${payload.lng}`, payload.data[payload.lng]),
    state,
  )
);

const initialLoadLngSuccess = (state: Object, payload: Object) => (
  $all(
    $set('initialLoadPending', false),
    $set('currentLng', payload.lng),
    $set(`lngs.${payload.lng}`, payload.data[payload.lng]),
    state,
  )
);

export default createReducer({
  [A.changeLngFail]: changeLngFail,
  [A.changeLngRequest]: changeLngRequest,
  [A.changeLngSuccess]: changeLngSuccess,
  [A.initialLoadLngFail]: initialLoadLngFail,
  [A.initialLoadLngRequest]: initialLoadLngRequest,
  [A.initialLoadLngSuccess]: initialLoadLngSuccess,
}, initialState);
