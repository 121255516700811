import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature mail-sending
import * as A from './actions';
//////////////////////////////////////////////////

export function* handleGetMailSendingSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.mailSending;
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMailSendingSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetMailSendingSaga fail', false);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetMailSendingSaga exception');
  }
}

export function* handleCreateMailSendingSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.mailSending, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createMailSendingSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateMailSendingSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateMailSendingSaga exception');
  }
}

export function* handleUpdateMailSendingSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'put', endpointsMap.mailSending, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateMailSendingSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateMailSendingSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateMailSendingSaga exception');
  }
}

export function* handleUploadMailSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.getMailGuidEndpoint(payload);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMailSendingSuccess(payload));
      FileDownload(data, 'mail.eml');
    } else {
      yield call(G.handleFailResponse, res, 'handleUploadMailSaga fail', false);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUploadMailSaga exception');
  }
}

function* handleGetContactListRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: R.or(payload, branchGuid) },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.contactBookList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const contactList = R.map(
        ({ email, lastName, firstName }: Object) => ({
          [GC.FIELD_EMAIL]: email,
          [GC.FIELD_LAST_NAME]: R.or(lastName, []),
          [GC.FIELD_FIRST_NAME]: R.or(firstName, []),
        }),
        R.or(data, []),
      );
      yield put(A.receivedContactListSuccess(contactList));
    } else {
      yield call(G.handleException, 'error', 'handleGetContactListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetContactListRequest exception');
  }
}

export function* MailSendingWatcherSaga() {
  yield takeLatest(A.uploadMailSendingRequest, handleUploadMailSaga);
  yield takeLatest(A.getMailSendingRequest, handleGetMailSendingSaga);
  yield takeLatest(A.getContactListRequest, handleGetContactListRequest);
  yield takeLatest(A.createMailSendingRequest, handleCreateMailSendingSaga);
  yield takeLatest(A.updateMailSendingRequest, handleUpdateMailSendingSaga);
}

export default MailSendingWatcherSaga;
