import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
// features
import PC from '../../permission/role-permission';
// feature load-board
import LBResultsList from './lb-results-list';
import { deleteBookedShipmentRequest } from '../actions';
import { bookedShipmentReport } from '../settings/field-settings';
import { bookmarksTableSettings } from '../settings/table-settings';
import { searchResultsColumnSettings } from '../settings/result-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleDeleteBookedShipment: (props: Object) => (shipment: Object) => {
      const { openModal, closeModal, deleteBookedShipmentRequest } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteBookedShipmentRequest(shipment),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:booked-shipment', 'Booked Shipment')}?`,
      });

      openModal(modalContent);
    },
  }),
  pure,
);
const BookedShipments = enhance((props: Object) => {
  const {
    itemList,
    handleDeleteBookedShipment,
  } = props;

  const actionButtons = [
    {
      iconName: 'trash',
      permissions: [PC.ANY],
      action: (guid: string, shipment: Object) => handleDeleteBookedShipment(shipment),
    },
  ];

  const tableSettings = {
    ...bookmarksTableSettings,
    actionButtons,
    withCheckbox: false,
    withLeftActions: true,
  };

  return (
    <LBResultsList
      loading={false}
      itemList={itemList}
      hasSelectable={false}
      report={bookedShipmentReport}
      tableSettings={tableSettings}
      totalCount={R.length(itemList)}
      columnSettings={searchResultsColumnSettings}
    />
  );
});

export default connect(null, {
  openModal,
  closeModal,
  deleteBookedShipmentRequest,
})(BookedShipments);

