import React from 'react';
// feature invoice/carrier
import Invoices from '../components/invoices';
//////////////////////////////////////////////////

export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  allowEditBtn: true,
  tableRowHeight: 40,
  titleRowHeight: 45,
  moreBtnCellWidth: 50,
  allowSelectAll: true,
  expandableItems: true,
  checkBoxCellWidth: 120,
  searchableTitles: true,
  checkBoxCellJustifyContent: 'unset',
  expandedDetailsComponent: (props: Object) => <Invoices {...props} />,
};
