import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  COUNTRY_OPTIONS,
  ITEM_TYPE_OPTIONS,
  ITEM_VOLUME_OPTIONS,
  getFreightClassOptions,
  ITEM_COMMODITY_OPTIONS,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
  TEMPERATURE_SENSOR_OPTIONS,
  ITEM_TEMPERATURE_TYPE_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
// feature item
import { VINLookup, CalculateFreightClass } from '../components/calc-fields';
//////////////////////////////////////////////////

const getFreightClassAdditionalLabelComponent = (item: Object, setFieldValue: Function) => {
  const config = G.getAmousConfigByNameFromWindow(GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR);

  if (G.isNilOrEmpty(config)) return null;

  return <CalculateFreightClass item={item} setFieldValue={setFieldValue} />;
};

export const getFieldValidation = (value: any, form: Object, fields: string) => {
  const filteredValues = R.filter((item: Object) => G.isNotNilAndNotEmpty(item), form);

  return G.ifElse(
    R.and(G.isNilOrEmpty(value), R.any((item: Object) => R.includes(item, R.keys(filteredValues)), fields)),
    G.getRequiredLocaleTxt(),
    undefined,
  );
};

const defaultBaseSectionFields = {
  [GC.FIELD_ITEM_ID]: '',
  [GC.FIELD_ITEM_TYPE]: '',
  [GC.FIELD_ITEM_WEIGHT]: '',
  [GC.FIELD_ITEM_VOLUME]: '',
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_ITEM_QUANTITY]: '',
  [GC.FIELD_ITEM_COMMODITY]: '',
  [GC.FIELD_ORIGIN_OF_GOODS]: '',
  [GC.FIELD_ITEM_VOLUME_UOM]: '',
  [GC.FIELD_ITEM_WEIGHT_TYPE]: '',
  [GC.FIELD_ITEM_PACKAGE_TYPE]: '',
  [GC.FIELD_ITEM_FREIGHT_CLASS]: '',
  [GC.FIELD_ITEM_MONETARY_VALUE]: '',
  [GC.FIELD_ITEM_DECLARED_VALUE]: '',
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: '',
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: '',
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: '',
  [GC.FIELD_ITEM_STACKABILITY]: false,
  [GC.FIELD_ITEM_TEMPERATURE_HIGH]: '',
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: '',
  [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: '',
  [GC.FIELD_ITEM_PICKED_UP_VOLUME]: '',
  [GC.FIELD_ITEM_PICKED_UP_WEIGHT]: '',
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: '',
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: '',
  [GC.FIELD_ITEM_PICKED_UP_QUANTITY]: '',
  [GC.FIELD_ITEM_TEMPERATURE_SENSOR]: '',
  [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: '',
  [GC.FIELD_ITEM_STACKABILITY_CONDITION]: '',
  [GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY]: '',
};

const defaultCommodityDetailsSectionFields = {
  [GC.FIELD_ITEM_N_M_F_C]: '',
  [GC.FIELD_ITEM_S_T_C_C]: '',
  [GC.FIELD_ITEM_E_C_C_N]: '',
  [GC.FIELD_ITEM_COMMODITY_TYPE]: '',
  [GC.FIELD_ITEM_HARMONIZED_TARIFF]: '',
};

const defaultHazmatSectionFields = {
  [GC.FIELD_ITEM_HAZARDOUS]: false,
  [GC.FIELD_ITEM_HAZMAT_CLASS]: '',
  [GC.FIELD_ITEM_HAZMAT_COMMENT]: '',
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT]: '',
  [GC.FIELD_ITEM_HAZMAT_EMS_NUMBER]: '',
  [GC.FIELD_ITEM_HAZMAT_PLACARDS]: false,
  [GC.FIELD_ITEM_HAZMAT_CONTACT_NAME]: '',
  [GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME]: '',
  [GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE]: '',
  [GC.FIELD_ITEM_HAZMAT_PACKAGE_GROUP]: '',
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM]: '',
  [GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER]: '',
  [GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS]: '',
};

const defaultCarHaulerSectionFields = {
  [GC.FIELD_BAY]: '',
  [GC.FIELD_VIN]: '',
  [GC.FIELD_YEAR]: '',
  [GC.FIELD_MAKE]: '',
  [GC.FIELD_MODEL]: '',
  [GC.FIELD_COLOR]: '',
  [GC.FIELD_ODOMETER]: '',
  [GC.FIELD_WHEELBASE]: '',
  [GC.FIELD_SUB_MODEL]: '',
  [GC.FIELD_ODOMETER_UOM]: '',
  [GC.FIELD_ORIGIN_STATE]: '',
  [GC.FIELD_MANUFACTURER]: '',
  [GC.FIELD_WHEELBASE_UOM]: '',
  [GC.FIELD_LICENSE_PLATE]: '',
  [GC.FIELD_ORIGIN_COUNTRY]: '',
  [GC.FIELD_OPERATIONAL]: false,
};

export const getDefaultFields = () => {
  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  const defaultFields = {
    ...defaultBaseSectionFields,
    ...defaultHazmatSectionFields,
    ...defaultCarHaulerSectionFields,
    ...defaultCommodityDetailsSectionFields,
  };

  if (G.isTrue(useContainers)) return R.assoc([GC.FIELD_CONTAINER_INTERNAL_ID], '', defaultFields);

  return defaultFields;
};

const isRequiredField = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const isPositiveNumber = Yup.number()
  .nullable(true)
  .positive(G.getShouldBeIntegerLocaleTxt())
  .typeError(G.getShouldBeIntegerLocaleTxt());

const isIntegerAndPositiveNumber = Yup.number()
  .nullable(true)
  .integer(G.getShouldBeIntegerLocaleTxt())
  .positive(G.getShouldBeIntegerLocaleTxt())
  .typeError(G.getShouldBeIntegerLocaleTxt());

const isIntegerNumber = Yup.number()
  .nullable(true)
  .integer(G.getShouldBeIntegerLocaleTxt())
  .typeError(G.getShouldBeIntegerLocaleTxt());

const isStringWithMin2Max40 = Yup.string()
  .nullable(true)
  .min(2, G.getShouldBeFromToLocaleTxt(2, 40))
  .max(40, G.getShouldBeFromToLocaleTxt(2, 40));

const isStringWithMin3Max250 = Yup.string()
  .nullable(true)
  .min(3, G.getShouldBeFromToLocaleTxt(3, 250))
  .max(250, G.getShouldBeFromToLocaleTxt(3, 250));

export const getBaseSectionValidationSchema = (values: Object) => {
  const {
    volume,
    quantity,
    dimensionsW,
    dimensionsH,
    dimensionsL,
    monetaryValue,
    declaredValue,
    temperatureLow,
    temperatureHigh,
  } = values;

  let schema = {
    [GC.FIELD_ITEM_TYPE]: isRequiredField,
    [GC.FIELD_ITEM_VOLUME]: isPositiveNumber,
    [GC.FIELD_ITEM_WEIGHT_TYPE]: isRequiredField,
    [GC.FIELD_DESCRIPTION]: isStringWithMin3Max250,
    [GC.FIELD_ITEM_MONETARY_VALUE]: isPositiveNumber,
    [GC.FIELD_ITEM_DECLARED_VALUE]: isPositiveNumber,
    [GC.FIELD_ITEM_TEMPERATURE_LOW]: isIntegerNumber,
    [GC.FIELD_ITEM_TEMPERATURE_HIGH]: isIntegerNumber,
    [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: isPositiveNumber,
    [GC.FIELD_ITEM_PICKED_UP_WEIGHT]: isPositiveNumber,
    [GC.FIELD_ITEM_PICKED_UP_VOLUME]: isPositiveNumber,
    [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: isPositiveNumber,
    [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: isPositiveNumber,
    [GC.FIELD_ITEM_QUANTITY]: isIntegerAndPositiveNumber,
    [GC.FIELD_ITEM_STACKABILITY_CONDITION]: isStringWithMin3Max250,
    [GC.FIELD_ITEM_PICKED_UP_QUANTITY]: isIntegerAndPositiveNumber,
    [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: Yup.string()
      .nullable(true)
      .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
      .max(40, G.getShouldBeFromToLocaleTxt(3, 40)),
    [GC.FIELD_ITEM_WEIGHT]: Yup.number()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt()),
    [GC.FIELD_ITEM_ID]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
      .max(40, G.getShouldBeFromToLocaleTxt(3, 40)),
  };

  if (G.isNotNilAndNotEmpty(quantity)) {
    schema = R.assoc(GC.FIELD_ITEM_PACKAGE_TYPE, isRequiredField, schema);
  }

  if (G.isNotNilAndNotEmpty(volume)) {
    schema = R.assoc(GC.FIELD_ITEM_VOLUME_UOM, isRequiredField, schema);
  }

  if (G.isNotNilAndNotEmpty(declaredValue)) {
    schema = R.assoc(GC.FIELD_ITEM_DECLARED_CURRENCY, isRequiredField, schema);
  }

  if (G.isNotNilAndNotEmpty(monetaryValue)) {
    schema = R.assoc(GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY, isRequiredField, schema);
  }

  if (G.isOneNotNilOrNotEmpty([temperatureLow, temperatureHigh])) {
    schema = R.assoc(GC.FIELD_ITEM_TEMPERATURE_UOM, isRequiredField, schema);
  }

  if (G.isOneNotNilOrNotEmpty([dimensionsW, dimensionsH, dimensionsL])) {
    schema = R.assoc(GC.FIELD_ITEM_DIMENSIONS_UOM, isRequiredField, schema);
  }

  return schema;
};

export const commodityDetailsValidationSchema = {
  [GC.FIELD_ITEM_N_M_F_C]: isStringWithMin2Max40,
  [GC.FIELD_ITEM_S_T_C_C]: isStringWithMin2Max40,
  [GC.FIELD_ITEM_HARMONIZED_TARIFF]: isStringWithMin2Max40,
};

export const getHazmatSectionValidationSchema = ({ hazmatFlashpoint }: Object) => {
  let schema = {
    [GC.FIELD_ITEM_HAZMAT_FLASHPOINT]: isIntegerNumber,
    [GC.FIELD_ITEM_HAZMAT_COMMENT]: isStringWithMin3Max250,
    [GC.FIELD_ITEM_HAZMAT_EMS_NUMBER]: isStringWithMin2Max40,
    [GC.FIELD_ITEM_HAZMAT_CONTACT_NAME]: isStringWithMin2Max40,
    [GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME]: isStringWithMin2Max40,
    [GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER]: isStringWithMin2Max40,
    [GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE]: Yup.string()
      .nullable(true)
      .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
    [GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS]: Yup.string()
      .nullable(true)
      .min(2, G.getShouldBeFromToLocaleTxt(2, 100))
      .max(100, G.getShouldBeFromToLocaleTxt(2, 100)),
  };

  if (G.isNotNilAndNotEmpty(hazmatFlashpoint)) {
    schema = R.assoc(GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM, isRequiredField, schema);
  }

  return schema;
};

export const getCarHaulerSectionValidationSchema = ({ itemType }: Object) => {
  let schema = {
    [GC.FIELD_YEAR]: Yup.number()
      .nullable(true)
      .integer(G.getShouldBeIntegerLocaleTxt())
      .positive(G.getShouldBeIntegerLocaleTxt())
      .typeError(G.getShouldBeIntegerLocaleTxt())
      .max(2100, G.getShouldBeLessLocaleTxt(2100))
      .min(1900, G.getShouldBeBiggerLocaleTxt(1900)),
    [GC.FIELD_ODOMETER]: isIntegerAndPositiveNumber,
  };

  if (R.equals(itemType, GC.ITEM_TYPE_VEHICLE)) {
    schema = R.assoc(GC.FIELD_VIN, isRequiredField, schema);
  }

  return schema;
};

export const getItemStatusField = (active: boolean) => [
  {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: GC.FIELD_ACTIVE,
    label: G.ifElse(active, ['titles:active', 'Active'], ['titles:inactive', 'Inactive']),
  },
];

export const containerField = [
  {
    type: 'select',
    options: 'containerOptions',
    inputWrapperStyles: { width: 200 },
    label: ['titles:container', 'Container'],
    fieldName: GC.FIELD_CONTAINER_INTERNAL_ID,
  },
];

const inputWrapperStyles = {
  mb: 25,
  mr: 20,
  flexGrow: 1,
  flexBasis: 180,
};

export const getFirstBaseSectionFields = (item: Object, setFieldValue: Function) => [
  {
    type: 'select',
    isRequired: true,
    options: ITEM_TYPE_OPTIONS,
    fieldName: GC.FIELD_ITEM_TYPE,
    label: ['titles:item-type', 'Item Type'],
    inputWrapperStyles: { mb: 25, mr: 20, width: 100 },
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_ID,
    label: ['titles:item-id', 'Item ID'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles: { mb: 25 },
    fieldName: GC.FIELD_ITEM_WEIGHT,
    label: ['titles:weight', 'Weight'],
    inputStyles: { width: 110, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'select',
    fieldName: GC.FIELD_ITEM_WEIGHT_TYPE,
    inputWrapperStyles: { mr: 20, mb: 25 },
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    inputStyles: { width: 110, borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'select',
    options: getFreightClassOptions(),
    fieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
    label: ['titles:freight-class', 'Freight Class'],
    inputWrapperStyles: { mb: 25, mr: 20, width: 140 },
    additionalLabelComponent: getFreightClassAdditionalLabelComponent(item, setFieldValue),
  },
  {
    type: 'select',
    fieldName: GC.FIELD_ORIGIN_OF_GOODS,
    inputWrapperStyles: { mb: 25, mr: 0, width: 140 },
    label: ['titles:origin-of-goods', 'Origin Of Goods'],
    options: G.prependEmptyLabelValueOption(COUNTRY_OPTIONS),
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_QUANTITY,
    label: ['titles:package-quantity', 'Package Quantity'],
    inputStyles: { width: 100, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    fieldName: GC.FIELD_ITEM_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    inputStyles: { width: 100, borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'select',
    options: ITEM_COMMODITY_OPTIONS,
    fieldName: GC.FIELD_ITEM_COMMODITY,
    label: ['titles:commodity', 'Commodity'],
    inputWrapperStyles: { ...inputWrapperStyles, mb: 0},
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_VOLUME,
    label: ['titles:volume', 'Volume'],
    inputStyles: { width: 110, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'select',
    options: ITEM_VOLUME_OPTIONS,
    inputWrapperStyles: { mr: 20 },
    fieldName: GC.FIELD_ITEM_VOLUME_UOM,
    inputStyles: { width: 110, borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_MONETARY_VALUE,
    label: ['titles:monetary-value', 'Monetary Value'],
    inputStyles: { width: 110, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'select',
    options: GC.CURRENCY_OPTIONS_2,
    fieldName: GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY,
    inputStyles: { width: 95, borderRadius: '0 4px 4px 0' },
  },
];

export const secondBaseSectionFields = [
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_DECLARED_VALUE,
    label: ['titles:declared-value', 'Declared Value'],
    inputStyles: { width: 95, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'select',
    options: GC.CURRENCY_OPTIONS_2,
    inputWrapperStyles: { mr: 20 },
    fieldName: GC.FIELD_ITEM_DECLARED_CURRENCY,
    inputStyles: { width: 75, borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
    label: ['titles:customer-part-num', 'Customer Part Num'],
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 105 },
  },
  {
    type: 'select',
    options: TEMPERATURE_SENSOR_OPTIONS,
    fieldName: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
    label: ['titles:temperature-sensor', 'Temperature Sensor'],
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 105 },
  },
  {
    type: 'select',
    options: ITEM_TEMPERATURE_TYPE_OPTIONS,
    fieldName: GC.FIELD_ITEM_TEMPERATURE_TYPE,
    label: ['titles:temperature-type', 'Temperature Type'],
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 100 },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
    placeholder: G.getWindowLocaleArr('titles:from', 'From'),
    inputStyles: { width: 105, borderRadius: '4px 0 0 4px' },
    label: ['titles:temperature-range', 'Temperature Range'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_TEMPERATURE_HIGH,
    inputStyles: { width: 105, borderRadius: '0' },
    placeholder: G.getWindowLocaleArr('titles:to', 'To'),
  },
  {
    type: 'select',
    options: ITEM_TEMPERATURE_OPTIONS,
    fieldName: GC.FIELD_ITEM_TEMPERATURE_UOM,
    inputStyles: { width: 100, borderRadius: '0 4px 4px 0' },
  },
];

export const thirdBaseSectionFields = [
  {
    type: 'number',
    placeholder: 'L',
    fieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
    label: ['titles:dimensions', 'Dimensions'],
    inputStyles: { width: 100, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'number',
    placeholder: 'W',
    fieldName: GC.FIELD_ITEM_DIMENSIONS_WIDTH,
    inputStyles: { width: 100, borderRadius: '0' },
  },
  {
    type: 'number',
    placeholder: 'H',
    fieldName: GC.FIELD_ITEM_DIMENSIONS_HEIGHT,
    inputStyles: { width: 100, borderRadius: '0' },
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_ITEM_DIMENSIONS_UOM,
    inputStyles: { width: 105, borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_PICKED_UP_WEIGHT,
    label: ['titles:actual-weight', 'Actual Weight'],
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 130 },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_PICKED_UP_QUANTITY,
    label: ['titles:actual-quantity', 'Actual Quantity'],
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 130 },
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_PICKED_UP_VOLUME,
    label: ['titles:actual-volume', 'Actual Volume'],
    inputWrapperStyles: { flexGrow: 1, flexBasis: 130 },
  },
];

export const fourthBaseSectionFields = [
  {
    type: 'toggle',
    fieldName: GC.FIELD_ITEM_STACKABILITY,
    inputWrapperStyles: { mr: 20, width: 105 },
    label: ['titles:stackability', 'Stackability'],
  },
  {
    type: 'textarea',
    inputWrapperStyles: { mr: 20, width: 280 },
    fieldName: GC.FIELD_ITEM_STACKABILITY_CONDITION,
    label: ['titles:stackability-condition', 'Stackability Condition'],
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: { flexGrow: 1, flexBasis: 425 },
  },
];

const commodityInputWrapperStyles = {
  mr: 20,
  flexGrow: 1,
  flexBasis: 150,
};

export const commodityDetailsSectionFields = [
  {
    type: 'text',
    label: ['titles:nmfc', 'NMFC'],
    fieldName: GC.FIELD_ITEM_N_M_F_C,
    inputWrapperStyles: commodityInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:stcc', 'STCC'],
    fieldName: GC.FIELD_ITEM_S_T_C_C,
    inputWrapperStyles: commodityInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ITEM_HARMONIZED_TARIFF,
    inputWrapperStyles: commodityInputWrapperStyles,
    label: ['titles:harmonized-tariff', 'Harmonized Tariff'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_ITEM_COMMODITY_TYPE,
    label: ['titles:domestic-foreign-military', 'Domestic/Foreign/Military'],
    options: [
      { name: '', value: '' },
      { name: 'Domestic', value: 'Domestic' },
      { name: 'Foreign', value: 'Foreign' },
      { name: 'Military', value: 'Military' },
    ],
    inputWrapperStyles: commodityInputWrapperStyles,
  },
  {
    options: [''],
    type: 'select',
    label: ['titles:eccn', 'ECCN'],
    fieldName: GC.FIELD_ITEM_E_C_C_N,
    inputWrapperStyles: { ...commodityInputWrapperStyles, mr: 0 },
  },
];

export const hazmatSectionFields = [
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_HAZARDOUS,
    label: ['titles:hazardous', 'Hazardous'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER,
    label: ['titles:hazmat-un-or-na-number', 'HazMat UN or NA Number'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_HAZMAT_PACKAGE_GROUP,
    label: ['titles:hazmat-package-group', 'HazMat Package Group'],
    options: [
      { label: '', value: '' },
      { label: 'I', value: 'I' },
      { label: 'II', value: 'II' },
      { label: 'III', value: 'III' },
      { label: 'N/A', value: 'N/A' },
    ],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_ITEM_HAZMAT_CLASS,
    label: ['titles:hazmat-class', 'HazMat Class'],
    inputWrapperStyles: { ...inputWrapperStyles, mr: 0 },
    options: [
      {
        value: '',
        label: '',
      },
      {
        value: '1 - Explosives',
        label: '1 - Explosives',
      },
      {
        value: '1.1 - Explosives_Mass explosion hazard',
        label: '1.1 - Explosives_Mass explosion hazard',
      },
      {
        value: '1.2 - Explosives_Projection hazard',
        label: '1.2 - Explosives_Projection hazard',
      },
      {
        value: '1.3 - Explosives_Fire, minor blast, andor projection hazard',
        label: '1.3 - Explosives_Fire, minor blast, andor projection hazard',
      },
      {
        value: '1.4 - Explosives_No significant hazard',
        label: '1.4 - Explosives_No significant hazard',
      },
      {
        value: '1.5 - Explosives_Insensitive material with mass explosion hazard',
        label: '1.5 - Explosives_Insensitive material with mass explosion hazard',
      },
      {
        value: '1.6 - Explosives_Insenitive articles with no mass explosion hazard',
        label: '1.6 - Explosives_Insenitive articles with no mass explosion hazard',
      },
      {
        value: '2 - Gases',
        label: '2 - Gases',
      },
      {
        value: '2.1 - Gases_Flammable Gas',
        label: '2.1 - Gases_Flammable Gas',
      },
      {
        value: '2.2 - Gases_Non-flammable non poisonous',
        label: '2.2 - Gases_Non-flammable non poisonous',
      },
      {
        value: '2.3 - Gases_Poisonous or toxic gas',
        label: '2.3 - Gases_Poisonous or toxic gas',
      },
      {
        value: '3 - Flammable and Combustible Liquids',
        label: '3 - Flammable and Combustible Liquids',
      },
      {
        value: '4 - Flammable Solids',
        label: '4 - Flammable Solids',
      },
      {
        value: '4.1 - Flammable Solids_Flammable Solid',
        label: '4.1 - Flammable Solids_Flammable Solid',
      },
      {
        value: '4.2 - Flammable Solids_Spontaneously combustible material',
        label: '4.2 - Flammable Solids_Spontaneously combustible material',
      },
      {
        value: '4.3 - Flammable Solids_Dangerous when wet material',
        label: '4.3 - Flammable Solids_Dangerous when wet material',
      },
      {
        value: '5 - Oxidizers and Organic Peroxides',
        label: '5 - Oxidizers and Organic Peroxides',
      },
      {
        value: '5.1 - Oxidizers and Organic Peroxides_Oxidizer',
        label: '5.1 - Oxidizers and Organic Peroxides_Oxidizer',
      },
      {
        value: '5.2 - Oxidizers and Organic Peroxides_Organic Peroxide',
        label: '5.2 - Oxidizers and Organic Peroxides_Organic Peroxide',
      },
      {
        value: '6 - Poisonous Toxic and Infectious Substances',
        label: '6 - Poisonous Toxic and Infectious Substances',
      },
      {
        value: '6.1 - Poisonous Toxic and Infectious Substances_Poisonus or toxic substances',
        label: '6.1 - Poisonous Toxic and Infectious Substances_Poisonus or toxic substances',
      },
      {
        value: '6.2 - Poisonous Toxic and Infectious Substances_Infectious substances',
        label: '6.2 - Poisonous Toxic and Infectious Substances_Infectious substances',
      },
      {
        value: '7 - Radioactive Materials',
        label: '7 - Radioactive Materials',
      },
      {
        value: '8 - Corrosive Materials',
        label: '8 - Corrosive Materials',
      },
      {
        value: '9 - Miscellaneous Dangerous Substances or Articles',
        label: '9 - Miscellaneous Dangerous Substances or Articles',
      },
    ],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME,
    label: ['titles:hazmat-shipping-name', 'HazMat Shipping Name'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_HAZMAT_CONTACT_NAME,
    label: ['titles:hazmat-contact-name', 'HazMat Contact Name'],
  },
  {
    type: 'phoneNumber',
    inputWrapperStyles,
    fieldName: GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE,
    label: ['titles:hazmat-contact-phone', 'HazMat Contact Phone'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ITEM_HAZMAT_EMS_NUMBER,
    inputWrapperStyles: { ...inputWrapperStyles, mr: 0 },
    label: ['titles:hazmat-ems-number', 'HazMat EMS Number'],
  },
  {
    type: 'toggle',
    fieldName: GC.FIELD_ITEM_HAZMAT_PLACARDS,
    label: ['titles:hazmat-placards', 'HazMat Placards'],
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 180 },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS,
    inputWrapperStyles: { mr: 20, flexGrow: 1, flexBasis: 180 },
    label: ['titles:hazmat-placard-details', 'HazMat Placard Details'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ITEM_HAZMAT_FLASHPOINT,
    inputStyles: { width: 100, borderRadius: '4px 0 0 4px' },
    label: ['titles:hazmat-flashpoint', 'HazMat Flashpoint'],
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    options: ITEM_TEMPERATURE_OPTIONS,
    fieldName: GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM,
    inputStyles: { width: 100, borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ITEM_HAZMAT_COMMENT,
    inputWrapperStyles: { flexGrow: 1, flexBasis: 180 },
    label: ['titles:hazmat-comments', 'Hazmat Comments'],
  },
];

const carHaulerInputWrapperStyles = {
  mb: 25,
  mr: 20,
  flexGrow: 1,
  flexBasis: 200,
};

export const getCarHaulerSectionFields = (item: Object, updateItemDetails: Function) => [
  {
    type: 'text',
    fieldName: GC.FIELD_VIN,
    label: ['titles:vin', 'VIN'],
    isRequired: G.isItemTypeVehicle(item),
    inputWrapperStyles: carHaulerInputWrapperStyles,
    additionalLabelComponent:
      G.isItemTypeVehicle(item) && <VINLookup item={item} updateItemDetails={updateItemDetails} />,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_MANUFACTURER,
    label: ['titles:manufacturer', 'Manufacturer'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_YEAR,
    label: ['titles:year', 'Year'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_MAKE,
    label: ['titles:make', 'Make'],
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mr: 0},
  },
  {
    type: 'text',
    fieldName: GC.FIELD_MODEL,
    label: ['titles:model', 'Model'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_SUB_MODEL,
    label: ['titles:sub-model', 'Sub Model'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_COLOR,
    label: ['titles:color', 'Color'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_WHEELBASE,
    label: ['titles:wheelbase', 'Wheelbase'],
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mr: 0},
  },
  {
    type: 'select',
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_WHEELBASE_UOM,
    inputWrapperStyles: carHaulerInputWrapperStyles,
    label: ['titles:wheelbase-uom', 'Wheelbase UOM'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_ODOMETER,
    label: ['titles:odometer', 'Odometer'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'select',
    fieldName: GC.FIELD_ODOMETER_UOM,
    options: ITEM_ODOMETER_UOM_OPTIONS,
    label: ['titles:odometer-uom', 'Odometer UOM'],
    inputWrapperStyles: carHaulerInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_BAY,
    label: ['titles:bay', 'Bay'],
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mr: 0},
  },
  {
    type: 'text',
    fieldName: GC.FIELD_LICENSE_PLATE,
    label: ['titles:license-plate', 'License Plate'],
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mb: 0},
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ORIGIN_STATE,
    label: ['titles:origin-state', 'Origin State'],
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mb: 0},
  },
  {
    type: 'select',
    fieldName: GC.FIELD_ORIGIN_COUNTRY,
    label: ['titles:origin-country', 'Origin Country'],
    options: G.prependEmptyLabelValueOption(COUNTRY_OPTIONS),
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mb: 0},
  },
  {
    type: 'toggle',
    fieldName: GC.FIELD_OPERATIONAL,
    label: ['titles:operational', 'Operational'],
    inputWrapperStyles: { ...carHaulerInputWrapperStyles, mr: 0, mb: 0},
  },
];
