import { createLogic } from 'redux-logic';
// common
import { globalCleanReports } from '../../common/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// feature invoice/carrier
import { setInitialState as setDriverInvoiceInitialState } from './driver/actions';
import { setInitialState as setVendorInvoiceInitialState } from './vendor/actions';
import { setInitialState as setCustomerInvoiceInitialState } from './customer/actions';
//////////////////////////////////////////////////

const handleSwitchBranchLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    receivedSwitchBranchSuccess,
  ],
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setVendorInvoiceInitialState());
    dispatch(setDriverInvoiceInitialState());
    dispatch(setCustomerInvoiceInitialState());
    done();
  },
});

export default [handleSwitchBranchLogic];
