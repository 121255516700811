import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withProps, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { LocalLoader } from '../../../components/local-loader';
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { ChargesTotal } from '../../../components/charge/components';
import { DMChargeComponent } from '../../../components/charge/formik/charges-array';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
import { getIsPriceSheetItemFieldsEditable } from '../../../components/price-sheet-items/settings';
import { withAsyncChargeAccessorials } from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import { GeneralSectionForTel } from '../../rate/sections';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { getChargesFromValues, getChargesArrayNameFromProps } from '../../rate/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncSequence,
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
  withAsyncGetFactoringPaymentTerm,
  withAsyncGetGLCodeMappingsByScope,
} from '../../../hocs';
// schemes
import * as VS from '../../../schemes';
// ui
import { Box, Flex, StickedBox, SectionsDivider } from '../../../ui';
// feature invoice
import PaymentsArray from './payments-array';
import ReferencesArray from './references-array';
import { RemitToSection } from './header-section';
import { FleetInvoiceSection } from './invoice-section';
import { carrierInfoFields } from '../settings/fields-settings';
import { validationSchemaReferences } from '../settings/common-fields';
import {
  setOptionsForSelect,
  getTotalsFromValues,
  setDefaultInvoiceValues,
  getDataWithTransformedReferences,
} from '../helpers';
//////////////////////////////////////////////////

const configsNamesArray = [
  GC.INVOICE_GL_CODE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_SEQUENCE,
  GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_AUTOGENERATED,
];

const validationSchemaObject = Yup.lazy((values: Object) => Yup.object()
  .shape(validationSchemaReferences)
  .shape(VS.validationSchemaInvoiceObject)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.getValidationSchemaTripTotalObject(values))
  .shape(G.ifElse(getIsPriceSheetItemFieldsEditable(values), VS.validationSchemaPriceSheetItems, {})));

const invoiceFieldsToOmit = [
  GC.FIELD_GUID,
  GC.FIELD_TYPE,
  GC.FIELD_SOURCE,
  GC.FIELD_VERSION,
  GC.FIELD_SELECTED,
  GC.FIELD_TEL_GUID,
  GC.FIELD_LOAD_GUID,
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_PRIMARY_DRIVER_CHARGES,
  GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT,
];

const InvoiceSection = (props: Object) => {
  const {
    values,
    branchGuid,
    isEditMode,
    authorities,
    activeDriver,
    asyncConfigs,
    glCodeMappings,
    totalCustomersRate,
  } = props;

  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const stopsQuantity = R.pathOr('', [GC.FIELD_TEL, GC.FIELD_LOAD_STOPS, 'length'], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  const totalQuantities = {
    [R.path(['values', GC.FIELD_PACKAGE_TYPE], props)]: R.path(['values', GC.FIELD_TOTAL_PICKUP_QUANTITY], props),
  };

  const glCodeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  );

  const chargesArrayName = getChargesArrayNameFromProps(props);

  return (
    <Box width='100%'>
      <FleetInvoiceSection
        {...G.getFormikPropsToFieldset(props)}
        invoiceType='carrier'
        authorities={authorities}
        optionsForSelect={setOptionsForSelect('CarrierIStatusConfig', props)}
      />
      <PaymentsArray {...props} />
      <ReferencesArray {...props} scope={GC.REF_SCOPE_NAME_CARRIER_INVOICE} />
      <PriceSheetItems
        {...G.getFormikProps(props)}
        tableMarginBottom={20}
        editable={getIsPriceSheetItemFieldsEditable(values)}
        additionalFormHeaderSectionStyles={{ my: 0, mb: 20 }}
      />
      <SectionsDivider />
      <GeneralSectionForTel
        {...G.getFormikPropsToFieldset(props)}
        optionsForSelect={setOptionsForSelect('CarrierIStatusConfig', props)}
      />
      <DMChargeComponent
        {...getTotalsFromValues(values)}
        isInvoice={true}
        isCarrier={true}
        rateProps={props}
        currency={currency}
        loadType={GC.FIELD_TEL}
        isEditMode={isEditMode}
        branchGuid={branchGuid}
        activeTab={activeDriver}
        authorities={authorities}
        serviceDays={serviceDays}
        stopsQuantity={stopsQuantity}
        glCodeOptions={glCodeOptions}
        glCodeMappings={glCodeMappings}
        totalQuantities={totalQuantities}
        normalizedTotal={normalizedTotal}
        chargesArrayName={chargesArrayName}
        totalCustomersRate={totalCustomersRate}
      />
    </Box>
  );
};

const CarrierInfo = (props: Object) => (
  <Flex p={20} justifyContent='center' bg={G.getTheme('modal.bgColor')}>
    {
      carrierInfoFields.map(({ field, label }: string, index: number) => {
        const fieldValue = R.pathOr(
          null,
          ['values', GC.FIELD_PREFIX_CARRIER_ASSIGNMENT, GC.FIELD_ROUTE_CARRIER_SNAPSHOT, field],
          props,
        );

        if (R.isNil(fieldValue)) return null;

        return (
          <Box
            p='8px'
            mr={20}
            key={index}
            minWidth={200}
            maxWidth={250}
            borderRadius='8px'
            bg={G.getTheme('colors.dark.lightGrey')}
          >
            <TextComponent
              minWidth='100%'
              maxWidth='100%'
              withEllipsis={true}
              whiteSpace='pre-line'
              wordBreak='break-all'
            >
              <Flex justifyContent='space-between'>
                <Box fontWeight={600} textTransform='uppercase'>
                  {`${G.getWindowLocale(label)}:`}
                </Box>
              </Flex>
              {fieldValue}
            </TextComponent>
          </Box>
        );
      })
    }
  </Flex>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
  availableTypes: makeSelectAvailableReferenceTypesByScope(state),
});

const enhanceCreate = compose(
  connect(mapStateToProps),
  withProps(() => ({
    isCarrier: true,
    isInvoice: true,
    isEditMode: false,
    configsNamesArray,
    sequenceConfigName: GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_SEQUENCE,
    autogenerateConfigName: GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_AUTOGENERATED,
  })),
  withAsyncChargeAccessorials(),
  withState('origSequence', 'setOrigSequence', null),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => ({
      ...setDefaultInvoiceValues(props, true),
      [GC.FIELD_TYPE]: GC.INVOICE_TYPE_CARRIER_INVOICE,
    }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { origSequence, handleSendCarrierInvoice } = props;

      const fieldsToPick = [GC.FIELD_CARRIER_GUID, GC.FIELD_CARRIER_TERMINAL_GUID];

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, G.getPropFromObject(GC.FIELD_TEL_GUID, values)),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const charges = G.mapOmitObjectSystemFields(R.pathOr([], [GC.FIELD_CARRIER_RATE_CHARGES], values));

      const chargesToUse = R.reject(
        R.propEq(true, GC.FIELD_CHARGE_INTERNAL_EXPENSE),
        G.omitEmptyChargesFromCharges(charges),
      );

      const carrierValues = R.pick(fieldsToPick, R.pathOr({}, [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT], values));

      const items = R.map(G.omitObjectSystemFields, R.propOr([], 'items', values));

      const invoice = R.compose(
        R.mergeLeft({ items, references, [GC.FIELD_CHARGES]: chargesToUse }),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        R.omit(invoiceFieldsToOmit),
        G.omitEmptyPaymentsFromData,
      )(values);

      if (R.equals(R.prop(GC.FIELD_INVOICE_NUMBER, invoice), origSequence)) {
        handleSendCarrierInvoice(R.assoc(GC.FIELD_INVOICE_NUMBER, null, R.mergeRight(invoice, carrierValues)));
      } else {
        handleSendCarrierInvoice(R.mergeRight(invoice, carrierValues));
      }
    },
    displayName: 'CARRIER_INVOICE_FORM',
  }),
  withAsyncSequence,
  withPropsOnChange(
    ['asyncSequence'],
    (props: Object) => {
      const { asyncSequence, setFieldValue, setOrigSequence } = props;

      const value = G.ifElse(
        G.isNotNil(asyncSequence),
        asyncSequence,
        props.defaultInvoiceName,
      );

      if (G.isNotNil(asyncSequence)) setOrigSequence(asyncSequence);

      setFieldValue(GC.FIELD_INVOICE_NUMBER, value);
    },
  ),
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetFactoringPaymentTerm,
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_CARRIER),
  pure,
);

const asyncSetDefaultValues = (props: Object) => {
  const { availableTypes, asyncInitialData: { data } } = props;

  if (G.isNilOrEmpty(data)) return {};

  const dataToUse = getDataWithTransformedReferences(data, availableTypes);

  return setDefaultInvoiceValues(R.assoc('initialValues', dataToUse, props), true);
};

const enhanceAsyncUpdate = compose(
  connect(mapStateToProps),
  withAsyncInitialDataOnDidMount,
  withProps(() => ({
    isAsync: true,
    isCarrier: true,
    isInvoice: true,
    isEditMode: true,
    configsNamesArray,
  })),
  withAsyncChargeAccessorials(),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => asyncSetDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendCarrierInvoice } = props;

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, G.getPropFromObject(GC.FIELD_TEL_GUID, values)),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const chargesToUse = G.omitEmptyChargesFromCharges(R.pathOr([], [GC.FIELD_CHARGES], values));

      const invoice = R.compose(
        R.assoc(GC.FIELD_CHARGES, chargesToUse),
        R.assoc(GC.FIELD_REFERENCES, references),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        G.omitEmptyPaymentsFromData,
      )(values);

      handleSendCarrierInvoice(invoice);
    },
    displayName: 'ASYNC_EDIT_CARRIER_INVOICE_FORM',
  }),
  withHandlers({
    updateRemitToRequest: (props: Object) => (values: Object) => {
      const { setFieldValue } = props;

      const initialValues = R.pathOr({}, ['initialValues', GC.SYSTEM_OBJECT_REMIT_TO], props);
      setFieldValue(GC.SYSTEM_OBJECT_REMIT_TO, R.mergeRight(initialValues, values));
    },
  }),
  withAsyncConfigs,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_CARRIER),
  pure,
);

const CarrierInvoiceForm = (props: Object) => {
  const { closeModal, isEditMode, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <StickedBox top='0px' zIndex={1001}>
        <FormGroupTitleMultiple
          mb='0px'
          showArrowToggle={false}
          title={G.getWindowLocale(...G.ifElse(
            isEditMode,
            ['titles:edit-carrier-invoice', 'Edit Carrier Invoice'],
            ['titles:add-carrier-invoice', 'Add Carrier Invoice'],
          ))}
        />
      </StickedBox>
      {G.isFalse(isEditMode) && <CarrierInfo {...props} />}
      {G.isTrue(isEditMode) && <RemitToSection {...props} />}
      <SectionsDivider />
      <InvoiceSection {...props} />
      <StickedBox bottom='0px' zIndex={1001}>
        <ChargesTotal {...props} isInvoice={true} charges={getChargesFromValues(props)} />
        <ChargeFormFooter closeModal={closeModal} />
      </StickedBox>
    </form>
  );
};

export const AsyncEditCarrierInvoiceForm = enhanceAsyncUpdate((props: Object) => {
  const { closeModal, isEditMode, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <StickedBox top='0px' zIndex={1001}>
        <FormGroupTitleMultiple
          mb='0px'
          showArrowToggle={false}
          title={G.getWindowLocale('titles:edit-carrier-invoice', 'Edit Carrier Invoice')}
        />
      </StickedBox>
      {G.isFalse(isEditMode) && <CarrierInfo {...props} />}
      {isEditMode && <RemitToSection {...props} />}
      <SectionsDivider />
      <LocalLoader localLoaderOpen={R.pathOr(true, ['asyncInitialData', 'loading'], props)}>
        <InvoiceSection {...props} />
      </LocalLoader>
      <StickedBox bottom='0px' zIndex={1001}>
        <ChargesTotal {...props} isInvoice={true} charges={getChargesFromValues(props)} />
        <ChargeFormFooter closeModal={closeModal} />
      </StickedBox>
    </form>
  );
});

export default enhanceCreate(CarrierInvoiceForm);
