import * as R from 'ramda';
// components
import { recalculateAllCharges } from '../../components/charge/formik/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature lite-new-do
import { defaultBillToFields, defaultCustomerFields } from './settings';
//////////////////////////////////////////////////

export const makeReqRate = (rate: Object) => {
  if (G.isNilOrEmpty(rate)) return null;

  const rateContact = R.pathOr({}, ['rateContact'], rate);

  const withoutCharges = R.mergeRight(
    R.omit(R.append('rateContact', R.keys(defaultBillToFields)), rate),
    rateContact,
  );

  return G.omitEmptyChargesFromData(withoutCharges, GC.FIELD_CHARGES);
};

export const makeReqBillTo = (rate: Object) => {
  if (G.isNilOrEmpty(rate)) return null;

  const billTo = R.pick(R.keys(defaultBillToFields), rate);

  const locationWithTemplId = G.resetTemplateIdToLocation(billTo);

  return G.resetLocationTypeToLocation(locationWithTemplId);
};

const customerInitFields = G.mapObjectEmptyStringFieldsToNull(defaultCustomerFields);

const getInitialValuesWithWeight = (initialValues: Object, totalWeight: Object) => {
  const { totalTripWeight, totalTripWeightUom } = initialValues;

  const totalTripWeightToUse = R.or(totalTripWeight, R.path([GC.FIELD_TOTAL_TRIP_WEIGHT], totalWeight));
  const totalTripWeightUomToUse = R.or(totalTripWeightUom, R.path([GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], totalWeight));

  const totalWeightObject = {
    [GC.FIELD_TOTAL_TRIP_WEIGHT]: totalTripWeightToUse,
    [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: totalTripWeightUomToUse,
  };

  return R.mergeRight(initialValues, totalWeightObject);
};

const getCustomerFormFields = (props: Object) => {
  const {
    billTo,
    isEditMode,
    defaultOrderAccessorials,
    sharedAccessorialsApplyTo,
    defaultOrderFuelAccessorial,
  } = props;

  if (G.isTrue(isEditMode)) return customerInitFields;

  const allAccessorials = R.concat(R.or(defaultOrderAccessorials, []), R.or(sharedAccessorialsApplyTo, []));

  const fieldsWithCharges = R.assoc(
    GC.FIELD_CHARGES,
    recalculateAllCharges(G.addMainFuelChargeToCharges2(allAccessorials, defaultOrderFuelAccessorial), props),
    customerInitFields,
  );

  return R.mergeRight(fieldsWithCharges, billTo);
};

export const setDefaultCustomerFormValues = (props: Object) => {
  const {
    totalWeight,
    branchConfigs,
    totalDistance,
    initialValues,
    asyncTotalDistance,
  } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const initValues = G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE],
      initialValues,
    );
    const initValuesWithWeight = getInitialValuesWithWeight(initValues, totalWeight);
    const rateInitWithDist = R.mergeRight(customerInitFields, asyncTotalDistance);

    return R.mergeRight(rateInitWithDist, initValuesWithWeight);
  }

  return G.setDefaultFieldsToRateInvoiceAsyncFormik({
    totalWeight,
    formikInitFields: getCustomerFormFields(props),
    defaultUomFields: G.getDefaultUomFields(branchConfigs),
    totalDistance: R.mergeRight(asyncTotalDistance, totalDistance),
    currency: G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs),
    mode: G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, branchConfigs),
    serviceType: G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_SERVICE_TYPE, branchConfigs),
  });
};
