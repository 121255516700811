import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const Text = ({ bg, text, color, textOptions }: Object) => (
  <TextComponent
    {...textOptions}
    bg={G.getTheme(`colors.light.${bg}`)}
    color={G.getTheme(`colors.light.${color}`)}
  >
    {text}
  </TextComponent>
);

export const columnSettings = {
  [`${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`]: {
    width: 200,
    name: 'titles:branch',
  },
  [`${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`]: {
    width: 200,
    name: 'titles:account-number',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_STATUS]: {
    width: 200,
    name: 'titles:integration-status',
    customComponent: ({ data }: Object) => {
      const status = R.pathOr(' ', [GC.GRC.ACCOUNTING_INTEGRATION_STATUS], data);
      const text = G.getWindowLocale(GC.statusLocaleMap[status], status);
      return (
        <span title={text}>{text}</span>
      );
    },
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:type',
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:status',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:service-type',
  },
  [GC.FIELD_SERVICE_DAYS]: {
    width: 200,
    name: 'titles:service-days',
  },
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: {
    name: 'titles:payment-due-date',
  },
  [GC.FIELD_INVOICE_NET_DAYS]: {
    width: 200,
    name: 'titles:net-days',
  },
  [GC.FIELD_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:invoice-number',
  },
  [GC.GRC.GL_CODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:gl-code',
  },
  [GC.FIELD_INVOICE_DATE]: {
    name: 'titles:invoice-date',
  },
  [GC.GRC.PAYMENTS_DEPOSIT_DATE]: {
    name: 'titles:deposit-date',
  },
  [GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER]: {
    width: 200,
    name: 'titles:ach-payment-confirmation',
  },
  [GC.GRC.PAYMENTS_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.GRC.PAYMENTS_CHECK_DATE]: {
    name: 'titles:check-date',
  },
  [GC.GRC.PAYMENTS_CHECK_AMOUNT]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:check-amount',
  },
  [GC.GRC.REMIT_TO_LOCATION_NAME]: {
    width: 200,
    name: ['titles:remit-to', 'titles:name'],
  },
  [GC.GRC.REMIT_TO_LOCATION_TYPE_DISPLAYED]: {
    width: 200,
    name: ['titles:remit-to', 'titles:type'],
  },
  [GC.GRC.REMIT_TO_COUNTRY]: {
    width: 200,
    name: ['titles:remit-to', 'titles:country'],
  },
  [GC.GRC.REMIT_TO_STATE]: {
    width: 200,
    name: ['titles:remit-to', 'titles:state'],
  },
  [GC.GRC.REMIT_TO_CITY]: {
    width: 200,
    name: ['titles:remit-to', 'titles:city'],
  },
  [GC.GRC.REMIT_TO_ADDRESS1]: {
    width: 200,
    name: ['titles:remit-to', 'titles:address1'],
  },
  [GC.GRC.REMIT_TO_ZIP]: {
    width: 200,
    name: ['titles:remit-to', 'titles:zip'],
  },
  [GC.GRC.REMIT_TO_COMMENTS]: {
    width: 200,
    name: ['titles:remit-to', 'titles:comments'],
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.GRC.TEL_PRIMARY_REFERENCE_NAME]: {
    width: 200,
    name: 'titles:primary-ref-name',
  },
  [GC.GRC.TEL_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    useExpandedContainer: true,
    guidPropName: GC.FIELD_TEL_GUID,
    name: 'titles:primary-ref-value',
    type: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
  },
  [GC.GRC.SERVICE_VENDOR_NAME]: {
    width: 200,
    name: 'titles:service-vendor-name',
  },
  [GC.GRC.TEL_FIRST_EVENT_LATE_DATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:late-date'],
  },
  [GC.GRC.TEL_FIRST_EVENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:early-date'],
  },
  [GC.GRC.TEL_FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.TEL_FIRST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.TEL_FIRST_EVENT_APPOINTMENT_LATE_DATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:appointment-late-date'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:first-event', 'titles:zip'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:first-event', 'titles:city'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:state'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:first-event', 'titles:country'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:first-event', 'titles:address1'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.TEL_FIRST_EVENT_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:first-event', 'titles:location-type'],
  },
  [GC.GRC.TEL_LAST_EVENT_LATE_DATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:late-date'],
  },
  [GC.GRC.TEL_LAST_EVENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:early-date'],
  },
  [GC.GRC.TEL_LAST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:complete-date'],
  },
  [GC.GRC.TEL_LAST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.TEL_LAST_EVENT_APPOINTMENT_LATE_DATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:appointment-late-date'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:last-event', 'titles:zip'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:last-event', 'titles:city'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:last-event', 'titles:state'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:last-event', 'titles:country'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:last-event', 'titles:address1'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.TEL_LAST_EVENT_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:last-event', 'titles:location-type'],
  },
  // totals
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
    customComponent: ({ data }: Object) => (
      <Text
        bg='blue'
        maxWidth={84}
        color='mainLight'
        withEllipsis={true}
        title={`${data.currency} ${data.total}`}
        text={`${G.getCurrencySymbol(data.currency)} ${G.toFixed(data.total)}`}
        textOptions={{
          p: '4px 6px',
          fontWeight: 700,
          borderRadius: 3,
          width: 'fit-content',
          display: 'inline-block',
        }}
      />
    ),
  },
  [GC.GRC.NORMALIZED_TOTAL_TOTAL]: {
    width: 200,
    name: 'titles:normalized-total',
  },
  [GC.GRC.NORMALIZED_TOTAL_CURRENCY]: {
    width: 200,
    name: 'titles:normalized-currency',
  },
  [GC.GRC.TEL_TOTAL_INCOME]: {
    width: 200,
    name: 'titles:total-trip-income',
  },
  // tel rate
  [GC.GRC.TEL_RATE_TOTAL]: {
    width: 120,
    name: ['titles:rate', 'titles:total'],
  },
  [GC.GRC.TEL_RATE_MAIN_CHARGES_TOTAL]: {
    width: 120,
    name: ['titles:rate', 'titles:linehaul'],
  },
  [GC.GRC.TEL_RATE_FUEL_CHARGES_TOTAL]: {
    width: 120,
    name: ['titles:rate', 'titles:fuel'],
  },
  [GC.GRC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL]: {
    width: 120,
    name: ['titles:rate', 'titles:accessorials'],
  },
  [GC.GRC.TEL_ROUTE_NAME]: {
    width: 150,
    name: 'titles:route-name',
  },
  [GC.FIELD_INTEGRATED_DATE]: {
    name: 'titles:integrated-date',
  },
  [GC.GRC.TEL_CLOS_SALE_PERSONS_ID]: {
    width: 200,
    name: ['titles:sale-persons', 'titles:id'],
  },
  [GC.GRC.TEL_CLOS_SALE_PERSONS_NAME]: {
    width: 200,
    name: ['titles:sale-persons', 'titles:name'],
  },
  [GC.GRC.TEL_BROKER_AGENTS_ID]: {
    width: 200,
    name: ['titles:broker-agents', 'titles:id'],
  },
  [GC.GRC.TEL_BROKER_AGENTS_NAME]: {
    width: 200,
    name: ['titles:broker-agents', 'titles:name'],
  },
  [GC.FIELD_MAIN_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:linehaul',
  },
  [GC.FIELD_DISCOUNT_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:discount',
  },
  [GC.FIELD_FUEL_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:fuel',
  },
  [GC.FIELD_ADDITIONAL_CHARGES_TOTAL]: {
    width: 150,
    name: 'titles:other-accessorials',
  },
  [GC.FIELD_BALANCE]: {
    width: 150,
    name: 'titles:balance',
  },
  [GC.GRC.TEL_CLOS_BRANCH_NAME]: {
    width: 200,
    name: ['titles:clo', 'titles:branch'],
  },
};
