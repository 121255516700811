import * as R from 'ramda';
import React from 'react';
import * as P from 'plow-js';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components';
// features
import { Checkbox, TableRow, TableInner, TableWrapper2 } from '../../payroll/ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, StickedBox, RelativeBox } from '../../../ui';
// feature master-invoice
import * as H from '../helpers';
import GroupTableRow, { TextCell } from './group-table-row';
import { GROUP_BY_NO_GROUP, GROUP_BY_REFERENCE, FIELD_GROUP_BY_REFERENCE_VALUE } from '../constants';
//////////////////////////////////////////////////

const enhance = compose(
  withState(
    'groups',
    'updateGroups',
    (props: Object) => props.initialData,
  ),
  withState('submitValidation', 'setSubmitValidation', false),
  withHandlers({
    handleSelectAll: ({ groups, updateGroups }: Object) => () => (
      updateGroups(H.getGroupsOnToggleSelectAll(groups, G.isAllChecked(R.values(groups))))
    ),
    handleClickMasterInvoiceCheckbox: ({ groups, updateGroups }: Object) => (group: string) => (
      updateGroups(H.getGroupsOnToggleGroupSelect(group, groups))
    ),
    handleToggleMasterInvoiceDetails: (props: Object) => (group: string) => {
      const hasInvoices = G.isNotZero(group.invoicesCount);

      const groupState = R.mergeRight(
        group,
        {
          invoicesError: false,
          expanded: R.not(group.expanded),
          invoicesLoading: R.and(hasInvoices, G.isNilOrEmpty(group.invoices)),
        },
      );
      props.updateGroups(R.assoc(group.groupName, groupState, props.groups));

      if (G.isAllTrue(
        hasInvoices,
        G.isNilOrEmpty(group.invoices),
        R.not(group.expanded),
      )) H.loadInvoices(props, groupState, R.map(({ value }: Object) => value, props.documentTypes));
    },
    handleChangeMasterInvoiceNumber: (props: Object) => (value: string, group: Object) => (
      props.updateGroups(R.assocPath(
        [group.groupName, 'masterInvoiceNumber'],
        value,
        props.groups,
      ))
    ),
    handleChangeMasterInvoiceDateRange: (props: Object) => (dates: string, groupName: string) => (
      props.updateGroups(P.$all(
        P.$set(`${groupName}.dateTo`, dates.dateTo),
        P.$set(`${groupName}.dateFrom`, dates.dateFrom),
        props.groups,
      ))
    ),
    handleClickInvoiceCheckbox: (props: Object) => (group: Object) => (
      props.updateGroups(R.assoc(group.groupName, group, props.groups))
    ),
    handleSave: (props: Object) => () => {
      const { groups, groupBy, reference, closeModal, addMasterInvoices, setSubmitValidation } = props;

      if (R.equals(groups.length, 0)) return closeModal();

      let groupsData = R.compose(
        R.map((group: Object) => R.assoc('groupName', G.getStringTailBySymbol(group.groupName, '-'), group)),
        R.filter(R.prop('selected')),
        R.values,
      )(groups);

      if (R.equals(groupBy, GROUP_BY_REFERENCE)) {
        groupsData = R.map(
          (group: Object) => R.assoc(
            FIELD_GROUP_BY_REFERENCE_VALUE,
            `${G.getPropFromObject(GC.FIELD_NAME, reference)}: ${group.groupName}`,
            group,
          ),
          groupsData,
        );
      }

      setSubmitValidation(true);
      addMasterInvoices(groupsData);
    },
    handleAddDocument: (props: Object) => (invoice: Object, groupName: string) => {
      const callback = () => props.updateGroups(R.assoc(
        groupName,
        R.mergeRight(R.path(['groups', groupName], props), { expanded: false, invoices: null }),
        props.groups,
      ));
      props.addDocument(invoice, callback);
    },
  }),
  lifecycle({
    componentWillUpdate(nextProps: Object) {
      if (G.notEquals(this.props.dates, nextProps.dates)) {
        return this.props.updateGroups(R.map(
          (group: Object) => R.mergeRight(
            group,
            {
              dateTo: nextProps.dates.dateTo,
              dateFrom: nextProps.dates.dateFrom,
            },
          ),
          this.props.groups,
        ));
      }

      if (R.and(
        G.notEquals(this.props.updatedInvoice, nextProps.updatedInvoice),
        G.isNotNil(nextProps.updatedInvoice),
      )) {
        return this.props.updateGroups(H.recalculateGroupInvoices(this.props.groups, nextProps.updatedInvoice));
      }
    },
  }),
  pure,
);

const GroupsWithInvoicesTable = (props: Object) => {
  const {
    groups,
    groupBy,
    reference,
    closeModal,
    branchGuid,
    handleSave,
    documentTypes,
    openFixedPopup,
    closeFixedPopup,
    handleSelectAll,
    submitValidation,
    handleEditInvoice,
    handleAddDocument,
    updateInvoiceRequest,
    handleClickInvoiceCheckbox,
    handleChangeMasterInvoiceNumber,
    handleToggleMasterInvoiceDetails,
    handleClickMasterInvoiceCheckbox,
    handleChangeMasterInvoiceDateRange,
  } = props;

  const groupList = R.values(groups);

  const empty = R.not(R.any(
    (entity: Object) => entity.selected,
    groupList,
  ));

  const {
    invoicesCount,
    masterInvoicesTotal,
    selectedInvoicesCount,
  } = H.getMasterInvoicesTotals(groupList, empty);

  return (
    <RelativeBox width='100%' zIndex='0'>
      <TableWrapper2 maxH='calc(100vh - 500px)'>
        <TableInner width='100%'>
          {
            R.and(G.isNotNilAndNotEmpty(groupList), G.notEquals(groupBy, GROUP_BY_NO_GROUP)) && (
              <TableRow p={12} justifyContent='center' lightGreyBorderBottom={true}>
                <StickedBox
                  left='0px'
                  zIndex={11}
                  bg={G.getTheme('colors.light.mainLight')}
                >
                  <Flex p='0 12px' height='100%'>
                    <Checkbox
                      ml={23}
                      mr={50}
                      type='checkbox'
                      name='select-all'
                      onChange={handleSelectAll}
                      checked={G.isAllChecked(groupList)}
                    />
                    <TextCell
                      text={`${G.getWindowLocale('titles:invoices', 'Invoices')}: ${
                        selectedInvoicesCount} (${invoicesCount})`}
                      textOptions={{
                        width: 120,
                        withEllipsis: true,
                        title: `${G.getWindowLocale('titles:invoices', 'Invoices')}: ${
                          selectedInvoicesCount} (${invoicesCount})`,
                      }}
                    />
                    <TextCell
                      width={180}
                      textOptions={{
                        ml: 15,
                        fontSize: 11,
                        p: '4px 10px',
                        fontWeight: 700,
                        borderRadius: 3,
                        display: 'inline-block',
                        bg: G.getTheme('colors.light.blue'),
                        color: G.getTheme('colors.light.mainLight'),
                      }}
                      text={`${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
                        G.toFixed(masterInvoicesTotal)}`}
                    />
                  </Flex>
                </StickedBox>
              </TableRow>
            )
          }
          {
            groupList.map((group: Object, i: number) => (
              <GroupTableRow
                data={group}
                groupBy={groupBy}
                reference={reference}
                closeModal={closeModal}
                branchGuid={branchGuid}
                autoNumber={group.autoNumber}
                documentTypes={documentTypes}
                key={`add-master-invoice-${i}`}
                addDocument={handleAddDocument}
                openFixedPopup={openFixedPopup}
                closeFixedPopup={closeFixedPopup}
                submitValidation={submitValidation}
                handleEditInvoice={handleEditInvoice}
                toggle={handleToggleMasterInvoiceDetails}
                updateInvoiceRequest={updateInvoiceRequest}
                onNumberChange={handleChangeMasterInvoiceNumber}
                onDateChange={handleChangeMasterInvoiceDateRange}
                onCheckboxClick={handleClickMasterInvoiceCheckbox}
                onInvoiceCheckboxClick={handleClickInvoiceCheckbox}
              />
            ))
          }
        </TableInner>
      </TableWrapper2>
      <FormFooter2
        boxStyles={{ p: 15 }}
        submitDisabled={empty}
        submitAction={G.ifElse(empty, () => {}, handleSave)}
        submitBtnText={G.getWindowLocale('actions:save', 'Save')}
      />
    </RelativeBox>
  );
};

export default enhance(GroupsWithInvoicesTable);
