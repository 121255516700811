// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_VOLUME_OPTIONS,
  getFreightClassOptions,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
  ITEM_TEMPERATURE_TYPE_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_ITEM_ID,
    name: G.getWindowLocale('titles:item-id', 'Item ID'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ACTIVE,
    name: G.getWindowLocale('titles:active', 'Active'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    type: 'selectMultiple',
    options: getFreightClassOptions(),
    value: GC.FIELD_ITEM_FREIGHT_CLASS,
    name: G.getWindowLocale('titles:freight-class', 'Freight Class'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_WEIGHT,
    name: G.getWindowLocale('titles:weight', 'Weight'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_ITEM_WEIGHT_TYPE,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    name: G.getWindowLocale('titles:weight-type', 'Weight Type'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_ITEM_TEMPERATURE_TYPE,
    options: ITEM_TEMPERATURE_TYPE_OPTIONS,
    name: G.getWindowLocale('titles:temperature-type', 'Temperature Type'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
    name: G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_QUANTITY,
    name: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    name: G.getWindowLocale('titles:package-type', 'Package Type'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
    name: G.getWindowLocale('titles:dimensions-length', 'Dimensions Length'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_DIMENSIONS_WIDTH,
    name: G.getWindowLocale('titles:dimensions-width', 'Dimensions Width'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_DIMENSIONS_HEIGHT,
    name: G.getWindowLocale('titles:dimensions-height', 'Dimensions Height'),
  },
  {
    type: 'selectMultiple',
    options: ITEM_DIMENSIONS_OPTIONS,
    value: GC.FIELD_ITEM_DIMENSIONS_UOM,
    name: G.getWindowLocale('titles:dimensions-uom', 'Dimensions UOM'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_DESCRIPTION,
    name: G.getWindowLocale('titles:description', 'Description'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_COMMODITY,
    name: G.getWindowLocale('titles:commodity', 'Commodity'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_MONETARY_VALUE,
    name: G.getWindowLocale('titles:monetary-value', 'Monetary Value'),
  },
  {
    type: 'selectMultiple',
    options: GC.CURRENCY_OPTIONS_2,
    value: GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
    placeholder: {
      text: 'Choose a currency',
      key: 'titles:choose-a-currency',
    },
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_VOLUME,
    name: G.getWindowLocale('titles:volume', 'Volume'),
  },
  {
    type: 'selectMultiple',
    options: ITEM_VOLUME_OPTIONS,
    value: GC.FIELD_ITEM_VOLUME_UOM,
    name: G.getWindowLocale('titles:volume-uom', 'Volume UOM'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_DECLARED_VALUE,
    name: G.getWindowLocale('titles:declared-value', 'Declared Value'),
  },
  {
    type: 'selectMultiple',
    options: GC.CURRENCY_OPTIONS_2,
    value: GC.FIELD_ITEM_DECLARED_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
    placeholder: {
      text: 'Choose a currency',
      key: 'titles:choose-a-currency',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_ORIGIN_OF_GOODS,
    name: G.getWindowLocale('titles:origin-of-goods', 'Origin Of Goods'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ITEM_STACKABILITY,
    name: G.getWindowLocale('titles:stackability', 'Stackability'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_STACKABILITY_CONDITION,
    name: G.getWindowLocale('titles:stackability-condition', 'Stackability Condition'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
    name: G.getWindowLocale('titles:customer-part-num', 'Customer Part Num'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_TEMPERATURE_LOW,
    name: G.getWindowLocale('titles:temperature-minimum', 'Temperature Minimum'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_TEMPERATURE_HIGH,
    name: G.getWindowLocale('titles:temperature-maximum', 'Temperature Maximum'),
  },
  {
    type: 'selectMultiple',
    options: ITEM_TEMPERATURE_OPTIONS,
    value: GC.FIELD_ITEM_TEMPERATURE_UOM,
    name: G.getWindowLocale('titles:temperature-uom', 'Temperature UOM'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_N_M_F_C,
    name: G.getWindowLocale('titles:nmfc', 'NMFC'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_S_T_C_C,
    name: G.getWindowLocale('titles:stcc', 'STCC'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HARMONIZED_TARIFF,
    name: G.getWindowLocale('titles:harmonized-tariff', 'Harmonized Tariff'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_COMMODITY_TYPE,
    name: G.getWindowLocale('titles:domestic-foreign-military', 'Domestic/Foreign/Military:'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_E_C_C_N,
    name: G.getWindowLocale('titles:eccn', 'ECCN'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ITEM_HAZARDOUS,
    name: G.getWindowLocale('titles:hazardous', 'Hazardous'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER,
    name: G.getWindowLocale('titles:hazmat-un-or-na-number', 'HazMat UN or NA Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_PACKAGE_GROUP,
    name: G.getWindowLocale('titles:hazmat-package-group', 'HazMat Package Group'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_CLASS,
    name: G.getWindowLocale('titles:hazmat-class', 'HazMat Class'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME,
    name: G.getWindowLocale('titles:hazmat-shipping-name', 'HazMat Shipping Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_CONTACT_NAME,
    name: G.getWindowLocale('titles:hazmat-contact-name', 'HazMat Contact Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE,
    name: G.getWindowLocale('titles:hazmat-contact-phone', 'HazMat Contact Phone'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_EMS_NUMBER,
    name: G.getWindowLocale('titles:hazmat-ems-number', 'HazMat EMS Number'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ITEM_HAZMAT_PLACARDS,
    name: G.getWindowLocale('titles:hazmat-placards', 'HazMat Placards'),
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS,
    name: G.getWindowLocale('titles:hazmat-placard-details', 'HazMat Placard Details'),
  },
  {
    type: 'number',
    value: GC.FIELD_ITEM_HAZMAT_FLASHPOINT,
    name: G.getWindowLocale('titles:hazmat-flashpoint', 'HazMat Flashpoint'),
  },
  {
    type: 'selectMultiple',
    options: ITEM_TEMPERATURE_OPTIONS,
    value: GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM,
    name: G.getWindowLocale('titles:hazmat-flashpoint-uom', 'HazMat Flashpoint UOM'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_ITEM_HAZMAT_COMMENT,
    name: G.getWindowLocale('titles:hazmat-comments', 'HazMat Comments'),
  },
  {
    type: 'string',
    value: GC.FIELD_MANUFACTURER,
    name: G.getWindowLocale('titles:manufacturer', 'Manufacturer'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_OPERATIONAL,
    name: G.getWindowLocale('titles:operational', 'Operational'),
  },
  {
    type: 'number',
    value: GC.FIELD_YEAR,
    name: G.getWindowLocale('titles:year', 'Year'),
  },
  {
    type: 'string',
    value: GC.FIELD_MAKE,
    name: G.getWindowLocale('titles:make', 'Make'),
  },
  {
    type: 'string',
    value: GC.FIELD_MODEL,
    name: G.getWindowLocale('titles:model', 'Model'),
  },
  {
    type: 'string',
    value: GC.FIELD_SUB_MODEL,
    name: G.getWindowLocale('titles:sub-model', 'Sub Model'),
  },
  {
    type: 'number',
    value: GC.FIELD_WHEELBASE,
    name: G.getWindowLocale('titles:wheelbase', 'Wheelbase'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_WHEELBASE_UOM,
    options: ITEM_DIMENSIONS_OPTIONS,
    name: G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase UOM'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_BAY,
    name: G.getWindowLocale('titles:bay', 'Bay'),
  },
  {
    type: 'number',
    value: GC.FIELD_ODOMETER,
    name: G.getWindowLocale('titles:odometer', 'Odometer'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_ODOMETER_UOM,
    options: ITEM_ODOMETER_UOM_OPTIONS,
    name: G.getWindowLocale('titles:odometer-uom', 'Odometer UOM'),
    placeholder: {
      text: 'Choose a status',
      key: 'titles:choose-a-status',
    },
  },
  {
    type: 'string',
    value: GC.FIELD_COLOR,
    name: G.getWindowLocale('titles:color', 'Color'),
  },
  {
    type: 'string',
    value: GC.FIELD_LICENSE_PLATE,
    name: G.getWindowLocale('titles:license-plate', 'License Plate'),
  },
  {
    type: 'string',
    value: GC.FIELD_VIN,
    name: G.getWindowLocale('titles:vin', 'VIN'),
  },
  {
    type: 'string',
    value: GC.FIELD_ORIGIN_STATE,
    name: G.getWindowLocale('titles:origin-state', 'Origin State'),
  },
  {
    type: 'string',
    value: GC.FIELD_ORIGIN_COUNTRY,
    name: G.getWindowLocale('titles:origin-country', 'Origin Country'),
  },
];
