import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature load-board
import { getUOMs } from './options';
import { getLBDisplayValue } from '../helpers';
import {
  Email,
  Contact,
  Summary,
  TimeFrom,
  Distance,
  PickupDate,
  SeeDetails,
  CompanyName,
  BookingAction,
  BookingStatus,
  FilterColorCell,
  PhoneNumberIdReference,
} from '../components/table-cells';
//////////////////////////////////////////////////

const indexedTruckList = R.indexBy(R.prop(GC.FIELD_VALUE), GC.truckOptions);

export const searchResultsColumnSettings = {
  highlighted: {
    width: 6,
    notResizable: true,
    styles: { padding: '0' },
    customComponent: ({ data: { searchFilterGuid } }: Object) => (
      <FilterColorCell searchFilterGuid={searchFilterGuid} />
    ),
  },
  bookingAction: {
    width: 65,
    customComponent: ({ data }: Object) => <BookingAction data={data} />,
  },
  [GC.FIELD_STATUS]: {
    width: 80,
    name: 'titles:status',
    customComponent: ({ data }: Object) => (
      <BookingStatus data={data} />
    ),
  },
  [GC.FIELD_LOAD_BOARD_EMAIL]: {
    width: 200,
    name: 'titles:email',
    customComponent: ({ data, width }: Object) => {
      const value = R.path(
        [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_CONTACT, GC.FIELD_LOAD_BOARD_EMAIL],
        data,
      );

      return (
        value &&
        <Email data={data.shipment} width={`calc(${width} - 16px)`} />
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE]: {
    width: 65,
    notResizable: true,
    name: 'titles:age',
    customComponent: ({ data }: Object) => (
      <TimeFrom data={R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE], data)} />
    ),
  },
  [GC.FIELD_LOAD_BOARD_COMPANY]: {
    width: 120,
    name: 'titles:company',
    customComponent: ({ data }: Object) => (
      R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMPANY], data) &&
      <CompanyName data={data} />
    ),
  },
  [GC.FIELD_LOAD_BOARD_REFERENCE_ID]: {
    width: 120,
    name: 'titles:load-number',
    customComponent: ({ data, width }: Object) => (
      <PhoneNumberIdReference
        data={data}
        noIcon={true}
        width={`calc(${width} - 16px)`}
        openedFromCell={GC.FIELD_LOAD_BOARD_REFERENCE_ID}
        displayText={R.pathOr(
          '-',
          [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_REFERENCE_ID],
          data,
        )}
      />
    ),
  },
  [GC.FIELD_LOAD_BOARD_PHONE]: {
    width: 120,
    name: 'titles:phone',
    customComponent: ({ data, width }: Object) => {
      const phone = R.pathOr(
        '',
        [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_CONTACT, GC.FIELD_LOAD_BOARD_PHONE],
        data,
      );

      const phoneExt = R.pathOr(
        '',
        [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_CONTACT, GC.FIELD_LOAD_BOARD_PHONE_EXT],
        data,
      );
      const phoneExtText = G.ifElse(G.isNotNilAndNotEmpty(phoneExt), `(${phoneExt})`, '');

      const displayText = `${phone} ${phoneExtText}`;

      return (
        G.isNotNilAndNotEmpty(phone) &&
        <PhoneNumberIdReference
          data={data}
          displayText={displayText}
          width={`calc(${width} - 36px)`}
          openedFromCell={GC.FIELD_LOAD_BOARD_PHONE}
        />
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_DISTANCE]: {
    width: 120,
    name: 'titles:distance',
    customComponent: ({ data, width }: Object) => (
      R.path(
        [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO],
        data,
      ) &&
      <Distance
        data={data}
        width={width}
        openedFromCell={GC.FIELD_LOAD_BOARD_DISTANCE}
        searchFilterId={R.prop('searchFilterGuid', data)}
      />
    ),
  },
  [GC.FIELD_LOAD_BOARD_PICKUP_DATE]: {
    width: 130,
    name: 'titles:pickup-date',
    customComponent: ({ data }: Object) => <PickupDate data={R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data)} />,
  },
  [GC.FIELD_LOAD_BOARD_SOURCE]: {
    width: 160,
    type: 'enum',
    name: 'titles:source',
    customComponent: ({ data, width }: Object) => {
      const displayText = getLBDisplayValue(R.propOr('', GC.FIELD_TYPE, data));

      return (
        <PhoneNumberIdReference
          data={data}
          noIcon={true}
          width={width}
          displayText={displayText}
          openedFromCell={GC.FIELD_LOAD_BOARD_SOURCE}
        />
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_TRUCK]: {
    width: 200,
    name: 'titles:equipment',
    customComponent: ({ data, width }: Object) => {
      const name = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_TRUCK], data);
      const displayValue = R.pathOr(name, [name, 'label'], indexedTruckList);

      return (
        <TextComponent
          width={width}
          withEllipsis={true}
          title={displayValue}
        >
          {displayValue}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD]: {
    width: 120,
    name: 'titles:origin-deadhead',
    customComponent: ({ data }: Object) => {
      const distanceInfo = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO], data);

      return (
        G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD, distanceInfo)) &&
        <Box>
          {
            `${R.prop(GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD, distanceInfo)
            } ${getUOMs(R.prop(GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD_UOM, distanceInfo))}`
          }
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_ORIGIN]: {
    width: 150,
    name: 'titles:origin',
    customComponent: ({ data, width }: Object) => {
      const origin = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_ORIGIN], data);

      return (
        G.isNotNil(origin) &&
        <TextComponent
          withEllipsis={true}
          width={`calc(${width} - 16px)`}
          title={
            `${R.prop(GC.FIELD_CITY, origin)
            }, ${R.prop(GC.FIELD_STATE, origin)}`
          }
        >
          {
            `${R.prop(GC.FIELD_CITY, origin)
            }, ${R.prop(GC.FIELD_STATE, origin)}`
          }
        </TextComponent>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD]: {
    width: 120,
    name: 'titles:destination-deadhead',
    customComponent: ({ data }: Object) => {
      const distanceInfo = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO], data);

      return (
        G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD, distanceInfo)) &&
        <Box>
          {
            `${R.prop(GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD, distanceInfo)
            } ${getUOMs(R.prop(GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD_UOM, distanceInfo))}`
          }
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_DESTINATION]: {
    width: 150,
    name: 'titles:destination',
    customComponent: ({ data, width }: Object) => {
      const destination = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DESTINATION], data);

      return (
        G.isNotNil(destination) &&
        <TextComponent
          withEllipsis={true}
          width={`calc(${width} - 16px)`}
          title={
            `${R.prop(GC.FIELD_CITY, destination)
            }, ${R.prop(GC.FIELD_STATE, destination)}`
          }
        >
          {
            `${R.prop(GC.FIELD_CITY, destination)
            }, ${R.prop(GC.FIELD_STATE, destination)}`
          }
        </TextComponent>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_LENGTH]: {
    width: 120,
    name: 'titles:length',
    customComponent: ({ data }: Object) => {
      const dimensions = R.pathOr({}, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DIMENSIONS], data);

      return (
        G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_LENGTH, dimensions)) &&
        <Box>
          {
            `${R.prop(GC.FIELD_LOAD_BOARD_LENGTH, dimensions)
            } ${getUOMs(R.prop(GC.FIELD_LOAD_BOARD_LENGTH_UOM, dimensions))}`
          }
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_WEIGHT]: {
    width: 120,
    name: 'titles:weight',
    customComponent: ({ data }: Object) => {
      const dimensions = R.pathOr({}, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DIMENSIONS], data);

      return (
        G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_WEIGHT, dimensions)) &&
        <Box>
          {
            `${R.prop(GC.FIELD_LOAD_BOARD_WEIGHT, dimensions)
            } ${getUOMs(R.prop(GC.FIELD_LOAD_BOARD_WEIGHT_UOM, dimensions))}`
          }
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_RATE]: {
    width: 120,
    name: 'titles:pay-rate',
    customComponent: ({ data }: Object) => {
      const rateInfo = R.pathOr({}, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_RATE], data);

      const { rate, rateUnit, rateCurrency } = rateInfo;

      return (
        G.isNotNil(rate) &&
        <Box
          fontWeight='bold'
          title={
            `${G.getWindowLocale('titles:rate-unit', 'Rate Unit')}: ${R.or(rateUnit, '-')}`
          }
        >
          {
            `${G.getCurrencySymbol(rateCurrency)} ${G.toFixed(rate)}`
          }
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE]: {
    width: 120,
    name: 'titles:negotiated-price',
    customComponent: ({ data }: Object) => {
      const rateInfo = R.pathOr({}, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_RATE], data);

      return (
        G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE, rateInfo)) &&
        <Box>
          {
            `${G.toFixed(R.prop(GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE, rateInfo))
            } ${R.prop(GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY, rateInfo)}`
          }
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_CONTACT]: {
    width: 140,
    name: 'titles:contact',
    customComponent: ({ data, width }: Object) => {
      const { type, detailsLoaded } = data;

      const lbTypesNeedDetails = [GC.EXTERNAL_LOAD_BOARD_LB123];

      if (R.and(R.includes(type, lbTypesNeedDetails), R.not(detailsLoaded))) {
        return <SeeDetails data={data} />;
      }

      return (
        <Contact data={data} width={`calc(${width} - 16px)`} />
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO]: {
    width: 140,
    name: 'titles:person-talked-to',
    customComponent: ({ data }: Object) => R.pathOr(
      '',
      [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO],
      data,
    ),
  },
  [GC.FIELD_LOAD_BOARD_NOTE]: {
    width: 150,
    name: 'titles:note',
    customComponent: ({ data }: Object) => {
      const shipment = R.propOr({}, GC.FIELD_LOAD_BOARD_SHIPMENT, data);

      return (
        G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_NOTE, shipment)) &&
        <TextComponent
          width={110}
          withEllipsis={true}
          title={R.prop(GC.FIELD_LOAD_BOARD_NOTE, shipment)}
        >
          {R.prop(GC.FIELD_LOAD_BOARD_NOTE, shipment)}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_COMMENTS]: {
    width: 150,
    name: 'titles:comments',
    customComponent: ({ data, width }: Object) => {
      const comments = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMMENTS], data);

      return (
        G.isNotNilAndNotEmpty(comments) &&
        <TextComponent
          withEllipsis={true}
          title={R.join(', ', comments)}
          width={`calc(${width} - 16px)`}
        >
          {R.join(', ', comments)}
        </TextComponent>
      );
    },
  },
  summary: {
    width: 150,
    name: 'titles:summary',
    customComponent: ({ data, width }: Object) => <Summary data={data} width={width} />,
  },
};

