import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik, setNestedObjectValues } from 'formik';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Form, Fieldset2 } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
// feature load-board
import * as C from '../constants';
import {
  defaultFields,
  originFieldset,
  getSubmitValues,
  commentFieldset,
  getFirstFieldset,
  equipmentFieldset,
  getValidationSchema,
  getValuesFromTemplate,
  getDestinationFieldset,
} from '../settings/search-filter-form';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SEARCH_FILTER_FORM',
    mapPropsToValues: ({ initialValues }: Object) => R.mergeRight(
      defaultFields,
      G.mapObjectNullFieldsToEmptyStrings(initialValues),
    ),
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(getValidationSchema(values))
    )),
    handleSubmit: (values: Object, { props }: Object) => props.handleSave(getSubmitValues(values)),
  }),
  withHandlers({
    handleUpdateFilter: (props: Object) => () => {
      const { errors, values, setTouched, handleSave } = props;

      if (G.isNotNilAndNotEmpty(errors)) return setTouched(setNestedObjectValues(values, true));

      handleSave(getSubmitValues(values), true);
    },
    handleSaveTemplate: (props: Object) => () => {
      const { values, isFilterEdit, saveTemplate } = props;

      saveTemplate(getSubmitValues(values, true, isFilterEdit));
    },
    handleCustomChange: (props: Object) => (data: Object, fieldName: string) => {
      const { values, templates, setValues } = props;

      if (R.equals(fieldName, C.FIELD_TEMPLATE)) {
        setValues(R.mergeRight(
          values,
          G.mapObjectNullFieldsToEmptyStrings(getValuesFromTemplate(R.prop(data, templates))),
        ));
      } else if (R.equals(fieldName, C.FIELD_DESTINATION_COUNTRY)) {
        const { value } = data.target;

        setValues(R.mergeRight(values, {
          [fieldName]: value,
          [C.FIELD_DESTINATION_CITY]: '',
          [C.FIELD_DESTINATION_STATES]: [],
        }));
      } else if (R.equals(fieldName, C.FIELD_DESTINATION_STATES)) {
        setValues(R.mergeRight(values, {
          [fieldName]: data,
          [C.FIELD_DESTINATION_CITY]: '',
        }));
      } else if (R.propEq(C.FIELD_DESTINATION_CITY, 'name', data)) {
        const { value } = data;

        const {
          city,
          state,
          country,
          latitude,
          longitude,
        } = value;

        setValues(R.mergeRight(values, {
          [C.FIELD_DESTINATION_CITY]: city || '',
          [C.FIELD_DESTINATION_COUNTRY]: country || '',
          [C.FIELD_DESTINATION_LATITUDE]: latitude || '',
          [C.FIELD_DESTINATION_LONGITUDE]: longitude || '',
          [C.FIELD_DESTINATION_STATES]: state ? [state] : [],
        }));
      }
    },
    handleLocationChange: (props: Object) => (data: Object, name: string) => {
      const { values, setValues } = props;

      const { city, state, country, latitude, longitude } = data;

      const [countryToUse, stateToUse] = G.getCountryAndStateByStringValues({ state, country });

      if (R.equals(name, C.FIELD_ORIGIN_CITY)) {
        setValues({
          ...values,
          [C.FIELD_ORIGIN_CITY]: R.or(city, ''),
          [C.FIELD_ORIGIN_STATE]: R.or(stateToUse, ''),
          [C.FIELD_ORIGIN_LATITUDE]: R.or(latitude, 0),
          [C.FIELD_ORIGIN_LONGITUDE]: R.or(longitude, 0),
          [C.FIELD_ORIGIN_COUNTRY]: R.or(countryToUse, ''),
        });
      } else {
        setValues({
          ...values,
          [C.FIELD_DESTINATION_CITY]: R.or(city, ''),
          [C.FIELD_DESTINATION_LATITUDE]: R.or(latitude, 0),
          [C.FIELD_DESTINATION_LONGITUDE]: R.or(longitude, 0),
          [C.FIELD_DESTINATION_COUNTRY]: R.or(countryToUse, ''),
          [C.FIELD_DESTINATION_STATES]: G.ifElse(G.isNotNilAndNotEmpty(stateToUse), R.of(Array, stateToUse), []),
        });
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.validateForm();
    },
  }),
  pure,
);

const BlockDivider = ({ mb, title }: Object) => (
  <Flex
    p='5px'
    fontWeight='bold'
    mb={R.or(mb, 20)}
    justifyContent='center'
    alignItems='flex-start'
    textTransform='uppercase'
    bg={G.getTheme('colors.whiteGrey')}
    color={G.getTheme('colors.dark.blue')}
  >
    <Box mx='auto'>{title}</Box>
  </Flex>
);

const OriginValue = ({
  errors,
  touched,
  originCity,
  originState,
  originCountry,
  originLatitude,
  originLongitude,
}: Object) => {
  const text = R.compose(
    R.join(', '),
    R.filter(G.isNotNilAndNotEmpty),
  )([originCity, originState, originCountry]);

  const stateError = G.isNotNil(R.prop(C.FIELD_ORIGIN_STATE, errors));
  const countryError = G.isNotNil(R.prop(C.FIELD_ORIGIN_COUNTRY, errors));
  const coordsError = R.or(
    G.isNotNil(R.prop(C.FIELD_ORIGIN_LATITUDE, errors)),
    G.isNotNil(R.prop(C.FIELD_ORIGIN_LONGITUDE, errors)),
  );

  const showError = R.and(
    touched,
    G.isAnyTrue(
      stateError,
      coordsError,
      countryError,
    ),
  );

  const errorText = R.compose(
    R.join(', '),
    R.map(R.prop('text')),
    R.filter(R.prop('error')),
  )([
    { error: stateError, text: G.getWindowLocale('titles:state', 'State') },
    { error: countryError, text: G.getWindowLocale('titles:country', 'Country') },
    { error: coordsError, text: G.getWindowLocale('titles:coordinates', 'Coordinates') },
  ]);

  return (
    <Box mb={10} color={G.getTheme('colors.darkGrey')}>
      <Box>{text}</Box>
      {
        R.and(G.isNotNilAndNotEmpty(originLatitude), G.isNotNilAndNotEmpty(originLongitude)) &&
        <Box>{`(${originLongitude} - ${originLatitude})`}</Box>
      }
      {
        showError &&
        <Box fontSize={11} color={G.getTheme('colors.light.lightRed')}>
          {G.getWindowLocale('titles:required-fields', 'required')}: {errorText}
        </Box>
      }
    </Box>
  );
};

const SearchFilterForm = enhance((props: Object) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isFilterEdit,
    isTemplateEdit,
    templateOptions,
    loadBoardOptions,
    handleCustomChange,
    handleSaveTemplate,
    handleUpdateFilter,
    handleLocationChange,
  } = props;

  const darkColor = G.getTheme('colors.dark.mainDark');
  const fieldsWrapperStyles = { alignItems: 'flex-start' };
  const originDestinationTitle = `${G.getWindowLocale('titles:origin', 'Origin')} - ${
    G.getWindowLocale('titles:destination', 'Destination')}`;

  let actionButtons = [{
    action: handleSaveTemplate,
    buttonStyles: { width: 'fit-content' },
    displayText: G.ifElse(
      isTemplateEdit,
      G.getWindowLocale('actions:update-template', 'Update Template'),
      G.getWindowLocale('actions:save-as-template', 'Save As Template'),
    ),
  }];

  const buttonStyles = G.ifElse(isFilterEdit, { width: 90, minWidth: 90 }, {});

  if (isFilterEdit) {
    actionButtons = R.append({
      buttonStyles,
      action: handleUpdateFilter,
      displayText: G.getWindowLocale('actions:update', 'Update'),
    }, actionButtons);
  }

  return (
    <Form
      height='100%'
      overflow='auto'
      minWidth={1000}
      onSubmit={handleSubmit}
      data-testid='search-filter-form-submit'
    >
      <Box>
        <Box p='20px 15px 0'>
          <Fieldset2
            {...props}
            onChange={handleChange}
            justifyContent='space-between'
            fields={getFirstFieldset(values)}
            handlers={{ handleCustomChange }}
            fieldsWrapperStyles={fieldsWrapperStyles}
            optionsForSelect={{
              templateOptions,
              loadBoardOptions,
            }}
          />
        </Box>
        <BlockDivider mb='0' title={originDestinationTitle} />
        <Flex p='10px 15px 0' alignItems='flex-start'>
          <Box>
            <Box
              mb={20}
              color={darkColor}
              fontWeight='bold'
            >
              {G.getWindowLocale('titles:origin', 'Origin')}:
            </Box>
            <Fieldset2
              {...props}
              onChange={handleChange}
              fields={originFieldset}
              justifyContent='space-between'
              handlers={{ handleCustomChange }}
              handleCustomChange={handleCustomChange}
              customSelectLocationFunction={handleLocationChange}
            />
            {
              G.isNotNilAndNotEmpty(R.prop(C.FIELD_ORIGIN_CITY, values)) &&
              <OriginValue
                {...values}
                errors={errors}
                touched={R.prop(C.FIELD_ORIGIN_CITY, touched)}
              />
            }
          </Box>
          <Box>
            <Box
              mb={20}
              color={darkColor}
              fontWeight='bold'
            >
              {G.getWindowLocale('titles:destination', 'Destination')}:
            </Box>
            <Fieldset2
              {...props}
              onChange={handleChange}
              justifyContent='space-between'
              handlers={{ handleCustomChange }}
              fields={getDestinationFieldset(values)}
              handleCustomChange={handleCustomChange}
              fieldsWrapperStyles={fieldsWrapperStyles}
              customSelectLocationFunction={handleLocationChange}
            />
          </Box>
        </Flex>
        <BlockDivider title={G.getWindowLocale('titles:equipment', 'Equipment')} />
        <Fieldset2
          {...props}
          onChange={handleChange}
          fields={equipmentFieldset}
          justifyContent='space-between'
          fieldsWrapperStyles={{ ...fieldsWrapperStyles, px: 15 }}
        />
        <Fieldset2
          {...props}
          onChange={handleChange}
          fields={commentFieldset}
          justifyContent='space-between'
          fieldsWrapperStyles={{ ...fieldsWrapperStyles, px: 15 }}
        />
      </Box>
      <FormFooter2
        actionButtons={actionButtons}
        submitBtnStyles={buttonStyles}
        cancelBtnStyles={buttonStyles}
        submitBtnText={G.getWindowLocale('actions:post', 'Post')}
        boxStyles={{
          p: 15,
          mt: 'auto',
          bottom: '0px',
          position: 'sticky',
          bg: G.getTheme('colors.white'),
        }}
      />
    </Form>
  );
});

export default SearchFilterForm;
