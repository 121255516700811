import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const toggleSubmenus = createAction('toggleSubmenus');
export const getMenusRequest = createAction('getMenusRequest');
export const toggleSidebar = createAction('toggleSidebarRequest');
export const clickOnItemAction = createAction('clickOnItemAction');
export const receivedMenusFail = createAction('receivedMenusFail');
export const addItemToNavRequest = createAction('addItemToNavRequest');
export const splitNavItemsAction = createAction('splitNavItemsAction');
export const addItemToNavSuccess = createAction('addItemToNavSuccess');
export const deleteNavItemRequest = createAction('deleteNavItemRequest');
export const deleteNavItemSuccess = createAction('deleteNavItemSuccess');
export const receivedMenusSuccess = createAction('receivedMenusSuccess');
