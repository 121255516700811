import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
// feature new-do
import Tabs from '../../new-do/components/tabs';
import { getStopIconColor } from '../../new-do/helpers';
import {
  TAB_NAME_PRICING,
  TAB_NAME_REFERENCE,
  TAB_NAME_TRIP_PRICING,
} from '../../new-do/constants';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const getPricingTab = (rate: Object) => (
  {
    name: TAB_NAME_PRICING,
    textColor: getStopIconColor(R.path(['isValid'], rate)),
    text: G.getWindowLocaleArr(['titles:customer', 'titles:rate']),
  }
);

const getTripPricingTab = () => {
  const hasPermissions = G.hasAmousCurrentUserPermissions(PC.FLEET_RATE_WRITE);

  if (G.isFalse(hasPermissions)) return null;

  return ({
    name: TAB_NAME_TRIP_PRICING,
    textColor: getStopIconColor(true),
    text: G.getWindowLocaleArr(['titles:add', 'titles:driver']),
  });
};

const getAdditionalInfoTab = (referenceFormData: Object) => (
  {
    name: TAB_NAME_REFERENCE,
    textColor: getStopIconColor(R.path(['isValid'], referenceFormData)),
    text: G.getWindowLocale('titles:additional-info', 'Additional Info'),
  }
);

const createTabSettings = (props: Array) => {
  const { rate, stops, referenceFormData } = props;

  const mappedStops = R.map((stop: Object) => ({
    name: R.prop(GC.FIELD_ORDER, stop),
    textColor: getStopIconColor(R.path(['isValid'], stop)),
    text: `${R.head(R.prop(GC.FIELD_EVENT_TYPE, stop))} ${R.prop(GC.FIELD_ORDER, stop)}`,
  }), R.values(stops));

  const tabs = R.reject((item: any) => G.isNilOrEmpty(item), [
    ...mappedStops,
    getAdditionalInfoTab(referenceFormData),
    getPricingTab(rate),
    getTripPricingTab(props),
  ]);

  return tabs;
};

const StopActions = (props: Object) => {
  const { addNewStopToStore } = props;

  const whiteColor = G.getTheme('colors.white');
  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Flex>
      <Box flexShrink={0}>
        <Flex>
          <TextComponent
            p='3px 8px'
            minWidth={80}
            fontSize={12}
            bg={whiteColor}
            cursor='pointer'
            borderRadius='4px'
            color={darkBlueColor}
            border={`1px solid ${darkBlueColor}`}
            onClick={() => addNewStopToStore(GC.EVENT_TYPE_PICKUP)}
          >
            {G.getWindowLocale('titles:add-pickup', 'Add Pickup')}
          </TextComponent>
          <TextComponent
            mx='8px'
            p='3px 8px'
            minWidth={80}
            fontSize={12}
            bg={whiteColor}
            cursor='pointer'
            borderRadius='4px'
            color={darkBlueColor}
            border={`1px solid ${darkBlueColor}`}
            onClick={() => addNewStopToStore(GC.EVENT_TYPE_DROP)}
          >
            {G.getWindowLocale('titles:add-drop', 'Add Drop')}
          </TextComponent>
        </Flex>
      </Box>
    </Flex>
  );
};

const FormTab = (props: Object) => (
  <Flex justifyContent='space-between' bg={G.getTheme('colors.bgGrey')}>
    <Tabs
      width={475}
      overflow='auto'
      defaultActiveName={1}
      options={createTabSettings(props)}
      activeFromParent={props.leftActiveTad}
      onClickTabCallback={props.onClickTabCallback}
    />
    <StopActions {...props} />
  </Flex>
);

export default FormTab;
