// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    name: G.getWindowLocale('titles:branch', 'Branch'),
    value: `${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
  },
  {
    type: 'string',
    value: `${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`,
    name: G.getWindowLocale('titles:account-number', 'Account Number'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.TEL_CLOS_BRANCH_NAME,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:branch', 'Branch')
    }`,
  },
  {
    type: 'string',
    value: GC.GRC.STATUS_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    value: GC.FIELD_COMMENTS,
    name: G.getWindowLocale('titles:comments', 'Comments'),
  },
  {
    type: 'string',
    value: GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    type: 'number',
    value: GC.FIELD_SERVICE_DAYS,
    name: G.getWindowLocale('titles:service-days', 'Service Days'),
  },
  {
    type: 'date',
    value: GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
    name: G.getWindowLocale('titles:payment-due-date', 'Payment Due Date'),
  },
  {
    type: 'number',
    value: GC.FIELD_INVOICE_NET_DAYS,
    name: G.getWindowLocale('titles:net-days', 'Net Days'),
  },
  {
    type: 'string',
    value: GC.FIELD_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:invoice-number', 'Invoice #'),
  },
  {
    type: 'string',
    value: GC.GRC.GL_CODE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:gl-code', 'GL Code'),
  },
  {
    type: 'date',
    value: GC.FIELD_INVOICE_DATE,
    name: G.getWindowLocale('titles:invoice-date', 'Invoice Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_INTEGRATED_DATE,
    name: G.getWindowLocale('titles:integrated-date', 'Integrated Date'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.PAYMENTS_DEPOSIT_DATE,
    name: G.getWindowLocale('titles:deposit-date', 'Deposit Date'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_NUMBER,
    name: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_DATE,
    name: G.getWindowLocale('titles:check-date', 'Check Date'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_AMOUNT,
    name: G.getWindowLocale('titles:check-amount', 'Check Amount'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER,
    name: G.getWindowLocale('titles:ach-payment-confirmation', 'ACH Payment Confirmation'),
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_LOCATION_TYPE_DISPLAYED,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_STATE,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:state')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_CITY,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:city')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_ZIP,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:remit-to')}: ${G.getWindowLocale('titles:comments')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_ROUTE_NAME,
    name: G.getWindowLocale('titles:route-name', 'Route Name'),
  },
  {
    type: 'date',
    value: GC.GRC.TEL_FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_FIRST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_FIRST_EVENT_APPOINTMENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:appointment-late-date', 'Appointment LATE Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_LAST_EVENT_APPOINTMENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:lsat-event', 'Last Event')}: ${
      G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_LAST_EVENT_APPOINTMENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:appointment-late-date', 'Appointment Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.TEL_LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_PRIMARY_REFERENCE_NAME,
    name: G.getWindowLocale('titles:primary-ref-name', 'Primary Reference Name'),
  },
  {
    type: 'string',
    value: GC.GRC.TEL_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value'),
  },
  {
    type: 'string',
    value: GC.GRC.SERVICE_VENDOR_NAME,
    name: G.getWindowLocale('titles:service-vendor-name', 'Service Vendor Name'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
    name: G.getWindowLocale('titles:integration-status', 'Integration Status'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
    name: G.getWindowLocale('titles:integration-type', 'Integration Type'),
  },
  // total
  {
    type: 'number',
    value: GC.FIELD_TOTAL,
    name: G.getWindowLocale('titles:invoice-total', 'Invoice Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAIN_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:linehaul', 'Linehaul'),
  },
  {
    type: 'number',
    value: GC.FIELD_DISCOUNT_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    type: 'number',
    value: GC.FIELD_FUEL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    type: 'number',
    value: GC.FIELD_ADDITIONAL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:other-accessorials', 'Other Accessorials'),
  },
  {
    type: 'number',
    value: GC.FIELD_BALANCE,
    name: G.getWindowLocale('titles:balance', 'Balance'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'number',
    value: GC.GRC.NORMALIZED_TOTAL_TOTAL,
    name: G.getWindowLocale('titles:normalized-total', 'Normalized Total'),
  },
  {
    type: 'string',
    value: GC.GRC.NORMALIZED_TOTAL_CURRENCY,
    name: G.getWindowLocale('titles:normalized-currency', 'Normalized Currency'),
  },
  {
    type: 'number',
    value: GC.GRC.TEL_TOTAL_INCOME,
    name: G.getWindowLocale('titles:total-trip-income', 'Total Trip Income'),
  },
  // tel rate
  {
    type: 'number',
    value: GC.GRC.TEL_RATE_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:total', 'Total')}`,
  },
  {
    type: 'number',
    value: GC.GRC.TEL_RATE_MAIN_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:linehaul', 'Linehaul')}`,
  },
  {
    type: 'number',
    value: GC.GRC.TEL_RATE_FUEL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:fuel', 'Fuel')}`,
  },
  {
    type: 'number',
    value: GC.GRC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:accessorials', 'Accessorials')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CLOS_SALE_PERSONS_ID,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_CLOS_SALE_PERSONS_NAME,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_BROKER_AGENTS_ID,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.TEL_BROKER_AGENTS_NAME,
    name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
];
