import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
// feature load-board
import { saveTemplateFields, saveTemplateValidationSchema } from '../settings/save-template-form';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SAVE_TEMPLATE_FORM',
    validationSchema: saveTemplateValidationSchema,
    mapPropsToValues: () => ({ templateName: null }),
    handleSubmit: (values: Object, { props }: Object) => props.submit(values),
  }),
  pure,
);

const SaveTemplateForm = enhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box py='8px'>
      <FieldsetComponent
        {...props}
        labelFontSize={14}
        labelMargin='4px 0'
        fieldsGroupMargin='0px'
        fields={saveTemplateFields}
        onChange={props.handleChange}
        justifyContent='space-between'
        setOptionsFunction={(field: Object) => field.options}
      />
    </Box>
    <FormFooter closeModal={props.closeModal} />
  </form>
));

export default SaveTemplateForm;
