import { createAction } from 'redux-act';
// common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
// shipments table title
const { setTableTitleSort, setTableTitleFilter } = getReportActions();

export { setTableTitleSort, setTableTitleFilter };

export const startLoadSearchPage = createAction('startLoadSearchPage');
// saved shipment
export const saveShipmentRequest = createAction('saveShipmentRequest');
export const saveShipmentSuccess = createAction('saveShipmentSuccess');
export const updateShipmentRequest = createAction('updateShipmentRequest');
export const updateShipmentSuccess = createAction('updateShipmentSuccess');
export const getSavedShipmentsRequest = createAction('getSavedShipmentsRequest');
export const getSavedShipmentsSuccess = createAction('getSavedShipmentsSuccess');
export const deleteSavedShipmentRequest = createAction('deleteSavedShipmentRequest');
export const deleteSavedShipmentSuccess = createAction('deleteSavedShipmentSuccess');
// booking
export const bookSavedShipmentRequest = createAction('bookSavedShipmentRequest');
export const bookSavedShipmentSuccess = createAction('bookSavedShipmentSuccess');
export const getBookedShipmentsRequest = createAction('getBookedShipmentsRequest');
export const getBookedShipmentsSuccess = createAction('getBookedShipmentsSuccess');
export const deleteBookedShipmentRequest = createAction('deleteBookedShipmentRequest');
export const deleteBookedShipmentSuccess = createAction('deleteBookedShipmentSuccess');
// load board
export const loginLoadBoardRequest = createAction('loginLoadBoardRequest');
export const loginLoadBoardSuccess = createAction('loginLoadBoardSuccess');
export const logoutLoadBoardRequest = createAction('logoutLoadBoardRequest');
export const logoutLoadBoardSuccess = createAction('logoutLoadBoardSuccess');
export const getLoggedLoadBoardsRequest = createAction('getLoggedLoadBoardsRequest');
export const getLoggedLoadBoardsSuccess = createAction('getLoggedLoadBoardsSuccess');
// search filter
export const getSearchFiltersRequest = createAction('getSearchFiltersRequest');
export const getSearchFiltersSuccess = createAction('getSearchFiltersSuccess');
export const toggleSelectSearchFilter = createAction('toggleSelectSearchFilter');
export const activateSearchFilterState = createAction('activateSearchFilterState');
export const createSearchFilterRequest = createAction('createSearchFilterRequest');
export const createSearchFilterSuccess = createAction('createSearchFilterSuccess');
export const updateSearchFilterRequest = createAction('updateSearchFilterRequest');
export const updateSearchFilterSuccess = createAction('updateSearchFilterSuccess');
export const deleteSearchFilterRequest = createAction('deleteSearchFilterRequest');
export const deleteSearchFilterSuccess = createAction('deleteSearchFilterSuccess');
export const toggleSearchFilterDetails = createAction('toggleSearchFilterDetails');
export const setFilteredBySearchFilter = createAction('setFilteredBySearchFilter');
export const getSearchFilterStatesRequest = createAction('getSearchFilterStatesRequest');
export const getSearchFilterStatesSuccess = createAction('getSearchFilterStatesSuccess');
export const createSearchFilterStateSuccess = createAction('createSearchFilterStateSuccess');
export const deleteSearchFilterStateSuccess = createAction('deleteSearchFilterStateSuccess');
export const activateSearchFilterStateSuccess = createAction('activateSearchFilterStateSuccess');
// company filter
export const addCompanyFilterRequest = createAction('addCompanyFilterRequest');
export const addCompanyFilterSuccess = createAction('addCompanyFilterSuccess');
export const getCompanyFiltersSuccess = createAction('getCompanyFiltersSuccess');
export const deleteCompanyFilterRequest = createAction('deleteCompanyFilterRequest');
export const deleteCompanyFilterSuccess = createAction('deleteCompanyFilterSuccess');
// templates
export const getTemplatesRequest = createAction('getTemplatesRequest');
export const getTemplatesSuccess = createAction('getTemplatesSuccess');
export const saveTemplateRequest = createAction('saveTemplateRequest');
export const saveTemplateSuccess = createAction('saveTemplateSuccess');
export const deleteTemplateRequest = createAction('deleteTemplateRequest');
export const deleteTemplateSuccess = createAction('deleteTemplateSuccess');
// report
export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
// configs
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
// misc
export const toggleAutodial = createAction('toggleAutodial');
export const toggleCancelledShown = createAction('toggleCancelledShown');
export const makeNotificationViewed = createAction('makeNotificationViewed');
// 123 load board
export const get123LoadBoardDetailsSuccess = createAction('get123LoadBoardDetailsSuccess');
