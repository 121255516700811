import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { FormFooter } from '../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../forms';
import MultiEmailsForm from '../../../forms/forms/multi-emails-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount, withAsyncMailSendingIntegrationExists } from '../../../hocs';
// ui
import { Flex, RelativeBox } from '../../../ui';
// feature master-invoice
import * as C from '../constants';
import { withAddCloDocumentAndPrintCloInvoice } from '../hocs';
import { reportWithEmails, getColumnSettings, printTableSettings } from '../settings/invoices-table-settings';
import {
  getFields1,
  getFields2,
  defaultValues,
  getValidationSchema,
} from '../settings/print-invoices-formik-settings';
//////////////////////////////////////////////////

const getEmailsMap = (invoices: Array) => {
  if (G.isNilOrEmpty(invoices)) return {};

  let map = {};

  R.forEach(
    ({ guid, emails }: Object) => {
      if (G.isNotNilAndNotEmpty(emails)) {
        map = R.assoc(guid, emails, map);
      }
    },
    invoices,
  );

  return map;
};

const enhance = compose(
  withState('documentTypes', 'setDocumentTypes', ({ initialValues, documentTypeOptions }: Object) => R.filter(
    ({ value }: Object) => R.includes(value, R.pathOr([], ['documentTypeGuids'], initialValues)),
    documentTypeOptions,
  )),
  withState('itemList', 'setItemList', ({ invoices }: Object) => R.map(
    R.assoc('selected', true),
    R.or(invoices, []),
  )),
  withState('emailsMap', 'setEmailsMap', {}),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultValues,
      props.initialValues,
    ),
    validationSchema: () => Yup.object().shape(getValidationSchema()),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction({
      ...values,
      emailsMap: getEmailsMap(props.itemList),
      invoiceGuids: R.compose(
        R.map(R.prop(GC.FIELD_GUID)),
        R.filter(({ selected }: Object) => selected),
      )(props.itemList),
    }),
    displayName: 'PRINT_OPTIONS_FORM',
  }),
  withHandlers({
    handleClickInvoiceCheckbox: (props: Object) => (data: any) => {
      const { itemList, setItemList } = props;

      if (R.equals(data, 'all')) {
        const value = R.not(R.all(
          (item: Object) => item.selected,
          itemList,
        ));

        return setItemList(R.map(R.assoc('selected', value), itemList));
      }
      setItemList(R.map(
        (item: Object) => {
          if (R.propEq(data, GC.FIELD_GUID, item)) {
            return R.assoc('selected', R.not(item.selected), item);
          }

          return item;
        },
        itemList,
      ));
    },
    handleSaveAndSend: (props: Object) => () => props.saveAndSend({
      ...props.values,
      invoiceGuids: R.compose(
        R.map(R.prop(GC.FIELD_GUID)),
        R.filter(({ selected }: Object) => selected),
      )(props.itemList),
    }),
    handleAddDocument: (props: Object) => (invoice: Object) => {
      const {
        setLoading,
        asyncOptions,
        asyncEndpoint,
        documentTypes,
        handleAddCloDocument,
        getInitialDataRequest,
      } = props;

      const callback = () => {
        setLoading();
        getInitialDataRequest(asyncEndpoint, R.assoc(C.DOCUMENT_TYPES, documentTypes, asyncOptions));
      };
      handleAddCloDocument(invoice, callback);
    },
    handleChangeEmails: (props: Object) => (invoice: Object) => {
      const { openModal, closeModal, itemList, emailsMap, setItemList, setEmailsMap } = props;

      let newEmailsMap = emailsMap;
      const submitAction = ({ emails }: Object) => {
        setItemList(R.map(
          (entity: Object) => {
            if (R.not(R.eqProps(GC.FIELD_GUID, entity, invoice))) return entity;

            newEmailsMap = R.assoc(G.getGuidFromObject(entity), emails, newEmailsMap);

            return R.assoc(GC.FIELD_EMAILS, emails, entity);
          },
          itemList,
        ));
        setEmailsMap(newEmailsMap);
        closeModal();
      };
      const component = (
        <MultiEmailsForm
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={{ [GC.FIELD_EMAILS]: R.pathOr([], [GC.FIELD_EMAILS], invoice)}}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          width: 'fit-content',
        },
      };
      openModal(modal);
    },
    handleCustomChange: (props: Object) => (e: Object) => {
      const { values, setValues } = props;

      const { name, value } = R.path(['currentTarget'], e);
      let newValues = {};

      if (R.equals(name, C.INTEGRATION_TYPE)) {
        newValues = {
          ...values,
          [C.SEND_EMAILS]: false,
          [C.INTEGRATION_TYPE]: value,
        };
      } else if (R.equals(name, C.MERGE_FILES)) {
        const newValue = R.not(R.prop(C.MERGE_FILES, values));

        newValues = {
          ...values,
          [C.SEND_EMAILS]: false,
          [C.MERGE_FILES]: newValue,
        };
      } else {
        const newValue = R.not(R.prop(C.SEND_EMAILS, values));

        newValues = {
          ...values,
          [C.MERGE_FILES]: false,
          [C.INTEGRATION_TYPE]: '',
          [C.SEND_EMAILS]: newValue,
        };
      }

      setValues(newValues);
    },
  }),
  withPropsOnChange(['values'], ({
    values,
    setLoading,
    asyncOptions,
    asyncEndpoint,
    documentTypes,
    setDocumentTypes,
    documentTypeOptions,
    getInitialDataRequest,
  }: Object) => {
    if (R.equals(R.path([C.DOCUMENT_TYPES, 'length'], values), R.length(documentTypes))) return;

    setDocumentTypes(R.filter(
      ({ value }: Object) => R.includes(value, R.pathOr([], [C.DOCUMENT_TYPES], values)),
      documentTypeOptions,
    ));
    setLoading();
    getInitialDataRequest(
      asyncEndpoint,
      R.assocPath(['params', C.DOCUMENT_TYPES], R.join(',', R.prop(C.DOCUMENT_TYPES, values)), asyncOptions),
    );
  }),
  withPropsOnChange(['invoices'], ({ invoices, emailsMap, setItemList }: Object) => setItemList(R.map(
    (invoice: Object) => R.mergeRight(invoice, {
      selected: true,
      [GC.FIELD_EMAILS]: R.pathOr(R.pathOr([], [GC.FIELD_EMAILS], invoice), [G.getGuidFromObject(invoice)], emailsMap),
    }),
    R.or(invoices, []),
  ))),
  pure,
);

const InvoicesTable = (props: Object) => {
  const {
    values,
    loading,
    itemList,
    closeModal,
    documentTypes,
    openFixedPopup,
    closeFixedPopup,
    handleAddDocument,
    handleChangeEmails,
    handleClickInvoiceCheckbox,
    mailSendingIntegrationExists,
  } = props;

  const offset = itemList.length;
  const allChecked = G.isAllChecked(itemList);
  const data = {
    loading,
    allChecked,
    pagination: {
      offset,
      limit: offset,
    },
    totalCount: offset,
    hasSelectable: true,
    report: reportWithEmails,
    tableSettings: printTableSettings,
    itemList: G.ifElse(loading, [], itemList),
    onOptionClick: handleClickInvoiceCheckbox,
    columnSettings: getColumnSettings({
      closeModal,
      documentTypes,
      openFixedPopup,
      closeFixedPopup,
      handleChangeEmails,
      mailSendingIntegrationExists,
      addDocument: handleAddDocument,
      sendEmails: R.prop(C.SEND_EMAILS, values),
    }),
  };

  return <RelativeBox zIndex='0'><Table {...data} /></RelativeBox>;
};

const getActionButtons = (values: Object, handleSaveAndSend: Function, noSelectedInvoices: boolean) => {
  const { integrationType } = values;

  if (G.isNilOrEmpty(integrationType)) return [];

  return [{
    action: handleSaveAndSend,
    buttonStyles: { width: 'auto' },
    displayText: G.getWindowLocale('titles:send-to-factoring', 'Send To Factoring'),
    disabled: R.or(noSelectedInvoices, G.isNilOrEmpty(R.path([C.INTEGRATION_TYPE], values))),
  }];
};

const PrintInvoicesOptionsForm = enhance((props: Object) => {
  const {
    values,
    itemList,
    closeModal,
    templateOptions,
    handleSaveAndSend,
    handleCustomChange,
    documentTypeOptions,
    vendorDocumentTypeOptions,
    activeFactoringIntegration,
    mailSendingIntegrationExists,
  } = props;

  const noSelectedInvoices = R.isEmpty(R.filter(({ selected }: Object) => selected, itemList));

  return (
    <form onSubmit={props.handleSubmit}>
      <Flex p='10px 20px'>
        <FieldsetComponent
          {...props}
          flexGrow='unset'
          labelFontSize={12}
          fieldsetWidth={150}
          flexDirection='column'
          justifyContent='space-between'
          handleCustomChange={handleCustomChange}
          fields={getFields1(mailSendingIntegrationExists)}
        />
        <FieldsetComponent
          {...props}
          labelFontSize={12}
          fieldsetWidth='max-content'
          justifyContent='flex-start'
          onChange={props.handleChange}
          setOptionsFunction={(field: Object) => field.options}
          fields={getFields2(
            templateOptions,
            documentTypeOptions,
            R.prop(C.SEND_EMAILS, values),
            activeFactoringIntegration,
            vendorDocumentTypeOptions,
          )}
        />
      </Flex>
      <InvoicesTable {...props} />
      <FormFooter
        closeModal={closeModal}
        submitStyles={{ ml: 16 }}
        cancelStyles={{ mr: 'auto' }}
        submitDisabled={noSelectedInvoices}
        boxStyles={{ p: '10px 30px', justifyContent: 'space-around' }}
        actionButtons={getActionButtons(values, handleSaveAndSend, noSelectedInvoices)}
        submitBtnText={G.ifElse(
          R.prop(C.SEND_EMAILS, values),
          G.getWindowLocale('titles:send-emails', 'Send Emails'),
          G.getWindowLocale('actions:download', 'Download'),
        )}
      />
    </form>
  );
});

const enhanceAsyncInvoices = compose(
  withAsyncMailSendingIntegrationExists,
  withAddCloDocumentAndPrintCloInvoice,
  withAsyncInitialDataOnDidMount,
  pure,
);

const AsyncPrintInvoicesOptionsForm = enhanceAsyncInvoices((props: Object) => {
  const { data, loading } = props.asyncInitialData;

  return (
    <PrintInvoicesOptionsForm {...props} loading={loading} invoices={data} />
  );
});

export default AsyncPrintInvoicesOptionsForm;
