import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSelectors } from '../../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('customerInvoice');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectAdditionalFilters = () => createSelector(
  store,
  ({ additionalFilters }: Object) => additionalFilters,
);

const makeSelectConfigs = () => createSelector(
  store,
  ({ configs }: Object) => ({
    driverPayrollInvoicesStatuses: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configs,
      GC.INVOICE_CI_STATUS,
      true,
    ),
  }),
);

const makeSelectShownMarkAsReady = () => createSelector(
  store,
  ({ configs }: Object) => (
    G.getConfigValueFromStore(
      GC.INVOICE_CLO_CUSTOMER_INVOICE_MARK_AS_READY,
      configs,
    )
  ),
);

export {
  makeSelectConfigs,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectShownMarkAsReady,
  makeSelectTableTitleFilters,
  makeSelectAdditionalFilters,
};

