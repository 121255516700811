import React from 'react';
// components
import { HoveringTitle } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const ActionButton = ({ icon, title, action }: Object) => (
  <HoveringTitle
    title={title}
    handleClick={action}
    positionConfig={{
      top: 25,
      zIndex: 14,
      left: '5px',
      width: 'max-content',
    }}
  >
    <Flex cursor='pointer' onClick={action}>{icon}</Flex>
  </HoveringTitle>
);

export const LocationSectionTitle = (props: Object) => {
  const { handleSelectLocation, handleSearchLocation } = props;

  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Flex>
      <Box mr={15}>{G.getWindowLocale('titles:location', 'Location')}</Box>
      <Flex
        width={40}
        fontWeight='normal'
        textTransform='none'
        justifyContent='space-between'
        color={G.getTheme('colors.black')}
      >
        <ActionButton
          action={handleSearchLocation}
          icon={I.globalSearch2(darkBlueColor)}
          title={G.getWindowLocale('titles:search-for-existing-location', 'Search for Existing Location')}
        />
        <ActionButton
          action={handleSelectLocation}
          icon={I.onMap(darkBlueColor)}
          title={G.getWindowLocale('titles:search-on-map', 'Search on Map')}
        />
      </Flex>
    </Flex>
  );
};
