import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withProps, withState, lifecycle, withHandlers, renderNothing } from 'react-recompose';
// components
import { FormSectionHeader } from '../../../components/form-section-header';
import { FormFooter2, TextComponent, ConfirmComponent } from '../../../components';
// features
import { renderConfirmationModal } from '../../template/helpers';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { changeTemplateItemStatusRequest } from '../../template/report/actions';
import { ReferenceSection } from '../../reference/components/reference-section';
import { makeSelectCurrentBranch, makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { withInitialValuesFromSearchTemplate } from '../../fleet/hocs/initial-values-from-search-template';
// forms
import { Form, Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncSequence } from '../../../hocs';
// ui
import { Box, Flex } from '../../../ui';
// feature location
import { ContactsArray } from './contacts-array';
import { LocationSectionTitle } from './location-section-title';
import { BusinessHoursSection } from './business-hours-section';
import {
  defaultLocationFields,
  getLocationHeaderFields,
  getLocationFieldSettings,
  locationFieldsValidationSchema,
} from '../settings/fields';
import {
  getCurrentLocationRequest,
  getLocationReferenceRequest,
  deleteLocationReferenceRequest,
  updateLocationReferenceRequest,
  createLocationReferenceRequest,
} from '../actions';
//////////////////////////////////////////////////

const formEnhance = withFormik({
  enableReinitialize: true,
  validationSchema: locationFieldsValidationSchema,
  mapPropsToValues: ({ fromTemplate, asyncSequence, initialValues }: Object) => {
    const initialValuesWithLocationTypeGuid = G.ifElse(
      G.isNotNilAndNotEmpty(initialValues),
      R.assoc(
        GC.FIELD_LOCATION_TYPE,
        R.pathOr('', [GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues),
        initialValues,
      ),
      initialValues,
    );

    const defaultFields = G.ifElse(
      R.and(G.isTrue(fromTemplate), G.isNotNilAndNotEmpty(asyncSequence)),
      R.assoc(GC.FIELD_TEMPLATE_ID, asyncSequence, defaultLocationFields),
      defaultLocationFields,
    );

    return G.setInitialFormikValues(defaultFields, initialValuesWithLocationTypeGuid);
  },
  handleSubmit: (values: Object, { props }: Object) => {
    const { guid, submitAction, asyncSequence, fromTemplate, saveToTemplate } = props;

    const residential = R.pathOr(false, ['residential'], values);
    const operationHour = G.convertOperationHoursToDefaultFormat(R.pathOr([], [GC.FIELD_OPERATION_HOUR], values));

    const createData = {
      ...values,
      residential,
      [GC.FIELD_OPERATION_HOUR]: R.map(R.dissoc(GC.FIELD_GUID), operationHour),
    };

    if (G.isTrue(fromTemplate)) {
      if (G.isNotNilAndNotEmpty(guid)) {
        return submitAction(R.assoc(GC.FIELD_OPERATION_HOUR, operationHour, values));
      }

      if (R.equals(G.getPropFromObject(GC.FIELD_TEMPLATE_ID, createData), asyncSequence)) {
        return submitAction(R.assoc(GC.FIELD_TEMPLATE_ID, null, createData));
      }

      return submitAction(createData);
    }

    submitAction(R.assoc('saveToTemplate', saveToTemplate, createData));
  },
});

const enhance = compose(
  withState('references', 'setReferences', []),
  withState('saveToTemplate', 'setSaveToTemplate', false),
  withProps(() => ({
    sequenceConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
    autogenerateConfigName: GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    configsNamesArray: [
      GC.TEMPLATES_LOCATION_TYPE,
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_SEQUENCE,
      GC.TEMPLATES_LOCATION_TEMPLATE_ID_AUTOGENERATED,
    ],
  })),
  withAsyncSequence,
  withInitialValuesFromSearchTemplate,
  withProps(({ asyncConfigs }: Object) => ({
    locationTypes: G.mapDropdownConfigWithParentGuid(asyncConfigs, GC.TEMPLATES_LOCATION_TYPE),
  })),
  formEnhance,
  withHandlers({
    handleChangeStatus: (props: Object) => (entity: Object) => {
      const { openModal } = props;

      const { active } = entity;

      const action = G.ifElse(G.isTrue(active), 'deactivate', 'activate');

      const modal = renderConfirmationModal({ ...props, entity, action });

      openModal(modal);
    },
    handleCreateReference: (props: Object) => (values: Object) => {
      const { guid, references, setReferences, createLocationReferenceRequest } = props;

      createLocationReferenceRequest({
        args: R.assoc('primaryObjectGuids', R.of(Array, guid), values),
        callback: (data: Object) => setReferences(R.assoc(G.getGuidFromObject(data), data, references)),
      });
    },
    handleUpdateReference: (props: Object) => (reference: Object) => {
      const { references, setReferences, updateLocationReferenceRequest } = props;

      updateLocationReferenceRequest({
        reference,
        callback: (data: Object) => setReferences(R.assoc(G.getGuidFromObject(data), data, references)),
      });
    },
    handleRemoveReference: (props: Object) => (guid: string) => {
      const { openModal, closeModal, references, setReferences, deleteLocationReferenceRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([guid, GC.FIELD_NAME], references)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:remove', 'Remove'),
              action: () => {
                closeModal();

                deleteLocationReferenceRequest({ guid, callback: () => setReferences(R.dissoc(guid, references)) });
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  lifecycle({
    componentDidMount() {
      const {
        guid,
        fromTemplate,
        setReferences,
        setInitialValues,
        getCurrentLocationRequest,
        getLocationReferenceRequest,
      } = this.props;

      if (G.isAllTrue(fromTemplate, G.isNotNilAndNotEmpty(guid))) {
        getCurrentLocationRequest({ guid, callback: setInitialValues });
        getLocationReferenceRequest({ guid, callback: setReferences });
      }
    },
  }),
  pure,
);

const OwnerInfo = ({ owner }: Object) => (
  <Flex>
    <Box color={G.getTheme('colors.darkGrey')}>{G.getWindowLocale('titles:owner', 'Owner')}:</Box>
    <TextComponent ml='8px' maxWidth={400} fontWeight={700} withEllipsis={true}>{owner}</TextComponent>
  </Flex>
);

const HeaderSection = (props: Object) => {
  const { initialValues, handleChangeStatus, isEditTemplateMode } = props;

  const isLocationActive = R.pathOr(false, [GC.FIELD_ACTIVE], initialValues);

  return (
    <Fieldset2
      {...G.getFormikProps(props)}
      handleCustomChange={() => handleChangeStatus(initialValues)}
      fieldsWrapperStyles={{ width: 440, gap: 245, justifyContent: 'flex-end' }}
      fields={getLocationHeaderFields({ isEditTemplateMode, active: isLocationActive })}
    />
  );
};

const getActionButtons = (setSaveToTemplate: Function) => [{
  type: 'submit',
  action: () => setSaveToTemplate(true),
  buttonStyles: { width: 180, textTransform: 'none' },
  displayText: G.getWindowLocale('titles:save-and-add-to-template', 'Save & Add to Template'),
}];

const LocationForm = (props: Object) => {
  const { guid, branch, values, fromTemplate, handleSubmit, locationTypes, setSaveToTemplate } = props;

  const isEditTemplateMode = G.isAllTrue(fromTemplate, G.isNotNilAndNotEmpty(guid));

  return (
    <Box
      width={910}
      overflow='auto'
      maxHeight='87vh'
    >
      <Form onSubmit={handleSubmit}>
        <FormSectionHeader expanded={true} title={<LocationSectionTitle {...props} />}>
          <Flex p='10px 15px 0 20px' justifyContent='space-between'>
            <OwnerInfo owner={R.pathOr('', [GC.FIELD_BRANCH_NAME], branch)} />
            <HeaderSection {...props} isEditTemplateMode={isEditTemplateMode} />
          </Flex>
          <Fieldset2
            {...G.getFormikProps(props)}
            locationTypes={locationTypes}
            fieldsWrapperStyles={{ mt: 25, mb: 20, px: 15, justifyContent: 'space-between' }}
            fields={getLocationFieldSettings(G.getPropFromObject(GC.FIELD_INTEGRATION_TYPE, values))}
          />
        </FormSectionHeader>
        {
          G.isTrue(fromTemplate) &&
          <BusinessHoursSection
            {...props}
            useFormikSetFieldValue={true}
            operationHour={R.pathOr([], [GC.FIELD_OPERATION_HOUR], values)}
          />
        }
        <ContactsArray {...props} contacts={R.pathOr([], [GC.FIELD_CONTACTS], values)} />
        {isEditTemplateMode && <ReferenceSection {...props} scope={GC.REF_SCOPE_NAME_LOCATION} />}
        <FormFooter2
          submitAction={() => setSaveToTemplate(false)}
          boxStyles={{ p: 15, bg: G.getTheme('colors.whiteGrey') }}
          actionButtons={G.ifElse(
            R.and(G.isNilOrFalse(fromTemplate), G.isNilOrEmpty(guid)),
            getActionButtons(setSaveToTemplate),
            [],
          )}
        />
      </Form>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  branch: makeSelectCurrentBranch(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  getCurrentLocationRequest,
  getLocationReferenceRequest,
  deleteLocationReferenceRequest,
  updateLocationReferenceRequest,
  createLocationReferenceRequest,
  changeTemplateItemStatusRequest,
})(enhance(LocationForm));
