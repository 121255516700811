import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
// feature master-invoice
import * as LC from '../constants';
//////////////////////////////////////////////////

export const getValidationSchema = () => ({
  [LC.DOCUMENT_TEMPLATE]: Yup.string()
    .nullable(true)
    .required(G.getWindowLocale('validation:field-required', 'Field is Required')),
});

export const defaultValues = {
  [LC.DOCUMENT_TYPES]: null,
  [LC.DOCUMENT_TEMPLATE]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

export const getFields = (useVendorDocuments: boolean) => {
  const fields = [
    {
      isRequired: true,
      inputWrapperStyles,
      type: 'reactSelect',
      options: 'templateOptions',
      fieldName: LC.DOCUMENT_TEMPLATE,
      label: ['titles:template', 'Template'],
    },
    {
      isMulti: true,
      inputWrapperStyles,
      type: 'reactSelect',
      fieldName: LC.DOCUMENT_TYPES,
      options: 'documentTypeOptions',
      label: ['titles:document-type', 'Document Type'],
    },
    {
      isMulti: true,
      inputWrapperStyles,
      type: 'reactSelect',
      fieldName: LC.VENDOR_DOCUMENT_TYPES,
      options: 'vendorDocumentTypeOptions',
      label: ['titles:vendor-document-type', 'Vendor Document Type'],
    },
  ];

  if (G.isFalse(useVendorDocuments)) return R.dropLast(1, fields);

  return fields;
};
