import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'react-recompose';
// features
import Audit2 from '../audit2';
// forms
import { SelectDropdownForm } from '../../forms/forms/select-dropdown-form';
import { SelectGLPostedDateForm } from '../../forms/forms/select-gl-posted-date-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature master-invoice
import { RowActions } from './components/actions';
import DocumentFormWithAsyncCloEvents from './components/document-form-with-async-clo-events';
import { printCloInvoiceRequest, updateCloInvoiceRequest, createCloDocumentRequest } from './actions';
import {
  makeSelectUpdatedInvoice,
  makeSelectDocumentTypeOptions,
  makeSelectMasterInvoiceConfigs,
} from './selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  configs: makeSelectMasterInvoiceConfigs(state),
  updatedInvoice: makeSelectUpdatedInvoice(state),
  documentTypeOptions: makeSelectDocumentTypeOptions(state),
});

const addCloDocumentAndPrintCloInvoiceActions = {
  printCloInvoiceRequest,
  updateCloInvoiceRequest,
  createCloDocumentRequest,
};

const withAddCloDocumentAndPrintCloInvoice = compose(
  connect(mapStateToProps, addCloDocumentAndPrintCloInvoiceActions),
  withState('documentTypes', 'setDocumentTypes', (props: Object) => R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    R.map((value: string) => R.find(R.propEq(value, GC.FIELD_VALUE), props.documentTypeOptions)),
    R.pathOr([], ['configs', 'defaultDocumentTypeGuids']),
  )(props)),
  withHandlers({
    handleAddCloDocument: (props: Object) => (invoice: Object, callback: Function) => {
      const {
        openModal,
        closeFixedPopup,
        createCloDocumentRequest,
      } = props;

      const cloGuid = R.prop(GC.FIELD_CLO_GUID, invoice);

      const handler = (options: Object) => {
        const valuesToUse = G.mapObjectEmptyStringFieldsToNull(options.data);

        createCloDocumentRequest({
          callback,
          setSubmitting: options.callback,
          reqData: R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, cloGuid, valuesToUse),
        });
      };

      const component = (
        <DocumentFormWithAsyncCloEvents
          {...props}
          cloGuid={cloGuid}
          load={{ loadType: GC.FIELD_CLO }}
          handleActionLoadDocument={handler}
          initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: 'NONE' }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-doc', 'Add Document'),
        },
      };

      closeFixedPopup();
      openModal(modal);
    },
  }),
);

const withHandleAudit = withHandlers({
  handleShowAudit: (props: Object) => ({ guid, masterInvoiceNumber }: string) => {
    const { openModal, closeFixedPopup } = props;

    closeFixedPopup();

    const requestOptions = {
      [GC.FIELD_OBJECT_GUID]: guid,
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_MASTER_INVOICE,
    };

    const component = <Audit2 requestOptions={requestOptions} />;

    const modal = {
      p: '0',
      component,
      options: {
        boxShadow: 'none',
        withCloseIcon: true,
        withBorderRadius: true,
        backgroundColor: 'transparent',
        title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${masterInvoiceNumber}`,
      },
    };

    openModal(modal);
  },
});

const withMasterInvoiceActions = compose(
  withHandlers({
    handleGenerateFactoringFileByFactoringType: (props: Object) => (data: Object) => {
      const { availableFactoringTypes, getFactoringCompanyFileRequest } = props;

      const { factoringType } = data;

      if (R.pathEq(factoringType, [GC.FIELD_FACTORING_TYPE], availableFactoringTypes)) {
        return getFactoringCompanyFileRequest(data);
      }

      const messagesMap = {
        [GC.FACTORING_COMPANY_TYPE_TRIUMPH]: ['messages:check-triumph-configs', 'Please, check your Triumph configs'],
        [GC.FACTORING_COMPANY_TYPE_COMPASS]: ['messages:check-compass-configs', 'Please, check your Compass configs'],
        [GC.FACTORING_COMPANY_TYPE_RTS_FINANCIAL]: [
          'messages:check-rts-financial-configs',
          'Please, check your RTS Financial configs',
        ],
        [GC.FACTORING_COMPANY_TYPE_OTR_CAPITAL]: [
          'messages:check-otr-capital-configs',
          'Please, check your OTR Capital configs',
        ],
      };

      const message = G.getWindowLocale(...R.propOr([], factoringType, messagesMap));

      return G.showToastrMessageSimple('info', message);
    },
    handleSelectOptionForExport: (props: Object) => (data: Object, actionID: string) => {
      const { openModal, closeModal, qbIIFImportRequest, sendToQuickBookRequest, sendToSageIntacctRequest } = props;

      const actionsMap = {
        IFF: qbIIFImportRequest,
        QB: sendToQuickBookRequest,
        SAGE: sendToSageIntacctRequest,
      };

      const submitAction = (value: boolean) => {
        actionsMap[actionID](R.assoc('separateInvoices', R.equals(value, 'AS_SINGLE'), data));
        closeModal();
      };

      const options = [
        { label: G.getWindowLocale('titles:as-single', 'As Single'), value: 'AS_SINGLE' },
        { label: G.getWindowLocale('titles:as-master', 'As Master'), value: 'AS_MASTER' },
      ];

      const component = (
        <SelectDropdownForm
          options={options}
          optionRequired={false}
          cancelAction={closeModal}
          submitAction={submitAction}
          initialValues={{ option: 'AS_MASTER' }}
          fieldLabel={G.getWindowLocale('titles:select-export-option', 'Select Export Option')}
        />
      );

      const modal = { p: 15, component, options: {} };
      openModal(modal);
    },
    handleExportCustomerMasterInvoiceToEDI: (props: Object) => (guid: string) => {
      const { closeFixedPopup, exportCustomerMasterInvoiceToEDIRequest } = props;

      closeFixedPopup();
      exportCustomerMasterInvoiceToEDIRequest(guid);
    },
  }),
  withHandlers({
    handleSendMasterInvoiceListToSageIntacct: (props: Object) => (guid: string) => {
      const {
        itemList,
        openModal,
        closeModal,
        handleSelectOptionForExport,
      } = props;

      const guids = G.getGuidsFromStringOrSelectedList(guid, itemList);

      if (R.propEq(0, 'length', guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:driver:select-invoice', 'Please, select an Invoice!'),
        );
      }

      const component = (
        <SelectGLPostedDateForm
          closeModal={closeModal}
          submitAction={(date: any) => {
            closeModal();
            handleSelectOptionForExport({
              guids,
              [GC.FIELD_GL_POSTED_DATE]: date,
            }, 'SAGE');
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
          footerZIndex: 'unset',
          title: G.getWindowLocale('titles:gl-posted-date', 'GL Posted Date'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, masterInvoice: Object) => {
      const {
        openFixedPopup,
        closeFixedPopup,
        handleShowAudit,
        handlePrintInvoices,
        handleShowDocuments,
        handlePrintMasterInvoice,
        getMasterInvoiceXMLRequest,
        handleSelectOptionForExport,
        handleOpenUpdateMasterInvoiceInvoices,
        handleExportCustomerMasterInvoiceToEDI,
        handleSendMasterInvoiceListToSageIntacct,
        handleGenerateFactoringFileByFactoringType,
      } = props;

      const dataToSend = {
        guids: R.of(Array, G.getGuidFromObject(masterInvoice)),
      };

      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        boxStyles: { zIndex: 16 },
        content: (
          <RowActions
            data={masterInvoice}
            closeFixedPopup={closeFixedPopup}
            handleShowAudit={() => handleShowAudit(masterInvoice)}
            handlePrintCloInvoices={() => handlePrintInvoices(masterInvoice)}
            sendToSageIntacctRequest={handleSendMasterInvoiceListToSageIntacct}
            handlePrintMasterInvoice={() => handlePrintMasterInvoice(masterInvoice)}
            handleGetXMLRequest={(data: Object) => getMasterInvoiceXMLRequest(data)}
            qbIIFImportRequest={() => handleSelectOptionForExport(dataToSend, 'IFF')}
            openUpdateInvoices={() => handleOpenUpdateMasterInvoiceInvoices(masterInvoice)}
            handleSendToQuickBookRequest={() => handleSelectOptionForExport(dataToSend, 'QB')}
            handleGenerateFactoringFileByFactoringType={handleGenerateFactoringFileByFactoringType}
            handleExportCustomerMasterInvoiceToEDI={() =>
              handleExportCustomerMasterInvoiceToEDI(G.getGuidFromObject(masterInvoice))
            }
            showDocuments={() =>
              handleShowDocuments(G.getGuidFromObject(masterInvoice), GC.DOCUMENTS_TYPE_MASTER_INVOICE)}
          />
        ),
      });
    },
  }),
);

export {
  withHandleAudit,
  withMasterInvoiceActions,
  withAddCloDocumentAndPrintCloInvoice,
};
