import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { payToTypeOptions } from '../../../helpers/options';
// feature invoice
import {
  invoiceDateCustomBlurHandler,
  invoiceDateCustomChangeHandler2,
  invoiceNetDaysCustomChangeHandler,
} from '../helpers';
//////////////////////////////////////////////////

export const contactFields = [
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_CONTACT_NAME,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax-number', 'Fax Number'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
  },
];

export const commentFields = [
  {
    type: 'textarea',
    inputStyles: {
      p: 15,
      height: 60,
    },
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 500,
    },
    fieldName: GC.FIELD_COMMENTS,
  },
];

export const generalFields = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 80,
    },
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 80,
    },
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
    label: ['titles:mode', 'Mode'],
  },
  {
    type: 'select',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 80,
    },
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
    label: ['titles:service-type', 'Service Type'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 60,
    },
    fieldName: GC.FIELD_SERVICE_DAYS,
    label: ['titles:service-days', 'Service Days'],
  },
];

export const customerTotalField = {
  type: 'text',
  inputWrapperStyles: {
    mr: 0,
    flexGrow: 1,
    flexBasis: 60,
  },
  fieldName: GC.FIELD_CUSTOMER_TOTAL,
  label: ['titles:customer-total', 'Customer Total'],
};

export const commonFields1 = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_INVOICE_NUMBER,
    label: ['titles:invoice-number', 'Invoice #'],
  },
  {
    type: 'select',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    options: GC.FIELD_INVOICE_STATUS,
    fieldName: GC.FIELD_INVOICE_STATUS,
    label: ['titles:invoice-status', 'Invoice Status'],
  },
  {
    type: 'select',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    options: GC.FIELD_GL_CODE,
    fieldName: GC.FIELD_GL_CODE,
    label: ['titles:gl-code', 'GL Code'],
    customDisabledFunction: 'handleDisableGlCode',

  },
  {
    disabled: true,
    type: 'datePicker',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_GL_POSTED_DATE,
    label: ['titles:gl-posted-date', 'GL Posted Date'],
  },
];

export const commonFields2 = [
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_INVOICE_DATE,
    label: ['titles:invoice-date', 'Invoice Date'],
    customBlurHandler: (event: Object, field: Object, props: Object) => (
      invoiceDateCustomBlurHandler(event, field, props)
    ),
    customChangeHandler2: (event: Object, field: Object, props: Object) => (
      invoiceDateCustomChangeHandler2(event, field, props)
    ),
  },
  {
    type: 'number',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    shouldCustomChange: true,
    fieldName: GC.FIELD_INVOICE_NET_DAYS,
    label: ['titles:net-days', 'Net Days'],
    customChangeHandler: (event: Object, field: Object, props: Object) => (
      invoiceNetDaysCustomChangeHandler(event, field, props)
    ),
  },
  {
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
    label: ['titles:payment-due-date', 'Payment Due Date'],
  },
  {
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 150,
    },
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_INTEGRATED_DATE,
    label: ['titles:integrated-date', 'Integrated Date'],
  },
];

export const getPaymentFields = (zIndex: number) => [
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_INVOICE_CHECK_NUMBER,
    label: ['titles:check-number', 'Check Number'],
  },
  {
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 20,
      zIndex,
      flexGrow: 1,
      flexBasis: 150,
    },
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_INVOICE_CHECK_DATE,
    label: ['titles:check-date', 'Check Date'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_CHECK_AMOUNT,
    label: ['titles:check-amount', 'Check Amount'],
  },
  {
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 20,
      zIndex,
      flexGrow: 1,
      flexBasis: 150,
    },
    placeholder: 'Select a weekday',
    fieldName: GC.FIELD_INVOICE_DEPOSIT_DATE,
    label: ['titles:deposit-date', 'Deposit Date'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER,
    label: ['titles:ach-payment-confirmation', 'ACH Payment Confirmation'],
  },
];

export const payTypeField = {
  type: 'select',
  inputWrapperStyles: {
    flexGrow: 1,
    flexBasis: 100,
  },
  fieldName: GC.FIELD_PAY_TYPE,
  label: ['titles:pay-type', 'Pay Type'],
  options: G.prependEmptyLabelValueOption(payToTypeOptions),
};

export const externalInvoiceNumberField = {
  type: 'text',
  inputWrapperStyles: {
    flexGrow: 1,
    flexBasis: 150,
  },
  fieldName: GC.FIELD_EXTERNAL_INVOICE_NUMBER,
  label: ['titles:external-number', 'External Number'],
};

export const getPaymentNewFields = () => ({
  uniq: G.generateGuid(),
  [GC.FIELD_CHECK_AMOUNT]: '',
  [GC.FIELD_INVOICE_CHECK_DATE]: null,
  [GC.FIELD_INVOICE_CHECK_NUMBER]: '',
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: null,
  [GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER]: '',
});

const validationSchemaReferenceObject = {
  [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_VALUE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(250, G.getShouldBeFromToLocaleTxt(1, 250)),
};

export const validationSchemaReferences = {
  [GC.FIELD_LOAD_REFERENCES]: Yup.array()
    .of(Yup.object(validationSchemaReferenceObject))
    .nullable(true)
    .notRequired(),
};

export const getReferencesFields = (allowedValues: Array, disabled: boolean) => [
  {
    disabled,
    type: 'select',
    isRequired: true,
    options: 'referenceTypes',
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    inputWrapperStyles: { mr: 25, width: 270 },
  },
  {
    isRequired: true,
    autoComplete: 'off',
    options: 'allowedValues',
    fieldName: GC.FIELD_VALUE,
    label: ['titles:value', 'Value'],
    inputWrapperStyles: { width: 270 },
    type: G.ifElse(G.isNotEmpty(allowedValues), 'select', 'text'),
  },
];

export const referenceDefaultFields = {
  [GC.FIELD_VALUE]: '',
  [GC.FIELD_REFERENCE_TYPE_GUID]: '',
};

export const getNewReferenceFields = () => R.assoc('uniq', G.generateGuid(), referenceDefaultFields);
