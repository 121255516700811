import styled from 'styled-components';
import { space, width, height } from 'styled-system';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const CustomMarker = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  transition: 0.2s transform;
  background-color: ${({ bg }: Object) => bg || G.getTheme('map.bgColor')};
  border: 5px solid ${({ color }: Object) => color || G.getTheme('map.markerColor')};
  transform: ${({ active, transform }: Object) => `${G.ifElse(active, 'scale(1.1)', 'scale(1)')} ${transform}`};
  &:hover {
    transform: ${({ transform }: Object) => `scale(1.1) ${transform}`};
  }
  &:after {
    top: 26px;
    right: 50%;
    content: '';
    position: absolute;
    transform: translateX(50%);
    border: 13px solid transparent;
    border-top: 18px solid ${({ color }: Object) => color || G.getTheme('map.markerColor')};
  }
`;

export const CustomMarkerContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`;

export const CommentUI = styled.div`
  ${space}
  ${width}
  ${height}

  white-space: normal;
  word-break: break-word;
`;
