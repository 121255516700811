import React from 'react';
import * as R from 'ramda';
// components
import { FormSectionHeader } from '../../../components/form-section-header';
// features
import BusinessHours, { withBusinessHours } from '../../business-hours';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const BusinessHoursSection = withBusinessHours(({
  iconsML,
  maxWidth,
  operationHour,
  componentType,
  handleOpenBusinessHours,
  handleRemoveBusinessHours,
}: Object) => (
  <FormSectionHeader
    expanded={true}
    title={G.getWindowLocale('titles:business-hours', 'Business Hours')}
    action={G.ifElse(G.isNilOrEmpty(operationHour), handleOpenBusinessHours, null)}
  >
    <BusinessHours
      version={2}
      iconsML={iconsML}
      maxWidth={maxWidth}
      operationHour={operationHour}
      componentType={R.or(componentType, 'location')}
      handleOpenBusinessHours={handleOpenBusinessHours}
      handleRemoveBusinessHours={handleRemoveBusinessHours}
    />
  </FormSectionHeader>
));
