import React from 'react';
import * as R from 'ramda';
// feature load-board
import FilterList from './filter-list';
import TemplateList from './template-list';
import SavedShipments from './saved-shipments';
import BookedShipments from './booked-shipments';
//////////////////////////////////////////////////


const FiltersBlock = ({
  templates,
  activeTab,
  savedShipments,
  bookedShipments,
  handleEditOrder,
  handleSaveTemplate,
  availableLoadBoards,
}: Object) => (
  <div>
    {
      R.equals(activeTab, 0) &&
      <FilterList
        templates={templates}
        handleEditOrder={handleEditOrder}
        handleSaveTemplate={handleSaveTemplate}
        availableLoadBoards={availableLoadBoards}
      />
    }
    {
      R.equals(activeTab, 1) && <TemplateList />
    }
    {
      R.equals(activeTab, 2) && <SavedShipments itemList={savedShipments} />
    }
    {
      R.equals(activeTab, 3) && <BookedShipments itemList={bookedShipments} />
    }
  </div>
);

export default FiltersBlock;
