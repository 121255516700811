import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const commonAuthFieldProps = {
  width: 300,
  fontSize: 12,
  labelPl: '0px',
  errorTop: '103%',
  isRequired: true,
  labelWidth: '100%',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
  errorPosition: 'absolute',
};

export const loginFormFields = [
  {
    ...commonAuthFieldProps,
    afterTop: 13,
    type: 'select',
    afterRight: 13,
    loc: 'titles:source',
    fieldName: GC.FIELD_LOAD_BOARD_TYPE,
    options: GC.EXTERNAL_LOGIN_LOAD_BOARD_LIST_OPTIONS,
  },
  {
    ...commonAuthFieldProps,
    type: 'text',
    loc: 'titles:login',
    fieldName: GC.LOGIN_EXTERNAL_LOAD_BOARD_LOGIN,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.includes(type, [GC.EXTERNAL_LOAD_BOARD_DAT, GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS]),
      'flex',
      'none',
    ),
  },
  {
    ...commonAuthFieldProps,
    type: 'password',
    loc: 'titles:password',
    fieldName: GC.LOGIN_EXTERNAL_LOAD_BOARD_PASSWORD,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, GC.EXTERNAL_LOAD_BOARD_DAT),
      'flex',
      'none',
    ),
  },
];

export const defaultLoginValues = {
  [GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE]: null,
  [GC.LOGIN_EXTERNAL_LOAD_BOARD_LOGIN]: null,
  [GC.LOGIN_EXTERNAL_LOAD_BOARD_PASSWORD]: null,
};

export const loginValidationSchema = Yup.lazy((values: Object) => {
  const loadBoardType = G.getPropFromObject(GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE, values);
  const login = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());
  const type = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());
  const password = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

  let schema = { type };

  if (R.equals(loadBoardType, GC.EXTERNAL_LOAD_BOARD_DAT)) {
    schema = { login, type, password };
  } else if (R.equals(loadBoardType, GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS)) {
    schema = { login, type };
  }

  return Yup.object().shape(schema);
});

export const logoutValidationSchema = Yup.object().shape({
  [GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});

export const defaultLogoutValues = {
  [GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE]: null,
};

export const logoutFormFields = [
  {
    ...commonAuthFieldProps,
    afterTop: 13,
    type: 'select',
    afterRight: 13,
    loc: 'titles:source',
    fieldName: GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE,
    options: GC.EXTERNAL_LOGIN_LOAD_BOARD_LIST_OPTIONS,
  },
];

