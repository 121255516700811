import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const getItemListSuccess = createAction('getItemListSuccess');
export const getCrossDockLocationsSuccess = createAction('getCrossDockLocationsSuccess');
export const toggleLoadPlannerTemplateDetails = createAction('toggleLoadPlannerTemplateDetails');
export const removeLoadPlannerTemplateRequest = createAction('removeLoadPlannerTemplateRequest');
export const removeLoadPlannerTemplateSuccess = createAction('removeLoadPlannerTemplateSuccess');
