import React from 'react';
import * as R from 'ramda';
import { FieldArray } from 'formik';
// components
import { FormSectionHeader } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms/formik';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature location
import { contactFieldSettings, getNewContactsArrayFields } from '../settings/fields';
//////////////////////////////////////////////////

const ContactRow = (props: Object) => {
  const { fields, remove, itemIndex, arrayLength } = props;

  return (
    <Flex mt={25}>
      <Flex
        mr={10}
        cursor='pointer'
        onClick={() => remove(itemIndex)}
        title={G.getWindowLocale('titles:remove', 'Remove')}
      >
        {I.trash(G.getTheme('colors.dark.blue'))}
      </Flex>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...G.getArrayFormikProps(props)}
        arrayLength={arrayLength}
        arrayName={GC.FIELD_CONTACTS}
        fields={R.or(fields, contactFieldSettings)}
      />
    </Flex>
  );
};

export const ContactsArray = (props: Object) => {
  const { contacts, contactRowFields } = props;

  return (
    <FieldArray
      name={GC.FIELD_CONTACTS}
      render={(arrayHelpers: Object) => (
        <Box>
          <FormSectionHeader
            expanded={true}
            title={G.getWindowLocale('titles:contacts', 'Contacts')}
            action={() => arrayHelpers.push(getNewContactsArrayFields())}
          >
            {
              G.isNotNilAndNotEmpty(contacts) &&
              <Box px={20} mb={15}>
                {
                  contacts.map((_: any, index: number) => (
                    <ContactRow
                      {...props}
                      {...arrayHelpers}
                      key={index}
                      itemIndex={index}
                      fields={contactRowFields}
                      arrayLength={R.length(contacts)}
                    />
                  ))
                }
              </Box>
            }
          </FormSectionHeader>
        </Box>
      )}
    />
  );
};
