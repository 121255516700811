// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature master-invoice
import { ChargeTotal, ChargeFuelRelated } from '../components/charges-ui';
//////////////////////////////////////////////////

export const masterInvoiceInitFields = {
  [GC.FIELD_MASTER_INVOICE_NUMBER]: null,
  [GC.FIELD_MASTER_INVOICE_STATUS]: null,
  [GC.FIELD_MASTER_INVOICE_CHARGES]: null,
  [GC.FIELD_MASTER_INVOICE_DATE_TO]: null,
  [GC.FIELD_MASTER_INVOICE_NET_DAYS]: null,
  [GC.FIELD_MASTER_INVOICE_DUE_DATE]: null,
  [GC.FIELD_MASTER_INVOICE_CURRENCY]: null,
  [GC.FIELD_MASTER_INVOICE_DATE_FROM]: null,
  [GC.FIELD_MASTER_INVOICE_CHECK_DATE]: null,
  [GC.FIELD_MASTER_INVOICE_CHECK_NUMBER]: null,
  [GC.FIELD_MASTER_INVOICE_CHECK_AMOUNT]: null,
  [GC.FIELD_MASTER_INVOICE_DEPOSIT_DATE]: null,
};

export const chargeInitFields = {
  [GC.FIELD_CHARGE_RATE]: '',
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_TYPE]: null,
  [GC.FIELD_CHARGE_QUANTITY]: '',
  [GC.FIELD_CHARGE_RATE_NAME]: null,
  [GC.FIELD_CHARGE_RATE_TYPE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_DISCOUNT]: false,
  [GC.FIELD_CHARGE_NON_TAXABLE]: null,
  [GC.FIELD_CHARGE_SEQUENCE_NUM]: null,
  [GC.FIELD_CHARGE_RATE_NAME_STORED]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

const getFirstFieldset = (glDisabled: boolean) => ({
  fields: [
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_MASTER_INVOICE_NUMBER,
      label: ['titles:master-invoice-number', 'Master Invoice Number'],
    },
    {
      isClearable: true,
      type: 'datePicker',
      label: ['titles:date', 'Date'],
      fieldName: GC.FIELD_MASTER_INVOICE_DATE,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    },
    {
      type: 'select',
      inputWrapperStyles,
      label: ['titles:status', 'Status'],
      options: GC.FIELD_MASTER_INVOICE_STATUS,
      fieldName: GC.FIELD_MASTER_INVOICE_STATUS,
    },
    {
      type: 'select',
      inputWrapperStyles,
      disabled: glDisabled,
      options: GC.FIELD_GL_CODE,
      fieldName: GC.FIELD_GL_CODE,
      label: ['titles:gl-code', 'GL Code'],
    },
    {
      type: 'select',
      isRequired: true,
      inputWrapperStyles,
      options: GC.CURRENCY_OPTIONS,
      label: ['titles:currency', 'Currency'],
      fieldName: GC.FIELD_MASTER_INVOICE_CURRENCY,
    },
  ],
});

const getSecondFieldset = ({ dateTo, dateFrom }: Object) => ({
  fields: [
    {
      maxDate: dateTo,
      isRequired: true,
      type: 'datePicker',
      label: ['titles:date-from', 'Date From'],
      fieldName: GC.FIELD_MASTER_INVOICE_DATE_FROM,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 14 },
    },
    {
      isRequired: true,
      minDate: dateFrom,
      type: 'datePicker',
      label: ['titles:date-to', 'Date To'],
      fieldName: GC.FIELD_MASTER_INVOICE_DATE_TO,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
    },
    {
      type: 'number',
      inputWrapperStyles,
      label: ['titles:net-days', 'Net Days'],
      fieldName: GC.FIELD_MASTER_INVOICE_NET_DAYS,
    },
    {
      type: 'datePicker',
      label: ['titles:due-date', 'Due Date'],
      fieldName: GC.FIELD_MASTER_INVOICE_DUE_DATE,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    },
  ],
});

const thirdFieldset = {
  fields: [
    {
      type: 'text',
      inputWrapperStyles,
      label: ['titles:check-number', 'Check Number'],
      fieldName: GC.FIELD_MASTER_INVOICE_CHECK_NUMBER,
    },
    {
      type: 'datePicker',
      label: ['titles:check-date', 'Check Date'],
      fieldName: GC.FIELD_MASTER_INVOICE_CHECK_DATE,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
    },
    {
      type: 'number',
      inputWrapperStyles,
      label: ['titles:check-amount', 'Check Amount'],
      fieldName: GC.FIELD_MASTER_INVOICE_CHECK_AMOUNT,
    },
    {
      type: 'datePicker',
      label: ['titles:deposit-date', 'Deposit Date'],
      fieldName: GC.FIELD_MASTER_INVOICE_DEPOSIT_DATE,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    },
  ],
};

const rateTypeOptions = [
  { label: G.getWindowLocale('titles:flat', 'Flat'), value: GC.CHARGE_RATE_TYPE_FLAT },
];

export const getMasterInvoiceChargeFieldset = (glDisabled: boolean = false) => [
  {
    type: 'select',
    isRequired: true,
    fieldWidth: '100%',
    wrapperBoxWidth: '20%',
    options: GC.FIELD_CHARGE_RATE_NAME,
    fieldName: GC.FIELD_CHARGE_RATE_NAME,
    placeholder: GC.FIELD_CHARGE_RATE_NAME,
  },
  {
    type: 'checkbox',
    fieldWidth: '100%',
    wrapperBoxWidth: '3%',
    fieldName: GC.FIELD_CHARGE_DISCOUNT,
  },
  {
    type: 'text',
    isRequired: true,
    fieldWidth: '100%',
    wrapperBoxWidth: '10%',
    fieldName: GC.FIELD_CHARGE_RATE,
    placeholder: G.getWindowLocale('titles:rate', 'Rate'),
  },
  {
    type: 'select',
    disabled: true,
    isRequired: false,
    fieldWidth: '100%',
    wrapperBoxWidth: '15%',
    options: rateTypeOptions,
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    placeholder: GC.FIELD_CHARGE_RATE_TYPE,
  },
  {
    type: 'text',
    isRequired: true,
    fieldWidth: '100%',
    wrapperBoxWidth: '10%',
    fieldName: GC.FIELD_CHARGE_QUANTITY,
    placeholder: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    type: 'select',
    isRequired: false,
    fieldWidth: '100%',
    disabled: glDisabled,
    wrapperBoxWidth: '10%',
    fieldName: GC.FIELD_GL_CODE,
  },
  {
    type: 'component',
    fieldWidth: '100%',
    wrapperBoxWidth: '2%',
    component: ChargeFuelRelated,
    fieldName: GC.FIELD_CHARGE_NON_TAXABLE,
  },
  {
    type: 'component',
    fieldWidth: '100%',
    wrapperBoxWidth: '10%',
    component: ChargeTotal,
    fieldName: GC.FIELD_CHARGE_TOTAL,
  },
];

export const getMasterInvoiceSectionSettings = (values: Object, glDisabled: boolean = false) => [
  getFirstFieldset(glDisabled),
  getSecondFieldset(values),
  thirdFieldset,
];
