import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const cloInvoiceInitFields = {
  [GC.FIELD_MODE]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_CHECK_AMOUNT]: null,
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_INVOICE_DATE]: null,
  [GC.FIELD_SERVICE_DAYS]: null,
  [GC.FIELD_INVOICE_STATUS]: null,
  [GC.FIELD_INVOICE_NUMBER]: null,
  [GC.FIELD_INVOICE_NET_DAYS]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: null,
  [GC.FIELD_INVOICE_CHECK_DATE]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: null,
  [GC.FIELD_INVOICE_CHECK_NUMBER]: null,
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: null,
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: null,
};

export const serviceVendorInitFields = {
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_CHECK_AMOUNT]: null,
  [GC.FIELD_INVOICE_DATE]: null,
  [GC.FIELD_INVOICE_STATUS]: null,
  [GC.FIELD_INVOICE_NUMBER]: null,
  [GC.FIELD_INVOICE_NET_DAYS]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: null,
  [GC.FIELD_INVOICE_CHECK_DATE]: null,
  [GC.FIELD_SERVICE_VENDOR_GUID]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: null,
  [GC.FIELD_INVOICE_CHECK_NUMBER]: null,
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: null,
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: null,
  [GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER]: null,
};

export const getDefaultActionFormFields = (statusConfigName: string) => {
  if (G.isNilOrEmpty(statusConfigName)) {
    return {
      [GC.FIELD_INVOICE_INVOICE_TEMPLATES]: '',
    };
  }

  return {
    [GC.FIELD_INVOICE_STATUS]: '',
    [GC.FIELD_INVOICE_NET_DAYS]: '',
  };
};

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

export const actionFormFields = [
  {
    type: 'number',
    inputWrapperStyles,
    fieldName: GC.FIELD_INVOICE_NET_DAYS,
    label: ['titles:net-days', 'Net Days'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: 'options',
    label: ['titles:status', 'Status'],
    fieldName: GC.FIELD_INVOICE_STATUS,
  },
];

export const getActionFormValidationSchema = (statusConfigName: string) => {
  if (G.isNilOrEmpty(statusConfigName)) {
    return Yup.object().shape({
      [GC.FIELD_INVOICE_INVOICE_TEMPLATES]: Yup.string()
        .nullable(true)
        .required(G.getRequiredLocaleTxt()),
    });
  }

  return Yup.object().shape({
    [GC.FIELD_INVOICE_NET_DAYS]: Yup.number()
      .nullable(true)
      .integer(G.getShouldBeIntegerLocaleTxt())
      .min(0, G.getShouldBePositiveLocaleTxt())
      .max(100, G.getShouldBeLessOrEqualLocaleTxt(100)),
  });
};

export const getStatusFormField = (statusConfigName: string) => [
  {
    width: 280,
    type: 'select',
    afterRight: 15,
    name: 'titles:status',
    options: statusConfigName,
    nameForAttribute: GC.FIELD_INVOICE_STATUS,
    firstOptionConfig: { [GC.FIELD_TEXT]: G.getWindowLocale('titles:status', 'Status') },
  },
];

export const printField = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'options',
    label: ['titles:template', 'Template'],
    fieldName: GC.FIELD_INVOICE_INVOICE_TEMPLATES,
  },
];

export const carrierInfoFields = [
  {
    field: GC.FIELD_NAME,
    label: 'titles:carrier-name',
  },
  {
    label: 'titles:duns-number',
    field: GC.FIELD_CARRIER_DUNS_NUMBER,
  },
  {
    label: 'titles:usdot-number',
    field: GC.FIELD_CARRIER_USDOT_NUMBER,
  },
  {
    label: 'titles:ms-number',
    field: GC.FIELD_CARRIER_MC_NUMBER,
  },
];

export const serviceVendorInfoFields = [
  {
    label: 'titles:dba',
    field: GC.FIELD_DBA,
  },
  {
    label: 'titles:duns-number',
    field: GC.FIELD_DUNS_NUMBER,
  },
];
