import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withProps,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { Switcher } from '../../../components/switcher';
import { TextComponent } from '../../../components/text';
import { LocalLoader } from '../../../components/local-loader';
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { ChargesTotal } from '../../../components/charge/components';
import { DMChargeComponent } from '../../../components/charge/formik/charges-array';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
import { getIsPriceSheetItemFieldsEditable } from '../../../components/price-sheet-items/settings';
import { withAsyncChargeAccessorials } from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import SectionDivider from '../../new-do/components/section-divider';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { getChargesFromValues, getChargesArrayNameFromProps } from '../../rate/helpers';
// forms
import { Fieldset2 } from '../../../forms/formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncSequence,
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
  withAsyncGetGLCodeMappingsByScope,
} from '../../../hocs';
// schemes
import * as VS from '../../../schemes';
// ui
import { Box, Flex, StickedBox, SectionsDivider } from '../../../ui';
// feature invoice
import PaymentsArray from './payments-array';
import ReferencesArray from './references-array';
import { withAsyncTelInvoiceAssignments } from '../hocs/with-async-tel-invoice-assignments';
import { withAsyncGetFleetVendorPayToLocation } from '../hocs/with-async-fleet-vendor-pay-to-location';
import {
  setOptionsForSelect,
  getTotalsFromValues,
  setDefaultInvoiceValues,
  setConfigOptionGuidToEntity,
  getDataWithTransformedReferences,
} from '../helpers';
import {
  telTotalFields,
  telInvoiceInitFields,
} from '../settings/tel-fields';
import {
  payTypeField,
  commonFields1,
  commonFields2,
  generalFields,
  commentFields,
  customerTotalField,
  validationSchemaReferences,
} from '../settings/common-fields';
//////////////////////////////////////////////////

const configsNamesArrayForCreateInvoice = [
  GC.INVOICE_GL_CODE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_SEQUENCE,
  GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_AUTOGENERATED,
];

const configsNamesArrayForUpdateInvoice = [
  GC.INVOICE_GL_CODE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
];

const validationSchemaObject = Yup.lazy((values: Object) => Yup.object()
  .shape(validationSchemaReferences)
  .shape(VS.validationSchemaInvoiceObject)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.getValidationSchemaTripTotalObject(values))
  .shape(G.ifElse(getIsPriceSheetItemFieldsEditable(values), VS.validationSchemaPriceSheetItems, {})));

const invoiceFieldsToOmit = [
  GC.FIELD_TYPE,
  GC.FIELD_GUID,
  GC.FIELD_SOURCE,
  GC.FIELD_VERSION,
  GC.FIELD_SELECTED,
  GC.FIELD_TEL_GUID,
  GC.FIELD_LOAD_GUID,
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_FLEET_VENDOR_CHARGES,
  GC.FIELD_PRIMARY_DRIVER_CHARGES,
  GC.FIELD_SECONDARY_DRIVER_CHARGES,
  GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT,
];

const getDriverGuid = ({ activeDriver, selectedRate, fleetAssignment }: Object) => {
  const driverObject = R.or(fleetAssignment, R.path([GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT], selectedRate));

  if (R.isNil(driverObject)) return null;

  if (R.equals(activeDriver, 'primary')) {
    return R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_DRIVER, GC.FIELD_GUID], driverObject);
  }

  return R.pathOr('', [GC.SYSTEM_OBJECT_SECONDARY_DRIVER, GC.FIELD_GUID], driverObject);
};


const getDriverVendorInfo = (props: Object) => {
  const { activeDriver } = props;

  const selectedRate = R.pathOr({}, ['selectedRate'], props);

  if (R.equals(activeDriver, 'primary')) {
    return G.getFirstDriverInfo(selectedRate);
  }

  if (R.equals(activeDriver, 'vendor')) {
    return R.pathOr('', [GC.FIELD_FLEET_ASSIGNMENT, 'truck', 'fleetVendor', 'name'], selectedRate);
  }

  return G.getTeamDriverInfo(selectedRate);
};

const hasSecondaryDriver = (props: Object) => R.pathOr(
  null,
  ['selectedRate', GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT, GC.FIELD_SECONDARY_DRIVER_GUID],
  props,
);

const hasFleetVendor = (props: Object) => G.isNotNilAndNotEmpty(R.pathOr(
  null,
  ['selectedRate', GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT, 'truck', 'fleetVendorGuid'],
  props,
));

const asyncSetDefaultValues = (props: Object) => {
  const { availableTypes, asyncInitialData: { data } } = props;

  if (G.isNotNil(data)) {
    const dataToUse = getDataWithTransformedReferences(data, availableTypes);

    return R.mergeRight(telInvoiceInitFields, setConfigOptionGuidToEntity(dataToUse));
  }

  return telInvoiceInitFields;
};

const getFields1 = (activeDriver: string, isVendorInvoice: boolean) => {
  if (R.or(G.isTrue(isVendorInvoice), R.equals(activeDriver, 'vendor'))) {
    return R.compose(
      R.append(payTypeField),
      R.dropLast(1),
    )(commonFields1);
  }

  return R.compose(
    R.map(
      R.when(
        R.propSatisfies(R.equals(R.__, GC.FIELD_GL_CODE), 'fieldName'),
        R.assocPath(['inputWrapperStyles', 'mr'], 0),
      ),
    ),
    R.dropLast(1),
  )(commonFields1);
};

const InvoiceSection = (props: Object) => {
  const {
    values,
    branchGuid,
    isEditMode,
    authorities,
    activeDriver,
    asyncConfigs,
    glCodeMappings,
    totalCustomersRate,
  } = props;

  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  const chargesArrayName = getChargesArrayNameFromProps(props);
  const optionsForSelect = setOptionsForSelect('DPStatusConfig', props);
  const isVendorInvoice = R.pathEq(GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE, ['type'], values);

  const stopsQuantity = R.pathOr(
    R.pathOr(0, ['stopsQuantity'], props),
    [GC.FIELD_TEL, GC.FIELD_LOAD_STOPS, 'length'],
    props,
  );

  const glCodeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  );

  const fields1 = getFields1(activeDriver, isVendorInvoice);

  const fields2 = R.compose(
    R.map(
      R.when(
        R.propSatisfies(R.equals(R.__, GC.FIELD_INVOICE_PAYMENT_DUE_DATE), 'fieldName'),
        R.assocPath(['inputWrapperStyles', 'mr'], 0),
      ),
    ),
    R.dropLast(1),
  )(commonFields2);

  const fields3 = R.append(customerTotalField, generalFields);

  return (
    <Box width='100%'>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={fields1}
        fieldsWrapperStyles={{ px: 10, mt: 20, mb: 25 }}
        handlers={{
          handleDisableGlCode: () => G.notContain(PC.GL_CODE_WRITE, authorities),
        }}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields2}
        fieldsWrapperStyles={{ px: 10, flexWrap: 'nowrap' }}
      />
      <PaymentsArray {...props} />
      <ReferencesArray {...props} scope={GC.REF_SCOPE_NAME_FLEET_INVOICE} />
      <PriceSheetItems
        {...G.getFormikProps(props)}
        tableMarginBottom={20}
        editable={getIsPriceSheetItemFieldsEditable(values)}
        additionalFormHeaderSectionStyles={{ my: 0, mb: 20 }}
      />
      <SectionDivider
        text={G.getWindowLocale('titles:comments', 'Comments', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={commentFields}
        fieldsWrapperStyles={{ px: 10 }}
      />
      <SectionDivider
        text={G.getWindowLocale('titles:rate', 'Rate', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={fields3}
        fieldsWrapperStyles={{ mb: 25, px: 10, flexWrap: 'nowrap' }}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={telTotalFields}
        fieldsWrapperStyles={{ mb: 10, px: 10, flexWrap: 'nowrap' }}
      />
      <DMChargeComponent
        {...getTotalsFromValues(values)}
        isInvoice={true}
        rateProps={props}
        currency={currency}
        isFleetInvoice={true}
        loadType={GC.FIELD_TEL}
        isEditMode={isEditMode}
        branchGuid={branchGuid}
        activeTab={activeDriver}
        authorities={authorities}
        serviceDays={serviceDays}
        isVendor={isVendorInvoice}
        stopsQuantity={stopsQuantity}
        glCodeOptions={glCodeOptions}
        glCodeMappings={glCodeMappings}
        normalizedTotal={normalizedTotal}
        isVendorInvoice={isVendorInvoice}
        chargesArrayName={chargesArrayName}
        totalCustomersRate={totalCustomersRate}
      />
    </Box>
  );
};

const infoCommonProps = {
  mr: 20,
  p: '8px',
  minWidth: 200,
  maxWidth: 250,
  borderRadius: '8px',
  bg: G.getTheme('colors.dark.lightGrey'),
};

const DriverInfo = ({ title, children }: Object) => (
  <Box {...infoCommonProps}>
    <TextComponent
      minWidth='100%'
      maxWidth='100%'
      withEllipsis={true}
      whiteSpace='pre-line'
      wordBreak='break-all'
    >
      <Flex justifyContent='spaceBetween'>
        <Box fontSize='14px' fontWeight='600' textTransform='uppercase'>{`${title}:`}</Box>
      </Flex>
      {children}
    </TextComponent>
  </Box>
);

const SWITCH_RATE_OPTIONS = [
  {
    width: 100,
    value: 'primary',
    name: G.getWindowLocale('titles:primary-driver', 'Primary Driver'),
  },
  {
    width: 100,
    value: 'team',
    name: G.getWindowLocale('titles:team-driver', 'Team Driver'),
  },
  {
    width: 100,
    value: 'vendor',
    name: G.getWindowLocale('titles:vendor', 'Vendor'),
  },
];

const selectedIndexMap = {
  'primary': 0,
  'team': 1,
  'vendor': 2,
};

const renderSwitch = (props: Object) => {
  const { activeDriver, handleChangeDriver } = props;

  let selectedOption = R.pathOr(0, [activeDriver], selectedIndexMap);
  let options = SWITCH_RATE_OPTIONS;

  if (R.not(hasSecondaryDriver(props))) {
    if (R.equals(selectedOption, 2)) selectedOption = R.dec(selectedOption);

    options = R.reject(R.propEq('team', 'value'), options);
  }

  if (R.not(hasFleetVendor(props))) {
    options = R.reject(R.propEq('vendor', 'value'), options);
  }

  if (R.or(hasSecondaryDriver(props), hasFleetVendor(props))) {
    return (
      <Switcher
        version={2}
        options={options}
        onSwitch={handleChangeDriver}
        selectedOptionIndex={selectedOption}
      />
    );
  }

  return null;
};

const DriversSwitch = (props: Object) => (
  <Flex p={20} justifyContent='center' bg={G.getTheme('modal.bgColor')}>
    <DriverInfo title={G.getWindowLocale('titles:driver-vendor-info', 'Driver/Vendor Info')}>
      {getDriverVendorInfo(props)}
    </DriverInfo>
    {
      R.or(hasSecondaryDriver(props), hasFleetVendor(props)) &&
      <Flex mr={20} alignItems='center'>
        {G.getWindowLocale('titles:invoice-for', 'Invoice For')}
      </Flex>
    }
    { renderSwitch(props) }
  </Flex>
);

const enhance = compose(
  withState('activeDriver', 'setActiveDriver', (props: Object) => {
    const fleetVendorCharges = R.path(['selectedRate', 'fleetVendorCharges'], props);
    const fleetVendorGuid = R.path(['selectedRate', 'fleetAssignment', 'truck', 'fleetVendorGuid'], props);

    return G.ifElse(
      G.isAllNotNilOrNotEmpty([fleetVendorGuid, fleetVendorCharges]),
      'vendor',
      'primary',
    );
  }),
  withHandlers({
    handleChangeDriver: ({ setActiveDriver }: Object) => (value: string) => (
      setActiveDriver(value)
    ),
  }),
  pure,
);

const getInvoiceType = ({ activeDriver }: Object) => G.ifElse(
  R.equals(activeDriver, 'vendor'),
  GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE,
  GC.INVOICE_TYPE_FLEET_INVOICE,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
  availableTypes: makeSelectAvailableReferenceTypesByScope(state),
});

const enhanceCreate = compose(
  connect(mapStateToProps),
  withProps(() => ({ isInvoice: true, isEditMode: false, configsNamesArray: configsNamesArrayForCreateInvoice })),
  withAsyncTelInvoiceAssignments,
  withAsyncChargeAccessorials(),
  enhance,
  withState('origSequence', 'setOrigSequence', null),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultInvoiceValues(props, true),
    handleSubmit: (values: Object, { props }: Object) => {
      const { isInvoice, isEditMode, activeDriver, origSequence, handleSendFleetInvoice } = props;

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, G.getPropFromObject(GC.FIELD_TEL_GUID, values)),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const type = getInvoiceType(props);
      const driverGuid = getDriverGuid(props);

      const charges = G.mapOmitObjectSystemFields(
        G.omitEmptyChargesFromCharges(getChargesFromValues({ values, isInvoice, isEditMode, activeDriver })),
      );

      const chargesToUse = R.reject(R.propEq(true, GC.FIELD_CHARGE_INTERNAL_EXPENSE), charges);
      const fleetVendorGuid = R.path(['selectedRate', 'fleetAssignment', 'truck', 'fleetVendorGuid'], props);

      const items = R.map(G.omitObjectSystemFields, R.propOr([], 'items', values));

      const invoice = R.compose(
        R.mergeLeft({
          type,
          items,
          references,
          driverGuid,
          fleetVendorGuid,
          [GC.FIELD_CHARGES]: chargesToUse,
        }),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        R.omit(invoiceFieldsToOmit),
        G.omitEmptyPaymentsFromData,
      )(values);

      if (R.equals(R.prop(GC.FIELD_INVOICE_NUMBER, invoice), origSequence)) {
        handleSendFleetInvoice(R.assoc(GC.FIELD_INVOICE_NUMBER, null, invoice));
      } else {
        handleSendFleetInvoice(invoice);
      }
    },
    displayName: 'DRIVER_INVOICE_FORM',
  }),
  withAsyncSequence,
  withPropsOnChange(
    ['asyncSequence'],
    (props: Object) => {
      const { asyncSequence, setFieldValue, setOrigSequence, defaultInvoiceName } = props;

      const value = G.ifElse(
        G.isNotNil(asyncSequence),
        asyncSequence,
        defaultInvoiceName,
      );

      if (G.isNotNil(asyncSequence)) setOrigSequence(asyncSequence);

      setFieldValue(GC.FIELD_INVOICE_NUMBER, value);
    },
  ),
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_DRIVER),
  withAsyncGetFleetVendorPayToLocation,
  pure,
);

const enhanceAsyncUpdate = compose(
  connect(mapStateToProps),
  withAsyncInitialDataOnDidMount,
  withProps(() => ({
    isAsync: true,
    isInvoice: true,
    isEditMode: true,
    configsNamesArray: configsNamesArrayForUpdateInvoice,
  })),
  withAsyncConfigs,
  withAsyncChargeAccessorials(),
  withAsyncInvoiceStatusConfigs,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => asyncSetDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { isInvoice, isEditMode, activeDriver, handleSendFleetInvoice } = props;

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, G.getPropFromObject(GC.FIELD_TEL_GUID, values)),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const charges = G.omitEmptyChargesFromCharges(
        getChargesFromValues({ values, isInvoice, isEditMode, activeDriver }),
      );

      const invoice = R.compose(
        R.assoc(GC.FIELD_CHARGES, charges),
        R.assoc(GC.FIELD_REFERENCES, references),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
      )(values);

      handleSendFleetInvoice(invoice);
    },
    displayName: 'ASYNC_EDIT_DRIVER_INVOICE_FORM',
  }),
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_DRIVER),
  pure,
);

const getEditInvoiceTitle = ({ initialValues }: Object) => {
  const { type, driver, fleetVendor } = initialValues;

  if (G.isNilOrEmpty(type)) return G.getWindowLocale('titles:edit-invoice', 'Edit Invoice');

  const vendorName = R.pathOr('', [GC.FIELD_NAME], fleetVendor);

  const { fullText } = G.getUserInfo(driver);

  const title = G.ifElse(
    G.isInvoiceTypeFleetVendorInvoice(type),
    `${G.getWindowLocale('titles:edit-invoice-for-vendor', 'Edit Invoice For Vendor')} - ${vendorName}`,
    `${G.getWindowLocale('titles:edit-invoice-for-driver', 'Edit Invoice For Driver')} - ${fullText}`,
  );

  return title;
};

const DriverInvoiceForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <StickedBox top='0px' zIndex={1001}>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:add-driver-invoice', 'Add Driver Invoice')}
      />
      <DriversSwitch {...props} />
    </StickedBox>
    <SectionsDivider />
    <InvoiceSection {...props} />
    <StickedBox bottom='0px' zIndex={102}>
      <ChargesTotal {...props} isInvoice={true} charges={getChargesFromValues(props)} />
      <ChargeFormFooter closeModal={props.closeModal} />
    </StickedBox>
  </form>
);

export const AsyncEditDriverInvoiceForm = enhanceAsyncUpdate((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <StickedBox top='0px' zIndex={1001}>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={getEditInvoiceTitle(props)}
      />
    </StickedBox>
    <LocalLoader localLoaderOpen={R.pathOr(true, ['asyncInitialData', 'loading'], props)}>
      <InvoiceSection {...props} />
    </LocalLoader>
    <StickedBox bottom='0px' zIndex={102}>
      <ChargesTotal {...props} charges={getChargesFromValues(props)} />
      <ChargeFormFooter closeModal={props.closeModal} />
    </StickedBox>
  </form>
));

export default enhanceCreate(DriverInvoiceForm);
