import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
import { DivIcon, polygon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { Map, Marker, TileLayer } from 'react-leaflet';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withWindowSize } from '../../../hocs';
// components
import { StopMarker } from '../../../components/map/components/stop-components';
// ui
import { Box, StickedBox } from '../../../ui';
// feature load-board
import { CustomMarker, CustomMarkerContent } from './ui';
//////////////////////////////////////////////////

const getMapCenterAndBounds = (locations: Array = []) => {
  if (G.isNilOrEmpty(locations)) return { mapCenter: [38.755157, -98.269035] };

  const bounds = R.map((latLng: Object) => R.values(latLng), locations);

  return {
    bounds,
    center: polygon(bounds).getBounds().getCenter(),
  };
};

const locationsWithContent = (stops: Array) => (
  R.map((stop: Object) => ({
    ...stop,
    markerContent: <StopMarker {...stop} />,
  }), stops)
);

const MarkersWithInfo = ({location}: Object) => (
  <Box transform='translate(0, -91%)'>
    <CustomMarker
      color={location.color}
      active={location.isOpen}
      transform={location.transform}
    >
      <CustomMarkerContent>
        {location.markerContent}
      </CustomMarkerContent>
    </CustomMarker>
  </Box>
);

const Markers = ({ markerLocations }: Array) => (
  markerLocations.map((location: Object, index: number) => {
    const { latLng } = location;

    const markerComponent = <MarkersWithInfo location={location} />;

    const icon = new DivIcon({
      iconSize: [36, 36],
      className: 'route-marker',
      html: renderToString(markerComponent),
    });

    const markerKey = `route-marker-${index}`;

    return <Marker key={markerKey} position={latLng} icon={icon} />;
  })
);

const OtherBlocksHeightSum = 435;

const DetailsMap = (props: Object) => {
  const {
    stops,
    locations,
  } = props;

  const markerLocations = G.makeLocationsWithTransform(locationsWithContent(stops));
  const { bounds, center } = getMapCenterAndBounds(locations);

  return (
    <StickedBox
      left='0'
      p='0 15px'
      width='100%'
    >
      <Map
        zoom={4}
        maxZoom={18}
        bounds={bounds}
        center={center}
        css={css`
          z-index: 1;
          width: 100%;
          height: calc(100vh - ${OtherBlocksHeightSum}px);
        }`}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Markers markerLocations={markerLocations} />
      </Map>
    </StickedBox>
  );
};

export default withWindowSize(DetailsMap);
