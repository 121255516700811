import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  printByReportRequest,
  setIgnorePromptStatus,
  getXMLByReportRequest,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const getConfigsRequest = createAction('getConfigsRequest');
const getConfigsSuccess = createAction('getConfigsSuccess');
const setAdditionalFilters = createAction('setAdditionalFilters');
const getOrderBillToRequest = createAction('getOrderBillToRequest');
const changeInvoicesRequest = createAction('changeInvoicesRequest');
const changeInvoicesSuccess = createAction('changeInvoicesSuccess');
const readyForBillingRequest = createAction('readyForBillingRequest');
const readyForBillingSuccess = createAction('readyForBillingSuccess');
const createReferenceRequest = createAction('createReferenceRequest');
const createReferenceSuccess = createAction('createReferenceSuccess');
const sendToFinancialRequest = createAction('sendToFinancialRequest');
const printInvoiceListRequest = createAction('printInvoiceListRequest');
const updateCloInvoiceSuccess = createAction('updateCloInvoiceSuccess');
const updateCloInvoiceRequest = createAction('updateCloInvoiceRequest');
const getOrderInvoiceXMLRequest = createAction('getOrderInvoiceXMLRequest');

export {
  // report
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  printByReportRequest,
  setQuickFilterParams,
  getOrderBillToRequest,
  setIgnorePromptStatus,
  getXMLByReportRequest,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  getConfigsRequest,
  getConfigsSuccess,
  setAdditionalFilters,
  changeInvoicesRequest,
  changeInvoicesSuccess,
  createReferenceRequest,
  createReferenceSuccess,
  readyForBillingRequest,
  readyForBillingSuccess,
  sendToFinancialRequest,
  printInvoiceListRequest,
  updateCloInvoiceSuccess,
  updateCloInvoiceRequest,
  getOrderInvoiceXMLRequest,
};
