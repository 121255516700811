import * as R from 'ramda';
import { put, fork, call, take, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { setUsedReportStatus } from '../report-format/actions';
import { getCurrentUserSettingsSuccess } from '../profile/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature menus
import * as A from './actions';
import { makeSelectNavItems, makeSelectDropdownItems } from './selectors';
//////////////////////////////////////////////////

// TODO: remove setUsedReportStatus for new lists

const filterMenuItems = (menuItems: Array=[]) => R.differenceWith(
  (menuItem: Object, action: string) => R.equals(menuItem.action, action),
  menuItems,
  [],
);

// NOTE: remove it on API later. Today - 18.05.2020
const filterSubMenuCreateCLO = R.filter(
  (menuItem: Object) => G.notEquals(menuItem.action, 'createCLO'),
);

export function* getMenusSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.menus);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      let sideMenuItemsByUserType = data.sideMenuItems;
      if (G.isCurrentUserTypeCarrier()) {
        sideMenuItemsByUserType = R.filter(
          (item: Object) => G.notEquals(item.title, 'Invoices'),
          sideMenuItemsByUserType,
        );
      }
      const sortedData = {
        ...data,
        sideMenuItems: R.map(
          (menuItem: Object) => R.assoc(
            'submenu',
            filterMenuItems(filterSubMenuCreateCLO(menuItem.submenu)),
            menuItem,
          ),
          filterMenuItems(sideMenuItemsByUserType),
        ),
        navigationMenuItems: R.sortBy(R.prop('navBarOrder'), data.navigationMenuItems),
      };
      yield put(A.receivedMenusSuccess(sortedData));
      return true;
    }
    throw new Error('getMenusSaga fail');
  } catch (error) {
    return false;
  }
}

export function* handleNavItemsClickSaga() {
  while (true) { // eslint-disable-line
    const request = yield take(A.clickOnItemAction);
    const action = request.payload;
    switch (action) {
      case GC.MENU_ACTION_BRANCH_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.branchList);
        break;
      case GC.MENU_ACTION_CLO_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.cloList);
        break;
      case GC.MENU_ACTION_ROUTE_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.routeList);
        break;
      case GC.MENU_ACTION_TEL_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.telList);
        break;
      case GC.MENU_ACTION_USERS_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.usersPage);
        break;
      case GC.MENU_ACTION_ROLES_LIST:
        yield call(G.goToRoute, routesMap.rolesPage);
        break;
      case GC.MENU_ACTION_ITEMS_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.itemsList);
        break;
      case GC.MENU_ACTION_REFERENCE_TYPES_LIST:
        yield call(G.goToRoute, routesMap.referencesList);
        break;
      case GC.MENU_ACTION_FLEET_DRIVERS_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetDriverList);
        break;
      case GC.MENU_ACTION_FLEET_VENDOR_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetVendorList);
        break;
      case GC.MENU_ACTION_FLEET_TRUCKS_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetTruckList);
        break;
      case GC.MENU_ACTION_FLEET_TRAILERS_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetTrailerList);
        break;
      case GC.MENU_ACTION_FLEET_SERVICE_ISSUES_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetServiceIssueList);
        break;
      case GC.MENU_ACTION_FLEET_EQUIPMENT_SERVICES_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetEquipmentServiceList);
        break;
      case GC.MENU_ACTION_FLEET_SHARED_COMPONENT_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.fleetSharedComponentList);
        break;
      case GC.MENU_ACTION_WORK_ORDER_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.workOrderList);
        break;
      case GC.MENU_ACTION_LOCATION_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.locationsList);
        break;
      case GC.MENU_ACTION_REPORT_FORMATS_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.reportsList);
        break;
      case GC.MENU_ACTION_CHART_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.chartList);
        break;
      case GC.MENU_ACTION_ROUTE_BETA:
        const amousDefaultRouteTab = G.getItemFromWindow('amousDefaultRouteTab');
        const route = G.ifElse(
          R.equals(amousDefaultRouteTab, GC.FIELD_CLO),
          routesMap.routeListOrder,
          routesMap.routeListLoad,
        );
        yield call(G.goToRoute, route);
        break;
      case GC.MENU_ACTION_STYLING_CONFIG:
        yield call(G.goToRoute, routesMap.stylingConfig);
        break;
      case GC.MENU_ACTION_SEQUENCE_LIST:
        yield call(G.goToRoute, routesMap.sequencesList);
        break;
      case GC.MENU_ACTION_CREATE_CLO:
        yield call(G.goToRoute, routesMap.createCLO);
        break;
      case GC.MENU_ACTION_CONFIG_CLO:
        yield call(G.goToRoute, routesMap.configCLO);
        break;
      case GC.MENU_ACTION_CONFIG_TEL:
        yield call(G.goToRoute, routesMap.configTEL);
        break;
      case GC.MENU_ACTION_CONFIG_GENERAL:
        yield call(G.goToRoute, routesMap.configGeneral);
        break;
      case GC.MENU_ACTION_CONFIG_MASTER:
        yield call(G.goToRoute, routesMap.configOptional);
        break;
      case GC.MENU_ACTION_CONFIG_PASSWORD:
        yield call(G.goToRoute, routesMap.configPassword);
        break;
      case GC.MENU_ACTION_CONFIG_TEMPLATES:
        yield call(G.goToRoute, routesMap.configTemplates);
        break;
      case GC.MENU_ACTION_CONFIG_FLEET_GENERAL:
        yield call(G.goToRoute, routesMap.configFleetGeneral);
        break;
      case GC.MENU_ACTION_CONFIG_DRIVER:
        yield call(G.goToRoute, routesMap.configDriver);
        break;
      case GC.MENU_ACTION_CONFIG_TRUCK:
        yield call(G.goToRoute, routesMap.configTruck);
        break;
      case GC.MENU_ACTION_CONFIG_TRAILER:
        yield call(G.goToRoute, routesMap.configTrailer);
        break;
      case GC.MENU_ACTION_CONFIG_COMMUNICATION:
        yield call(G.goToRoute, routesMap.configCommunication);
        break;
      case GC.MENU_ACTION_CONFIG_INVOICES:
        yield call(G.goToRoute, routesMap.configInvoices);
        break;
      case GC.MENU_ACTION_CONFIG_RATING:
        yield call(G.goToRoute, routesMap.configRating);
        break;
      case GC.MENU_ACTION_CONFIG_UI:
        yield call(G.goToRoute, routesMap.configUI);
        break;
      case GC.MENU_ACTION_CONFIG_INTEGRATION:
        yield call(G.goToRoute, routesMap.configIntegration);
        break;
      case GC.MENU_ACTION_CONFIG_MOBILEAPP:
        yield call(G.goToRoute, routesMap.configMobileApp);
        break;
      case GC.MENU_ACTION_DRIVER_INVOICES_LIST:
        yield call(G.goToRoute, routesMap.driverInvoice);
        break;
      case GC.MENU_ACTION_VENDOR_INVOICES_LIST:
        yield call(G.goToRoute, routesMap.vendorInvoice);
        break;
      case GC.MENU_ACTION_CUSTOMER_INVOICES_LIST:
        yield call(G.goToRoute, routesMap.customerInvoice);
        break;
      case GC.MENU_ACTION_CARRIER_INVOICES_LIST:
        yield call(G.goToRoute, routesMap.carrierInvoice);
        break;
      case GC.MENU_ACTION_SERVICE_VENDOR_INVOICE_LIST:
        yield call(G.goToRoute, routesMap.serviceVendorInvoice);
        break;
      case GC.MENU_ACTION_DRIVER_PAYROLL_LIST:
        yield call(G.goToRoute, routesMap.driverPayrollList);
        break;
      case GC.MENU_ACTION_CONFIG_AUDIT:
        yield call(G.goToRoute, routesMap.configAudit);
        break;
      case GC.MENU_ACTION_TEMPLATES_DO_LIST:
        yield call(G.goToRoute, routesMap.doTemplates);
        break;
      case GC.MENU_ACTION_ANALYTICS:
        yield call(G.goToRoute, routesMap.analyticsDashboard);
        break;
      case GC.MENU_ACTION_DASHBOARDS:
        yield call(G.goToRoute, routesMap.dashboards);
        break;
      case GC.MENU_ACTION_ANALYTIC_MAPS:
        yield call(G.goToRoute, routesMap.analyticMaps);
        break;
      case GC.MENU_ACTION_CUSTOMER_PORTAL:
        yield call(G.goToRoute, routesMap.customerPortal);
        break;
      case GC.MENU_ACTION_IMPORT_REPORT:
        yield call(G.goToRoute, routesMap.importReport);
        break;
      case GC.MENU_ACTION_INSPECTIONS:
        yield call(G.goToRoute, routesMap.inspections);
        break;
      case GC.MENU_ACTION_CARRIER_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.carrierList);
        break;
      case GC.MENU_ACTION_CONFIG_CARRIER:
        yield call(G.goToRoute, routesMap.configCarrier);
        break;
      case GC.MENU_ACTION_ROUTE_BUILDER:
        yield call(G.goToRoute, routesMap.routeBuilder);
        break;
      case GC.MENU_ACTION_IMPORTS:
        yield call(G.goToRoute, routesMap.imports);
        break;
      case GC.MENU_ACTION_EXTERNAL_LOADBOARD:
        yield call(G.goToRoute, routesMap.externalloadboard);
        break;
      case GC.MENU_ACTION_CUSTOMER_MASTER_INVOICE_LIST:
        yield call(G.goToRoute, routesMap.customerMasterInvoice);
        break;
      case GC.MENU_ACTION_FUEL_CARDS:
        yield call(G.goToRoute, routesMap.fuelCards);
        break;
      case GC.MENU_ACTION_CONTACT_BOOK:
        yield call(G.goToRoute, routesMap.contactBook);
        break;
      case GC.MENU_ACTION_COMPENSATION_TEMPLATE_LIST:
        yield call(G.goToRoute, routesMap.compensationTemplates);
        break;
      case GC.MENU_ACTION_CARRIER_CONTRACT_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.carrierContracts);
        break;
      case GC.MENU_ACTION_CUSTOMER_CONTRACT_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.customerContracts);
        break;
      case GC.MENU_ACTION_CHARGE_REPORTS:
        yield call(G.goToRoute, routesMap.cloRateChargeReport);
        break;
      case GC.MENU_ACTION_SHARED_ACCESSORIAL_LIST:
        yield call(G.goToRoute, routesMap.sharedAccessorialList);
        break;
      case GC.MENU_ACTION_AVERAGE_FUEL_PRICE:
        yield call(G.goToRoute, routesMap.averageFuelPrice);
        break;
      case GC.MENU_ACTION_GEO_FENCING_ZONE:
        yield call(G.goToRoute, routesMap.geoFencingZoneList);
        break;
      case GC.MENU_ACTION_GEO_FENCING_ZONE_REPORT:
        yield call(G.goToRoute, routesMap.reportGeoFencingZoneList);
        break;
      case GC.MENU_ACTION_DRIVERS_CARD:
        yield call(G.goToRoute, routesMap.driversCard);
        break;
      case GC.MENU_ACTION_AVAILABLE_DRIVERS:
        yield call(G.goToRoute, routesMap.availableDrivers);
        break;
      case GC.MENU_ACTION_LOAD_PLANNER:
        yield call(G.goToRoute, routesMap.routeBuilderEvents);
        break;
      case GC.MENU_ACTION_TOLL_CHARGES:
        yield call(G.goToRoute, routesMap.tollCharges);
        break;
      case GC.MENU_ACTION_IFTA_REPORT:
        yield call(G.goToRoute, routesMap.iftaReport);
        break;
      case GC.MENU_ACTION_DISPATCHING_GROUP:
        yield call(G.goToRoute, routesMap.dispatchingGroup);
        break;
      case GC.MENU_ACTION_CONTAINER_TYPE_LIST:
        yield call(G.goToRoute, routesMap.containerTypes);
        break;
      case GC.MENU_ACTION_CONTAINER_TEMPLATE_LIST:
        yield put(setUsedReportStatus(false));
        yield call(G.goToRoute, routesMap.containerTemplates);
        break;
      case GC.MENU_ACTION_CURRENCY_EXCHANGE_RATE:
        yield call(G.goToRoute, routesMap.normalizeCurrencyList);
        break;
      case GC.MENU_ACTION_ROUTE_TEMPLATES:
        yield call(G.goToRoute, routesMap.routeTemplates);
        break;
      case GC.MENU_ACTION_LOAD_PLANNER_TEMPLATE:
        yield call(G.goToRoute, routesMap.loadPlannerTemplate);
        break;
      case GC.MENU_ACTION_INSPECTION_TEMPLATES:
        yield call(G.goToRoute, routesMap.inspectionTemplates);
        break;
      case GC.MENU_ACTION_GEO_FENCING_LOCATION:
        yield call(G.goToRoute, routesMap.geoFencingLocation);
        break;
      case GC.MENU_ACTION_COMMISSION_ASSIGNEE:
        yield call(G.goToRoute, routesMap.commissionAssignment);
        break;
      case GC.MENU_ACTION_CARRIER_RATING_REPORT:
        yield call(G.goToRoute, routesMap.carrierRatingReport);
        break;
      case GC.MENU_ACTION_SERVICE_VENDOR_LIST:
        yield call(G.goToRoute, routesMap.serviceVendorList);
        break;
      case GC.MENU_ACTION_CONFIG_SERVICE_VENDOR:
        yield call(G.goToRoute, routesMap.configServiceVendor);
        break;
      case GC.MENU_ACTION_RATE_SHOP:
        yield call(G.goToRoute, routesMap.rateShop);
        break;
      case GC.MENU_ACTION_ROUTE_MASS_CREATE_REPORT:
        yield call(G.goToRoute, routesMap.routeMassCreateReport);
        break;
      default:
        yield put({ type: 'DEFAULT_CLICK_NAV_ITEM_ACTION' });
        break;
    }
  }
}

export function* handleAddItemToNavSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = {
      ...payload,
      placeholder: 'both',
    };
    const res = yield call(sendRequest, 'put', endpointsMap.menuItem, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const navItems = yield select(makeSelectNavItems());
      const dropdownItems = yield select(makeSelectDropdownItems());
      let items = {};
      if (R.isEmpty(dropdownItems)) {
        items = {
          dropdown: dropdownItems,
          nav: R.concat(navItems, R.of(Array, payload)),
        };
      } else {
        items = {
          nav: navItems,
          dropdown: R.concat(dropdownItems, R.of(Array, payload)),
        };
      }
      yield put(closeLoader());
      yield put(A.splitNavItemsAction(items));
      yield put(A.addItemToNavSuccess(R.sortBy(R.prop('navBarOrder'), res.data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleAddItemToNavSaga fail');
    }
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddItemToNavSaga exception');
  }
}

export function* handleDeleteNavItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = R.assoc('placeholder', 'sideMenu', payload);
    const res = yield call(sendRequest, 'put', endpointsMap.menuItem, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const navItems = yield select(makeSelectNavItems());
      const dropdownItems = yield select(makeSelectDropdownItems());
      const items = {
        nav: R.filter(R.where({ guid: G.notEquals(payload.guid) }), navItems),
        dropdown: R.filter(R.where({ guid: G.notEquals(payload.guid) }), dropdownItems),
      };
      yield put(A.splitNavItemsAction(items));
      yield put(A.deleteNavItemSuccess(R.sortBy(R.prop('navBarOrder'), res.data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteNavItemSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteNavItemSaga exception');
  }
}

export function* menusWatcherSaga() {
  yield fork(handleNavItemsClickSaga);
  yield takeLatest(A.getMenusRequest, getMenusSaga);
  yield takeLatest(getCurrentUserSettingsSuccess, getMenusSaga);
  yield takeLatest(A.addItemToNavRequest, handleAddItemToNavSaga);
  yield takeLatest(A.deleteNavItemRequest, handleDeleteNavItemSaga);
}

export default menusWatcherSaga;
