import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// features
import { makeSelectCurrentUserData } from '../auth/selectors';
// helpers/constants
import * as G from '../../helpers';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature locale
import * as A from './actions';
//////////////////////////////////////////////////

function* setLocaleToWindow(data: Object) {
  try {
    const isLocaleUnavailable = G.isNilOrEmpty(R.path(['localStorage', 'amousLocale'], window));
    window.localStorage.amousLocale = JSON.stringify(data);
    if (isLocaleUnavailable) window.location.reload();
  } catch (error) {
    yield call(G.handleException, error, 'getLocaleSaga exception');
  }
}

export function* getLocaleSaga() {
  try {
    const userSettings = yield select(makeSelectCurrentUserData());

    const lng = R.propOr('ENGLISH', 'locale', userSettings);

    const url = yield call(endpointsMap.getLocaleEndpoint, lng);

    const res = yield call(sendRequest, 'get', url);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.isEmpty(data)) {
        yield put(A.initialLoadLngFail());
      } else {
        yield call(setLocaleToWindow, data);

        yield put(A.initialLoadLngSuccess(lng, {[lng]: data}));
      }
    } else {
      yield put(A.initialLoadLngFail());
    }

    return true;
  } catch (error) {
    yield put(A.initialLoadLngFail());

    yield call(G.handleException, error, 'getLocaleSaga exception');

    return true;
  }
}

function* handleChangeLngRequest(action: Object) {
  try {
    const lng = action.payload.lng;
    const url = yield call(endpointsMap.getLocaleEndpoint, lng);
    const res = yield call(sendRequest, 'get', url);
    if (G.isResponseSuccess(res.status)) {
      yield put(A.changeLngSuccess(lng, {[lng]: res.data}));
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeLngRequest fail', true);
      yield put(A.changeLngFail());
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeLngRequest exception');
    yield put(A.changeLngFail());
  }
}

function* localesWatcherSaga() {
  yield takeLatest(A.changeLngRequest, handleChangeLngRequest);
}

export default localesWatcherSaga;
