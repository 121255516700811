import React from 'react';
import { pure } from 'react-recompose';
// features
import PC from '../../permission/role-permission';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// feature invoice
import { getFleetInvoiceSectionSettings } from '../settings/formik-settings';
//////////////////////////////////////////////////

export const FleetInvoiceSection = pure((props: Object) => {
  const { invoiceType, authorities, optionsForSelect } = props;

  return (
    <Fieldset2
      {...G.getFormikProps(props)}
      {...optionsForSelect}
      fieldsWrapperStyles={{ mt: 25, px: 20 }}
      fields={getFleetInvoiceSectionSettings(invoiceType)}
      handlers={{ handleDisableGlCode: () => G.notContain(PC.GL_CODE_WRITE, authorities) }}
    />
  );
});
