import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
import { pure, compose, withState } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { Fieldset2 } from '../../../forms/formik';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature invoice
import { getPaymentFields, getPaymentNewFields } from '../settings/common-fields';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withState('expanded', 'setExpanded', false),
  pure,
);

const Header = (props: Object) => {
  const {
    push,
    total,
    title,
    expanded,
    setExpanded,
  } = props;

  const totalText = `(${total})`;
  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Flex
      p='5px'
      fontWeight='bold'
      color={darkBlueColor}
      justifyContent='center'
      alignItems='flex-start'
      textTransform='uppercase'
      bg={G.getTheme('colors.whiteGrey')}
    >
      <Box mr={15} ml='auto'>{title} {totalText}</Box>
      <Box
        cursor='pointer'
        onClick={() => {
          push(getPaymentNewFields());

          if (R.not(expanded)) {
            setExpanded(true);
          }
        }}
      >
        {I.plusRound(darkBlueColor)}
      </Box>
      <Box
        px='6px'
        ml='auto'
        cursor='pointer'
        onClick={() => setExpanded(R.not(expanded))}
      >
        {I[toggleIconName](darkBlueColor)}
      </Box>
    </Flex>
  );
};

const PaymentRow = (props: Object) => {
  const { remove, itemIndex, arrayName, arrayLength } = props;

  return (
    <Flex mt={25}>
      <Box>
        <Flex
          mx={10}
          display='flex'
          cursor='pointer'
          alignItems='center'
          onClick={() => remove(itemIndex)}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(darkBlueColor)}
        </Flex>
      </Box>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...G.getArrayFormikProps(props)}
        arrayName={arrayName}
        fieldsWrapperStyles={{ px: 10 }}
        fields={getPaymentFields(R.add(11, R.subtract(arrayLength, itemIndex)))}
      />
    </Flex>
  );
};

const Balance = ({ text }: Object) => (
  <Flex mt={10} px={10} justifyContent='end'>
    <Flex>
      <Text
        fontSize='14px'
        fontWeight='bold'
        textTransform='uppercase'
        color={G.getTheme('colors.darkGrey')}
      >
        {`${G.getWindowLocale('titles:balance', 'Balance')}:`}
      </Text>
      <TextComponent
        ml={10}
        title={text}
        fontSize={14}
        fontWeight='bold'
        withEllipsis={true}
        display='inline-block'
        color={G.getTheme('colors.black')}
      >
        {text}
      </TextComponent>
    </Flex>
  </Flex>
);

const getTotal = R.reduce(
  (total: number, { checkAmount }: Object) => R.add(total, G.NaNToZero(checkAmount)),
  0,
);

const PaymentsArray = enhance((props: Object) => {
  const {
    values,
    expanded,
    setExpanded,
  } = props;

  const currency = R.pathOr('', [GC.FIELD_CURRENCY], values);
  const payments = R.pathOr([], [GC.FIELD_PAYMENTS], values);
  const paymentsTotal = getTotal(payments);
  const charges = G.getChargesFromObject(values);
  const totalPay = G.getCalcRateChargesTotal(charges, currency);
  const balance = G.mathRoundNumber(R.subtract(totalPay, paymentsTotal));

  return (
    <Box my={20}>
      <FieldArray
        name={GC.FIELD_PAYMENTS}
        render={(arrayHelpers: Object) => (
          <Box>
            <Header
              expanded={expanded}
              total={paymentsTotal}
              push={arrayHelpers.push}
              setExpanded={setExpanded}
              title={G.getWindowLocale('titles:payments', 'Payments')}
            />
            <Box>
              {
                R.and(expanded, G.isNotNilAndNotEmpty(payments)) &&
                payments.map((item: string, i: number) => (
                  <PaymentRow
                    {...props}
                    {...arrayHelpers}
                    key={i}
                    item={item}
                    itemIndex={i}
                    arrayName={GC.FIELD_PAYMENTS}
                    arrayLength={R.length(payments)}
                  />
                ))
              }
            </Box>
          </Box>
        )}
      />
      <Balance text={`${G.getCurrencySymbol(currency)} ${balance}`} />
    </Box>
  );
});

export default PaymentsArray;
