import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { DateRangeMui } from '../../../components/date-range';
import { LocalLoader } from '../../../components/local-loader';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withDataFilterHoc } from '../../../hocs';
// ui
import { Box, Flex } from '../../../ui';
// feature master-invoice
import GroupInvoicesTable from './group-invoices-table';
import { withAddCloDocumentAndPrintCloInvoice } from '../hocs';
//////////////////////////////////////////////////

const enhance = compose(
  withAddCloDocumentAndPrintCloInvoice,
  withDataFilterHoc,
  withState('filterDates', 'setFilterDates', (props: Object) => {
    const dateTo = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);
    return {
      dateTo,
      dateFrom: G.subtractMomentTimeWithFormat(
        dateTo,
        props.initialFilterDays,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      ),
      quickDaysValue: props.initialFilterDays,
    };
  }),
  withHandlers({
    handleSetDates: (props: Object) => (dates: Object) => {
      props.setFilterDates(dates);
      const options = {
        data: R.mergeRight(dates, {
          masterInvoiceGuid: props.data.guid,
          documentTypeGuids: props.documentTypeGuids,
        }),
      };
      props.setLoading();
      props.getFilteredDataRequest(options);
    },
    handleSetFilterQuickDays: (props: Object) => (value: number) => {
      const dates = {
        dateFrom: G.subtractMomentTimeWithFormat(
          props.filterDates.dateTo,
          R.dec(value),
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
        dateTo: props.filterDates.dateTo,
      };
      props.setFilterDates(R.assoc('quickDaysValue', value, dates));
      const options = {
        data: R.mergeRight(dates, {
          masterInvoiceGuid: props.data.guid,
          documentTypeGuids: props.documentTypeGuids,
        }),
      };
      props.setLoading();
      props.getFilteredDataRequest(options);
    },
    handleGetData: ({ data, setLoading, filterDates, documentTypeGuids, getFilteredDataRequest }: Object) => () => {
      const options = {
        data: R.mergeRight(
          {
            documentTypeGuids,
            masterInvoiceGuid: G.getGuidFromObject(data),
          },
          filterDates,
        ),
      };
      setLoading();
      getFilteredDataRequest(options);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetData();
    },
  }),
  pure,
);

const UpdateInvoices = (props: Object) => {
  const {
    data,
    asyncData,
    closeModal,
    branchGuid,
    filterDates,
    documentTypes,
    handleGetData,
    openFixedPopup,
    handleSetDates,
    closeFixedPopup,
    handleAddCloDocument,
    handleSetFilterQuickDays,
    updateMasterInvoiceInvoices,
  } = props;

  return (
    <Box width='95vw' maxWidth={1200} zIndex='unset'>
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:update-invoices', 'Update Invoices')}
      />
      <Flex p={15}>
        <DateRangeMui
          {...filterDates}
          width={80}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          quickDays={GC.QUICK_DAYS_FILTER}
          onSelectDateRange={handleSetDates}
          setQuickDays={handleSetFilterQuickDays}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
          label={G.getWindowLocale('titles:invoices-date-range', 'Invoices Date Range')}
        />
      </Flex>
      <LocalLoader localLoaderOpen={R.pathOr(false, ['loading'], asyncData)}>
        <GroupInvoicesTable
          data={data}
          closeModal={closeModal}
          branchGuid={branchGuid}
          documentTypes={documentTypes}
          handleGetData={handleGetData}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          addDocument={handleAddCloDocument}
          invoices={R.pathOr([], ['data'], asyncData)}
          updateMasterInvoiceInvoices={updateMasterInvoiceInvoices}
        />
      </LocalLoader>
    </Box>
  );
};

export default enhance(UpdateInvoices);

