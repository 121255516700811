// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_SUBSCRIPTION_ID,
    name: G.getWindowLocale('titles:subscription-id', 'Subscription ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_STATUS,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'date',
    value: GC.FIELD_ACCEPT_OR_REJECT_DATE,
    name: G.getWindowLocale('titles:accept-reject-date', 'Accept/Reject Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_ACCEPTED_OR_REJECTED_BY,
    name: G.getWindowLocale('titles:accepted-rejected-by', 'Accepted/Rejected By'),
  },
  {
    type: 'string',
    value: GC.FIELD_REJECT_REASON,
    name: G.getWindowLocale('titles:reject-reason', 'Reject Reason'),
  },
  {
    type: 'date',
    value: GC.FIELD_UNSUBSCRIPTION_DATE,
    name: G.getWindowLocale('titles:unsubscription-date', 'Unsubscription Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_RECEIVED_DATE,
    name: G.getWindowLocale('titles:received-date', 'Received Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_CONFIRMATION_DATE,
    name: G.getWindowLocale('titles:confirmation-date', 'Confirmation Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_MASTER_ENTERPRISE_NAME,
    name: G.getWindowLocale('titles:master-enterprise-name', 'Master Enterprise Name'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_LINKED,
    name: G.getWindowLocale('titles:linked', 'Linked'),
  },
  {
    type: 'string',
    value: GC.FIELD_LINKED_BY,
    name: G.getWindowLocale('titles:linked-by', 'Linked By'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_BRANCH_DELETED,
    name: G.getWindowLocale('titles:branch-deleted', 'Branch Deleted'),
  },
  {
    type: 'string',
    value: GC.GRC.CUSTOMER_DETAILS_NAME,
    name: `${G.getWindowLocale('titles:customer-details', 'Customer Details')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CUSTOMER_DETAILS_FEDERAL_EIN,
    name: `${G.getWindowLocale('titles:customer-details', 'Customer Details')}: ${
      G.getWindowLocale('titles:federal-ein', 'Federal EIN')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CUSTOMER_DETAILS_CONTACT_NUMBER,
    name: `${G.getWindowLocale('titles:customer-details', 'Customer Details')}: ${
      G.getWindowLocale('titles:contact-number', 'Contact Number')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CREATED_BY_USER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:created-by-user', 'Created By User')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CREATED_BY_USER_LAST_NAME,
    name: `${G.getWindowLocale('titles:created-by-user', 'Created By User')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CREATED_BY_USER_EMAIL_ID,
    name: `${G.getWindowLocale('titles:created-by-user', 'Created By User')}: ${
      G.getWindowLocale('titles:email-id', 'Email ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CREATED_BY_USER_CONTACT_NUMBER,
    name: `${G.getWindowLocale('titles:created-by-user', 'Created By User')}: ${
      G.getWindowLocale('titles:contact-number', 'Contact Number')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CONFIRMED_BY_USER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:confirmed-by-user', 'Confirmed By User')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CONFIRMED_BY_USER_LAST_NAME,
    name: `${G.getWindowLocale('titles:confirmed-by-user', 'Confirmed By User')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CONFIRMED_BY_USER_EMAIL_ID,
    name: `${G.getWindowLocale('titles:confirmed-by-user', 'Confirmed By User')}: ${
      G.getWindowLocale('titles:email-id', 'Email ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CONFIRMED_BY_USER_CONTACT_NUMBER,
    name: `${G.getWindowLocale('titles:confirmed-by-user', 'Confirmed By User')}: ${
      G.getWindowLocale('titles:contact-number', 'Contact Number')}`,
  },
  {
    type: 'string',
    value: GC.GRC.LOS_SUBSCRIPTION_ORDER_PLAN_NAME,
    name: `${G.getWindowLocale('titles:order', 'Order')}: ${
      G.getWindowLocale('titles:plan-name', 'Plan Name')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_LOS_PRICING_CONFIG_NAME,
    name: G.getWindowLocale('titles:los-pricing-config-name', 'LOS Pricing Config Name'),
  },
];
