import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const MissingDocumentTypes = (props: Object) => (
  <Box p='3px 12px' maxWidth={props.maxWidth}>
    <Flex width='100%' fontWeight={700} justifyContent='space-between'>
      <Box>{G.getWindowLocale('titles:missing-document-types', 'Missing Document Types')}:</Box>
      <Box
        pl={16}
        ml='auto'
        cursor='pointer'
        onClick={props.addDocument}
        title={G.getWindowLocale('titles:add-doc', 'Add Document')}
      >
        {I.renderClipIcon(G.getTheme('colors.dark.blue'))}
      </Box>
    </Flex>
    <TextComponent
      display='block'
      withEllipsis={true}
      title={R.join(', ', props.documentTypes)}
    >
      {R.join(', ', props.documentTypes)}
    </TextComponent>
  </Box>
);

export default MissingDocumentTypes;
