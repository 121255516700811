import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const setFilterProps = createAction('setFilterProps');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const downloadDocument = createAction('downloadDocument');
export const addMasterInvoices = createAction('addMasterInvoices');
export const setTableTitleSort = createAction('setTableTitleSort');
export const qbIIFImportRequest = createAction('qbIIFImportRequest');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const createReportRequest = createAction('createReportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const updateReportRequest = createAction('updateReportRequest');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const printInvoicesRequest = createAction('printInvoicesRequest');
export const updateInvoiceSuccess = createAction('updateInvoiceSuccess');
export const updateInvoiceRequest = createAction('updateInvoiceRequest');
export const printByReportRequest = createAction('printByReportRequest');
export const getXMLByReportRequest = createAction('getXMLByReportRequest');
export const sendToQuickBookRequest = createAction('sendToQuickBookRequest');
export const resetListAndPagination = createAction('resetListAndPagination');
export const printCloInvoiceRequest = createAction('printCloInvoiceRequest');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const getMasterInvoiceDetails = createAction('getMasterInvoiceDetails');
export const updateCloInvoiceRequest = createAction('updateCloInvoiceRequest');
export const updateCloInvoiceSuccess = createAction('updateCloInvoiceSuccess');
export const createFactoringDocument = createAction('createFactoringDocument');
export const exportFactoringDocument = createAction('exportFactoringDocument');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const createCloDocumentRequest = createAction('createCloDocumentRequest');
export const sendToSageIntacctRequest = createAction('sendToSageIntacctRequest');
export const printMasterInvoiceRequest = createAction('printMasterInvoiceRequest');
export const removeMasterInvoiceFromUI = createAction('removeMasterInvoiceFromUI');
export const getMasterInvoiceXMLRequest = createAction('getMasterInvoiceXMLRequest');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const toggleMasterInvoiceDetails = createAction('toggleMasterInvoiceDetails');
export const deleteMasterInvoicesRequest = createAction('deleteMasterInvoicesRequest');
export const updateMasterInvoiceInvoices = createAction('updateMasterInvoiceInvoices');
export const getMasterInvoiceDetailsError = createAction('getMasterInvoiceDetailsError');
export const getFactoringCompanyFileRequest = createAction('getFactoringCompanyFileRequest');
export const setMasterInvoiceDetailsLoading = createAction('setMasterInvoiceDetailsLoading');
export const getMasterInvoiceDetailsSuccess = createAction('getMasterInvoiceDetailsSuccess');
export const removeInvoiceFromMasterInvoice = createAction('removeInvoiceFromMasterInvoice');
export const handleUpdateMasterInvoiceRequest = createAction('handleUpdateMasterInvoiceRequest');
export const removeMasterInvoiceInvoiceFromUI = createAction('removeMasterInvoiceInvoiceFromUI');
export const createAndExportFactoringDocument = createAction('createAndExportFactoringDocument');
export const getAvailableFactoringTypesSuccess = createAction('getAvailableFactoringTypesSuccess');
export const refreshMasterInvoiceDetailsRequest = createAction('refreshMasterInvoiceDetailsRequest');
export const collapseAndResetMasterInvoiceInvoices = createAction('collapseAndResetMasterInvoiceInvoices');
export const exportCustomerMasterInvoiceToEDIRequest = createAction('exportCustomerMasterInvoiceToEDIRequest');
