import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import { FIELD_NAME } from '../../../constants';
//////////////////////////////////////////////////

export const saveTemplateFields = [
  {
    width: 300,
    type: 'text',
    fontSize: 14,
    labelPl: '0px',
    isRequired: true,
    errorTop: '103%',
    errorLeft: '0px',
    labelFontSize: 14,
    labelWidth: '100%',
    jc: 'space-between',
    fieldName: FIELD_NAME,
    flexDirection: 'column',
    errorPosition: 'absolute',
    loc: 'titles:template-name',
    inputWrapMargin: '8px 0 16px 0',
  },
];

export const saveTemplateValidationSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
});
