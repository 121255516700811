import * as R from 'ramda';
// constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultValues = {
  [GC.FIELD_LOAD_BOARD_NOTE]: null,
  [GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE]: null,
  [GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO]: null,
  [GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY]: null,
};

const labelStyles = { top: '-16px' };
const inputWrapperStyles = { mb: 30, width: 220 };

const fields = [
  {
    labelStyles,
    type: 'text',
    inputWrapperStyles,
    label: ['titles:person-talked-to'],
    fieldName: GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO,
  },
  {
    labelStyles,
    type: 'text',
    label: ['titles:negotiated-price'],
    fieldName: GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE,
    inputWrapperStyles: { mb: 30, mr: 10, width: 105 },
  },
  {
    labelStyles,
    type: 'select',
    label: ['titles:currency'],
    options: R.tail(GC.CURRENCY_OPTIONS_2),
    inputWrapperStyles: { mb: 30, width: 105 },
    fieldName: GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY,
  },
  {
    labelStyles,
    type: 'textarea',
    label: ['titles:note'],
    inputWrapperStyles: { width: 220 },
    fieldName: GC.FIELD_LOAD_BOARD_NOTE,
  },
];

export {
  fields,
  defaultValues,
};
