import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { InfoRow } from '../../payroll/components/payroll-invoice-details';
import { getRowOptions } from '../../payroll/settings/invoice-details-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  RelativeBox,
} from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature master-invoice
import * as H from '../helpers';
import MissingDocumentTypes from './missing-document-types';
import InvoiceDetailsActions from './invoice-details-actions';
import CloInvoiceDocumentList from './clo-invoice-document-list';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withState('expanded', 'toggle', false),
  withHandlers({
    handleClickClo: ({ cloGuid, closeModal }: Object) => () => {
      G.goToLoadDetailsByConfigAndLoadType(routesMap, cloGuid, true);

      if (G.isNotNilAndNotEmpty(closeModal)) closeModal();
    },
    handleClickMoreIcon: (props: Object) => (e: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <InvoiceDetailsActions
            edit={props.edit}
            completed={props.completed}
            closeFixedPopup={props.closeFixedPopup}
            remove={() => props.remove(props.guid, props.invoiceNumber)}
          />
        ),
      })
    ),
    handleShowMissingDocumentTypes: (props: Object) => (e: Object, data: Array) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <MissingDocumentTypes
            maxWidth={320}
            documentTypes={data}
            addDocument={props.addDocument}
          />
        ),
      })
    ),
  }),
  pure,
);

const InvoiceDetails = (props: Object) => {
  const {
    total,
    toggle,
    expanded,
    completed,
    documents,
    documentTypes,
    actionsDisabled,
    handleClickMoreIcon,
    handleShowMissingDocumentTypes,
  } = props;

  const rowOptions = getRowOptions(props);
  const darkBlue = G.getTheme('colors.dark.blue');
  const currency = G.getCurrencySymbol(props.currency);
  const mainLight = G.getTheme('colors.light.mainLight');
  const missingDocumentTypes = H.getMissingDocumentTypes(documentTypes, documents);

  return (
    <RelativeBox
      mr={15}
      mb={15}
      width={360}
      borderRadius={5}
      overflow='hidden'
      bg={G.getTheme('colors.light.lightGrey')}
    >
      <Flex
        width='100%'
        p='3px 10px'
        bg={darkBlue}
        color={mainLight}
      >
        <Box width='50%'>
          <InfoRow mb='0px' options={rowOptions.invoiceNumber} />
        </Box>
        <Box width='50%'>
          <InfoRow mb='0px'options={rowOptions.status} />
        </Box>
      </Flex>
      <Flex width='100%' p={10} height={90}>
        <Flex width='50%' flexDirection='column'>
          <InfoRow options={rowOptions.clo} clickHandler={props.handleClickClo} />
          <InfoRow options={rowOptions.invoiceDate} />
          <InfoRow options={rowOptions.paymentDueDate} />
          <InfoRow options={G.getPropFromObject(GC.FIELD_GL_CODE, rowOptions)} />
        </Flex>
        <Flex width='50%' flexDirection='column' name='COLUMN'>
          <InfoRow options={rowOptions.transportationMode} />
          <InfoRow options={rowOptions.totalTripDistance} />
          <InfoRow options={rowOptions.totalTripWeight} />
          <Flex width='100%' fontSize={11}>
            <TextComponent
              mr={10}
              title={'Total:'}
              withEllipsis={true}
              width='calc(45% - 10px)'
            >
              {G.getWindowLocale('titles:total', 'Total')}:
            </TextComponent>
            {
              G.isNotNil(total) &&
              <TextComponent
                p='4px'
                fontWeight={700}
                borderRadius={3}
                color={mainLight}
                withEllipsis={true}
                display='inline-block'
                maxWidth='calc(55% - 10px)'
                title={`${currency} ${total}`}
                bg={G.getTheme('colors.light.blue')}
              >
                {`${currency} ${G.toFixed(total)}`}
              </TextComponent>
            }
          </Flex>
        </Flex>
      </Flex>
      <Box
        overflow='auto'
        borderTop={G.ifElse(
          expanded,
          '2px solid',
          'none',
        )}
        transition='height 0.1s linear'
        minHeight={G.ifElse(expanded, 90, '0px')}
        maxHeight={G.ifElse(expanded, 250, '0px')}
        borderColor={G.getTheme('tables.rows.borderColor')}
      >
        <Flex width='100%' p='10px 10px 0 10px' flexDirection='column'>
          <InfoRow options={rowOptions.lineHaul} />
          <InfoRow options={rowOptions.fuel} />
          <InfoRow mb={10} options={rowOptions.otherCharges} />
          <InfoRow options={rowOptions.comments} />
        </Flex>
        <CloInvoiceDocumentList documents={documents} />
      </Box>
      <Flex
        height={10}
        width='100%'
        bg={darkBlue}
        justifyContent='center'
        onClick={() => toggle(R.not(expanded))}
      >
        {
          G.ifElse(
            expanded,
            I.arrowUpSimple,
            I.arrowDownSimple,
          )(mainLight)
        }
      </Flex>
      {
        R.not(actionsDisabled) &&
        <AuthWrapper
          has={G.ifElse(
            G.isTrue(completed),
            [PC.CLO_INVOICE_OVERWRITE_EXECUTE],
            [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
          )}
        >
          <AbsoluteBox top={28} right='8px'>
            <RelativeBox
              px='8px'
              zIndex={10}
              cursor='pointer'
              onClick={handleClickMoreIcon}
            >
              {I.threeDots(null, null, 15)}
            </RelativeBox>
          </AbsoluteBox>
        </AuthWrapper>
      }
      {
        G.isNotNilAndNotEmpty(missingDocumentTypes) &&
        <AbsoluteBox
          top={48}
          right='6px'
          cursor='pointer'
          onClick={(e: Object) => handleShowMissingDocumentTypes(e, missingDocumentTypes)}
        >
          {I.missingDocuments(G.getTheme('colors.light.mainRed'))}
        </AbsoluteBox>
      }
    </RelativeBox>
  );
};

export default enhance(InvoiceDetails);
