import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { triumphFactoringPaymentTermTypeOptions } from '../../../helpers/options';
// feature invoice
import {
  invoiceDateCustomBlurHandler,
  invoiceDateCustomChangeHandler2,
  invoiceNetDaysCustomChangeHandler,
} from '../helpers';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mr: 20,
  flexGrow: 1,
  flexBasis: 100,
};

const factoringPaymentTermField = {
  type: 'select',
  fieldName: GC.FIELD_FACTORING_PAYMENT_TERM,
  inputWrapperStyles: { ...inputWrapperStyles, mr: 0 },
  label: ['titles:factoring-payment-term', 'Factoring Payment Term'],
  options: G.addEmptyOptionToDropDown(triumphFactoringPaymentTermTypeOptions),
};

const externalInvoiceNumberField = {
  type: 'text',
  fieldName: GC.FIELD_EXTERNAL_INVOICE_NUMBER,
  inputWrapperStyles: { ...inputWrapperStyles, mr: 0 },
  label: ['titles:external-number', 'External Number'],
  options: G.addEmptyOptionToDropDown(triumphFactoringPaymentTermTypeOptions),
};

export const getFleetInvoiceSectionSettings = (invoiceType: string) => {
  const isCarrierInvoice = R.equals(invoiceType, 'carrier');

  const fieldsSettings = [
    {
      type: 'text',
      isRequired: true,
      inputWrapperStyles,
      fieldName: GC.FIELD_INVOICE_NUMBER,
      label: ['titles:invoice-number', 'Invoice Number'],
    },
    {
      type: 'select',
      inputWrapperStyles,
      options: GC.FIELD_INVOICE_STATUS,
      fieldName: GC.FIELD_INVOICE_STATUS,
      label: ['titles:invoice-status', 'Invoice Status'],
    },
    {
      type: 'select',
      inputWrapperStyles,
      options: GC.FIELD_GL_CODE,
      fieldName: GC.FIELD_GL_CODE,
      label: ['titles:gl-code', 'GL Code'],
      customDisabledFunction: 'handleDisableGlCode',
    },
    {
      isRequired: true,
      type: 'datePicker',
      shouldCustomBlur: true,
      shouldCustomChange: true,
      placeholder: 'Select a weekday',
      fieldName: GC.FIELD_INVOICE_DATE,
      label: ['titles:invoice-date', 'Invoice Date'],
      customBlurHandler: invoiceDateCustomBlurHandler,
      customChangeHandler2: invoiceDateCustomChangeHandler2,
      inputWrapperStyles: { ...inputWrapperStyles, zIndex: 100 },
    },
    {
      type: 'number',
      inputWrapperStyles,
      shouldCustomChange: true,
      fieldName: GC.FIELD_INVOICE_NET_DAYS,
      label: ['titles:net-days', 'Net Days'],
      customChangeHandler: invoiceNetDaysCustomChangeHandler,
    },
    {
      type: 'datePicker',
      placeholder: 'Select a weekday',
      fieldName: GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
      label: ['titles:payment-due-date', 'Payment Due Date'],
      inputWrapperStyles: {
        ...inputWrapperStyles,
        zIndex: 100,
        mr: G.ifElse(isCarrierInvoice, 20, 0),
      },
    },
  ];

  if (isCarrierInvoice) {
    const addition = [factoringPaymentTermField, externalInvoiceNumberField];

    return R.compose(
      R.map(
        R.when(
          R.propSatisfies(R.equals(R.__, GC.FIELD_FACTORING_PAYMENT_TERM), 'fieldName'),
          R.assocPath(['inputWrapperStyles', 'mr'], 20),
        ),
      ),
      R.concat(fieldsSettings),
    )(addition);
  }

  return fieldsSettings;
};
