import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

export const enhance = compose(
  withHandlers({
    handleResetByReportGuid: (props: Object) => () => {
      const {
        closeFixedPopup,
        handleClickResetByGuid,
      } = props;

      handleClickResetByGuid();

      closeFixedPopup();
    },
    handleEditOrder: (props: Object) => () => {
      const {
        closeFixedPopup,
        handleOpenEditOrder,
      } = props;

      handleOpenEditOrder();

      closeFixedPopup();
    },
  }),
  pure,
);

const getOptions = ({
  handleEditOrder,
  handleOpenEditOrder,
  handleResetByReportGuid,
}: Object) => ([
  ...(handleOpenEditOrder ? [{
    permissions: [PC.ANY],
    action: handleEditOrder,
    frontIcon: I.pencil(iconColor, 14, 14),
    text: G.getWindowLocale(
      'titles:edit-columns',
      'Edit columns',
    ),
  }] : []),
  {
    permissions: [PC.ANY],
    action: handleResetByReportGuid,
    frontIcon: I.refresh(iconColor, 15, 15),
    text: G.getWindowLocale(
      'titles:reset-columns-width',
      'Reset columns width',
    ),
  },
]);

export const ColumnActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(ColumnActions);
