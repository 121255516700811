import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// common
import { createQBIIFImportByInvoiceTypeRequest } from '../../../../common/actions';
// components
import { TextComponent } from '../../../../components/text';
import { PopperComponent } from '../../../../components/popper';
import { getConfirmModal } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncEditCarrierInvoice } from '../../../../hocs/with-async-edit-carrier-invoice';
// forms
import { Form } from '../../../../forms';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, RelativeBox } from '../../../../ui';
// feature invoice
import { getArrowIcon, isValidCharge } from '../../helpers';
import { getDetailRows, getChargeRows } from '../settings/detail-settings';
// feature invoice/carrier
import RowContent from './row-content';
import StatusReason from './status-reason';
import {
  deleteCarrierInvoicesRequest,
  updateCIReconciliationRequest,
  approveOrRejectCarrierInvoiceRequest,
  exportCarrierInvoiceToQuickBooksRequest } from '../actions';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.light.mainLight');
const mainRedColor = G.getTheme('colors.light.mainRed');
const lightGreenColor = G.getTheme('colors.light.green');

const enhance = compose(
  withState('expanded', 'toggle', false),
  withState('rejectPopup', 'setRejectPopupStatus', false),
  withState('approvePopup', 'setApprovePopupStatus', false),
  withState('carrierInvoiceCharges', 'setCarrierInvoiceCharges', null),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ currentInvoice }: Object) => {
      const chargeFields = {};
      currentInvoice.charges.forEach((charge: Object, i: number) => {
        chargeFields[`${charge.type}-${i}`] = charge.total;
      });

      return chargeFields;
    },
  }),
  withAsyncEditCarrierInvoice(),
  withHandlers({
    handleApproveOrRejectInvoice: (props: Object) => (actionType: string, statusReason: string) => {
      const { values, currentInvoice, approveOrRejectCarrierInvoiceRequest } = props;

      const { charges } = currentInvoice;

      const mappedCharges = charges.map((charge: Object, i: number) => ({
        [GC.FIELD_GUID]: G.getGuidFromObject(charge),
        [GC.FIELD_INVOICE_APPROVED_TOTAL]: values[`${charge.type}-${i}`],
      }));
      const requestData = {
        charges: mappedCharges,
        [GC.FIELD_STATUS]: statusReason,
        [GC.FIELD_GUID]: G.getGuidFromObject(currentInvoice),
      };
      const endpointName = G.ifElse(
        R.equals(actionType, 'approve'),
        'telInvoiceApprove',
        'telInvoiceReject',
      );
      approveOrRejectCarrierInvoiceRequest({ requestData, endpointName });
    },
  }),
  withHandlers({
    handleApproveInvoice: (props: Object) => () => {
      const { invoiceStatusOptions, setApprovePopupStatus, handleApproveOrRejectInvoice } = props;

      const approvedStatusOptions = R.pathOr([], [GC.INVOICE_SYSTEM_STATUS_APPROVED], invoiceStatusOptions);

      if (R.lte(R.length(approvedStatusOptions), 1)) {
        const statusReason = R.pathOr(null, [0, GC.FIELD_VALUE], approvedStatusOptions);

        return handleApproveOrRejectInvoice('approve', statusReason);
      }

      return setApprovePopupStatus(true);
    },
    handleRejectInvoice: (props: Object) => () => {
      const { invoiceStatusOptions, setRejectPopupStatus, handleApproveOrRejectInvoice } = props;

      const rejectedStatusOptions = R.pathOr([], [GC.INVOICE_SYSTEM_STATUS_REJECTED], invoiceStatusOptions);

      if (R.lte(R.length(rejectedStatusOptions), 1)) {
        const statusReason = R.pathOr(null, [0, GC.FIELD_VALUE], rejectedStatusOptions);

        return handleApproveOrRejectInvoice('reject', statusReason);
      }
      setRejectPopupStatus(true);
    },
  }),
  withHandlers({
    handleExportToQuickBooks: ({ currentInvoice, exportCarrierInvoiceToQuickBooksRequest }: Object) => () => (
      exportCarrierInvoiceToQuickBooksRequest({
        guids: R.of(Array, G.getGuidFromObject(currentInvoice)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      })
    ),
    handleQBIIFImport: ({ currentInvoice, createQBIIFImportByInvoiceTypeRequest }: Object) => () => (
      createQBIIFImportByInvoiceTypeRequest({
        type: 'carrier',
        guids: R.of(Array, G.getGuidFromObject(currentInvoice)),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      })
    ),
    handleDeleteInvoice: (props: Object) => () => {
      const { openModal, closeModal, currentInvoice, deleteCarrierInvoicesRequest } = props;
      const modalContent = getConfirmModal({
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: G.getWindowLocale('titles:carrier-invoice:', 'Carrier Invoice'),
        text: G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        ),
        submitAction: () => {
          const guids = R.of(Array, R.prop(GC.FIELD_GUID, currentInvoice));

          deleteCarrierInvoicesRequest({ guids, [GC.FIELD_CURRENT_BRANCH]: G.getAmousCurrentBranchGuidFromWindow() });
          closeModal();
        },
      });
      openModal(modalContent);
    },
    handleChangeCharge: ({ handleChange }: Object) => (event: Object) => {
      if (isValidCharge(event)) {
        handleChange(event);
      }
    },
    handleBlurCharge: ({ handleBlur, setFieldValue }: Object) => (event: Object) => {
      const { name, value } = event.target;
      if (R.equals(value.indexOf('.'), R.subtract(value.length, 1))) {
        setFieldValue(name, G.toFixed(Number(value)));
      }
      handleBlur(event);
    },
  }),
  pure,
);

const Actions = (props: Object) => {
  const {
    currentInvoice,
    handleQBIIFImport,
    handleDeleteInvoice,
    handleEditCarrierInvoice,
    handleExportToQuickBooks,
  } = props;

  const hoverColor = G.getTheme('colors.light.darkRed');

  const actions = [
    {
      action: handleExportToQuickBooks,
      icon: I.quickbook(iconColor, 12, 12),
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      action: handleQBIIFImport,
      icon: I.quickbook(iconColor, 12, 12),
      text: G.getWindowLocale('actions:qb-iif-export', 'Quickbooks IIF Export'),
    },
    {
      icon: I.pencil(iconColor, 12, 12),
      permissions: [PC.TEL_CARRIER_INVOICE_WRITE],
      text: G.getWindowLocale('actions:edit', 'Edit'),
      action: () => handleEditCarrierInvoice(currentInvoice),
    },
    {
      action: handleDeleteInvoice,
      icon: I.trash(iconColor, 12, 12),
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];

  return (
    <Flex
      p='5px'
      fontSize={12}
      flexDirection='column'
      alignItems='flex-start'
    >
      {
        actions.map(
          ({ icon, text, action, permissions }: Object, index: number) => (
            <AuthWrapper key={index} has={permissions}>
              <Flex
                p='6px 4px'
                onClick={action}
                cursor='pointer'
                flexDirection='row'
                alignItems='flex-start'
              >
                <Box mr='5px' height={12}>
                  {icon}
                </Box>
                {text}
              </Flex>
            </AuthWrapper>
          ),
        )
      }
    </Flex>
  );
};

const Fields = (props: Object) => (
  <Flex width='100%' p={10} pb={0}>
    <Flex width='33%' flexDirection='column'>
      <RowContent options={R.prop(GC.FIELD_TEL, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_INVOICE_DATE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_GL_CODE, props.rowOptions)} />
    </Flex>
    <Flex width='33%' flexDirection='column' name='COLUMN'>
      <RowContent options={R.prop(GC.FIELD_MODE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, props.rowOptions)} />
      <Flex width='100%' fontSize={11}>
        <TextComponent
          mr={10}
          withEllipsis={true}
          width='calc(45% - 10px)'
          title={`${G.getWindowLocale('titles:total', 'Total')}:`}
        >
          {G.getWindowLocale('titles:total', 'Total')}:
        </TextComponent>
        {
          G.isNotNil(R.prop(GC.FIELD_TOTAL, props.currentInvoice))
          &&
          <TextComponent
            p='4px'
            fontWeight={700}
            borderRadius={3}
            color={textColor}
            withEllipsis={true}
            display='inline-block'
            maxWidth='calc(55% - 10px)'
            bg={G.getTheme('colors.light.blue')}
            title={`${props.currencySymbol} ${R.prop(GC.FIELD_TOTAL, props.currentInvoice)}`}
          >
            {`${props.currencySymbol} ${G.toFixed(R.prop(GC.FIELD_TOTAL, props.currentInvoice))}`}
          </TextComponent>
        }
      </Flex>
    </Flex>
    <Flex width='33%' p='2px 5px' flexDirection='column'>
      <RowContent mb='0px' p='2px 0' options={R.path(['chargeRowOptions', 'currentCharge'], props)} />
      <RowContent mb='0px' p='2px 0' options={R.path(['chargeRowOptions', 'approvedCharge'], props)} />
      <RowContent mb='0px' p='2px 0' options={R.path(['chargeRowOptions', 'rejectedCharge'], props)} />
    </Flex>
  </Flex>
);

const ApproveReject = (props: Object) => (
  <Flex p='5px'>
    <Flex width='60%' height='inherit' flexDirection='column'>
      <RowContent jc='unset' options={R.prop(GC.FIELD_COMMENTS, props.rowOptions)} />
    </Flex>
    <Flex width='40%' justifyContent='flex-end'>
      <Box
        m='0 5px'
        p='5px 10px'
        fontSize={12}
        cursor='pointer'
        color={textColor}
        background={mainRedColor}
        onClick={props.handleRejectInvoice}
      >
        {G.getWindowLocale('titles:reject', 'Reject')}
      </Box>
      <Box
        m='0 5px'
        p='5px 10px'
        fontSize={12}
        cursor='pointer'
        color={textColor}
        background={lightGreenColor}
        onClick={props.handleApproveInvoice}
      >
        {G.getWindowLocale('titles:approve', 'Approve')}
      </Box>
    </Flex>
  </Flex>
);

const CurrentInvoiceCharges = (props: Object) => {
  const {
    values,
    expanded,
    currencySymbol,
    handleBlurCharge,
    handleChangeCharge,
  } = props;

  const borderColor = G.getTheme('tables.rows.borderColor');

  return (
    <Box
      overflow='hidden'
      transition='height 0.1s linear'
      minHeight={R.and(expanded, 100)}
      height={G.ifElse(expanded, 'max-content', '0px')}
      borderTop={G.ifElse(expanded, '2px solid', 'none')}
    >
      <Box display='flex' fontSize={11}>
        <Flex width='65%' flexDirection='column'>
          <Flex
            p='4px 0'
            width='100%'
            background={borderColor}
          >
            <Flex width='35%' />
            <Flex width='25%'>{G.getWindowLocale('titles:invoice', 'Invoice')}</Flex>
            <Flex width='20%'>{G.getWindowLocale('titles:difference', 'Difference')}</Flex>
            <Flex width='20%' />
          </Flex>
          <Form width='100%'>
            {
              R.pathOr([], ['currentInvoice', GC.FIELD_CHARGES], props).map((charge: Object, index: number) => {
                const diff = R.subtract(charge.total, values[`${charge.type}-${index}`]).toFixed(2);

                return (
                  <Flex
                    p='5px'
                    key={index}
                    width='100%'
                  >
                    <Flex width='35%'>
                      <TextComponent
                        withEllipsis={true}
                        width='calc(100% - 10px)'
                        title={G.getDisplayedValueFromObject(charge)}
                      >
                        {G.getDisplayedValueFromObject(charge)}:
                      </TextComponent>
                    </Flex>
                    <Flex width='25%'>
                      {`${R.or(G.getCurrencySymbolFromCharge(charge), currencySymbol)}
                      ${G.toFixed(G.getTotalFromCharge(charge))}`}
                    </Flex>
                    <Flex
                      width='20%'
                      color={G.ifElse(
                        R.gte(diff, 0),
                        lightGreenColor,
                        mainRedColor,
                      )}
                    >
                      {currencySymbol} {diff}
                    </Flex>
                    <Flex width='20%'>
                      <Field
                        type='text'
                        style={{
                          width: '100%',
                          padding: '2px',
                          fontSize: '11px',
                          borderRadius: '2px',
                          border: `1px solid ${G.getTheme('colors.veryLightGrey')}`,
                        }}
                        onBlur={handleBlurCharge}
                        onChange={handleChangeCharge}
                        name={`${charge.type}-${index}`}
                      />
                    </Flex>
                  </Flex>
                );
              })
            }
          </Form>
        </Flex>
        <Flex
          height='inherit'
          borderRight='2px solid'
          borderColor={borderColor}
        />
        <Flex width='35%' flexDirection='column'>
          <Flex
            p='4px 0'
            width='100%'
            background={borderColor}
          >
            <Flex width='70%' />
            <Flex width='30%'>{G.getWindowLocale('titles:rate', 'Rate')}</Flex>
          </Flex>
          {
            R.pathOr([], ['selectedRate', 'carrierRateCharges'], props).map((charge: Object, i: number) => (
              <Flex key={i} p='5px' width='100%'>
                <Flex width='65%'>
                  <TextComponent
                    withEllipsis={true}
                    width='calc(100% - 10px)'
                    title={G.getDisplayedValueFromObject(charge)}
                  >
                    {G.getDisplayedValueFromObject(charge)}:
                  </TextComponent>
                </Flex>
                <Flex width='35%'>
                  {`${R.or(G.getCurrencySymbolFromCharge(charge), currencySymbol)}
                  ${G.toFixed(G.getTotalFromCharge(charge))}`}
                </Flex>
              </Flex>
            ))
          }
        </Flex>
      </Box>
    </Box>
  );
};

const InvoiceDetails = (props: Object) => {
  const { expanded, currentInvoice } = props;

  const rowOptions = getDetailRows(props);
  const currencySymbol = G.getCurrencySymbol(currentInvoice.currency);
  const chargeRowOptions = getChargeRows(props, currencySymbol);

  return (
    <RelativeBox
      mr={15}
      mb={15}
      width={580}
      borderRadius={5}
      maxHeight='max-content'
      bg={G.getTheme('colors.light.lightGrey')}
    >
      {
        props.rejectPopup &&
        <StatusReason
          actionType='reject'
          placeholder='titles:reject-status'
          submitAction={props.handleApproveOrRejectInvoice}
          handleClosePopup={() => props.setRejectPopupStatus(false)}
          optionsForSelect={R.path(['invoiceStatusOptions', GC.INVOICE_SYSTEM_STATUS_REJECTED], props)}
        />
      }
      {
        props.approvePopup &&
        <StatusReason
          actionType='approve'
          placeholder='titles:approve-status'
          submitAction={props.handleApproveOrRejectInvoice}
          handleClosePopup={() => props.setApprovePopupStatus(false)}
          optionsForSelect={R.path(['invoiceStatusOptions', GC.INVOICE_SYSTEM_STATUS_APPROVED], props)}
        />
      }
      <Flex
        width='100%'
        p='3px 10px'
        bg={iconColor}
        color={textColor}
      >
        <Box width='50%'>
          <RowContent mb='0px' options={rowOptions.invoiceNumber} />
        </Box>
        <Box width='50%'>
          <RowContent mb='0px' options={rowOptions.status} />
        </Box>
      </Flex>
      <Fields
        {...props}
        rowOptions={rowOptions}
        currentInvoice={currentInvoice}
        currencySymbol={currencySymbol}
        chargeRowOptions={chargeRowOptions}
      />
      <Box top={25} right={10} zIndex={11} position='absolute'>
        <PopperComponent
          zi='21'
          type='click'
          rotate={false}
          minWidth={150}
          borderWidth='none'
          position='bottom-end'
          content={<Actions {...props} />}
          popperBoxShadow={`0 1px 8px 0 ${G.getTheme('colors.boxShadowGrey')}`}
        >
          {I.threeDots(null, null, 15)}
        </PopperComponent>
      </Box>
      <ApproveReject {...props} rowOptions={rowOptions} />
      <CurrentInvoiceCharges
        {...props}
        expanded={expanded}
        currencySymbol={currencySymbol}
        currentInvoice={currentInvoice}
      />
      <Flex
        height={10}
        width='100%'
        bg={iconColor}
        justifyContent='center'
        onClick={() => props.toggle(R.not(expanded))}
      >
        {getArrowIcon(expanded)}
      </Flex>
    </RelativeBox>
  );
};

export default connect(null, {
  openModal,
  closeModal,
  deleteCarrierInvoicesRequest,
  updateCIReconciliationRequest,
  approveOrRejectCarrierInvoiceRequest,
  createQBIIFImportByInvoiceTypeRequest,
  exportCarrierInvoiceToQuickBooksRequest,
})(enhance(InvoiceDetails));
