import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  getFreightClassOptions,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_ACTIVE]: {
    width: 50,
    type: 'boolean',
    name: 'titles:active',
  },
  [GC.FIELD_ITEM_ID]: {
    width: 200,
    name: 'titles:item-id',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: 'titles:branch',
  },
  [GC.FIELD_ITEM_FREIGHT_CLASS]: {
    width: 150,
    name: 'titles:freight-class',
    customComponent: ({ data }: string) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(R.prop(GC.FIELD_ITEM_FREIGHT_CLASS, data), GC.FIELD_VALUE)),
    )(getFreightClassOptions()),
  },
  [GC.FIELD_ITEM_WEIGHT]: {
    width: 110,
    name: 'titles:weight',
  },
  [GC.FIELD_ITEM_WEIGHT_TYPE]: {
    width: 110,
    name: 'titles:weight-type',
  },
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: {
    width: 110,
    name: 'titles:temperature-type',
  },
  [GC.FIELD_ITEM_TEMPERATURE_SENSOR]: {
    width: 110,
    name: 'titles:temperature-sensor',
  },
  [GC.FIELD_ITEM_QUANTITY]: {
    width: 150,
    name: 'titles:quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 150,
    name: 'titles:package-type',
  },
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: {
    width: 150,
    name: 'titles:dimensions-length',
  },
  [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: {
    width: 150,
    name: 'titles:dimensions-width',
  },
  [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: {
    width: 150,
    name: 'titles:dimensions-height',
  },
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: {
    width: 110,
    type: 'uomLocale',
    name: 'titles:dimensions-uom',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 250,
    name: 'titles:description',
  },
  [GC.FIELD_ITEM_COMMODITY]: {
    width: 150,
    name: 'titles:commodity',
  },
  [GC.FIELD_ITEM_MONETARY_VALUE]: {
    width: 110,
    name: 'titles:monetary-value',
  },
  [GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY]: {
    width: 110,
    name: 'titles:monetary-currency',
  },
  [GC.FIELD_ITEM_VOLUME]: {
    width: 110,
    name: 'titles:volume',
  },
  [GC.FIELD_ITEM_VOLUME_UOM]: {
    width: 110,
    name: 'titles:volume-uom',
  },
  [GC.FIELD_ITEM_DECLARED_VALUE]: {
    width: 110,
    name: 'titles:declared-value',
  },
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: {
    width: 110,
    name: 'titles:currency',
  },
  [GC.FIELD_ORIGIN_OF_GOODS]: {
    width: 150,
    name: 'titles:origin-of-goods',
  },
  [GC.FIELD_ITEM_STACKABILITY]: {
    width: 150,
    type: 'boolean',
    name: 'titles:stackability',
  },
  [GC.FIELD_ITEM_STACKABILITY_CONDITION]: {
    width: 150,
    name: 'titles:stackability-condition',
  },
  [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: {
    width: 150,
    name: 'titles:customer-part-num',
  },
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: {
    width: 150,
    name: 'titles:temperature-minimum',
  },
  [GC.FIELD_ITEM_TEMPERATURE_HIGH]: {
    width: 150,
    name: 'titles:temperature-maximum',
  },
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: {
    width: 150,
    name: 'titles:temperature-uom',
  },
  [GC.FIELD_ITEM_N_M_F_C]: {
    width: 150,
    name: 'titles:nmfc',
  },
  [GC.FIELD_ITEM_S_T_C_C]: {
    width: 150,
    name: 'titles:stcc',
  },
  [GC.FIELD_ITEM_HARMONIZED_TARIFF]: {
    width: 150,
    name: 'titles:harmonized-tariff',
  },
  [GC.FIELD_ITEM_COMMODITY_TYPE]: {
    width: 250,
    name: 'titles:domestic-foreign-military',
  },
  [GC.FIELD_ITEM_E_C_C_N]: {
    width: 150,
    name: 'titles:eccn',
  },
  [GC.FIELD_ITEM_HAZARDOUS]: {
    width: 150,
    type: 'boolean',
    name: 'titles:hazardous',
  },
  [GC.FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER]: {
    width: 150,
    name: 'titles:hazmat-un-or-na-number',
  },
  [GC.FIELD_ITEM_HAZMAT_PACKAGE_GROUP]: {
    width: 150,
    name: 'titles:hazmat-package-group',
  },
  [GC.FIELD_ITEM_HAZMAT_CLASS]: {
    width: 250,
    name: 'titles:hazmat-class',
  },
  [GC.FIELD_ITEM_HAZMAT_SHIPPING_NAME]: {
    width: 150,
    name: 'titles:hazmat-shipping-name',
  },
  [GC.FIELD_ITEM_HAZMAT_CONTACT_NAME]: {
    width: 150,
    name: 'titles:hazmat-contact-name',
  },
  [GC.FIELD_ITEM_HAZMAT_CONTACT_PHONE]: {
    width: 150,
    name: 'titles:hazmat-contact-phone',
  },
  [GC.FIELD_ITEM_HAZMAT_EMS_NUMBER]: {
    width: 150,
    name: 'titles:hazmat-ems-number',
  },
  [GC.FIELD_ITEM_HAZMAT_PLACARDS]: {
    width: 150,
    type: 'boolean',
    name: 'titles:hazmat-placards',
  },
  [GC.FIELD_ITEM_HAZMAT_PLACARDS_DETAILS]: {
    width: 150,
    name: 'titles:hazmat-placard-details',
  },
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT]: {
    width: 150,
    name: 'titles:hazmat-flashpoint',
  },
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM]: {
    width: 150,
    name: 'titles:hazmat-flashpoint-uom',
  },
  [GC.FIELD_ITEM_HAZMAT_COMMENT]: {
    width: 200,
    name: 'titles:hazmat-comments',
  },
  [GC.FIELD_DEFAULT]: {
    width: 300,
    name: 'titles:ref',
  },
  [GC.FIELD_MANUFACTURER]: {
    width: 200,
    name: 'titles:manufacturer',
  },
  [GC.FIELD_OPERATIONAL]: {
    width: 110,
    type: 'boolean',
    name: 'titles:operational',
  },
  [GC.FIELD_YEAR]: {
    width: 110,
    name: 'titles:year',
  },
  [GC.FIELD_MAKE]: {
    width: 150,
    name: 'titles:make',
  },
  [GC.FIELD_MODEL]: {
    width: 150,
    name: 'titles:model',
  },
  [GC.FIELD_SUB_MODEL]: {
    width: 150,
    name: 'titles:sub-model',
  },
  [GC.FIELD_WHEELBASE]: {
    width: 150,
    name: 'titles:wheelbase',
  },
  [GC.FIELD_WHEELBASE_UOM]: {
    width: 150,
    name: 'titles:wheelbase-uom',
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_WHEELBASE_UOM, data), GC.FIELD_VALUE)),
    )(ITEM_DIMENSIONS_OPTIONS),
  },
  [GC.FIELD_BAY]: {
    width: 150,
    name: 'titles:bay',
  },
  [GC.FIELD_ODOMETER]: {
    width: 150,
    name: 'titles:odometer',
  },
  [GC.FIELD_ODOMETER_UOM]: {
    width: 150,
    name: 'titles:odometer-uom',
    customComponent: ({ data }: Object) => R.compose(
      R.pathOr('', [GC.FIELD_LABEL]),
      R.find(R.propEq(G.getPropFromObject(GC.FIELD_ODOMETER_UOM, data), GC.FIELD_VALUE)),
    )(ITEM_ODOMETER_UOM_OPTIONS),
  },
  [GC.FIELD_COLOR]: {
    width: 150,
    name: 'titles:color',
  },
  [GC.FIELD_LICENSE_PLATE]: {
    width: 150,
    name: 'titles:license-plate',
  },
  [GC.FIELD_VIN]: {
    width: 150,
    name: 'titles:vin',
  },
  [GC.FIELD_ORIGIN_STATE]: {
    width: 250,
    name: 'titles:origin-state',
  },
  [GC.FIELD_ORIGIN_COUNTRY]: {
    width: 250,
    name: 'titles:origin-country',
  },
};
