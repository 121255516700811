import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { EmptyList } from '../../../../components/list';
import { LocalLoader } from '../../../../components/local-loader';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
import { setExpandedContainerOptions } from '../../../expanded-container/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, StickedBox } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature invoice
import { makeSelectInvoiceStatusOptions } from '../selectors';
// feature invoice/carrier
import InvoiceDetails from './invoice-details';
import RelatedDetails from './related-details';
//////////////////////////////////////////////////

const renderInvoices = (props: Object) => {
  const { data, branchGuid, invoiceStatusOptions, handleClickOnTelPrimaryReference } = props;

  if (G.isNilOrEmpty(props.data)) {
    return (
      <EmptyList>{G.getWindowLocale('titles:emptyList', 'Empty List')}</EmptyList>
    );
  }

  return (
    <InvoiceDetails
      {...data}
      branchGuid={branchGuid}
      invoiceStatusOptions={invoiceStatusOptions}
      handleClickOnTelPrimaryReference={handleClickOnTelPrimaryReference}
    />
  );
};

const renderRelatedInvoices = (props: Object) => {
  const { data, branchGuid, handleClickOnTelPrimaryReference } = props;

  if (G.isNilOrEmpty(R.pathOr(null, ['relatedInvoices'], data))) return null;

  return (
    <Box width={'calc(100% - 580px)'}>
      {
        data.relatedInvoices.map((relatedInvoice: Object, index: number) => (
          <RelatedDetails
            key={index}
            {...relatedInvoice}
            branchGuid={branchGuid}
            primaryReference={props.data.primaryReference}
            handleClickOnTelPrimaryReference={handleClickOnTelPrimaryReference}
          />
        ))
      }
    </Box>
  );
};

const Invoices = (props: Object) => (
  <StickedBox
    left='0px'
    display='inline-block'
    width={props.parentProps.detailsWidth}
  >
    <LocalLoader localLoaderOpen={props.parentProps.detailsLoading}>
      <Box
        p={15}
        width='100%'
        display='flex'
        flexWrap='wrap'
        alignItems='baseline'
      >
        {renderInvoices(props)}
        {renderRelatedInvoices(props)}
      </Box>
    </LocalLoader>
  </StickedBox>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchGuid: makeSelectCurrentBranchGuid(state),
  invoiceStatusOptions: makeSelectInvoiceStatusOptions(state),
});

const enhance = compose(
  connect(mapStateToProps, { setExpandedContainerOptions }),
  withHandlers({
    handleClickOnTelPrimaryReference: ({ setExpandedContainerOptions }: Object) => (guid: string) => {
      const openLoadInSidebar = G.getAmousConfigByNameFromWindow(GC.UI_OPEN_LOAD_IN_SIDEBAR);

      if (G.isFalse(openLoadInSidebar)) return G.goToLoadDetailsByConfigAndLoadType(routesMap, guid);

      return setExpandedContainerOptions({
        opened: true,
        visitPageGuid: guid,
        componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
      });
    },
  }),
  pure,
);

export default enhance(Invoices);
