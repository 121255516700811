import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
//components
import { TextComponent } from '../../../components/text';
import { DateRangeMui } from '../../../components/date-range';
import { Table, InputWithHandlers } from '../../../components';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { Checkbox, TableRow } from '../../payroll/ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  StickedBox,
  IconWrapper,
} from '../../../ui';
// feature master-invoice
import * as H from '../helpers';
import InvoiceActions from './group-table-invoice-actions';
import { report, tableSettings, getColumnSettings } from '../settings/invoices-table-settings';
import { GROUP_BY_NAME, GROUP_BY_BRANCH, FIELD_GROUP_NAME, GROUP_BY_NO_GROUP, GROUP_BY_REFERENCE } from '../constants';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleClickInvoiceCheckbox: (props: Object) => (data: string, invoice: Object) => (
      props.onInvoiceCheckboxClick(H.getGroupedDataOnToggleInvoiceSelect(data, invoice, props.data))
    ),
    handleClickEditIcon: (props: Object) => (e: Object, invoice: Object) => {
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <InvoiceActions
            invoice={invoice}
            closeFixedPopup={props.closeFixedPopup}
            openEditInvoiceForm={() => props.handleEditInvoice(invoice)}
          />
          ),
      });
    },
  }),
  pure,
);

export const ToggleButton = ({ data, toggle }: Object) => (
  <Flex
    pr='8px'
    height='100%'
    justify='center'
    onClick={toggle}
    direction='column'
  >
    {
      G.ifElse(
        G.isTrue(data.expanded),
        I.arrowUpSimple,
        I.arrowDownSimple,
      )(G.getTheme('colors.light.green'))
    }
  </Flex>
);

export const StickedIcons = ({ data, toggle, onCheckboxClick }: Object) => (
  <StickedBox
    left='0px'
    zIndex={11}
    bg={G.getTheme(G.ifElse(
      data.selected,
      'colors.light.mainLight',
      'tables.rows.notSelectedBgColor',
    ))}
  >
    <Flex p='0 12px' height='100%'>
      <ToggleButton data={data} toggle={toggle} />
      <Checkbox
        type='checkbox'
        checked={data.selected}
        onChange={onCheckboxClick}
        name={data.masterInvoiceNumber}
      />
    </Flex>
  </StickedBox>
);

export const InvoicesTable = (props: Object) => {
  const {
    loading,
    invoices,
    editMode,
    groupName,
    closeModal,
    addDocument,
    documentTypes,
    openFixedPopup,
    closeFixedPopup,
    handleClickEditIcon,
    handleClickInvoiceCheckbox,
  } = props;

  const itemList = R.values(invoices);
  const offset = itemList.length;
  const elementActionsComponent = (data: Object) => (
    <AuthWrapper
      has={[
        PC.CLO_INVOICE_READ,
        PC.CLO_INVOICE_WRITE,
        PC.CLO_INVOICE_OVERWRITE_EXECUTE,
      ]}
    >
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );
  const data = {
    report,
    loading,
    itemList,
    pagination: {
      offset,
      limit: offset,
    },
    totalCount: offset,
    hasSelectable: true,
    allChecked: G.isAllChecked(itemList),
    onOptionClick: handleClickInvoiceCheckbox,
    columnSettings: getColumnSettings({
      groupName,
      closeModal,
      addDocument,
      documentTypes,
      openFixedPopup,
      closeFixedPopup,
    }),
    tableSettings: G.ifElse(
      G.isTrue(editMode),
      R.assoc('allowEditBtn', false, tableSettings),
      tableSettings,
    ),
    renderRightStickedComponent: elementActionsComponent,
  };

  return <Box pl={24}><Table {...data} /></Box>;
};

export const Invoices = ({
  data,
  editMode,
  closeModal,
  addDocument,
  documentTypes,
  openFixedPopup,
  closeFixedPopup,
  handleClickEditIcon,
  handleClickInvoiceCheckbox,
}: Object) => {
  if (G.isTrue(data.invoicesError)) {
    return (
      <TextComponent>
        {G.getWindowLocale('titles:error-loading-invoices', 'Loading Invoices Error')}
      </TextComponent>
    );
  }

  return (
    <InvoicesTable
      editMode={editMode}
      closeModal={closeModal}
      invoices={data.invoices}
      addDocument={addDocument}
      groupName={data.groupName}
      documentTypes={documentTypes}
      loading={data.invoicesLoading}
      openFixedPopup={openFixedPopup}
      closeFixedPopup={closeFixedPopup}
      handleClickEditIcon={handleClickEditIcon}
      handleClickInvoiceCheckbox={handleClickInvoiceCheckbox}
    />
  );
};

const NumberValue = (props: Object) => {
  if (R.or(
    props.editMode,
    G.isTrue(props.autoNumber),
  )) {
    const displayValue = G.ifElse(
      G.isTrue(props.autoNumber),
      G.getWindowLocale('titles:autogenerated', 'Autogenerated'),
      props.data.masterInvoiceNumber,
    );
    return (
      <TextComponent
        width={80}
        withEllipsis={true}
        title={displayValue}
        color={G.getTheme('colors.light.darkGrey')}
      >
        {displayValue}
      </TextComponent>
    );
  }
  return (
    <InputWithHandlers
      styles={{
        width: 80,
        p: '0 8px',
        border: '1px solid',
      }}
      validate={G.isNotEmpty}
      value={props.data.masterInvoiceNumber}
      submitValidation={props.submitValidation}
      onChange={(value: string) => props.onNumberChange(value, props.data)}
    />
  );
};

export const Number = (props: Object) => (
  <Flex
    mr={16}
    fontSize={11}
    ml={G.ifElse(props.editMode, 16, 0)}
  >
    <TextComponent mr='6px' whiteSpace='normal'>
      {`${G.getWindowLocale('titles:master-invoice-number', 'Master Invoice #')}:`}
    </TextComponent>
    <NumberValue {...props} />
  </Flex>
);

const DateValues = (props: Object) => {
  const { data, editMode, onDateChange } = props;

  const { dateTo, dateFrom, groupName } = data;

  const setDateRangeMuiValues = (dates: Object) => {
    onDateChange(dates, groupName);
  };

  if (editMode) {
    return (
      <TextComponent>{`${dateFrom} - ${dateTo}`}</TextComponent>
    );
  }

  return (
    <DateRangeMui
      width={80}
      dateTo={dateTo}
      withIcon={false}
      dateFrom={dateFrom}
      withoutBorder={true}
      useNewMuiInputField={true}
      popperPlacement='bottom-end'
      onSelectDateRange={setDateRangeMuiValues}
      maxDate={G.momentAddYearsFromCurrent(100)}
      minDate={G.momentSubtractYearsFromCurrent(100)}
    />
  );
};

export const Dates = (props: Object) => (
  <Flex mr={16} fontSize={11}>
    <TextComponent mr='6px' whiteSpace='normal'>
      {`${G.getWindowLocale('titles:date-range', 'Date Range')}:`}
    </TextComponent>
    <DateValues {...props} />
  </Flex>
);

export const TextCell = (props: Object) => (
  <Flex mr={16} fontSize={11} width={R.or(props.width, 'auto')}>
    <TextComponent {...props.textOptions}>
      {props.text}
    </TextComponent>
  </Flex>
);

export const GroupTableRow = (props: Object) => {
  const {
    data,
    toggle,
    groupBy,
    editMode,
    reference,
    autoNumber,
    closeModal,
    addDocument,
    onDateChange,
    documentTypes,
    onNumberChange,
    openFixedPopup,
    closeFixedPopup,
    onCheckboxClick,
    submitValidation,
    handleClickEditIcon,
    handleClickInvoiceCheckbox,
  } = props;

  const groupByLabelMap = {
    [GROUP_BY_NO_GROUP]: '',
    [GROUP_BY_NAME]: G.getWindowLocale('titles:bill-to', 'Bill To'),
    [GROUP_BY_BRANCH]: G.getWindowLocale('titles:branch', 'Branch'),
    [GROUP_BY_REFERENCE]: G.getPropFromObject(GC.FIELD_NAME, reference),
  };

  const groupByLabel = R.prop(groupBy, groupByLabelMap);
  const groupName = R.pathOr('', [FIELD_GROUP_NAME], data);

  return (
    <Fragment>
      <TableRow
        py={6}
        lightGreyBorderBottom={true}
        bgColor={
          R.and(
            R.not(data.selected),
            G.getTheme('tables.rows.notSelectedBgColor'),
          )
        }
      >
        {
          R.not(editMode) &&
          <StickedIcons
            data={data}
            toggle={() => toggle(data)}
            onCheckboxClick={() => onCheckboxClick(data)}
          />
        }
        <Number
          data={data}
          editMode={editMode}
          autoNumber={autoNumber}
          onNumberChange={onNumberChange}
          submitValidation={submitValidation}
        />
        <Dates
          data={data}
          editMode={editMode}
          onDateChange={onDateChange}
        />
        <TextCell
          text={`${G.getWindowLocale('titles:invoices', 'Invoices')}: ${data.invoicesCount}`}
          textOptions={{
            width: 80,
            withEllipsis: true,
            title: `${G.getWindowLocale('titles:invoices', 'Invoices')}: ${data.invoicesCount}`,
          }}
        />
        <TextCell
          width={180}
          text={`${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
            G.getCurrencySymbol(R.propOr('', 'currency', data))} ${G.toFixed(data.invoicesTotal)}`
          }
          textOptions={{
            ml: 15,
            fontSize: 11,
            p: '4px 10px',
            fontWeight: 700,
            borderRadius: 3,
            display: 'inline-block',
            bg: G.getTheme('colors.light.blue'),
            color: G.getTheme('colors.light.mainLight'),
          }}
        />
        {
          R.and(G.isNotNilAndNotEmpty(groupBy), G.notEquals(groupBy, GROUP_BY_NO_GROUP)) &&
          <TextCell
            text={`${groupByLabel}: ${G.getStringTailBySymbol(groupName, '-')}`}
            textOptions={{
              width: 210,
              withEllipsis: true,
              title: `${groupByLabel}: ${G.getStringTailBySymbol(groupName, '-')}`,
            }}
          />
        }
        {
          R.and(R.not(data.selected), R.not(editMode)) &&
          <TextCell
            text={G.getWindowLocale(
              'titles:group-will-not-be-added-to-master-invoice',
              'This group will not be added to master invoice',
            )}
            textOptions={{
              maxWidth: 100,
              whiteSpace: 'normal',
              color: G.getTheme('colors.light.mainRed'),
            }}
          />
        }
      </TableRow>
      {
        data.expanded &&
        <Invoices
          data={data}
          editMode={editMode}
          closeModal={closeModal}
          addDocument={addDocument}
          documentTypes={documentTypes}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          handleClickEditIcon={handleClickEditIcon}
          handleClickInvoiceCheckbox={handleClickInvoiceCheckbox}
        />
      }
    </Fragment>
  );
};

export default enhance(GroupTableRow);
