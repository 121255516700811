import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ITEM_DIMENSIONS_OPTIONS, DEFAULT_WEIGHT_UOM_OPTIONS } from '../../../helpers/options';
// feature load-board
import * as C from '../constants';
//////////////////////////////////////////////////

const capacityOptions = [
  { value: C.CAPACITY_FULL, label: 'Full' },
  { value: C.CAPACITY_PARTIAL, label: 'Partial' },
];

const defaultFields = {
  [GC.FIELD_NOTES]: '',
  [GC.FIELD_LENGTH]: '',
  [GC.FIELD_WEIGHT]: '',
  [C.FIELD_TEMPLATE]: '',
  [C.FIELD_ORIGIN_CITY]: '',
  [GC.FIELD_TRUCK_TYPES]: [],
  [C.FIELD_ORIGIN_STATE]: '',
  [C.FIELD_ORIGIN_COUNTRY]: '',
  [C.FIELD_ORIGIN_LATITUDE]: '',
  [C.FIELD_ORIGIN_LONGITUDE]: '',
  [C.FIELD_DESTINATION_CITY]: '',
  [GC.FIELD_LOAD_BOARD_TYPES]: [],
  [C.FIELD_DESTINATION_STATES]: [],
  [C.FIELD_DESTINATION_LATITUDE]: 0,
  [C.FIELD_DESTINATION_LONGITUDE]: 0,
  [GC.FIELD_LENGTH_UOM]: GC.UOM_FOOT,
  [C.FIELD_DESTINATION_COUNTRY]: 'US',
  [GC.FIELD_WEIGHT_UOM]: GC.UOM_POUND,
  [GC.FIELD_LOAD_BOARD_DH_ORIGIN]: '150',
  [GC.FIELD_LOAD_BOARD_DH_DESTINATION]: '',
  [GC.FIELD_LOAD_BOARD_CAPACITY]: C.CAPACITY_FULL,
  [GC.FIELD_LOAD_BOARD_DATE_EARLIEST]: G.getCurrentDateWithFormat(),
  [GC.FIELD_LOAD_BOARD_DATE_LATEST]: G.addMomentTimeWithFormat(G.getCurrentDate(), 1, 'days'),
};

const inputWrapperStyles = { mb: 25, mr: 20, width: 215 };
const inputWrapperStylesLast = { mb: 25, width: 215 };

const getFirstFieldset = ({ latestDate, earliestDate }: Object) => [
  {
    inputWrapperStyles,
    type: 'reactSelect',
    shouldCustomChange: true,
    options: 'templateOptions',
    fieldName: C.FIELD_TEMPLATE,
    label: ['titles:select-template'],
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: 'loadBoardOptions',
    label: ['titles:load-boards'],
    fieldName: GC.FIELD_LOAD_BOARD_TYPES,
    inputWrapperStyles: inputWrapperStylesLast,
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    maxDate: latestDate,
    timeSelection: false,
    label: ['titles:earliest-date'],
    minDate: G.getCurrentDateWithFormat(),
    fieldName: GC.FIELD_LOAD_BOARD_DATE_EARLIEST,
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    timeSelection: false,
    minDate: earliestDate,
    label: ['titles:latest-date'],
    fieldName: GC.FIELD_LOAD_BOARD_DATE_LATEST,
    inputWrapperStyles: inputWrapperStylesLast,
  },
];

const originFieldset = ([
  {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:city'],
    shouldCustomChange: true,
    type: 'addressAutocomplete',
    fieldName: C.FIELD_ORIGIN_CITY,
    addressFieldName: C.FIELD_ORIGIN_CITY,
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:origin-radius'],
    fieldName: GC.FIELD_LOAD_BOARD_DH_ORIGIN,
  },
]);
const getDestinationFieldset = (values: Object) => {
  const {
    destinationCity,
    destinationCountry,
  } = values;

  const destinationStateOptions = R.prop(R.or(destinationCountry, 'NO_COUNTRY'), GC.STATE_OPTIONS_MAP);

  return [
    {
      label: ['titles:city'],
      type: 'addressAutocomplete',
      inputWrapperStyles: inputWrapperStylesLast,
      fieldName: GC.FIELD_LOAD_BOARD_DESTINATION_CITY,
      addressFieldName: GC.FIELD_LOAD_BOARD_DESTINATION_CITY,
      disabled: (field: Object, values: Object) => R.gt(
        R.length(R.propOr([], C.FIELD_DESTINATION_STATES, values)),
        1,
      ),
    },
    {
      isMulti: true,
      type: 'reactSelect',
      label: ['titles:states'],
      shouldCustomChange: true,
      options: destinationStateOptions,
      fieldName: C.FIELD_DESTINATION_STATES,
      inputWrapperStyles: inputWrapperStylesLast,
      reactSelectStyles: {
        container: R.assoc('marginTop', 20),
      },
    },
    {
      type: 'select',
      isRequired: true,
      shouldCustomChange: true,
      label: ['titles:country'],
      options: GC.COUNTRY_OPTIONS,
      inputWrapperStyles: inputWrapperStylesLast,
      fieldName: GC.FIELD_LOAD_BOARD_DESTINATION_COUNTRY,
    },
    {
      type: 'number',
      label: ['titles:destination-radius'],
      inputWrapperStyles: inputWrapperStylesLast,
      fieldName: GC.FIELD_LOAD_BOARD_DH_DESTINATION,
      isRequired: G.isNotNilAndNotEmpty(destinationCity),
    },
  ];
};

const equipmentFieldset = [
  {
    isMulti: true,
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: GC.truckOptions,
    label: ['titles:equipment'],
    fieldName: GC.FIELD_TRUCK_TYPES,
  },
  {
    type: 'select',
    isRequired: true,
    options: capacityOptions,
    label: ['titles:full-partial-short'],
    fieldName: GC.FIELD_LOAD_BOARD_CAPACITY,
    inputWrapperStyles: inputWrapperStylesLast,
  },
  {
    type: 'number',
    label: ['titles:length'],
    fieldName: GC.FIELD_LOAD_BOARD_LENGTH,
    inputWrapperStyles: { mr: 15, mb: 25, width: 105 },
  },
  {
    type: 'select',
    label: ['titles:length-uom'],
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_LOAD_BOARD_LENGTH_UOM,
    inputWrapperStyles: { mr: 20, mb: 25, width: 95 },
  },
  {
    type: 'number',
    label: ['titles:weight'],
    fieldName: GC.FIELD_LOAD_BOARD_WEIGHT,
    inputWrapperStyles: { mr: 15, mb: 25, width: 105 },
  },
  {
    type: 'select',
    label: ['titles:weight-uom'],
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_LOAD_BOARD_WEIGHT_UOM,
    inputWrapperStyles: { mb: 25, width: 95 },
  },
];

const commentFieldset = [{
  type: 'textarea',
  label: ['titles:note'],
  fieldName: GC.FIELD_LOAD_BOARD_NOTE,
  inputWrapperStyles: { mb: 10, width: 450 },
}];

const getValidationSchema = (values: Object) => {
  const destinationCity = R.prop(C.FIELD_DESTINATION_CITY, values);
  const requiredText = G.getRequiredLocaleTxt();
  const positiveNumberText = G.getShouldBePositiveLocaleTxt();
  const fromOneToHundredThousand = G.getShouldBeFromToLocaleTxt(1, 99999);

  const stringValidation = Yup
    .string()
    .nullable(true)
    .required(requiredText);

  const stringNotRequiredValidation = Yup
    .string()
    .nullable(true)
    .notRequired();


  const numberRequired = Yup
    .number()
    .nullable(true)
    .required(requiredText);

  const numberValidation = Yup
    .number()
    .nullable(true)
    .required(requiredText)
    .min(1, fromOneToHundredThousand)
    .max(99999, fromOneToHundredThousand)
    .typeError(positiveNumberText)
    .positive(positiveNumberText);

  const numberNotRequiredValidation = Yup
    .number()
    .nullable(true)
    .notRequired()
    .min(1, fromOneToHundredThousand)
    .max(99999, fromOneToHundredThousand)
    .typeError(positiveNumberText)
    .positive(positiveNumberText);

  const arrayValidation = Yup
    .array()
    .of(Yup.string())
    .min(1, requiredText)
    .required(requiredText);

  const statesValidation = Yup
    .array()
    .of(Yup.string())
    .max(10, G.getMaximumItemsToPickLocaleText(10))
    .notRequired();

  const trucksValidation = Yup.array()
    .of(Yup.string())
    .min(1, requiredText)
    .max(2, G.getMaximumItemsToPickLocaleText(2))
    .required(requiredText);

  const validationSchema = {
    [C.FIELD_ORIGIN_CITY]: stringValidation,
    [GC.FIELD_TRUCK_TYPES]: trucksValidation,
    [C.FIELD_ORIGIN_STATE]: stringValidation,
    [C.FIELD_ORIGIN_LATITUDE]: numberRequired,
    [C.FIELD_ORIGIN_LONGITUDE]: numberRequired,
    [GC.FIELD_ORIGIN_COUNTRY]: stringValidation,
    [GC.FIELD_LOAD_BOARD_TYPES]: arrayValidation,
    [GC.FIELD_LENGTH]: numberNotRequiredValidation,
    [GC.FIELD_WEIGHT]: numberNotRequiredValidation,
    [C.FIELD_DESTINATION_STATES]: statesValidation,
    [C.FIELD_DESTINATION_COUNTRY]: stringValidation,
    [GC.FIELD_LOAD_BOARD_CAPACITY]: stringValidation,
    [GC.FIELD_LOAD_BOARD_DH_ORIGIN]: numberValidation,
    [GC.FIELD_LOAD_BOARD_DATE_LATEST]: stringValidation,
    [GC.FIELD_LOAD_BOARD_DATE_EARLIEST]: stringValidation,
    [C.FIELD_DESTINATION_CITY]: stringNotRequiredValidation,
    [GC.FIELD_LOAD_BOARD_DH_DESTINATION]: G.ifElse(
      G.isNotNilAndNotEmpty(destinationCity),
      numberValidation,
      numberNotRequiredValidation,
    ),
  };

  return validationSchema;
};

const getValuesFromTemplate = (template: Object) => ({
  [C.FIELD_TEMPLATE]: G.getGuidFromObject(template),
  [GC.FIELD_LENGTH]: R.prop(GC.FIELD_LENGTH, template),
  [GC.FIELD_WEIGHT]: R.prop(GC.FIELD_WEIGHT, template),
  [GC.FIELD_LENGTH_UOM]: R.prop(GC.FIELD_LENGTH_UOM, template),
  [GC.FIELD_WEIGHT_UOM]: R.prop(GC.FIELD_WEIGHT_UOM, template),
  [GC.FIELD_TRUCK_TYPES]: R.prop(GC.FIELD_TRUCK_TYPES, template),
  [GC.FIELD_LOAD_BOARD_NOTE]: R.prop(GC.FIELD_LOAD_BOARD_NOTE, template),
  [GC.FIELD_LOAD_BOARD_CAPACITY]: R.prop(GC.FIELD_LOAD_BOARD_CAPACITY, template),
  [C.FIELD_ORIGIN_CITY]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_CITY], template),
  [GC.FIELD_LOAD_BOARD_DH_ORIGIN]: R.prop(GC.FIELD_LOAD_BOARD_DH_ORIGIN, template),
  [C.FIELD_ORIGIN_STATE]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_STATE], template),
  [C.FIELD_ORIGIN_COUNTRY]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_COUNTRY], template),
  [C.FIELD_ORIGIN_LATITUDE]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_LATITUDE], template),
  [C.FIELD_ORIGIN_LONGITUDE]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_LONGITUDE], template),
  [GC.FIELD_LOAD_BOARD_DH_DESTINATION]: R.prop(GC.FIELD_LOAD_BOARD_DH_DESTINATION, template),
  [C.FIELD_DESTINATION_CITY]: R.pathOr('', [C.FIELD_DESTINATIONS, 0, GC.FIELD_CITY], template),
  [C.FIELD_DESTINATION_COUNTRY]: R.pathOr('', [C.FIELD_DESTINATIONS, 0, GC.FIELD_COUNTRY], template),
  [C.FIELD_DESTINATION_STATES]: R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    R.map(R.prop(GC.FIELD_STATE)),
    R.propOr([], C.FIELD_DESTINATIONS),
  )(template),
});

const getFilterInitialValuesFromData = (filter: Object, filtersStates: Object) => {
  const pickArray = [
    GC.FIELD_GUID,
    GC.FIELD_NOTES,
    GC.FIELD_LENGTH,
    GC.FIELD_WEIGHT,
    GC.FIELD_LENGTH_UOM,
    GC.FIELD_WEIGHT_UOM,
    GC.FIELD_TRUCK_TYPES,
    GC.FIELD_LOAD_BOARD_CAPACITY,
    GC.FIELD_LOAD_BOARD_DH_ORIGIN,
    GC.FIELD_LOAD_BOARD_DH_DESTINATION,
  ];

  const latestDate = G.getPropFromObject(GC.FIELD_LOAD_BOARD_DATE_LATEST, filter);
  const earliestDate = G.getPropFromObject(GC.FIELD_LOAD_BOARD_DATE_EARLIEST, filter);

  const today = G.getCurrentDateWithFormat('YYYY-MM-DD');

  const isLatestNotValid = G.isBefore(latestDate, today);
  const isEarliestNotValid = G.isBefore(earliestDate, today);

  return R.compose(
    R.mergeRight({
      [C.FIELD_ORIGIN_CITY]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_CITY], filter),
      [C.FIELD_ORIGIN_STATE]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_STATE], filter),
      [C.FIELD_ORIGIN_COUNTRY]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_COUNTRY], filter),
      [C.FIELD_ORIGIN_LATITUDE]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_LATITUDE], filter),
      [C.FIELD_ORIGIN_LONGITUDE]: R.pathOr('', [GC.FIELD_ORIGIN, GC.FIELD_LONGITUDE], filter),
      [C.FIELD_DESTINATION_CITY]: R.pathOr('', [C.FIELD_DESTINATIONS, 0, GC.FIELD_CITY], filter),
      [C.FIELD_DESTINATION_COUNTRY]: R.pathOr('', [C.FIELD_DESTINATIONS, 0, GC.FIELD_COUNTRY], filter),
      [GC.FIELD_LOAD_BOARD_DATE_LATEST]: G.ifElse(
        isLatestNotValid,
        G.addMomentTimeWithFormat(G.getCurrentDate(), 1, 'days'),
        latestDate,
      ),
      [GC.FIELD_LOAD_BOARD_DATE_EARLIEST]: G.ifElse(
        isEarliestNotValid,
        today,
        earliestDate,
      ),
      [GC.FIELD_LOAD_BOARD_TYPES]: R.compose(
        R.map(R.prop(GC.FIELD_TYPE)),
        R.propOr([], G.getGuidFromObject(filter)),
      )(filtersStates),
      [C.FIELD_DESTINATION_STATES]: R.compose(
        R.filter(G.isNotNilAndNotEmpty),
        R.map(R.prop(GC.FIELD_STATE)),
        R.propOr([], C.FIELD_DESTINATIONS),
      )(filter),
    }),
    R.pick(pickArray),
  )(filter);
};

const getStateCoordinates = (state: string, country: string) => R.compose(
  R.path([state, 'coordinates']),
  R.indexBy(R.prop(GC.FIELD_VALUE)),
  R.prop(country),
)(GC.STATE_OPTIONS_MAP);

const getSubmitValues = (values: Object, forTemplate: boolean = false, isFilterEdit: boolean = false) => {
  const {
    originCity,
    originState,
    originCountry,
    originLatitude,
    originLongitude,
    destinationCity,
    destinationStates,
    destinationCountry,
    destinationLatitude,
    destinationLongitude,
  } = values;

  let destinations;

  if (G.isNotNilAndNotEmpty(destinationCity)) {
    destinations = [{
      city: destinationCity,
      country: destinationCountry,
      latitude: destinationLatitude,
      longitude: destinationLongitude,
      state: R.head(destinationStates),
    }];
  } else if (G.isNotEmpty(destinationStates)) {
    destinations = R.map(
      (state: string) => ({
        state,
        city: null,
        country: destinationCountry,
        ...getStateCoordinates(state, destinationCountry),
      }),
      destinationStates,
    );
  } else {
    destinations = [{
      city: null,
      state: null,
      country: R.or(destinationCountry, null),
      ...R.prop(destinationCountry, GC.COUNTRY_COORDINATES),
    }];
  }

  let omitFields = [
    C.FIELD_TEMPLATE,
    C.FIELD_ORIGIN_CITY,
    C.FIELD_ORIGIN_STATE,
    C.FIELD_ORIGIN_COUNTRY,
    C.FIELD_ORIGIN_LATITUDE,
    C.FIELD_ORIGIN_LONGITUDE,
    C.FIELD_DESTINATION_CITY,
    C.FIELD_DESTINATION_STATES,
    C.FIELD_DESTINATION_COUNTRY,
    C.FIELD_DESTINATION_LATITUDE,
    C.FIELD_DESTINATION_LONGITUDE,
  ];

  const origin = G.mapObjectEmptyStringFieldsToNull({
    [GC.FIELD_CITY]: originCity,
    [GC.FIELD_STATE]: originState,
    [GC.FIELD_COUNTRY]: originCountry,
    [GC.FIELD_LATITUDE]: originLatitude,
    [GC.FIELD_LONGITUDE]: originLongitude,
  });

  let mergeValues = {
    origin,
    destinations,
    distanceUom: 'MILE',
  };

  if (forTemplate) {
    omitFields = R.concat(omitFields, [
      GC.FIELD_LOAD_BOARD_TYPES,
      GC.FIELD_LOAD_BOARD_DATE_LATEST,
      GC.FIELD_LOAD_BOARD_DATE_EARLIEST,
    ]);

    if (isFilterEdit) {
      omitFields = R.append(GC.FIELD_GUID, omitFields);
    }
  } else {
    mergeValues = {
      ...mergeValues,
      latestDate: G.createLocalDateTimeString(R.prop(GC.FIELD_LOAD_BOARD_DATE_LATEST, values), 'YYYY-MM-DD'),
      earliestDate: G.createLocalDateTimeString(R.prop(GC.FIELD_LOAD_BOARD_DATE_EARLIEST, values), 'YYYY-MM-DD'),
    };
  }

  return R.mergeRight(G.mapObjectEmptyStringFieldsToNull(R.omit(omitFields, values)), mergeValues);
};

export {
  defaultFields,
  originFieldset,
  getSubmitValues,
  commentFieldset,
  getFirstFieldset,
  equipmentFieldset,
  getValidationSchema,
  getValuesFromTemplate,
  getDestinationFieldset,
  getFilterInitialValuesFromData,
};
