import React from 'react';
import { pure, compose } from 'react-recompose';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../hocs';
// feature master-invoice
import MasterInvoiceInvoices from './master-invoice-invoices';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncInitialDataOnDidMount,
  pure,
);

const AsyncMasterInvoiceInvoices = (props: Object) => {
  const { closeModal, asyncInitialData } = props;

  const { data, loading } = asyncInitialData;

  const parentProps = {
    detailsWidth: '100%',
    detailsLoading: loading,
  };

  return (
    <MasterInvoiceInvoices
      data={data}
      actionsDisabled={true}
      closeModal={closeModal}
      parentProps={parentProps}
    />
  );
};

export default enhance(AsyncMasterInvoiceInvoices);

