import * as R from 'ramda';
import { put, call, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature item
import * as A from './actions';
//////////////////////////////////////////////////

function* handleDeleteItemReferenceRequest({ payload }: Object) {
  try {
    const { guid, callback } = payload;

    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getRemoveItemReferenceEndpoint(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunction(callback);
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteItemReferenceRequest exception');
  }
}

function* handleGetItemReferenceRequest({ payload }: Object) {
  try {
    const { guid, callback } = payload;

    const options = {
      params: { itemGuid: guid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.itemReferenceList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, R.indexBy(R.prop(GC.FIELD_GUID), data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetItemReferenceRequest exception');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { args, callback } = payload;

    const res = yield call(sendRequest, 'post', endpointsMap.itemReference, { data: args });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, R.head(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReferenceSaga exception');
  }
}

function* handleUpdateItemReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { callback, reference } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.itemReference, {data: reference });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateItemReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateItemReferenceRequest exception');
  }
}

export function* ItemsWatcherSaga() {
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(A.getItemReferenceRequest, handleGetItemReferenceRequest);
  yield takeLatest(A.deleteItemReferenceRequest, handleDeleteItemReferenceRequest);
  yield takeLatest(A.updateItemReferenceRequest, handleUpdateItemReferenceRequest);
}

export default ItemsWatcherSaga;
