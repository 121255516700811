import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const getRelatedRows = ({ primaryReference, ...props}: Object) => ({
  [GC.FIELD_INVOICE_NUMBER]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:related-invoice-number', 'Related Invoice #')}:`,
    },
    {
      text: R.prop(GC.FIELD_INVOICE_NUMBER, props),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_TEL]: [
    {
      text: `${G.getWindowLocale('titles:tel', 'TEL')}:`,
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
    },
    {
      text: primaryReference.value,
      textProps: {
        mr: '10px',
        fontWeight: 700,
        cursor: 'pointer',
        width: 'calc(55% - 10px)',
        color: G.getTheme('colors.light.blue'),
        onClick: () => props.handleClickOnTelPrimaryReference(R.prop(GC.FIELD_TEL_GUID, props)),
      },
    },
  ],
  [GC.FIELD_INVOICE_DATE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:date', 'Date')}:`,
    },
    {
      text: R.prop(GC.FIELD_INVOICE_DATE, props),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:payment-due-date', 'Payment Due Date')}:`,
    },
    {
      text: R.prop(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, props),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_GL_CODE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:gl-code', 'GL Code')}:`,
    },
    {
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      text: R.pathOr('', [GC.FIELD_GL_CODE, GC.FIELD_DISPLAYED_VALUE], props),
    },
  ],
  [GC.FIELD_MODE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles.mode', 'Mode')}:`,
    },
    {
      text: R.prop(GC.FIELD_MODE, props),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:distance', 'Distance')}:`,
    },
    {
      text: G.ifElse(
        G.isNotNil(R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, props)),
        `${G.toFixed(R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, props))} ${R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, props)}`,
        '',
      ),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:weight', 'Weight')}:`,
    },
    {
      text: G.ifElse(
        G.isNotNil(R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, props)),
        `${G.toFixed(R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, props))} ${R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT_UOM, props)}`,
        '',
      ),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
});

export const getDetailRows = ({
  currentInvoice,
  primaryReference,
  handleClickOnTelPrimaryReference,
}: Object) => ({
  [GC.FIELD_INVOICE_NUMBER]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:related-invoice-number', 'Related Invoice #')}:`,
    },
    {
      text: R.prop(GC.FIELD_INVOICE_NUMBER, currentInvoice),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_INVOICE_STATUS]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:status', 'Status')}:`,
    },
    {
      text: R.pathOr('', [GC.FIELD_INVOICE_STATUS, GC.FIELD_DISPLAYED_VALUE], currentInvoice),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_TEL]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:tel', 'TEL')}:`,
    },
    {
      text: primaryReference.value,
      textProps: {
        mr: '10px',
        fontWeight: 700,
        cursor: 'pointer',
        width: 'calc(55% - 10px)',
        color: G.getTheme('colors.light.blue'),
        onClick: () => handleClickOnTelPrimaryReference(R.prop(GC.FIELD_TEL_GUID, currentInvoice)),
      },
    },
  ],
  [GC.FIELD_INVOICE_DATE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:date', 'Date')}:`,
    },
    {
      text: R.prop(GC.FIELD_INVOICE_DATE, currentInvoice),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:payment-due-date', 'Payment Due Date')}:`,
    },
    {
      text: R.prop(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, currentInvoice),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_GL_CODE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:gl-code', 'GL Code')}:`,
    },
    {
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
      text: R.pathOr('', [GC.FIELD_GL_CODE, GC.FIELD_DISPLAYED_VALUE], currentInvoice),
    },
  ],
  [GC.FIELD_MODE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles.mode', 'Mode')}:`,
    },
    {
      text: R.prop(GC.FIELD_MODE, currentInvoice),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:distance', 'Distance')}:`,
    },
    {
      text: G.ifElse(
        G.isNotNil(R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, currentInvoice)),
        `${G.toFixed(R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, currentInvoice))} ${R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, currentInvoice)}`,
        '',
      ),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: [
    {
      textProps: { mr: '10px', width: 'calc(45% - 10px)' },
      text: `${G.getWindowLocale('titles:weight', 'Weight')}:`,
    },
    {
      text: G.ifElse(
        G.isNotNil(R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, currentInvoice)),
        `${G.toFixed(R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, currentInvoice))} ${R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT_UOM, currentInvoice)}`,
        '',
      ),
      textProps: { mr: '10px', fontWeight: 700, width: 'calc(55% - 10px)' },
    },
  ],
  [GC.FIELD_COMMENTS]: [
    {
      textProps: { mr: '10px', minWidth: 'fit-content' },
      text: `${G.getWindowLocale('titles:comments', 'Comments')}:`,
    },
    {
      text: R.prop(GC.FIELD_COMMENTS, currentInvoice),
      textProps: { mr: '10px', width: 'fit-content' },
    },
  ],
});

export const getChargeRows = ({ currentInvoice, ...props }: Object, currency: string) => ({
  currentCharge: [
    {
      textProps: { mr: '10px', minWidth: 'fit-content' },
      text: `${G.getWindowLocale('titles:current', 'Current')}:`,
    },
    {
      text: `${currency} ${G.toFixed(currentInvoice.total)}`,
      textProps: { mr: '10px', width: 'fit-content' },
    },
  ],
  approvedCharge: [
    {
      textProps: { mr: '10px', minWidth: 'fit-content' },
      text: `${G.getWindowLocale('titles:approved', 'Approved')}:`,
    },
    {
      text: `${currency} ${R.or(G.toFixed(currentInvoice.approvedTotal), '0.00')}`,
      textProps: { mr: '10px', width: 'fit-content', color: G.getTheme('colors.light.green') },
    },
  ],
  rejectedCharge: [
    {
      textProps: { mr: '10px', minWidth: 'fit-content' },
      text: `${G.getWindowLocale('titles:rejected', 'Rejected')}:`,
    },
    {
      text: `${currency} ${
        G.ifElse(
          G.isNilOrEmpty(currentInvoice.approvedTotal),
          '0.00',
          G.toFixed(R.subtract(R.prop(GC.FIELD_TOTAL, currentInvoice), currentInvoice.approvedTotal)),
      )}`,
      textProps: { mr: '10px', width: 'fit-content', color: G.getTheme('colors.light.mainRed') },
    },
  ],
});
