import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_DIMENSIONS_OPTIONS,
  getRaleBillingEnumOptions,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const containerDefaultFields = {
  [GC.FIELD_WIDTH]: '',
  [GC.FIELD_HEIGHT]: '',
  [GC.FIELD_LENGTH]: '',
  [GC.FIELD_WEIGHT_UOM]: '',
  [GC.FIELD_DIMENSIONS_UOM]: '',
  [GC.FIELD_CONTAINER_NUMBER]: '',
  [GC.FIELD_CONTAINER_INITIAL]: '',
  [GC.FIELD_CONTAINER_TYPE_GUID]: '',
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: '',
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: '',
};

export const getNewContainerFields = () => ({
  ...containerDefaultFields,
  [GC.FIELD_CONTAINER_INTERNAL_ID]: G.generateGuid(),
});

const itemCommonProps1 = {
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
    width: 100,
  },
};

const itemCommonProps2 = {
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
    width: 160,
  },
};

const itemCommonProps3 = {
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
    width: 130,
  },
};

export const pickupContainerFields = [
  {
    ...itemCommonProps2,
    type: 'select',
    shouldCustomChange: true,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { values, itemIndex, containerTypes, setFieldValue } = props;

      const value = event.target.value;
      const containers = R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, values);
      const currentValues = R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS, itemIndex], values);

      const containerInternalId = R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, currentValues);
      if (G.isNilOrEmpty(value)) {
        const newValues = R.assoc(GC.FIELD_CONTAINER_TYPE_GUID, value, currentValues);
        const updatedContainers = R.update(itemIndex, newValues, containers);

        return setFieldValue(GC.FIELD_STOP_PICKED_UP_CONTAINERS, updatedContainers);
      }

      const newValues = R.compose(
        G.mapObjectNullFieldsToEmptyStrings,
        R.assoc(GC.FIELD_CONTAINER_TYPE_GUID, value),
        R.assoc(GC.FIELD_CONTAINER_INTERNAL_ID, containerInternalId),
        R.pick([
          GC.FIELD_WIDTH,
          GC.FIELD_HEIGHT,
          GC.FIELD_LENGTH,
          GC.FIELD_WEIGHT_UOM,
          GC.FIELD_DIMENSIONS_UOM,
          GC.FIELD_EMPTY_CONTAINER_WEIGHT,
        ]),
        R.find(R.propEq(value, GC.FIELD_GUID)),
      )(containerTypes);
      const newValuesWithFullWeight = R.assoc(
        GC.FIELD_FULL_CONTAINER_WEIGHT,
        R.prop(GC.FIELD_EMPTY_CONTAINER_WEIGHT, newValues),
        newValues,
      );
      const updatedContainers = R.update(itemIndex, newValuesWithFullWeight, containers);

      return setFieldValue(GC.FIELD_STOP_PICKED_UP_CONTAINERS, updatedContainers);
    },
    options: 'containerTypeOptions',
    fieldName: GC.FIELD_CONTAINER_TYPE_GUID,
    label: G.getWindowLocale('titles:container-type', 'Container Type'),
  },
  {
    ...itemCommonProps2,
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_INITIAL,
    label: G.getWindowLocale('titles:container-initial', 'Container Initial'),
  },
  {
    ...itemCommonProps2,
    type: 'text',
    isRequired: true,
    shouldCustomBlur: true,
    customBlurHandler: (event: Object, field: Object, props: Object) => {
      const { stop, values, itemIndex, handleBlur, setFieldValue, setFormDataToStop } = props;

      const value = event.target.value;
      const containers = R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, values);
      const currentValues = R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS, itemIndex], values);
      const newValues = R.assoc(GC.FIELD_CONTAINER_NUMBER, value, currentValues);
      const updatedContainers = R.update(itemIndex, newValues, containers);
      setFieldValue(GC.FIELD_STOP_PICKED_UP_CONTAINERS, updatedContainers);
      handleBlur(event);

      if (G.isNilOrEmpty(stop)) return;
      const { order, isValid } = stop;

      return setFormDataToStop({ isValid, formData: values, stopOrder: order });
    },
    fieldName: GC.FIELD_CONTAINER_NUMBER,
    label: G.getWindowLocale('titles:container-number', 'Container Number'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_EMPTY_CONTAINER_WEIGHT,
    label: G.getWindowLocale('titles:empty-weight', 'Empty Weight'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_FULL_CONTAINER_WEIGHT,
    label: G.getWindowLocale('titles:full-weight', 'Full Weight'),
  },
  {
    ...itemCommonProps3,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    label: G.getWindowLocale('titles:weight-uom', 'Weight UOM'),
  },
  {
    ...itemCommonProps3,
    type: 'select',
    fieldName: GC.FIELD_RAIL_BILLING_STATUS,
    label: G.getWindowLocale('titles:rail-billing', 'Rail Billing'),
    options: G.prependEmptyLabelValueOption(getRaleBillingEnumOptions()),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_WIDTH,
    label: G.getWindowLocale('titles:width', 'Width'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_HEIGHT,
    label: G.getWindowLocale('titles:height', 'Height'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_LENGTH,
    label: G.getWindowLocale('titles:length', 'Length'),
  },
  {
    ...itemCommonProps2,
    type: 'select',
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_DIMENSIONS_UOM,
    label: G.getWindowLocale('titles:dimensions-uom', 'Dimensions UOM'),
  },
];

export const dropContainerStopFields = [
  {
    inputWrapperStyles: {
      mr: 20,
      zIndex: 21,
      width: 300,
    },
    isMulti: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    options: 'dropContainerOptions',
    fieldName: GC.FIELD_STOP_DROPPED_CONTAINERS,
    label: G.getWindowLocale('titles:containers', 'Container(s)'),
  },
];
