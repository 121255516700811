import React from 'react';
// components
import AsyncDocuments from '../../../components/async-documents';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, scrollableContainerCss3px } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkGreyColor = G.getTheme('colors.darkGrey');
const lightGreyColor = G.getTheme('colors.lightGrey');

const MasterInvoiceDocuments = (props: Object) => {
  const { masterInvoiceGuid, mailIntegrationType, recallDocumentsGuid } = props;

  const asyncEndpoint = endpointsMap.customerMasterInvoiceDocuments;

  const options = {
    params: { [GC.FIELD_PRIMARY_OBJECT_GUID]: masterInvoiceGuid },
  };

  const columnSettingsProps = {
    [GC.FIELD_CREATED_DATE]: { width: 120 },
    [GC.FIELD_DOCUMENT_FILE_NAME]: { width: 155, maxWidth: 114 },
  };

  return (
    <Box
      width={500}
      flexShrink={0}
      borderRadius='6px'
      background={whiteColor}
    >
      <Flex
        p={15}
        height={32}
        width='100%'
        fontSize={13}
        bg={lightGreyColor}
        color={darkGreyColor}
        borderTopLeftRadius='6px'
        borderTopRightRadius='6px'
      >
        <Box>
          {G.getWindowLocale('titles:documents', 'Documents')}
        </Box>
      </Flex>
      <Box
        px={15}
        height={275}
        overflow='auto'
        css={scrollableContainerCss3px}
      >
        <AsyncDocuments
          showSend={false}
          contentType='card'
          asyncOptions={options}
          asyncEndpoint={asyncEndpoint}
          payrollGuid={masterInvoiceGuid}
          recallDocumentsGuid={recallDocumentsGuid}
          columnSettingsProps={columnSettingsProps}
          mailIntegrationType={mailIntegrationType}
          cancelBtnStyles={{ visibility: 'hidden' }}
          documentsType={GC.DOCUMENTS_TYPE_MASTER_INVOICE}
        />
      </Box>
    </Box>
  );
};

export default MasterInvoiceDocuments;
