import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature menus
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  navItems: [],
  dropdownItems: [],
  openedSidebar: false,
  requestPending: false,
  menus: {
    sideMenuItems: [],
    navigationMenuItems: [],
  },
  subMenus: {
    'menu:fleet': false,
    'menu:views': false,
    'menu:invoices': false,
    'menu:templates': false,
    'menu:contracts': false,
    'menu:configurations': false,
    'menu:background-processes': false,
  },
};

const toggleSubmenus = (state: Object, data: Object) => (
  P.$set('subMenus', data, state)
);

const getMenusRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const receivedMenusFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const toggleSidebar = (state: Object) => (
  P.$toggle('openedSidebar', state)
);

const receivedMenusSuccess = (state: Object, data: Array) => (
  P.$all(
    P.$set('menus', data),
    P.$set('requestPending', false),
    P.$set('navItems', data.navigationMenuItems),
    state,
  )
);

const addItemToNavSuccess = (state: Object, navItems: Array) => (
  P.$all(
    P.$set('navItems', navItems),
    P.$set('menus.navigationMenuItems', navItems),
    state,
  )
);

const deleteNavItemSuccess = (state: Object, navItems: Array) => (
  P.$all(
    P.$set('menus.navigationMenuItems', navItems),
    state,
  )
);

const splitNavItemsAction = (state: Object, data: Object) => (
  P.$all(
    P.$set('navItems', data.nav),
    P.$set('dropdownItems', data.dropdown),
    state,
  )
);

export default createReducer({
  [A.toggleSidebar]: toggleSidebar,
  [A.toggleSubmenus]: toggleSubmenus,
  [A.getMenusRequest]: getMenusRequest,
  [A.receivedMenusFail]: receivedMenusFail,
  [A.addItemToNavSuccess]: addItemToNavSuccess,
  [A.splitNavItemsAction]: splitNavItemsAction,
  [A.deleteNavItemSuccess]: deleteNavItemSuccess,
  [A.receivedMenusSuccess]: receivedMenusSuccess,
}, initialState);
