import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { PAYMENT_TERM_OPTIONS } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature master-invoice
import * as H from '../helpers';
import MissingDocumentTypes from '../components/missing-document-types';
import CloInvoiceDocumentList from '../components/clo-invoice-document-list';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = 'calc(100vh - 350px)';
const blueColor = G.getTheme('colors.light.blue');

const fields = [
  { name: GC.FIELD_BRANCH_NAME, sequence: 1 },
  { name: GC.FIELD_INVOICE_NUMBER, sequence: 2 },
  { name: GC.FIELD_ADDITIONAL_REFERENCES, sequence: 3 },
  { name: GC.FIELD_INVOICE_DATE, sequence: 4 },
  { name: GC.FIELD_INVOICE_STATUS, sequence: 5 },
  { name: GC.FIELD_PAYMENT_TERM, sequence: 6 },
  { name: GC.FIELD_TOTAL, sequence: 7 },
  { name: GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE, sequence: 8 },
  { name: GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT, sequence: 9 },
  { name: GC.FIELD_INVOICE_MODE, sequence: 10 },
  { name: GC.SYSTEM_OBJECT_FIRST_EVENT, sequence: 11 },
  { name: 'numberOfEvents', sequence: 12 },
  { name: GC.SYSTEM_OBJECT_LAST_EVENT, sequence: 13 },
  { name: GC.FIELD_COMMENTS, sequence: 14 },
];

export const report = {
  fields: [
    { name: GC.FIELD_DOCUMENTS, sequence: 0 },
    ...fields,
  ],
};

export const reportWithEmails = {
  fields: R.insert(2, { name: GC.FIELD_EMAILS, sequence: 3 }, report.fields),
};

export const reportWithoutDocuments = { fields };

export const tableSettings = {
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
  maxHeight: tableMaxHeight,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

export const printTableSettings = {
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
  maxHeight: tableMaxHeight,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

export const getColumnSettings = ({
  groupName,
  closeModal,
  sendEmails,
  addDocument,
  documentTypes,
  openFixedPopup,
  closeFixedPopup,
  handleChangeEmails,
  mailSendingIntegrationExists,
}: Object) => ({
  [GC.FIELD_EMAILS]: {
    width: 220,
    name: 'titles:emails',
    customComponent: ({ data }: Object) => {
      const text = R.compose(
        R.join(', '),
        R.pathOr([], [GC.FIELD_EMAILS]),
      )(data);

      return (
        <Flex justifyContent='space-between'>
          <TextComponent
            title={text}
            color={blueColor}
            withEllipsis={true}
            maxWidth={G.ifElse(G.isNotNilAndNotEmpty(mailSendingIntegrationExists), 190, 204)}
          >
            {text}
          </TextComponent>
          {
            R.and(sendEmails, G.isNotNilAndNotEmpty(mailSendingIntegrationExists)) &&
            <Box cursor='pointer' onClick={() => handleChangeEmails(data)}>
              {I.pencil(G.getTheme('colors.dark.blue'))}
            </Box>
          }
        </Flex>
      );
    },
  },
  [GC.FIELD_DOCUMENTS]: {
    width: 35,
    customComponent: ({ data }: Object) => {
      const missingDocumentTypes = H.getMissingDocumentTypes(documentTypes, data.documents);

      if (G.isNilOrEmpty(missingDocumentTypes)) return null;

      const handleClick = (e: Object) => {
        openFixedPopup({
          position: 'left',
          el: e.currentTarget,
          boxStyles: {
            zIndex: 1300,
          },
          content: (
            <Box p='3px 12px' maxWidth={400}>
              <MissingDocumentTypes
                documentTypes={missingDocumentTypes}
                addDocument={() => addDocument(data, groupName)}
              />
              <CloInvoiceDocumentList documents={data.documents} onClickCallback={closeFixedPopup} />
            </Box>
          ),
        });
      };

      return <Box cursor='pointer' onClick={handleClick}>{I.missingDocuments()}</Box>;
    },
  },
  [GC.FIELD_BRANCH_NAME]: {
    width: 100,
    name: 'titles:branch-name',
  },
  [GC.FIELD_INVOICE_NUMBER]: {
    width: 100,
    name: 'titles:number',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        cursor='pointer'
        color={blueColor}
        withEllipsis={true}
        onClick={() => {
          G.goToLoadDetailsByConfigAndLoadType(routesMap, R.prop(GC.FIELD_CLO_GUID, data), true);
          closeModal();
        }}
        title={`${data[GC.FIELD_INVOICE_NUMBER]} - ${
          G.getWindowLocale('actions:go-to-clo-details', 'Go To CLO Details')}`}
      >{data[GC.FIELD_INVOICE_NUMBER]}</TextComponent>
    ),
  },
  [GC.FIELD_ADDITIONAL_REFERENCES]: {
    width: 150,
    name: 'titles:additional-references',
  },
  [GC.FIELD_INVOICE_DATE]: {
    width: 100,
    name: 'titles:date',
  },
  [GC.FIELD_INVOICE_STATUS]: {
    width: 100,
    name: 'titles:status',
  },
  [GC.FIELD_PAYMENT_TERM]: {
    width: 150,
    name: 'titles:payment-term',
    customComponent: ({ data }: Object) => {
      const { paymentTerm } = data;
      if (G.isNilOrEmpty(paymentTerm)) return '';

      return R.compose(
        R.prop(GC.FIELD_LABEL),
        R.find(R.propEq(paymentTerm, GC.FIELD_VALUE)),
      )(PAYMENT_TERM_OPTIONS);
    },
  },
  [GC.FIELD_TOTAL]: {
    width: 100,
    name: 'titles:total',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data.currency} ${data[GC.FIELD_TOTAL]}`}
      >{`${G.getCurrencySymbol(data.currency)} ${G.toFixed(data[GC.FIELD_TOTAL])}`}</TextComponent>
    ),
  },
  [GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE]: {
    width: 100,
    name: 'titles:total-distance',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data[GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE]} ${data[GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE_UOM]}`}
      >{
        G.ifElse(
          G.isNotNilAndNotEmpty(data[GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE]),
          `${G.toFixed(data[GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE])} ${data[GC.FIELD_INVOICE_TOTAL_TRIP_DISTANCE_UOM]}`,
          null,
        )
      }</TextComponent>
    ),
  },
  [GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT]: {
    width: 100,
    name: 'titles:total-weight',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data[GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT]} ${data[GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT_UOM]}`}
      >{
        G.ifElse(
          G.isNotNilAndNotEmpty(data[GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT]),
          `${G.toFixed(data[GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT])} ${data[GC.FIELD_INVOICE_TOTAL_TRIP_WEIGHT_UOM]}`,
          null,
        )
      }</TextComponent>
    ),
  },
  [GC.FIELD_INVOICE_MODE]: {
    width: 100,
    name: 'titles:mode',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={data.transportationMode}
      >{data.transportationMode}</TextComponent>
    ),
  },
  [GC.SYSTEM_OBJECT_FIRST_EVENT]: {
    width: 100,
    name: 'titles:first-stop',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        withEllipsis={true}
        title={`${data.firstEventCity}, ${data.firstEventState}`}
      >{
        `${data.firstEventCity}, ${data.firstEventState}`
      }</TextComponent>
    ),
  },
  numberOfEvents: {
    width: 100,
    name: 'titles:stops-count',
  },
  [GC.SYSTEM_OBJECT_LAST_EVENT]: {
    width: 100,
    name: 'titles:last-stop',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={104}
        withEllipsis={true}
        title={`${data.lastEventCity}, ${data.lastEventState}`}
      >{
        `${data.lastEventCity}, ${data.lastEventState}`
      }</TextComponent>
    ),
  },
  [GC.FIELD_COMMENTS]: {
    width: 100,
    name: 'titles:comments',
  },
});
