import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_VOLUME_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const telInvoiceInitFields = {
  [GC.FIELD_MODE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CHECK_AMOUNT]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_INVOICE_DATE]: null,
  [GC.FIELD_SERVICE_DAYS]: null,
  [GC.FIELD_INVOICE_STATUS]: null,
  [GC.FIELD_INVOICE_NUMBER]: null,
  [GC.FIELD_INVOICE_NET_DAYS]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: null,
  [GC.FIELD_INVOICE_CHECK_DATE]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: null,
  [GC.FIELD_INVOICE_CHECK_NUMBER]: null,
  [GC.FIELD_INVOICE_DEPOSIT_DATE]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: null,
  [GC.FIELD_FACTORING_PAYMENT_TERM]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: null,
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: null,
  [GC.FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER]: null,
};

export const telTotalFields = [
  {
    type: 'text',
    isRequired: true,
    inputStyles: {
      width: 90,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    label: ['titles:trip-distance', 'Trip Distance'],
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    inputStyles: {
      width: 90,
      borderRadius: '0px 4px 4px 0px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  },
  {
    type: 'text',
    inputStyles: {
      width: 90,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_DEADHEAD_DISTANCE,
    label: ['titles:deadhead', 'Deadhead'],
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    inputStyles: {
      width: 90,
      borderRadius: '0px 4px 4px 0px',
    },
    fieldName: GC.FIELD_DEADHEAD_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  },
  {
    type: 'customComponent',
    inputWrapperStyles: { mr: 20, flexGrow: 1 },
    label: ['titles:total-distance', ' Total Distance'],
    Component: (props: Object) => {
      const { values } = props;

      const { deadHeadDistance, deadHeadDistanceUom, totalTripDistance, totalTripDistanceUom } = values;

      const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();
      const defaultUom = R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap);
      const defaultUomLocale = G.getUomLocale(defaultUom);

      const total = G.milesKmsFromToAccordingSystemWithNullable(
        defaultUom,
        totalTripDistanceUom,
        totalTripDistance,
      );

      const deadhead = G.milesKmsFromToAccordingSystemWithNullable(
        defaultUom,
        deadHeadDistanceUom,
        deadHeadDistance,
      );

      const totalDistance = G.mathRoundNumber(R.add(total, deadhead), 1);
      const text = `${totalDistance} ${defaultUomLocale}`;

      return (
        <TextComponent
          title={text}
          maxWidth={80}
          display='block'
          withEllipsis={true}
          color={G.getTheme('colors.dark.blue')}
        >
          {text}
        </TextComponent>
      );
    },
  },
  {
    type: 'text',
    isRequired: true,
    inputStyles: {
      width: 90,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    label: ['titles:total-weight', 'Total Weight'],
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    inputStyles: {
      width: 90,
      borderRadius: '0px 4px 4px 0px',
    },
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
  },
  {
    type: 'text',
    inputStyles: {
      width: 85,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_VOLUME,
    label: ['titles:total-volume', 'Total Volume'],
  },
  {
    type: 'select',
    inputStyles: {
      width: 85,
      borderRadius: '0px 4px 4px 0px',
    },
    options: ITEM_VOLUME_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_VOLUME_UOM,
  },
];
