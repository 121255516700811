import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { openModal } from '../../../components/modal/actions';
import { makeSelectModalStatus } from '../../../components/modal/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature load-board
import ShipmentDetails from './shipment-details';
import AutodialButtonUI from './autodial-button-ui';
import { toggleAutodial, makeNotificationViewed } from '../actions';
import {
  makeSelectCallAlarm,
  makeSelectNotifications,
  makeSelectAutodialConfigs,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenModal: ({
      openModal,
      notifications,
      makeNotificationViewed,
    }: Object) => (shipmentGuid: string) => {
      const data = R.find(R.propEq(shipmentGuid, GC.FIELD_GUID), notifications);

      if (G.isNotNilAndNotEmpty(data)) makeNotificationViewed(data);

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width: 1090, maxWidth: 'calc(100vw - 60px);' },
        component: (
          <ShipmentDetails
            data={data}
            openedFromCell='notification'
          />
        ),
        options: {
          minWidth: 500,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width: 1090, height: '100vh', maxWidth: 'calc(100vw - 60px);' },
          style: {
            borderLeft: '2px solid white',
            backgroundColor: G.getTheme('colors.bgGrey'),
          },
        },
      };

      openModal(modal);
    },
  }),
  withPropsOnChange(
    ['callAlarm'],
    (props: Object) => {
      const {
        configs,
        callAlarm,
        modalOpened,
        handleOpenModal,
      } = props;

      if (G.isAllTrue(
        configs.autodialOn,
        R.not(modalOpened),
        G.isNotNilAndNotEmpty(callAlarm),
      )) handleOpenModal(callAlarm);
    },
  ),
  pure,
);

const AutodialButton = enhance((props: Object) => (
  <AutodialButtonUI {...props} />
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  callAlarm: makeSelectCallAlarm(state),
  configs: makeSelectAutodialConfigs(state),
  modalOpened: makeSelectModalStatus(state),
  notifications: makeSelectNotifications(state),
});

export default connect(mapStateToProps, {
  openModal,
  toggleAutodial,
  makeNotificationViewed,
})(AutodialButton);
