import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
// feature new-do
import SaveLocationTmpl from '../../new-do/forms/save-location-tmpl';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const FormHeader = (props: Object) => (
  <Flex
    p='7px 10px'
    justifyContent='space-between'
    color={G.getTheme('colors.black')}
    bg={G.getTheme('colors.light.teaGreen')}
  >
    <TextComponent
      display='block'
      whiteSpace='normal'
      wordBreak='break-word'
      title={props.children}
    >
      {props.children}
    </TextComponent>
    <Box ml='auto'>
      <SaveLocationTmpl
        bg={whiteColor}
        color={blueColor}
        location={props.values}
        border={`1px solid ${blueColor}`}
        currentBranch={props.currentBranch}
        setFieldValue={props.setFieldValue}
        saveBtnText={props.saveLocationBtnText}
        initialValue={R.path(['values', 'externalId'], props)}
      />
    </Box>
    <Flex>
      {
        props.options &&
        props.options.map((item: Object, index: number) => (
          <Box key={index} mx='7px' title={item.title} onClick={item.action} cursor='pointer'>
            {item.Icon(blueColor)}
          </Box>
        ))
      }
    </Flex>
  </Flex>
);

export default FormHeader;
