import * as R from 'ramda';
// constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultShipmentValues = {
  [GC.FIELD_LOAD_BOARD_NOTE]: null,
  [GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE]: null,
  [GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO]: null,
  [GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY]: null,
};

const commomShipmentProps = {
  width: 410,
  fontSize: 12,
  labelPl: '0px',
  labelWidth: '100%',
  jc: 'space-between',
  flexDirection: 'column',
  inputWrapMargin: '4px 0',
};

export const shipmentFields = [
  {
    ...commomShipmentProps,
    type: 'text',
    loc: 'titles:person-talked-to',
    fieldName: GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO,
  },
  {
    ...commomShipmentProps,
    type: 'multi',
    loc: 'titles:negotiated-price',
    fields: [
      {
        width: 200,
        type: 'text',
        fieldName: GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE,
      },
      {
        pr: 25,
        width: 200,
        afterTop: 13,
        type: 'select',
        afterRight: 13,
        isWrapped: true,
        options: GC.CURRENCY_OPTIONS,
        fieldName: GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY,
      },
    ],
  },
  {
    ...commomShipmentProps,
    type: 'textarea',
    loc: 'titles:note',
    fieldName: GC.FIELD_LOAD_BOARD_NOTE,
  },
];

const uomsMap = {
  [GC.UOM_POUND]: 'lb',
  [GC.UOM_TON_US]: 'tn',
  [GC.UOM_KILOGRAM]: 'kg',
  [GC.UOM_METRIC_TON]: 'mt',
  [GC.UOM_POUND_100]: 'lb100',
  [GC.UOM_KILOGRAM_100]: 'kg100',
  [GC.UOM_MILE]: GC.UOM_MILE_LABEL,
  [GC.UOM_FOOT]: GC.UOM_FOOT_LABEL,
  [GC.UOM_INCH]: GC.UOM_INCH_LABEL,
  [GC.UOM_METER]: GC.UOM_METER_LABEL,
  [GC.UOM_KILOMETER]: GC.UOM_KILOMETER_LABEL,
  [GC.UOM_CENTIMETER]: GC.UOM_CENTIMETER_LABEL,
};

export const getUOMs = (uom: string) => R.propOr('', uom, uomsMap);
