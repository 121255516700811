import * as R from 'ramda';
// components
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// constants
import * as GC from '../../../constants';
// features load-board
import { searchResultsFilterParams } from './field-settings';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = 200;

export const resultsTableSettings = {
  fontSize: 11,
  maxHeight: '100%',
  titleRowHeight: 42,
  checkBoxCellWidth: 0,
  withRightActions: true,
  allowSelectItems: false,
  tableRowHeight: rowHeight,
  fixLocalLoaderHeight: true,
  withResizableColumns: true,
  filterProps: R.indexBy(
    R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(searchResultsFilterParams),
  ),
};

export const bookmarksTableSettings = {
  fontSize: 11,
  maxHeight: '100%',
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
  fixLocalLoaderHeight: true,
  withResizableColumns: false,
};
