import * as R from 'ramda';
import { put, all, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { getConfigByGroupSaga } from '../../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { getAllAvailableRefTypesByScopeRequestSaga } from '../../reference/sagas';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature invoice
import { COMPLETED } from '../constants';
import { getInvoiceConfigByGroupSaga } from '../sagas';
// feature invoice/driver
import * as A from './actions';
import * as S from './selectors';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.includes('Default', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const reqBody = {
      guids,
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      fields: R.concat(
        G.getOrElse(reportParams, 'fields', []),
        [
          { name: 'telGuid', freezed: false, sequence: 100, reference: false },
          { name: COMPLETED, freezed: false, sequence: 101, reference: false },
        ],
      ),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverPayrollInvoiceList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());

    const reqBody = {
      fields: payload.fields,
      orderFields: payload.orderFields,
      searchCriteria: payload.searchCriteria,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const data = G.setSearchCriteria({ reqBody, filterParams });

    const params = { format: payload.fileType };

    const options = {
      data,
      params,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telInvoiceFleetExportReport, options);

    if (G.isResponseSuccess(res.status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handlePrintSaga({ payload }: Object) {
  try {
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        templateGuid: payload.invoiceTemplates,
        documentsGuids: R.or(payload.documents, []),
      },
    };

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.telInvoiceExport,
      options,
    );

    if (G.isResponseSuccess(res.status)) {
      const reqOptions = {
        params: {
          fileName: res.data.documentFilename,
          primaryObjectGuid: res.data.primaryObjectGuid,
        },
        resType: 'arraybuffer',
      };

      const fileRes = yield call(
        sendRequest,
        'get',
        endpointsMap.telDocumentDownloadFile,
        reqOptions,
      );

      if (G.isResponseSuccess(fileRes.status)) {
        const file = new window.Blob([fileRes.data], { type: 'application/pdf' });

        G.openFile(file);
      } else {
        yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      }
    } else {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintDISaga exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.telFleetInvoice, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteDriverInvoicesSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteDriverInvoicesSaga exception');
  }
}

function* handleUpdateDriverInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { invoice } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.telFleetInvoice, { data: invoice });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      yield call(handleGetItemListSaga, { payload: {
        openLoader: true,
        guids: R.of(Array, G.getGuidFromObject(data)),
      } });

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverInvoiceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateDriverInvoiceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverPayrollInvoiceReference,
      { data: payload },
    );

    if (G.isResponseSuccess(res.status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
      yield put(A.createReferenceSuccess(R.head(res.data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReferenceSaga exception');
  }
}


function* handleSendToFinancialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        guids: payload,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.driverPayrollFinancialExport, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      }

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleSendToFinancialSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendToFinancialSaga exception');
  }
}

function* handleChangeInvoicesSaga({ payload }: Object) {
  try {
    const { guids } = payload;

    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_CURRENT_BRANCH, currentBranchGuid, payload),
    };

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.driverPayrollInvoiceMassUpdate,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { errors, updatedInvoices } = data;

      if (G.isNotNilAndNotEmpty(errors)) {
        yield all(R.map((item: string) => call(G.showToastrMessage, 'error', item), errors));
      }

      if (G.isNotNilAndNotEmpty(updatedInvoices)) yield put(A.changeInvoicesSuccess({ guids, data: updatedInvoices }));
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeInvoicesSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeInvoicesSaga exception');
  }
}

function* handleGetConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      group: GC.INVOICE_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };

    const res = yield call(getInvoiceConfigByGroupSaga, options);

    if (G.isResponseSuccess(res.status)) {
      yield put(A.getConfigsSuccess(G.mapConfigValuesByName(res.data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetConfigsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetConfigsSaga exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handlePrintByReportSaga,
  handleGetXMLByReportSaga,
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.DRIVER_PAYROLL_INVOICE_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);

function* handleVisitDriverInvoicesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport(['additionalFilters']));
    } else {
      yield put(A.setInitialState());
    }

    yield call(getConfigByGroupSaga, GC.INVOICE_CONFIG_GROUP);

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(A.getConfigsRequest(branchGuid));
    yield put(A.setIgnorePromptStatus(false));

    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_FLEET_INVOICE });
    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_TEL });

    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

export function* driverInvoicesWatcherSaga() {
  yield takeLatest(A.printRequest, handlePrintSaga);
  yield takeLatest(A.getConfigsRequest, handleGetConfigsSaga);
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.printByReportRequest, handlePrintByReportSaga);
  yield takeLatest(A.getXMLByReportRequest, handleGetXMLByReportSaga);
  yield takeLatest(A.changeInvoicesRequest, handleChangeInvoicesSaga);
  yield takeLatest(A.sendToFinancialRequest, handleSendToFinancialSaga);
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.updateDriverInvoiceRequest, handleUpdateDriverInvoiceSaga);
  yield takeLatest(GC.VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE, handleVisitDriverInvoicesPageSaga);
}

export default driverInvoicesWatcherSaga;
