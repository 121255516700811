import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components';
import { TableInner, TableWrapper } from '../../../components/table/ui';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature master-invoice
import * as H from '../helpers';
import GroupTableRow from './group-table-row';
//////////////////////////////////////////////////

const enhance = compose(
  withState('masterInvoice', 'updateMasterInvoice', ({ data, invoices }: Object) => R.mergeRight(
    data,
    {
      selected: true,
      expanded: true,
      invoices: R.indexBy(
        R.prop('guid'),
        R.map(
          (invoice: Object) => R.assoc(
            'selected',
            G.isNotNil(R.prop('masterInvoiceGuid', invoice)),
            invoice,
          ),
          invoices,
        ),
      ),
    },
  )),
  withHandlers({
    handleSetDates: (props: Object) => (dates: Object) => {
      props.setFilterDates(dates);
      const options = {
        data: R.assoc(
          GC.FIELD_BRANCH_GUID,
          props.branchGuid,
          dates,
        ),
      };
      props.setLoading();
      props.getFilteredDataRequest(options);
    },
    handleClickInvoiceCheckbox: (props: Object) => (data: Object) => props.updateMasterInvoice(data),
    handleSave: (props: Object) => () => (
      props.updateMasterInvoiceInvoices(H.getUpdateMasterInvoiceRequestData(props.masterInvoice))
    ),
    handleAddDocument: ({ addDocument, handleGetData }: Object) => (invoice: Object) => (
      addDocument(invoice, handleGetData)
    ),
  }),
  pure,
);

const GroupInvoicesTable = (props: Object) => (
  <Box width='100%'>
    <TableWrapper>
      <TableInner width='100%'>
        <GroupTableRow
          editMode={true}
          data={props.masterInvoice}
          branchGuid={props.branchGuid}
          closeModal={props.closeModal}
          documentTypes={props.documentTypes}
          addDocument={props.handleAddDocument}
          openFixedPopup={props.openFixedPopup}
          closeFixedPopup={props.closeFixedPopup}
          handleEditInvoice={props.handleEditInvoice}
          onInvoiceCheckboxClick={props.handleClickInvoiceCheckbox}
        />
      </TableInner>
    </TableWrapper>
    <FormFooter2
      boxStyles={{ p: 15 }}
      submitAction={props.handleSave}
      submitBtnText={G.getWindowLocale('actions:save', 'Save')}
    />
  </Box>
);

export default enhance(GroupInvoicesTable);
