import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 350,
    name: 'titles:name',
  },
  [GC.FIELD_TEL_COUNT]: {
    width: 150,
    name: 'titles:tel-count',
    customComponent: ({ data: { tels } }: Object) => R.length(R.or(tels, [])),
  },
  [GC.FIELD_EVENT_COUNT]: {
    width: 150,
    name: 'titles:event-count',
    customComponent: ({ data: { tels } }: Object) => R.compose(
      R.length,
      R.flatten,
      R.map(R.prop(GC.FIELD_LOAD_STOPS)),
    )(R.or(tels, [])),
  },
  [GC.BRANCH_GUID]: {
    width: 200,
    name: 'titles:branch',
    customComponent: ({ data }: Object) => {
      const { enterpriseGuid } = data;

      const text = R.pathOr('', [GC.BRANCH_NAME], G.getBranchFromWindowByGuid(enterpriseGuid));

      return (
        <TextComponent title={text} maxWidth='90%' display='block' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
};

export const report = {
  fields: [
    { sequence: 0, name: GC.FIELD_NAME },
    { sequence: 1, name: GC.FIELD_TEL_COUNT },
    { sequence: 2, name: GC.FIELD_EVENT_COUNT },
    { sequence: 3, name: GC.BRANCH_GUID },
    { sequence: 4, name: GC.FIELD_CREATED_DATE },
    { sequence: 5, name: GC.FIELD_CREATED_BY },
  ],
};
