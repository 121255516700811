import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const qbIIFImportRequest = createAction('qbIIFImportRequest');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const updateReportRequest = createAction('updateReportRequest');
export const createReportRequest = createAction('createReportRequest');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const printByReportRequest = createAction('printByReportRequest');
export const getXMLByReportRequest = createAction('getXMLByReportRequest');
export const changeInvoicesRequest = createAction('changeInvoicesRequest');
export const changeInvoicesSuccess = createAction('changeInvoicesSuccess');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const updateReconciliationRequest = createAction('updateReconciliationRequest');
export const updateCIReconciliationSuccess = createAction('updateCIReconciliationSuccess');
export const getServiceVendorInvoiceDetails = createAction('getServiceVendorInvoiceDetails');
export const collapseAndResetCarrierInvoices = createAction('collapseAndResetCarrierInvoices');
export const printServiceVendorInvoiceRequest = createAction('printServiceVendorInvoiceRequest');
export const toggleServiceVendorInvoiceDetails = createAction('toggleServiceVendorInvoiceDetails');
export const deleteServiceVendorInvoicesRequest = createAction('deleteServiceVendorInvoicesRequest');
export const getServiceVendorInvoiceDetailsError = createAction('getServiceVendorInvoiceDetailsError');
export const getServiceVendorInvoiceDetailsSuccess = createAction('getServiceVendorInvoiceDetailsSuccess');
export const setServiceVendorInvoiceDetailsLoading = createAction('setServiceVendorInvoiceDetailsLoading');
export const getServiceVendorInvoiceStatusesSuccess = createAction('getServiceVendorInvoiceStatusesSuccess');
export const approveOrRejectServiceVendorInvoiceRequest = createAction('approveOrRejectServiceVendorInvoiceRequest');
export const approveOrRejectServiceVendorInvoiceSuccess = createAction('approveOrRejectServiceVendorInvoiceSuccess');
export const exportServiceVendorInvoiceToQuickBooksRequest =
  createAction('exportServiceVendorInvoiceToQuickBooksRequest');
