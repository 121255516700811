import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMedia, useScroll, useWindowSize } from 'react-use';
import React, { useRef, Fragment, useState, useEffect, useCallback } from 'react';
import { pure, compose, withState, withProps, withHandlers } from 'react-recompose';
// components
import { Tabs2, withTabs2, ActionBox, FormFooter2 } from '../../../components';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
// forms
import { Fieldset2, PureStyledForm } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Text, Flex, RelativeFlex, SectionsDivider, scrollableContainerCss3px } from '../../../ui';
// hocs
import {
  withAsyncConfigs,
  withFixedPopover,
  withAsyncAccessorials,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
} from '../../../hocs';
// feature master-invoice
import ActiveTab from './active-tab';
import { HeaderActions } from './actions';
import MasterInvoiceTotals from './master-invoice-totals';
import { makeSelectRecallDocumentsGuid } from '../selectors';
import MasterInvoiceDocuments from './master-invoice-documents';
import {
  validationSchemaMasterInvoiceObject,
  validationSchemaMasterInvoiceObjectNumber,
} from '../validators';
import {
  tabsOptions,
  chargeInitFields,
  masterInvoiceInitFields,
  masterInvoiceFieldsetSettings,
} from '../settings/master-invoice-with-charges-settings-new';
//////////////////////////////////////////////////

const chargesArrayName = GC.FIELD_MASTER_INVOICE_CHARGES;

const COLLAPSED = 'COLLAPSED';
const FULL_SCREEN = 'FULL_SCREEN';

const whiteColor = G.getTheme('colors.white');
const lightGreyColor = G.getTheme('colors.light.lightGrey');
const darkMainDarkColor = G.getTheme('colors.dark.mainDark');

const getCharges = R.compose(
  R.map((item: Object) => R.assoc(
    GC.FIELD_GL_CODE,
    R.path([GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID], item),
    item,
  )),
  R.pathOr([], ['data', GC.FIELD_CHARGES]),
);

export const setDefaultValues = ({ asyncInitialData }: Object) => R.mergeAll([
  masterInvoiceInitFields,
  R.pathOr({}, ['data'], asyncInitialData),
  {
    [GC.FIELD_CHARGES]: getCharges(asyncInitialData),
    [GC.FIELD_GL_CODE]: R.path(['data', GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID], asyncInitialData),
    [GC.FIELD_MASTER_INVOICE_STATUS]: R.path(['data', GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], asyncInitialData),
  },
]);

const validationSchemaObject = Yup.object()
  .shape(validationSchemaMasterInvoiceObjectNumber)
  .shape(validationSchemaMasterInvoiceObject);

export const setOptionsForSelect = (props: Object) => ({
  [GC.FIELD_MASTER_INVOICE_STATUS]: G.mapCustomConfigOptionsFromProps('CIStatusConfig', props, true),
  [GC.FIELD_GL_CODE]: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    G.getPropFromObject('asyncConfigs', props),
    GC.INVOICE_GL_CODE,
    true,
  ),
});

const ViewButton = (props: Object) => {
  const { id, view, title, setView } = props;

  const handleSetView = useCallback(() => {
    setView(view);

    const saveSettingsToDB = async () => {
      await G.saveIndexedDBItem(GC.AmousUIIdbProps, { id, value: view });
    };

    saveSettingsToDB();
  }, [view]);

  return (
    <ActionBox text={title} action={handleSetView} />
  );
};

export const MasterInvoiceWithChargesNew = (props: Object) => {
  const {
    values,
    activeTab,
    authorities,
    handleSubmit,
    masterInvoiceGuid,
    billToLocationName,
    handleSetActiveTab,
    mailIntegrationType,
  } = props;

  const recallDocumentsGuid = useSelector(makeSelectRecallDocumentsGuid());

  const [chargesView, setChargesView] = useState(COLLAPSED);

  const scrollRef = useRef(null);

  const { width } = useWindowSize();

  const { x } = useScroll(scrollRef);

  const isLarge = useMedia('(min-width: 1570px)');

  const widthToUse = R.add(R.subtract(width, 3), x);

  useEffect(() => {
    const fetchSettings = async () => {
      const setting = await G.getIndexedDBItem(GC.AmousUIIdbProps, GC.masterInvoiceEditMasterInvoiceChargesViewIdbId);

      if (setting) {
        const { value } = setting;

        setChargesView(value);
      }
    };

    fetchSettings();
  }, []);

  const collapsedView = R.equals(chargesView, COLLAPSED);

  const masterInvoiceNumber = R.prop(GC.FIELD_MASTER_INVOICE_NUMBER, values);

  const optionsForSelect = setOptionsForSelect(props);

  const glDisabled = G.notContain(PC.GL_CODE_WRITE, authorities);

  const statusText = R.compose(
    R.path([G.getPropFromObject(GC.FIELD_STATUS, values), 'label']),
    G.indexByValue,
    R.pathOr([], [GC.FIELD_STATUS]),
  )(optionsForSelect);

  return (
    <Fragment>
      <Flex px={15} height={60} justifyContent='space-between'>
        <Flex>
          {masterInvoiceNumber && (
            <Text
              mr={15}
              fontSize={16}
              fontWeight='bold'
              color={darkMainDarkColor}
            >
              {`${G.getWindowLocale('titles:master-invoice-number', 'Master Invoice #')}: ${masterInvoiceNumber}`}
            </Text>
          )}
          {
            G.isNotNilAndNotEmpty(billToLocationName) &&
            <Text
              mr={15}
              fontSize={14}
              fontWeight='bold'
              color={darkMainDarkColor}
            >
              {`${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${billToLocationName}`}
            </Text>
          }
          {
            G.isNotNilAndNotEmpty(statusText) &&
            <Box
              mr={15}
              p='5px'
              borderRadius='3px'
              color={whiteColor}
              bg={G.getTheme('colors.mustardYellow')}
            >
              {statusText}
            </Box>
          }
        </Flex>
        <HeaderActions {...props} />
      </Flex>
      <SectionsDivider />
      <PureStyledForm onSubmit={handleSubmit}>
        <Box
          overflow='auto'
          ref={scrollRef}
          height='calc(100vh - 120px)'
          css={scrollableContainerCss3px}
          borderBottom={`1px solid ${lightGreyColor}`}
        >
          {
            collapsedView && (
              <RelativeFlex
                p={15}
                alignItems='flex-start'
                bg={G.getTheme('colors.#EDEFF1')}
                width={isLarge ? widthToUse : 'max-content'}
              >
                <Box
                  p={15}
                  pt={25}
                  mr={15}
                  width={450}
                  flexShrink={0}
                  bg={whiteColor}
                  borderRadius='6px'
                >
                  <Fieldset2
                    {...G.getFormikProps(props)}
                    {...optionsForSelect}
                    grouped={true}
                    fields={masterInvoiceFieldsetSettings}
                  />
                </Box>
                <MasterInvoiceTotals {...props} />
                <MasterInvoiceDocuments
                  masterInvoiceGuid={masterInvoiceGuid}
                  recallDocumentsGuid={recallDocumentsGuid}
                  mailIntegrationType={mailIntegrationType}
                />
              </RelativeFlex>
            )
          }
          {
            <Flex pr={15} width={widthToUse} justifyContent='space-between'>
              <Tabs2
                tabs={tabsOptions}
                activeTab={activeTab}
                setActiveTab={handleSetActiveTab}
                tabStyles={GC.COMMON_MUI_TAB_STYLES}
                tabsStyles={GC.COMMON_MUI_TABS_STYLES}
              />
              {
                collapsedView &&
                <ViewButton
                  view={FULL_SCREEN}
                  setView={setChargesView}
                  title={G.getWindowLocale('actions:expand', 'Expand')}
                  id={GC.masterInvoiceEditMasterInvoiceChargesViewIdbId}
                />
              }
              {
                R.not(collapsedView) &&
                <ViewButton
                  view={COLLAPSED}
                  setView={setChargesView}
                  id={GC.masterInvoiceEditMasterInvoiceChargesViewIdbId}
                  title={G.getWindowLocale('actions:collapse', 'Collapse')}
                />
              }
            </Flex>
          }
          <ActiveTab
            {...props}
            glDisabled={glDisabled}
            widthToUse={widthToUse}
            collapsedView={collapsedView}
          />
        </Box>
        <FormFooter2
          submitStyles={{ width: 'auto' }}
          boxStyles={{ p: 15, bottom: 0 }}
        />
      </PureStyledForm>
    </Fragment>
  );
};

const mapChargesWithSequenceNum = (masterInvoice: Object) => R.assoc(
  chargesArrayName,
  G.mapIndexed((charge: Object, index: number) => R.assoc(
    GC.FIELD_CHARGE_SEQUENCE_NUM,
    R.inc(index),
    charge,
  ), R.or(G.getPropFromObject(chargesArrayName, masterInvoice), [])),
  masterInvoice,
);

const configsNamesArray = [GC.INVOICE_GL_CODE];

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withAsyncInitialDataOnDidMount,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendMasterInvoice } = props;

      const data = R.compose(
        mapChargesWithSequenceNum,
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
      )(values);

      handleSendMasterInvoice(data);
    },
    displayName: 'MasterInvoiceForm',
  }),
  withState('showCharges', 'setShowCharges', true),
  withHandlers({
    handleAddAdditionalCharges: (props: Object) => (push: void) => {
      const {
        showCharges,
        setShowCharges,
      } = props;

      if (G.isFalse(showCharges)) {
        setShowCharges(true);
      }

      push(chargeInitFields);
    },
  }),
  withProps(() => ({ configsNamesArray })),
  withFixedPopover,
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncInvoiceStatusConfigs,
  withTabs2,
  pure,
);

export default enhance(MasterInvoiceWithChargesNew);
