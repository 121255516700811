import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// features
// feature new-do
import { TAB_NAME_REFERENCE } from '../new-do/constants';
import { withSearchLocationSetValues } from '../new-do/hocs';
import { emptyStopFields } from '../new-do/settings/fields-settings';
import { isDropFormValid, isPickupFormValid } from '../new-do/validation';
import { isPageLoadStopsEdit, checkSingleToSingleItemsAndDrop } from '../new-do/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncSearchTemplateAutocomplete } from '../../hocs';
//////////////////////////////////////////////////

export const enhanceStopForm = compose(
  withAsyncSearchTemplateAutocomplete({
    useCurrentBranch: true,
    shouldGetLocationTemplateSearchRequestProps: true,
  }),
  withSearchLocationSetValues,
  withHandlers({
    handleCleanForm: (props: Object) => () => {
      const { fromPage, setValues, initialValues } = props;

      const valuesToSet = G.ifElse(
        R.includes(fromPage, [
          GC.PAGE_DISPATCH_BOARD_NEW,
          GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
          GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
        ]),
        R.mergeRight(initialValues, emptyStopFields),
        emptyStopFields,
      );

      setValues(valuesToSet);
    },
    handleLeaveForm: (props: Object) => () => {
      const { stops, values, stopOrder, pageType, eventType, setFormDataToStop } = props;

      if (R.not(isPageLoadStopsEdit(pageType))) return;

      const isPickup = G.isEventTypePickup(eventType);
      const validationFunc = G.ifElse(
        isPickup,
        isPickupFormValid,
        isDropFormValid,
      );

      checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });

      const isValid = validationFunc(values);

      setFormDataToStop({ isValid, stopOrder, formData: values });
    },
    onClickTabCallback: (props: Object) => (activeName: Object, shouldSave: boolean = false) => {
      const { stops, values, stopOrder, eventType, setActiveLeftTab, setFormDataToStop } = props;

      const isPickup = G.isEventTypePickup(eventType);

      const validationFunc = G.ifElse(
        isPickup,
        isPickupFormValid,
        isDropFormValid,
      );

      checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });

      const isValid = validationFunc(values);

      setFormDataToStop({ isValid, stopOrder, shouldSave, formData: values });

      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { stops, values, eventType, stopOrder, setActiveLeftTab, setFormDataToStop } = props;

      const isPickup = G.isEventTypePickup(eventType);

      const validationFunc = G.ifElse(
        isPickup,
        isPickupFormValid,
        isDropFormValid,
      );

      checkSingleToSingleItemsAndDrop({ stops, values, isPickup, setFormDataToStop });

      const isValid = validationFunc(values);

      setFormDataToStop({ isValid, stopOrder, formData: values });

      if (R.equals(1, stopOrder)) {
        return setActiveLeftTab(TAB_NAME_REFERENCE);
      }

      setActiveLeftTab(R.dec(stopOrder));
    },
    handleChangeApptTime: ({ values, initialValues, setFieldValue }: Object) => (data: Object) => {
      if (R.equals(values, initialValues)) return;

      const { value, fieldName } = data;

      const appointmentDate = R.path([GC.FIELD_LOAD_APPOINTMENT_DATE], values);

      if (G.isAllTrue(
        G.isNotNil(appointmentDate),
        R.equals(fieldName, GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME),
      )) {
        const eventEarlyDate = `${appointmentDate} ${value}`;
        const notEqualValues = R.not(R.and(
          R.propEq(value, GC.FIELD_LOAD_EVENT_EARLY_TIME, values),
          R.propEq(eventEarlyDate, GC.FIELD_LOAD_EVENT_EARLY_DATE, values),
        ));

        if (R.and(notEqualValues, G.isValidMoment(eventEarlyDate))) {
          setFieldValue(GC.FIELD_LOAD_EVENT_EARLY_DATE, eventEarlyDate);
          setFieldValue(GC.FIELD_LOAD_EVENT_EARLY_TIME, value);
        }
      }

      if (G.isAllTrue(
        G.isNotNil(appointmentDate),
        R.equals(fieldName, GC.FIELD_LOAD_APPOINTMENT_LATE_TIME),
      )) {
        const eventLateDate = `${appointmentDate} ${value}`;
        const notEqualValues = R.not(R.and(
          R.propEq(value, GC.FIELD_LOAD_EVENT_LATE_TIME, values),
          R.propEq(eventLateDate, GC.FIELD_LOAD_EVENT_LATE_DATE, values),
        ));

        if (R.and(notEqualValues, G.isValidMoment(eventLateDate))) {
          setFieldValue(GC.FIELD_LOAD_EVENT_LATE_DATE, eventLateDate);
          setFieldValue(GC.FIELD_LOAD_EVENT_LATE_TIME, value);
        }
      }
    },
  }),
  pure,
);
