import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultLocationFields = {
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_CROSSDOCK]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_ADDRESS_2]: '',
  [GC.FIELD_LONGITUDE]: '',
  [GC.FIELD_TEMPLATE_ID]: '',
  [GC.FIELD_LOCATION_TYPE]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  [GC.FIELD_INTEGRATION_ID]: '',
  [GC.FIELD_INTEGRATION_TYPE]: '',
};

const yupStringRequiredWithMaxValue = (max: number) =>
  G.yupStringRequired.max(max, G.getShouldBeFromToLocaleTxt(1, max));

const contactFieldsValidationSchema = {
  [GC.FIELD_CONTACT_NAME]: yupStringRequiredWithMaxValue(50),
  [GC.FIELD_PHONE]: G.yupStringNotRequired
    .matches(GC.PHONE_NUMBER_REGEXP, G.getShouldBePhoneLocaleTxt()),
  [GC.FIELD_PHONE_EXTENSION]: G.yupStringNotRequired
    .max(5, G.getShouldBeFromToCharLocaleTxt(0, 5)),
  [GC.FIELD_EMAIL]: G.yupStringNotRequired
    .email(G.getShouldBeEmailLocaleTxt()),
  [GC.FIELD_FAX]: G.yupStringNotRequired
    .max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
};

export const validationSchemaContactsArray = {
  [GC.FIELD_CONTACTS]: G.yupArrayNotRequired.of(Yup.object(contactFieldsValidationSchema)),
};

export const locationFieldsValidationSchema = Yup.lazy(({ integrationType }: Object) => Yup.object()
  .shape(validationSchemaContactsArray)
  .shape({
    [GC.FIELD_CITY]: G.yupStringRequired,
    [GC.FIELD_STATE]: G.yupStringRequired,
    [GC.FIELD_COUNTRY]: G.yupStringRequired,
    [GC.FIELD_LATITUDE]: G.yupStringRequired,
    [GC.FIELD_LONGITUDE]: G.yupStringRequired,
    [GC.FIELD_ADDRESS_1]: G.yupStringRequired,
    [GC.FIELD_LOCATION_TYPE]: G.yupStringRequired,
    [GC.FIELD_ZIP]: yupStringRequiredWithMaxValue(40),
    [GC.FIELD_TEMPLATE_ID]: yupStringRequiredWithMaxValue(100),
    [GC.FIELD_LOCATION_NAME]: yupStringRequiredWithMaxValue(100),
    [GC.FIELD_COMMENTS]: G.yupStringNotRequired
      .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
    [GC.FIELD_INTEGRATION_ID]: G.ifElse(
      G.isNilOrEmpty(integrationType),
      G.yupStringNotRequired,
      yupStringRequiredWithMaxValue(100),
    ),
  }),
);

const inputWrapperStyles = {
  mb: 25,
  width: 200,
};

export const getLocationFieldSettings = (integrationType: string) => [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'locationTypes',
    label: ['titles:type', 'Type'],
    fieldName: GC.FIELD_LOCATION_TYPE,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:template-id', 'Template ID'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    fieldName: GC.FIELD_CROSSDOCK,
    label: ['titles:crossdock', 'Crossdock'],
  },
  {
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address1', 'Address1'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ADDRESS_2,
    label: ['titles:address2', 'Address2'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    isRequired: true,
    fieldName: GC.FIELD_ZIP,
    type: 'addressAutocomplete',
    label: ['titles:zip', 'Zip'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 11 },
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
  },
  {
    type: 'select',
    inputWrapperStyles: { width: 200 },
    fieldName: GC.FIELD_INTEGRATION_TYPE,
    label: ['titles:integration-type', 'Integration Type'],
    options: [
      GC.EMPTY_OPTION_OBJECT,
      { label: 'VeraCore', value: GC.TERMINAL_INTEGRATION_TYPE_VERACORE },
    ],
  },
  {
    type: 'text',
    inputWrapperStyles: { width: 200 },
    fieldName: GC.FIELD_INTEGRATION_ID,
    isRequired: G.isNotNilAndNotEmpty(integrationType),
    label: ['titles:integration-id', 'Integration ID'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    inputWrapperStyles: { width: 425 },
    label: ['titles:comments', 'Comments'],
  },
];

export const getLocationHeaderFields = ({ active, isEditTemplateMode }: Object) => {
  const fields = [
    {
      type: 'toggle',
      fieldName: GC.FIELD_RESIDENTIAL,
      label: ['titles:residential', 'Residential'],
    },
  ];

  const activeField = {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: GC.FIELD_ACTIVE,
    label: G.ifElse(active, ['titles:active', 'Active'], ['titles:inactive', 'Inactive']),
  };

  if (isEditTemplateMode) return R.prepend(activeField, fields);

  return fields;
};

const contactInputWrapperStyles = {
  mr: 20,
  flexGrow: 1,
  flexBasis: 150,
};

export const contactFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CONTACT_NAME,
    inputWrapperStyles: contactInputWrapperStyles,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: contactInputWrapperStyles,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    inputWrapperStyles: { mr: 20, width: 120 },
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
    inputWrapperStyles: contactInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_FAX,
    inputWrapperStyles: { width: 120 },
    label: ['titles:fax-number', 'Fax Number'],
  },
];

export const defaultContactFields = {
  [GC.FIELD_FAX]: '',
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_CONTACT_NAME]: '',
  [GC.FIELD_PHONE_EXTENSION]: '',
};

export const getNewContactsArrayFields = () => R.assoc(GC.FIELD_ID, G.genShortId(), defaultContactFields);
