import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
// components
import { Map } from '../../../components/map';
import { InfoPair } from '../../../components/info-pair';
import { Directions } from '../../../components/map/components/directions';
import { MarkerWithInfo } from '../../../components/map/components/marker-with-info';
import { StopInfo, StopMarker } from '../../../components/map/components/stop-components';
// feature new-do
import Tabs from '../../new-do/components/tabs';
import RatePreview from '../../new-do/components/rate-preview';
import AdditionalSummary from '../../new-do/components/additional-summary';
import { RightSectionEmptyInfo } from '../../new-do/components/right-section';
import { LiteStopsSummaryWithDnD } from '../../new-do/components/stops-summary-with-dnd';
import {
  getRightSectionWidth,
  renderLeftRightContent,
  renderLeftRightEmptyInfo,
} from '../../new-do/helpers';
import {
  TAB_NAME_MAP,
  TAB_NAME_CLO_SUMMARY,
  TAB_NAME_RATE_CONFIRMATION,
} from '../../new-do/constants';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withRerenderComponent } from '../../../hocs';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const tabSettings = [
  {
    name: TAB_NAME_CLO_SUMMARY,
    text: G.getWindowLocale('titles:clo-summary', 'CLO Summary'),
  },
  {
    name: TAB_NAME_RATE_CONFIRMATION,
    text: G.getWindowLocale('titles:rate-confirmation', 'Rate Confirmation'),
  },
  {
    name: TAB_NAME_MAP,
    text: G.getWindowLocale('titles:map', 'Map'),
  },
];

const getLocationsWithContent = (locations: Array) => (
  locations.map((oldLocation: Object) => {
    const location = R.clone(oldLocation);
    const eventEarlyDate = R.path(['stop', 'formData', GC.FIELD_LOAD_EVENT_EARLY_DATE], location);
    location.infoContent = (
      <StopInfo {...location} eventEarlyDate={eventEarlyDate} />
    );
    location.markerContent = <StopMarker {...location} />;

    return location;
  })
);

const enhanceMap = compose(
  withRerenderComponent(['mapLocations']),
);

export const MapLocations = enhanceMap((props: Object) => {
  const { mapLocations, showComponent } = props;
  const center = R.path(['latLng'], R.head(mapLocations));

  return (
    <Box>
      <Map
        center={center}
        trafficLayer={true}
        height='calc(100vh - 200px)'
      >
        {
          showComponent &&
          <MarkerWithInfo locations={G.makeLocationsWithTransform(getLocationsWithContent(mapLocations))} />
        }
        {
          showComponent &&
          <Directions
            hideDirectionsApiError={true}
            locations={mapLocations.map((location: Object) => location.latLng)}
          />
        }
      </Map>
    </Box>
  );
});

const integrationCarrierStyles = {
  fontSize: 14,
  margin: '0 15px',
  textMaxWidth: 500,
};

const renderTabContent = (props: Object) => {
  const { rightActiveTad } = props;

  if (R.equals(rightActiveTad, TAB_NAME_CLO_SUMMARY)) {
    return (
      <Box>
        <LiteStopsSummaryWithDnD {...props} />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
          text={R.path(['integrationCarriers', GC.FIELD_TRANSPORTING_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
          text={R.path(['integrationCarriers', GC.FIELD_ORIGIN_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          text={R.path(['integrationCarriers', GC.FIELD_DESTINATION_CARRIER_NAME], props)}
          label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
        />
        <AdditionalSummary {...props} />
      </Box>
    );
  }

  if (R.equals(rightActiveTad, TAB_NAME_RATE_CONFIRMATION)) {
    return <RatePreview {...props} />;
  }

  if (R.equals(rightActiveTad, TAB_NAME_MAP)) {
    return <MapLocations {...props} />;
  }

  return null;
};

const RightSection = (props: Object) => {
  const { leftActiveTad, rightActiveTad, onClickTabCallback } = props;

  return (
    <Box
      mb={20}
      flexShrink={0}
      minWidth={662}
      maxWidth={1200}
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
      width={getRightSectionWidth(leftActiveTad, rightActiveTad)}
    >
      {
        renderLeftRightContent(props) &&
        <Tabs
          overflow='auto'
          options={tabSettings}
          activeFromParent={rightActiveTad}
          onClickTabCallback={onClickTabCallback}
          defaultActiveName={TAB_NAME_CLO_SUMMARY}
        />
      }
      {
        renderLeftRightEmptyInfo(props) && <RightSectionEmptyInfo />
      }
      {renderLeftRightContent(props) && renderTabContent(props)}
    </Box>
  );
};

const enhance = compose(
  withHandlers({
    onClickTabCallback: ({ setActiveRightTab }: Object) => (activeName: Object) => {
      setActiveRightTab(activeName);
    },
  }),
);

export default enhance(RightSection);
