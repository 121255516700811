import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// constants/helpers
import * as G from '../../helpers';
import * as GC from '../../constants';
// features
import {
  socketLBStateReceived,
  socketLBResultReceived,
  socketFilterStateReceived,
  socketBookedShipmentStateReceived,
} from '../sockets-v2/actions';
// feature load-board
import * as A from './actions';
import * as H from './helpers';
import { FILTER_COLORS } from './constants';
// import lbShipments from './settings/load-boards-results-mock';
import { searchResultsReport } from './settings/field-settings';
//////////////////////////////////////////////////

export const initialState = {
  templates: [],
  shipments: [], // lbShipments, NOTE: for testing
  loading: false,
  callAlarm: null,
  searchFilters: {},
  autodialOn: false,
  companyFilters: [],
  savedShipments: [],
  bookedShipments: [],
  titleSortValues: {},
  availableReports: [],
  cancelledShown: false,
  tableTitleFilters: {},
  searchFilterColors: {},
  searchFiltersStates: {},
  autodialActionOn: false,
  authorizedLoadBoards: [],
  requiredMinMaxPrice: false,
  searchFiltersCalled: false,
  filteredBySearchFilter: [],
  initialPageDataLoaded: true,
  searchResultsByFilterId: {},
  usedReport: searchResultsReport,
  availableFilterColors: FILTER_COLORS,
  autodialAppName: GC.UI_LOADBOARD_AUTODIALAPPNAME,
};

const setInitialState = () => initialState;

const getSearchFiltersSuccess = (state: Object, data: Object) => {
  const availableFilterColors = P.$get('availableFilterColors', state);

  const elements = R.propOr([], 'elements', data);
  const count = R.length(elements);
  const colors = R.take(count, availableFilterColors);
  const colorsLeft = R.drop(count, availableFilterColors);

  const filters = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((el: Object, i: number) => R.mergeRight(el, {
      expanded: false,
      color: R.prop(i, colors),
    })),
  )(elements);

  const searchFilterColors = R.map(
    R.prop('color'),
    filters,
  );

  const searchResultsByFilterId = P.$get('searchResultsByFilterId', state);

  return P.$all(
    P.$set('searchFilters', filters),
    P.$set('searchFiltersCalled', true),
    P.$set('availableFilterColors', colorsLeft),
    P.$set('searchFilterColors', searchFilterColors),
    P.$set(
      'searchResultsByFilterId',
      R.map(({ guid }: Object) => R.pathOr([], [guid], searchResultsByFilterId), filters),
    ),
    state,
  );
};

const createSearchFilterSuccess = (state: Object, data: Object) => {
  const availableFilterColors = P.$get('availableFilterColors', state);

  const color = R.head(availableFilterColors);
  const filterGuid = G.getGuidFromObject(data);

  return P.$all(
    P.$set(`searchFilterColors.${filterGuid}`, color),
    P.$set(`searchResultsByFilterId.${filterGuid}`, []),
    P.$set('availableFilterColors', R.tail(availableFilterColors)),
    P.$set(
      `searchFilters.${filterGuid}`,
      R.mergeRight(data, {
        color,
        expanded: false,
      }),
    ),
    state,
  );
};

const updateSearchFilterSuccess = (state: Object, data: Object) => {
  const filterGuid = G.getGuidFromObject(data);
  const color = P.$get(`searchFilterColors.${filterGuid}`, state);
  const shipments = P.$get('shipments', state);

  return P.$all(
    P.$set(`searchResultsByFilterId.${filterGuid}`, []),
    P.$set('shipments', R.filter(
      ({ searchFilterGuid }: Object) => G.notEquals(filterGuid, searchFilterGuid),
      shipments,
    )),
    P.$set(
      `searchFilters.${filterGuid}`,
      R.mergeRight(data, {
        color,
        expanded: false,
      }),
    ),
    state,
  );
};

const deleteSearchFilterSuccess = (state: Object, data: string) => {
  const color = P.$get(`searchFilterColors.${data}`, state);
  const shipments = P.$get('shipments', state);

  return P.$all(
    P.$drop(`searchFilters.${data}`),
    P.$drop(`searchFilterColors.${data}`),
    P.$drop(`searchFiltersStates.${data}`),
    P.$add('availableFilterColors', color),
    P.$drop(`searchResultsByFilterId.${data}`),
    P.$set('shipments', R.filter(
      ({ searchFilterGuid }: Object) => G.notEquals(data, searchFilterGuid),
      shipments,
    )),
    state,
  );
};

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const toggleCancelledShown = (state: Object) => (
  P.$toggle('cancelledShown', state)
);

const makeNotificationViewed = (state: Object, data: Object) => {
  const { guid, searchFilterGuid } = data;

  const searchResultsByFilterId = R.prop(searchFilterGuid, P.$get('searchResultsByFilterId', state));
  const index = R.indexOf(guid, searchResultsByFilterId);

  return (
    P.$all(
      P.$set('callAlarm', null),
      P.$set(
        'shipments',
        R.map(
          (shipment: Object) => {
            if (R.propEq(guid, GC.FIELD_GUID, shipment)) {
              return R.assoc('highlighted', false, shipment);
            }

            return shipment;
          },
          P.$get('shipments', state),
        ),
      ),
      P.$drop(`searchResultsByFilterId.${searchFilterGuid}.${index}`),
      state,
    )
  );
};

const toggleAutodial = (state: Object) => (
  P.$toggle('autodialOn', state)
);

const getCompanyFiltersSuccess = (state: Object, data: Array) => (
  P.$set('companyFilters', data, state)
);

const addCompanyFilterSuccess = (state: Object, data: Object) => {
  const shipments = P.$get('shipments', state);
  const company = R.toLower(R.prop(GC.FIELD_NAME, data));

  const filteredShipments = R.filter(
    R.compose(
      G.notEquals(company),
      R.toLower,
      R.pathOr(
        '',
        [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMPANY, GC.FIELD_LOAD_BOARD_COMPANY],
      ),
    ),
    shipments,
  );

  return (
    P.$all(
      P.$set('shipments', filteredShipments),
      P.$add('companyFilters', data),
      state,
    )
  );
};

const deleteCompanyFilterSuccess = (state: Object, guid: string) => {
  const companyFilters = P.$get('companyFilters', state);
  const filtered = R.filter(G.notPropEq(guid, 'guid'), companyFilters);

  return (
    P.$set('companyFilters', filtered, state)
  );
};

const getSavedShipmentsSuccess = (state: Object, data: Object) => {
  const searchFilters = R.compose(
    R.map(R.assoc('savedShipments', [])),
    R.prop('searchFilters'),
  )(state);

  const savedShipments = R.map((bookmark: Object) => {
    const shipment = H.remapFromCommit(bookmark);
    const searchFilterGuid = R.prop('searchFilterGuid', shipment);
    const filter = R.prop(searchFilterGuid, searchFilters);

    if (G.isNotNilAndNotEmpty(filter)) {
      if (G.isNotNilAndNotEmpty(filter.savedShipments)) {
        filter.savedShipments.push(shipment);
      } else {
        filter.savedShipments = [shipment];
      }
    }

    return shipment;
  }, data);

  return P.$all(
    P.$set(
      'savedShipments',
      savedShipments,
    ),
    P.$set(
      'searchFilters',
      searchFilters,
    ),
    state,
  );
};

const saveShipmentSuccess = (state: Object, data: Object) => {
  const filterGuid = R.prop('searchFilterGuid', data);
  const savedShipmentCount = P.$get(`searchFilters.${filterGuid}.savedShipmentCount`, state);
  const shipment = H.remapFromCommit(R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data));

  const shipments = P.$get('shipments', state);
  const shipmentId = R.prop('shipmentId', shipment);

  const shipmentIndex = R.findIndex(
    R.propEq(shipmentId, GC.FIELD_GUID),
    shipments,
  );

  const savedShipments = R.compose(
    R.append(R.mergeRight(shipment, { saved: true, selected: false })),
    R.pathOr([], ['searchFilters', filterGuid, 'savedShipments']),
  )(state);

  return P.$all(
    P.$add('savedShipments', shipment),
    P.$set(
      `searchFilters.${filterGuid}.savedShipments`,
      savedShipments,
    ),
    P.$set(
      `searchFilters.${filterGuid}.savedShipmentCount`,
      R.inc(savedShipmentCount),
    ),
    P.$drop(`shipments.${shipmentIndex}`),
    state,
  );
};

const updateShipmentSuccess = (state: Object, data: Object) => {
  const searchFilters = P.$get('searchFilters', state);

  const filterGuid = G.getPropFromObject('searchFilterGuid', data);
  const guid = G.getGuidFromObject(data);
  const shipment = H.remapFromCommit(data);

  const savedShipmentIndex = R.findIndex(
    R.compose(R.propEq(guid, GC.FIELD_GUID), R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT)),
    P.$get('savedShipments', state),
  );

  if (R.isNil(R.prop(filterGuid, searchFilters))) {
    return P.$set(
      `savedShipments.${savedShipmentIndex}`,
      shipment,
      state,
    );
  }

  const filterSavedShipmentIndex = R.findIndex(
    R.pathEq(guid, [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_GUID]),
    R.or(P.$get(`searchFilters.${filterGuid}.savedShipments`, state), []),
  );

  return P.$all(
    P.$set(
      `savedShipments.${savedShipmentIndex}`,
      shipment,
    ),
    P.$set(
      `searchFilters.${filterGuid}.savedShipments.${filterSavedShipmentIndex}`,
      shipment,
    ),
    state,
  );
};

const deleteSavedShipmentSuccess = (state: Object, payload: Object) => {
  const searchFilters = P.$get('searchFilters', state);

  const filterGuid = G.getPropFromObject('searchFilterGuid', payload);
  const guid = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_GUID], payload);

  const savedShipments = R.filter(
    R.compose(G.notPropEq(guid, GC.FIELD_GUID), R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT)),
    P.$get('savedShipments', state),
  );

  if (R.isNil(R.prop(filterGuid, searchFilters))) {
    return P.$set('savedShipments', savedShipments, state);
  }

  const filterSavedShipments = R.filter(
    R.compose(G.notPropEq(guid, GC.FIELD_GUID), R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT)),
    R.or(P.$get(`searchFilters.${filterGuid}.savedShipments`, state), []),
  );

  return P.$all(
    P.$set('savedShipments', savedShipments),
    P.$set(
      `searchFilters.${filterGuid}.savedShipments`,
      filterSavedShipments,
    ),
    P.$set(
      `searchFilters.${filterGuid}.savedShipmentCount`,
      R.length(filterSavedShipments),
    ),
    state,
  );
};

const getBookedShipmentsSuccess = (state: Object, data: Object) => {
  const bookedShipments = R.map((shipment: Object) => H.remapFromCommit(shipment), data);

  return P.$set(
    'bookedShipments',
    bookedShipments,
    state,
  );
};

const deleteBookedShipmentSuccess = (state: Object, payload: Object) => {
  const guid = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_BOOKING, GC.FIELD_GUID], payload);

  const bookedShipments = R.filter(
    R.compose(G.notPropEq(guid, GC.FIELD_GUID), R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_BOOKING])),
    P.$get('bookedShipments', state),
  );

  return P.$set('bookedShipments', bookedShipments, state);
};

const bookSavedShipmentSuccess = (state: Object, { shipment, booking }: Object) => {
  const bookedShipments = P.$get('bookedShipments', state);

  const bookedShipment = {
    ...R.omit(GC.FIELD_LOAD_BOARD_SHIPMENT, shipment),
    shipment: {
      ...R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, shipment),
      [GC.FIELD_LOAD_BOARD_BOOKING]: booking,
    },
  };

  bookedShipments.push(bookedShipment);

  return P.$set(
    'bookedShipments',
    bookedShipments,
    state,
  );
};

const bookedShipmentStateReceived = (state: Object, bookedShipment: Object) => {
  const guid = R.path([GC.FIELD_LOAD_BOARD_BOOKING, GC.FIELD_GUID], bookedShipment);
  const convertedShipment = H.remapFromCommit(bookedShipment);
  const bookedShipments = P.$get('bookedShipments', state);

  const shipmentToChange = R.find(
    R.compose(
      R.equals(guid),
      R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_BOOKING, GC.FIELD_GUID]),
    ), bookedShipments);

  const index = R.indexOf(shipmentToChange, bookedShipments);

  if (R.lt(index, 0)) {
    bookedShipments.push(convertedShipment);
  } else {
    bookedShipments[index] = convertedShipment;
  }

  return P.$set(
    'bookedShipments',
    bookedShipments,
    state,
  );
};

const getLoggedLoadBoardsSuccess = (state: Object, data: Object = {}) => P.$set(
  'authorizedLoadBoards',
  R.pathOr([], ['elements'], data),
  state,
);

const loginLoadBoardSuccess = (state: Object, data: Array) => P.$add('authorizedLoadBoards', data, state);

const logoutLoadBoardSuccess = (state: Object, type: string) => {
  const loadBoards = P.$get('authorizedLoadBoards', state);

  const filtered = R.filter(G.notPropEq(type, GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE), loadBoards);

  return P.$set('authorizedLoadBoards', filtered, state);
};

const getTemplatesSuccess = (state: Object, { elements }: Object) => P.$set(
  'templates',
  G.indexByGuid(R.or(elements, [])),
  state,
);

const saveTemplateSuccess = (state: Object, data: Object) => P.$set(
  `templates.${G.getGuidFromObject(data)}`,
  data,
  state,
);

const deleteTemplateSuccess = (state: Object, deletedGuid: string) => {
  const templates = P.$get('templates', state);

  const result = R.filter(
    ({ guid }: Object) => G.notEquals(guid, deletedGuid),
    templates,
  );

  return P.$set('templates', result, state);
};

const getSearchFilterStatesSuccess = (state: Object, { data, searchFilterGuid }: Object) => {
  let shipments = P.$get('shipments', state);
  let resultsByFilterId = P.$get(`searchResultsByFilterId.${searchFilterGuid}`, state);

  const selected = R.any(R.propEq(GC.LB_STATUS_ACTIVE, GC.FIELD_STATUS), data);

  if (R.not(selected)) {
    shipments = R.filter(
      G.notPropEq(searchFilterGuid, 'searchFilterGuid'),
      shipments,
    );

    resultsByFilterId = [];
  }

  return P.$all(
    P.$set('shipments', shipments),
    P.$set(`searchFiltersStates.${searchFilterGuid}`, data),
    P.$set(`searchFilters.${searchFilterGuid}.selected`, selected),
    P.$set(`searchResultsByFilterId.${searchFilterGuid}`, resultsByFilterId),
    state,
  );
};

const toggleSearchFilterDetails = (state: Object, guid: string) => P.$toggle(
  `searchFilters.${guid}.expanded`,
  state,
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('autodialOn', G.getConfigValueFromStore(GC.UI_LOADBOARD_AUTODIALMODAL, data, false)),
    P.$set('autodialAppName', G.getConfigValueFromStore(GC.UI_LOADBOARD_AUTODIALAPPNAME, data, 'tel')),
    P.$set('autodialActionOn', G.getConfigValueFromStore(GC.UI_LOADBOARD_AUTODIALACTION, data, false)),
    state,
  )
);

const shipmentReceived = (state: Object, data: Array) => {
  const shipments = P.$get('shipments', state);
  const companyFilters = P.$get('companyFilters', state);
  const searchResultsByFilterId = P.$get('searchResultsByFilterId', state);

  const company = R.toLower(
    R.pathOr('', [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMPANY, GC.FIELD_LOAD_BOARD_COMPANY],
    data,
  ));

  const denied = R.any(R.propEq(company, GC.FIELD_NAME), companyFilters);

  if (denied) return state;

  const newShipment = {
    ...data,
    highlighted: true,
    cancelled: R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, 'cancelled'], data),
  };

  const { searchFilterGuid, guid } = data;

  const newShipments = R.concat([newShipment], R.take(R.dec(GC.SHIPMENT_LIMIT), shipments));

  const byFilterIdList = R.propOr([], searchFilterGuid, searchResultsByFilterId);

  byFilterIdList.push(guid);

  return P.$all(
    P.$set('shipments', newShipments),
    P.$set(`searchResultsByFilterId.${searchFilterGuid}`, byFilterIdList),
    P.$set('callAlarm', guid),
    state,
  );
};

const setTableTitleSort = (state: Object, sortData: Array) => {
  const titleSortValues = P.$get('titleSortValues', state);

  const sortValues = G.getTableTitleSortValues(titleSortValues, sortData);

  return P.$set('titleSortValues', sortValues, state);
};

const setTableTitleFilter = (state: Object, filterData: Array) => {
  const tableTitleFilters = P.$get('tableTitleFilters', state);

  const filterValues = G.getTableTitleFilterValues(tableTitleFilters, filterData);

  return P.$set('tableTitleFilters', filterValues, state);
};

const loadboardStateReceived = (state: Object, data: Array) => {
  const authorizedLoadBoards = P.$get('authorizedLoadBoards', state);

  if (R.isEmpty(authorizedLoadBoards)) return state;

  const {
    guid,
    status,
    description,
  } = data;

  const loadBoardToUpdate = R.find(R.propEq(guid, GC.FIELD_GUID), authorizedLoadBoards);

  if (R.isNil(loadBoardToUpdate)) return state;

  const lbIndex = R.indexOf(loadBoardToUpdate, authorizedLoadBoards);

  return P.$set(
    `authorizedLoadBoards.${lbIndex}`,
    {
      ...loadBoardToUpdate,
      status,
      description,
    },
    state,
  );
};

const filterStateReceived = (state: Object, status: Array) => {
  const { guid, searchFilterGuid } = status;

  const filterStates = R.or(P.$get(`searchFiltersStates.${searchFilterGuid}`, state), []);
  const loadBoardState = R.find(R.propEq(guid, GC.FIELD_GUID), filterStates);
  const index = R.indexOf(loadBoardState, filterStates);

  if (R.lt(index, 0)) {
    filterStates.push(status);
  } else {
    filterStates[index] = status;
  }

  return P.$set(
    `searchFiltersStates.${searchFilterGuid}`,
    filterStates,
    state,
  );
};

const filterStateUpdated = (state: Object, status: Array) => {
  const { guid, searchFilterGuid } = status;

  const filterStates = R.or(P.$get(`searchFiltersStates.${searchFilterGuid}`, state), []);
  const loadBoardState = R.find(R.propEq(guid, GC.FIELD_GUID), filterStates);
  const index = R.indexOf(loadBoardState, filterStates);

  filterStates[index] = status;

  const selected = R.any(R.propEq(GC.LB_STATUS_ACTIVE, GC.FIELD_STATUS), filterStates);

  return P.$all(
    P.$set(
      `searchFiltersStates.${searchFilterGuid}`,
      filterStates,
    ),
    P.$set(`searchFilters.${searchFilterGuid}.selected`, selected),
    state,
  );
};

const filterStateCreated = (state: Object, { payload, data }: Object) => {
  const { searchFilterGuid } = payload;

  return P.$add(
    `searchFiltersStates.${searchFilterGuid}`,
    data,
    state,
  );
};

const filterStateDeleted = (state: Object, { guid, searchFilterGuid }: Object) => {
  const filterStates = R.or(P.$get(`searchFiltersStates.${searchFilterGuid}`, state), []);
  const newFilterStates = R.filter(
    R.compose(R.not, R.propEq(guid, GC.FIELD_GUID)),
    filterStates,
  );

  return P.$add(
    `searchFiltersStates.${searchFilterGuid}`,
    newFilterStates,
    state,
  );
};

const setFilteredBySearchFilter = (state: Object, id: string = '') => {
  const list = P.$get('filteredBySearchFilter', state);

  if (R.includes(id, list)) return P.$remove('filteredBySearchFilter', id, state);

  return P.$add('filteredBySearchFilter', id, state);
};

const get123LoadBoardDetailsSuccess = (state: Object, { id, data }: Object) => P.$set(
  'shipments',
  R.map(
    (entity: Object) => {
      const { shipment } = entity;

      if (R.equals(id, G.getPropFromObject(GC.FIELD_ID, shipment))) {
        return {
          ...entity,
          detailsLoaded: true,
          shipment: R.mergeRight(shipment, data),
        };
      }

      return entity;
    },
    P.$get('shipments', state),
  ),
  state,
);

export default createReducer({
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.toggleAutodial]: toggleAutodial,
  [A.setInitialState]: setInitialState,
  [A.setTableTitleSort]: setTableTitleSort,
  [socketLBResultReceived]: shipmentReceived,
  [A.getTemplatesSuccess]: getTemplatesSuccess,
  [A.saveShipmentSuccess]: saveShipmentSuccess,
  [A.saveTemplateSuccess]: saveTemplateSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.toggleCancelledShown]: toggleCancelledShown,
  [socketLBStateReceived]: loadboardStateReceived,
  [A.deleteTemplateSuccess]: deleteTemplateSuccess,
  [A.loginLoadBoardSuccess]: loginLoadBoardSuccess,
  [A.updateShipmentSuccess]: updateShipmentSuccess,
  [socketFilterStateReceived]: filterStateReceived,
  [A.logoutLoadBoardSuccess]: logoutLoadBoardSuccess,
  [A.makeNotificationViewed]: makeNotificationViewed,
  [A.addCompanyFilterSuccess]: addCompanyFilterSuccess,
  [A.getSearchFiltersSuccess]: getSearchFiltersSuccess,
  [A.bookSavedShipmentSuccess]: bookSavedShipmentSuccess,
  [A.createSearchFilterStateSuccess]: filterStateCreated,
  [A.deleteSearchFilterStateSuccess]: filterStateDeleted,
  [A.getCompanyFiltersSuccess]: getCompanyFiltersSuccess,
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.getSavedShipmentsSuccess]: getSavedShipmentsSuccess,
  [A.activateSearchFilterStateSuccess]: filterStateUpdated,
  [A.createSearchFilterSuccess]: createSearchFilterSuccess,
  [A.deleteSearchFilterSuccess]: deleteSearchFilterSuccess,
  [A.getBookedShipmentsSuccess]: getBookedShipmentsSuccess,
  [A.updateSearchFilterSuccess]: updateSearchFilterSuccess,
  [A.toggleSearchFilterDetails]: toggleSearchFilterDetails,
  [A.setFilteredBySearchFilter]: setFilteredBySearchFilter,
  [A.deleteCompanyFilterSuccess]: deleteCompanyFilterSuccess,
  [A.getLoggedLoadBoardsSuccess]: getLoggedLoadBoardsSuccess,
  [A.deleteSavedShipmentSuccess]: deleteSavedShipmentSuccess,
  [A.deleteBookedShipmentSuccess]: deleteBookedShipmentSuccess,
  [A.getSearchFilterStatesSuccess]: getSearchFilterStatesSuccess,
  [A.get123LoadBoardDetailsSuccess]: get123LoadBoardDetailsSuccess,
  [socketBookedShipmentStateReceived]: bookedShipmentStateReceived,
}, initialState);
