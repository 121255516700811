import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature location
import * as A from './actions';
//////////////////////////////////////////////////

function* handleDeleteLocationReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, callback } = payload;

    const endpoint = endpointsMap.getRemoveLocationReferenceEndpoint(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunction(callback);
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteLocationReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteLocationReferenceRequest exception');
  }
}

function* handleGetLocationReferenceRequest({ payload }: Object) {
  try {
    const { guid, callback } = payload;

    const options = {
      params: { locationGuid: guid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.locationReferenceList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, R.indexBy(R.prop(GC.FIELD_GUID), data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLocationReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLocationReferenceRequest exception');
  }
}

function* handleGetCurrentLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, callback } = payload;

    const endpoint = endpointsMap.getCurrentLocationEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCurrentLocationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCurrentLocationRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateLocationReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { args, callback } = payload;

    const res = yield call(sendRequest, 'post', endpointsMap.locationReference, { data: args });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, R.head(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateLocationReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateLocationReferenceRequest exception');
  }
}

function* handleUpdateLocationReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { callback, reference } = payload;

    const endpoint = endpointsMap.locationReference;

    const res = yield call(sendRequest, 'put', endpoint, { data: reference });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateLocationReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateLocationReferenceRequest exception');
  }
}

export function* handleCreateNewLocationRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const data = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload);

    const res = yield call(sendRequest, 'post', endpointsMap.location, { data });

    const { status } = res;

    if (G.isResponseFail(status)) {
      yield call(G.handleFailResponse, res, 'handleCreateNewLocationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNewLocationRequest exception');
  }
}

export function* locationsWatcherSaga() {
  yield takeLatest(A.getCurrentLocationRequest, handleGetCurrentLocationRequest);
  yield takeLatest(A.getLocationReferenceRequest, handleGetLocationReferenceRequest);
  yield takeLatest(A.deleteLocationReferenceRequest, handleDeleteLocationReferenceRequest);
  yield takeLatest(A.createLocationReferenceRequest, handleCreateLocationReferenceRequest);
  yield takeLatest(A.updateLocationReferenceRequest, handleUpdateLocationReferenceRequest);
}

export default locationsWatcherSaga;
