import { createLogic } from 'redux-logic';
// common
import { globalCleanReports } from '../../../common/actions';
// features
import { sendLogOutRequest } from '../../auth/actions';
import { receivedSwitchBranchSuccess } from '../../branch/actions';
// helpers/constants
import * as GC from '../../../constants';
// feature invoice/carrier
import { setInitialState } from './actions';
//////////////////////////////////////////////////

const handleSwitchBranchOrChangeLocationLogic = createLogic({
  type: [
    sendLogOutRequest,
    globalCleanReports,
    GC.LOCATION_CHANGE_ACTION,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setInitialState());
    done();
  },
});

export default [handleSwitchBranchOrChangeLocationLogic];
