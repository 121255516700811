import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature los-subscription
import * as A from './actions';
import * as S from './selectors';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.includes('Default', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      systemFields: [
        { name: GC.FIELD_MASTER_ENTERPRISE_GUID, freezed: false, sequence: 100, reference: false },
        { name: GC.FIELD_USE_EXISTENT_ENTERPRISE, freezed: false, sequence: 101, reference: false },
      ],
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.losSubscriptionList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.LOS_SUBSCRIPTION_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);

function* handleAcceptLosSubscrioptionRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.setListLoading(true));

    const { guid, masterEnterpriseGuid, useExistentEnterprise } = payload;

    const options = {
      data: { masterEnterpriseGuid, useExistentEnterprise },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.losSubscriptionAccept(guid), options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.acceptLosSubscriptionSuccess({ guid, data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleAcceptLosSubscrioptionRequestSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAcceptLosSubscrioptionRequestSaga exception');
  }
}

function* handleRejectLosSubscriptionRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.setListLoading(true));

    const { guid, message } = payload;

    const options = {
      data: { rejectReason: message },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.losSubscriptionReject(guid), options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.rejectLosSubscriptionSuccess({ guid, data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleRejectLosSubscriptionRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleRejectLosSubscriptionRequestSaga exception');
  }
}

function* handleGetMasterEnterpriseListRequestSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'get', endpointsMap.mastersBranchList);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getMasterEnterpriseListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetMasterEnterpriseListRequestSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetMasterEnterpriseListRequestSaga exception');
  }
}

function* handleVisitLosSubscriptionListSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* losSubscriptionWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.rejectLosSubscriptionRequest, handleRejectLosSubscriptionRequestSaga);
  yield takeLatest(A.acceptLosSubscriptionRequest, handleAcceptLosSubscrioptionRequestSaga);
  yield takeLatest(GC.VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE, handleVisitLosSubscriptionListSaga);
  yield takeLatest(A.getMasterEnterpriseListRequest, handleGetMasterEnterpriseListRequestSaga);
}

export default losSubscriptionWatcherSaga;
