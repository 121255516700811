import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withProps, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { LocalLoader } from '../../../components/local-loader';
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { ChargesTotal } from '../../../components/charge/components';
import { setGlCodeToCharges } from '../../../components/charge/hocs';
import { DMChargeComponent } from '../../../components/charge/formik/charges-array';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
import { getIsPriceSheetItemFieldsEditable } from '../../../components/price-sheet-items/settings';
import { withAsyncChargeAccessorials } from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import { makeSelectAuthorities } from '../../permission/selectors';
import { GeneralSectionForServiceVendor } from '../../rate/sections';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { getChargesFromValues, getChargesArrayNameFromProps } from '../../rate/helpers';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { getSequenceRequest, getSequenceGuidFromConfig } from '../../../hocs/with-async-sequence';
import {
  withAsyncConfigs,
  getInvoiceGlCode2,
  withAsyncInvoicesCount,
  withAsyncGetServiceVendor,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
  withConnectModalAndLoaderActions,
  withAsyncGetGLCodeMappingsByScope,
  withComponentDidUpdatePropCallback,
  withAsyncGetServiceVendorListAvailabe,
} from '../../../hocs';
// schemes
import * as VS from '../../../schemes';
// ui
import { Box, Flex, StickedBox, SectionsDivider } from '../../../ui';
// feature invoice
import PaymentsArray from './payments-array';
import ReferencesArray from './references-array';
import { PayRemitToSection } from './header-section';
import { FleetInvoiceSection } from './invoice-section';
import { serviceVendorInfoFields } from '../settings/fields-settings';
import { validationSchemaReferences } from '../settings/common-fields';
import {
  getTotalsFromValues,
  setDefaultInvoiceValues2,
  setOptionsForServiceVendorSelect,
  getDataWithTransformedReferences,
} from '../helpers';
//////////////////////////////////////////////////

const configsNamesArrayForCreateInvoice = [
  GC.INVOICE_GL_CODE,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_SEQUENCE,
  GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_AUTOGENERATED,
];

const configsNamesArrayForUpdateInvoice = [
  GC.INVOICE_GL_CODE,
];

const validationSchemaObject = Yup.lazy((values: Object) => Yup.object()
  .shape(validationSchemaReferences)
  .shape(VS.validationSchemaInvoiceObject)
  .shape(VS.validationSchemaCommentObject)
  .shape(VS.validationSchemaTripServiceObject2)
  .shape(VS.validationSchemaServiceVendorObject)
  .shape(G.ifElse(getIsPriceSheetItemFieldsEditable(values), VS.validationSchemaPriceSheetItems, {})));

const InvoiceSection = (props: Object) => {
  const {
    values,
    branchGuid,
    isEditMode,
    authorities,
    asyncConfigs,
    glCodeMappings,
    totalCustomersRate,
  } = props;

  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  const stopsQuantity = R.pathOr(
    R.pathOr(0, ['stopsQuantity'], props),
    [GC.FIELD_TEL, GC.FIELD_LOAD_STOPS, 'length'],
    props,
  );

  const glCodeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  );

  const chargesArrayName = getChargesArrayNameFromProps(props);

  return (
    <Box width='100%'>
      <FleetInvoiceSection
        {...G.getFormikPropsToFieldset(props)}
        authorities={authorities}
        optionsForSelect={setOptionsForServiceVendorSelect('CarrierIStatusConfig', props)}
      />
      <PaymentsArray {...props} />
      <ReferencesArray {...props} scope={GC.REF_SCOPE_NAME_FLEET_INVOICE} />
      <PriceSheetItems
        {...G.getFormikProps(props)}
        tableMarginBottom={20}
        editable={getIsPriceSheetItemFieldsEditable(values)}
        additionalFormHeaderSectionStyles={{ my: 0, mb: 20 }}
      />
      <SectionsDivider />
      <GeneralSectionForServiceVendor
        {...G.getFormikPropsToFieldset(props)}
        optionsForSelect={setOptionsForServiceVendorSelect('CarrierIStatusConfig', props)}
      />
      <DMChargeComponent
        {...getTotalsFromValues(values)}
        isInvoice={true}
        rateProps={props}
        currency={currency}
        isServiceVendor={true}
        loadType={GC.FIELD_TEL}
        isEditMode={isEditMode}
        branchGuid={branchGuid}
        authorities={authorities}
        serviceDays={serviceDays}
        stopsQuantity={stopsQuantity}
        glCodeOptions={glCodeOptions}
        glCodeMappings={glCodeMappings}
        normalizedTotal={normalizedTotal}
        chargesArrayName={chargesArrayName}
        totalCustomersRate={totalCustomersRate}
      />
    </Box>
  );
};

const serviceVendorFields = [
  {
    isRequired: true,
    type: 'reactSelect',
    shouldCustomChange: true,
    fieldName: GC.FIELD_SERVICE_VENDOR_GUID,
    options: 'serviceVendorListAvailabeOptions',
    inputWrapperStyles: { zIndex: 13, width: 250 },
    label: ['titles:service-vendor', 'Service Vendor'],
  },
];

const ServiceVendorInfo = (props: Object) => {
  const {
    values,
    serviceVendorListAvailabe,
    handleChangeServiceVendorSelect,
    serviceVendorListAvailabeOptions,
  } = props;

  const serviceVendorGuid = G.getPropFromObject(GC.FIELD_SERVICE_VENDOR_GUID, values);
  const serviceVendor = R.find(R.propEq(serviceVendorGuid, GC.FIELD_GUID), serviceVendorListAvailabe);

  return (
    <Flex p={20} justifyContent='center' bg={G.getTheme('modal.bgColor')}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={serviceVendorFields}
        fieldsWrapperStyles={{ flexGrow: 1 }}
        handleCustomChange={handleChangeServiceVendorSelect}
        serviceVendorListAvailabeOptions={serviceVendorListAvailabeOptions}
      />
      {
        serviceVendorInfoFields.map(({ field, label }: string, index: number) => {
          const fieldValue = R.pathOr(null, [field], serviceVendor);

          if (R.isNil(fieldValue)) return null;

          return (
            <Box
              p='8px'
              mr={20}
              key={index}
              minWidth={200}
              maxWidth={250}
              borderRadius='8px'
              bg={G.getTheme('colors.dark.lightGrey')}
            >
              <TextComponent
                minWidth='100%'
                maxWidth='100%'
                withEllipsis={true}
                whiteSpace='pre-line'
                wordBreak='break-all'
              >
                <Flex justifyContent='space-between'>
                  <Box fontWeight={600} textTransform='uppercase'>
                    {`${G.getWindowLocale(label)}:`}
                  </Box>
                </Flex>
                {fieldValue}
              </TextComponent>
            </Box>
          );
        })
      }
    </Flex>
  );
};

const withHandleServiceVendorSelect = withHandlers({
  handleChangeServiceVendorSelect: (props: Object) => (serviceVendorGuid: string) => {
    const {
      values,
      setValues,
      glCodeMappings,
      serviceVendorListAvailabe,
      setVendorServiceTypeOptions,
    } = props;

    let newPaymentDueDate = '';

    const invoiceDate = R.prop('invoiceDate', values);
    const invoiceCurrency = G.getCurrencyFromObject(values);
    const invoiceGlCode = getInvoiceGlCode2(glCodeMappings, invoiceCurrency);
    const charges = setGlCodeToCharges(G.createMainAndFuelCharges(), glCodeMappings, invoiceCurrency);

    if (R.isNil(serviceVendorGuid)) {
      const result = {
        charges,
        [GC.FIELD_SERVICE_VENDOR_GUID]: null,
      };

      const newValues = R.mergeRight(values, result);

      return setValues(newValues);
    }

    const serviceVendor = R.find(R.propEq(serviceVendorGuid, GC.FIELD_GUID), serviceVendorListAvailabe);

    const netDays = R.pathOr('', [GC.SYSTEM_OBJECT_PAY_TO_LOCATION, GC.FIELD_NET_DAYS], serviceVendor);

    const serviceTypes = R.path(['serviceTypes'], serviceVendor);

    if (G.isNotNilAndNotEmpty(serviceTypes)) {
      setVendorServiceTypeOptions(G.prependEmptyLabelValueOption(G.mapDisplayedValueDropdownOptionGuidToLabelValue(
        R.map(R.pick([GC.FIELD_DISPLAYED_VALUE, GC.FIELD_DROPDOWN_OPTION_GUID]), serviceTypes),
      )));
    }

    if (R.and(R.isNotNil(invoiceDate), G.isNotNilAndNotEmpty(netDays))) {
      newPaymentDueDate = G.addMomentTimeWithFormat(invoiceDate, netDays, 'days', GC.DEFAULT_DATE_FORMAT);
    }

    const result = {
      charges,
      [GC.FIELD_NET_DAYS]: netDays,
      [GC.FIELD_GL_CODE]: invoiceGlCode,
      [GC.FIELD_SERVICE_VENDOR_GUID]: serviceVendorGuid,
      [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: newPaymentDueDate,
    };

    const newValues = R.mergeRight(values, result);

    return setValues(newValues);
  },
});

const withAsyncSequence = compose(
  withState('asyncSequence', 'setSequence', null),
  withState('origSequence', 'setOrigSequence', null),
  withHandlers({
    getAsyncSequence: (props: Object) => () => {
      const {
        branchGuid,
        setSequence,
        asyncConfigs,
        sequenceConfigName,
        autogenerateConfigName,
      } = props;

      const sequenceGuid = getSequenceGuidFromConfig(asyncConfigs, sequenceConfigName, autogenerateConfigName);

      if (R.and(
        G.isNotNilAndNotEmpty(branchGuid),
        G.isNotNilAndNotEmpty(sequenceGuid),
      )) {
        getSequenceRequest({ branchGuid, setSequence, sequenceGuid });
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'asyncConfigs',
    callbackName: 'getAsyncSequence',
  }),
  pure,
);

const withHandleAsyncSequence = compose(
  withHandlers({
    handleGetAsyncSequence: (props: Object) => () => {
      const { asyncSequence, setFieldValue, setOrigSequence } = props;

      if (G.isNotNil(asyncSequence)) {
        setOrigSequence(asyncSequence);

        setFieldValue(GC.FIELD_INVOICE_NUMBER, asyncSequence);
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'asyncSequence',
    callbackName: 'handleGetAsyncSequence',
  }),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
  availableTypes: makeSelectAvailableReferenceTypesByScope(state),
});

const enhanceCreate = compose(
  connect(mapStateToProps),
  withConnectModalAndLoaderActions,
  withProps(() => ({
    isInvoice: true,
    isEditMode: false,
    isServiceVendor: true,
    configsNamesArray: configsNamesArrayForCreateInvoice,
    sequenceConfigName: GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_SEQUENCE,
    autogenerateConfigName: GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_AUTOGENERATED,
  })),
  withState('vendorServiceTypeOptions', 'setVendorServiceTypeOptions', []),
  withAsyncConfigs,
  withAsyncSequence,
  withAsyncChargeAccessorials(),
  withAsyncGetServiceVendorListAvailabe(),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultInvoiceValues2(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { telGuid, origSequence, handleSendFleetInvoice } = props;

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, telGuid),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const charges = G.mapOmitObjectSystemFields(R.pathOr([], [GC.FIELD_CHARGES], values));
      const chargesToUse = R.reject(R.propEq(true, GC.FIELD_CHARGE_INTERNAL_EXPENSE), charges);

      const invoice = R.compose(
        R.assoc(GC.FIELD_CHARGES, chargesToUse),
        R.assoc(GC.FIELD_REFERENCES, references),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        G.omitEmptyPaymentsFromData,
      )(values);

      const dataToUse = G.omitEmptyChargesFromData(invoice, GC.FIELD_CHARGES);

      if (R.equals(R.prop(GC.FIELD_INVOICE_NUMBER, invoice), origSequence)) {
        handleSendFleetInvoice(R.assoc(GC.FIELD_INVOICE_NUMBER, null, dataToUse));
      } else {
        handleSendFleetInvoice(dataToUse);
      }
    },
    displayName: 'SERVICE_VENDOR_INVOICE_FORM',
  }),
  withHandlers({
    updateRemitToRequest: (props: Object) => (values: Object) => {
      const { setFieldValue } = props;

      const initialValues = R.pathOr({}, ['initialValues', GC.SYSTEM_OBJECT_REMIT_TO], props);
      setFieldValue(GC.SYSTEM_OBJECT_REMIT_TO, R.mergeRight(initialValues, values));
    },
  }),
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR),
  withHandleServiceVendorSelect,
  withHandleAsyncSequence,
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  pure,
);

const asyncSetDefaultValues = (props: Object) => {
  const { availableTypes, asyncInitialData: { data } } = props;

  if (G.isNilOrEmpty(data)) return {};

  const dataToUse = getDataWithTransformedReferences(data, availableTypes);

  return setDefaultInvoiceValues2(R.assoc('initialValues', dataToUse, props));
};

const enhanceAsyncUpdate = compose(
  connect(mapStateToProps),
  withAsyncInitialDataOnDidMount,
  withConnectModalAndLoaderActions,
  withProps(() => ({
    isAsync: true,
    isInvoice: true,
    isEditMode: true,
    isServiceVendor: true,
    configsNamesArray: configsNamesArrayForUpdateInvoice,
  })),
  withState('vendorServiceTypeOptions', 'setVendorServiceTypeOptions', []),
  withAsyncConfigs,
  withAsyncChargeAccessorials(),
  withAsyncGetServiceVendor(),
  withHandlers({
    handleServiceVendorChanged: (props: Object) => () => {
      const { serviceVendor, setVendorServiceTypeOptions } = props;

      const serviceTypes = R.path(['serviceTypes'], serviceVendor);

      if (G.isNotNilAndNotEmpty(serviceTypes)) {
        setVendorServiceTypeOptions(G.prependEmptyLabelValueOption(G.mapDisplayedValueDropdownOptionGuidToLabelValue(
          R.map(R.pick([GC.FIELD_DISPLAYED_VALUE, GC.FIELD_DROPDOWN_OPTION_GUID]), serviceTypes),
        )));
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'serviceVendor',
    callbackName: 'handleServiceVendorChanged',
  }),
  withAsyncGetServiceVendorListAvailabe(),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => asyncSetDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendFleetInvoice } = props;

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, G.getPropFromObject(GC.FIELD_TEL_GUID, values)),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const invoice = R.compose(
        R.assoc(GC.FIELD_REFERENCES, references),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        G.omitEmptyPaymentsFromData,
      )(values);

      const dataToUse = G.omitEmptyChargesFromData(invoice, GC.FIELD_CHARGES);

      handleSendFleetInvoice(dataToUse);
    },
    displayName: 'ASYNC_EDIT_SERVICE_VENDOR_INVOICE_FORM',
  }),
  withHandlers({
    updateRemitToRequest: (props: Object) => (values: Object) => {
      const { setFieldValue } = props;

      const initialValues = R.pathOr({}, ['initialValues', GC.SYSTEM_OBJECT_REMIT_TO], props);
      setFieldValue(GC.SYSTEM_OBJECT_REMIT_TO, R.mergeRight(initialValues, values));
    },
  }),
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR),
  withHandleServiceVendorSelect,
  withAsyncInvoiceStatusConfigs,
  pure,
);

const ServiceVendorInvoiceForm = (props: Object) => {
  const { closeModal, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <StickedBox top='0px' zIndex={1001}>
        <FormGroupTitleMultiple
          mb='0px'
          showArrowToggle={false}
          title={G.getWindowLocale('titles:add-service-vendor-invoice', 'Add Service Vendor Invoice')}
        />
      </StickedBox>
      <ServiceVendorInfo {...props} />
      <PayRemitToSection {...props} />
      <SectionsDivider />
      <InvoiceSection {...props} />
      <StickedBox bottom='0px' zIndex={1001}>
        <ChargesTotal {...props} isInvoice={true} charges={getChargesFromValues(props)} />
        <ChargeFormFooter closeModal={closeModal} />
      </StickedBox>
    </form>
  );
};

export const AsyncEditServiceVendorInvoiceForm = enhanceAsyncUpdate((props: Object) => {
  const { closeModal, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <StickedBox top='0px' zIndex={1001}>
        <FormGroupTitleMultiple
          mb='0px'
          showArrowToggle={false}
          title={G.getWindowLocale('titles:edit-service-vendor-invoice', 'Edit Service Vendor Invoice')}
        />
      </StickedBox>
      <ServiceVendorInfo {...props} />
      <PayRemitToSection {...props} />
      <SectionsDivider />
      <LocalLoader localLoaderOpen={R.pathOr(true, ['asyncInitialData', 'loading'], props)}>
        <InvoiceSection {...props} />
      </LocalLoader>
      <StickedBox bottom='0px' zIndex={1001}>
        <ChargesTotal {...props} isInvoice={true} charges={getChargesFromValues(props)} />
        <ChargeFormFooter closeModal={closeModal} />
      </StickedBox>
    </form>
  );
});

export default enhanceCreate(ServiceVendorInvoiceForm);
