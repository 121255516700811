import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { MAIL_SENDING_REPLY_TO_OPTIONS } from '../../../helpers/options';
// feature master-invoice
import * as LC from '../constants';
//////////////////////////////////////////////////

export const getValidationSchema = () => ({
  [LC.DOCUMENT_TEMPLATE]: Yup.string()
    .nullable(true)
    .required(G.getWindowLocale('validation:field-required', 'Field is Required')),
});

export const defaultValues = {
  [LC.MERGE_FILES]: true,
  [LC.SEND_EMAILS]: false,
  [LC.DOCUMENT_TYPES]: null,
  [LC.INTEGRATION_TYPE]: null,
  [LC.DOCUMENT_TEMPLATE]: null,
  [LC.VENDOR_DOCUMENT_TYPES]: null,
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: null,
};

export const getFields1 = (mailSendingIntegrationExists: string) => [
  {
    type: 'toggle',
    labelPl: '0px',
    width: 'fit-content',
    alignItems: 'center',
    shouldCustomChange: true,
    loc: 'titles:merge-files',
    labelWidth: 'fit-content',
    fieldName: LC.MERGE_FILES,
    justifyContent: 'space-between',
    inputWrapMargin: '4px 24px 4px 8px',
  },
  {
    type: 'toggle',
    labelPl: '0px',
    width: 'fit-content',
    alignItems: 'center',
    shouldCustomChange: true,
    loc: 'titles:send-emails',
    fieldName: LC.SEND_EMAILS,
    labelWidth: 'fit-content',
    justifyContent: 'space-between',
    inputWrapMargin: '4px 24px 4px 8px',
    disabled: G.isNilOrEmpty(mailSendingIntegrationExists),
    title: G.isNilOrEmpty(mailSendingIntegrationExists)
      ? G.getWindowLocale(
        'messages:you-do-not-have-mail-sending-integration',
        "You don't have mail sending integration",
      )
      : null,
  },
];

const commonProps = {
  width: 200,
  afterTop: 13,
  afterRight: 13,
  labelPl: '0px',
  errorTop: '103%',
  labelWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
  inputWrapMargin: '4px 24px 4px 0',
};

export const getFields2 = (
  templateOptions: Array,
  documentTypeOptions: Array,
  sendEmails: boolean = false,
  activeFactoringIntegration: string,
  vendorDocumentTypeOptions: Array,
) => [
  {
    ...commonProps,
    type: 'select',
    isRequired: true,
    loc: 'titles:template',
    options: templateOptions,
    fieldName: LC.DOCUMENT_TEMPLATE,
  },
  {
    ...commonProps,
    type: 'select',
    shouldCustomChange: true,
    loc: 'titles:factoring-name',
    fieldName: LC.INTEGRATION_TYPE,
    options: R.compose(
      G.addEmptyOptionToDropDown,
      R.filter(R.propEq(activeFactoringIntegration, GC.FIELD_VALUE)),
    )(LC.INTEGRATION_TYPE_OPTIONS),
  },
  {
    ...commonProps,
    type: 'multiselect',
    options: documentTypeOptions,
    fieldName: LC.DOCUMENT_TYPES,
    useMultiSelectMaxHeight: true,
    loc: 'titles:default-document-types',
  },
  {
    ...commonProps,
    type: 'select',
    loc: 'titles:reply-to',
    fieldName: GC.FIELD_MAIL_SENDING_REPLY_TO,
    fieldGroupDisplay: G.ifElse(sendEmails, 'flex', 'none'),
    options: G.addEmptyOptionToDropDown(MAIL_SENDING_REPLY_TO_OPTIONS),
  },
  {
    ...commonProps,
    type: 'multiselect',
    loc: 'titles:vendor-document-type',
    options: vendorDocumentTypeOptions,
    fieldName: LC.VENDOR_DOCUMENT_TYPES,
  },
];
