import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature master-invoice
import { FIELD_GROUP_BY_REFERENCE_VALUE } from '../constants';
import MasterInvoiceInvoices from '../components/master-invoice-invoices';
//////////////////////////////////////////////////

export const report = {
  fields: [
    { name: GC.FIELD_MASTER_INVOICE_NUMBER, sequence: 1 },
    { name: GC.FIELD_MASTER_INVOICE_DATE_FROM, sequence: 2 },
    { name: GC.FIELD_MASTER_INVOICE_DATE_TO, sequence: 3 },
    { name: GC.GRC.STATUS_DISPLAYED_VALUE, sequence: 4 },
    { name: GC.GRC.BILL_TO_LOCATION_NAME, sequence: 5 },
    { name: GC.FIELD_MASTER_INVOICE_CHECK_NUMBER, sequence: 6 },
    { name: GC.FIELD_MASTER_INVOICE_INVOICES_COUNT, sequence: 7 },
    { name: GC.FIELD_MASTER_INVOICE_TOTAL_DISCOUNT, sequence: 8 },
    { name: GC.FIELD_MASTER_INVOICE_CHARGES_TOTAL, sequence: 9 },
    { name: GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL, sequence: 10 },
    { name: GC.FIELD_MASTER_INVOICE_GRAND_TOTAL, sequence: 11 },
    { name: GC.FIELD_MASTER_INVOICE_CREATED_DATE, sequence: 12 },
    { name: GC.FIELD_MASTER_INVOICE_CURRENCY, sequence: 13 },
    { name: GC.FIELD_MASTER_INVOICE_INTEGRATED_DATE, sequence: 14 },
    { name: FIELD_GROUP_BY_REFERENCE_VALUE, sequence: 15 },
    { name: GC.FIELD_MASTER_INVOICE_DATE, sequence: 16 },
  ],
};

const commonTableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: true,
};

export const tableSettings = {
  ...commonTableSettings,
  minHeight: 320,
  moreBtnCellWidth: 50,
  allowSelectAll: true,
  expandableItems: true,
  searchableTitles: true,
  checkBoxCellWidth: 120,
  checkBoxCellJustifyContent: 'unset',
  expandedDetailsComponent: (props: Object) => <MasterInvoiceInvoices {...props} />,
};

export const tableSettingsAsyncMasterInvoice = {
  ...commonTableSettings,
  useMainColors: true,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export const columnSettings = {
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: 'titles:branch',
  },
  [GC.FIELD_MASTER_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:master-invoice-number',
    customComponent: ({ data }: Object) => {
      if (R.equals(G.getWindowLocationPathname(), GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST)) {
        return <div>{G.getPropFromObject(GC.FIELD_MASTER_INVOICE_NUMBER, data)}</div>;
      }

      const { closeModal } = data;

      return (
        <Box
          cursor='pointer'
          color={G.getTheme('colors.light.blue')}
          onClick={() => {
            if (G.isFunction(closeModal)) closeModal();

            G.goToRoute(GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST);
          }}
        >
          {G.getPropFromObject(GC.FIELD_MASTER_INVOICE_NUMBER, data)}
        </Box>
      );
    },
  },
  [GC.FIELD_MASTER_INVOICE_DATE_FROM]: {
    name: 'titles:date-from',
  },
  [GC.FIELD_MASTER_INVOICE_DATE_TO]: {
    name: 'titles:date-to',
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:status',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        G.getPropFromObject(GC.GRC.STATUS_DISPLAYED_VALUE, data),
        [GC.FIELD_STATUS, GC.FIELD_DISPLAYED_VALUE],
        data,
      );

      if (G.isNilOrEmpty(text)) return null;

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.GRC.BILL_TO_LOCATION_NAME]: {
    width: 200,
    name: 'titles:bill-to',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        G.getPropFromObject(GC.GRC.BILL_TO_LOCATION_NAME, data),
        [GC.FIELD_BILL_TO, GC.FIELD_LOCATION_NAME],
        data,
      );

      if (G.isNilOrEmpty(text)) return null;

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_MASTER_INVOICE_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.FIELD_MASTER_INVOICE_INVOICES_COUNT]: {
    width: 200,
    name: 'titles:invoices-count',
  },
  [GC.FIELD_MASTER_INVOICE_TOTAL_DISCOUNT]: {
    width: 200,
    name: 'titles:discount',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_TOTAL_DISCOUNT, data)) &&
      <TextComponent fontWeight={700}>
        {
          `${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CURRENCY, data))
          } ${G.toFixed(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_TOTAL_DISCOUNT, data))}`
        }
      </TextComponent>
    ),
  },
  [GC.FIELD_MASTER_INVOICE_CHARGES_TOTAL]: {
    width: 200,
    name: 'titles:charges-total',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CHARGES_TOTAL, data))
      && (
        <TextComponent fontWeight={700}>
          {
            `${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CURRENCY, data))
            } ${G.toFixed(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CHARGES_TOTAL, data))}`
          }
        </TextComponent>
      )
    ),
  },
  [GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL]: {
    width: 200,
    name: 'titles:invoices-total',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL, data)) &&
      <TextComponent fontWeight={700}>
        {
          `${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CURRENCY, data))
          } ${G.toFixed(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL, data))}`
        }
      </TextComponent>
    ),
  },
  [GC.FIELD_MASTER_INVOICE_GRAND_TOTAL]: {
    width: 200,
    name: 'titles:gross-total',
    customComponent: ({ data }: Object) => (
      G.isNotNil(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_GRAND_TOTAL, data)) &&
      <TextComponent
        p='4px 6px'
        fontWeight={700}
        borderRadius='3px'
        width='fit-content'
        display='inline-block'
        bg={G.getTheme('colors.light.blue')}
        color={G.getTheme('colors.light.mainLight')}
      >
        {
          `${G.getCurrencySymbol(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CURRENCY, data))
          } ${G.toFixed(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_GRAND_TOTAL, data))}`
        }
      </TextComponent>
    ),
  },
  [GC.FIELD_MASTER_INVOICE_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_MASTER_INVOICE_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_MASTER_INVOICE_INTEGRATED_DATE]: {
    name: 'titles:integrated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_STATUS]: {
    width: 200,
    name: 'titles:integration-status',
    customComponent: ({ data }: Object) => {
      const status = R.pathOr(' ', [GC.GRC.ACCOUNTING_INTEGRATION_STATUS], data);
      const text = G.getWindowLocale(GC.statusLocaleMap[status], status);

      return (
        <span title={text}>{text}</span>
      );
    },
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:integrated-by',
  },
  [GC.GRC.INVOICES_CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: 'titles:clo-reference',
  },
  [FIELD_GROUP_BY_REFERENCE_VALUE]: {
    width: 250,
    name: 'titles:reference',
  },
  [GC.FIELD_MASTER_INVOICE_DATE]: {
    name: 'titles:date',
  },
  [GC.FIELD_MASTER_INVOICE_GROUP_BY]: {
    width: 200,
    type: 'enum',
    name: 'titles:group-by',
  },
};
