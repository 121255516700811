import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getMailSendingRequest = createAction('getMailSendingRequest');
export const getMailSendingSuccess = createAction('getMailSendingSuccess');
export const getContactListRequest = createAction('getContactListRequest');
export const createMailSendingRequest = createAction('createMailSendingRequest');
export const createMailSendingSuccess = createAction('createMailSendingSuccess');
export const updateMailSendingRequest = createAction('updateMailSendingRequest');
export const updateMailSendingSuccess = createAction('updateMailSendingSuccess');
export const uploadMailSendingRequest = createAction('uploadMailSendingRequest');
export const uploadMailSendingSuccess = createAction('uploadMailSendingSuccess');
export const receivedContactListSuccess = createAction('receivedContactListSuccess');
