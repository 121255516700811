import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { DEFAULT_REPLY_TO } from '../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 390,
};

export const documentTemplateField = {
  inputWrapperStyles,
  type: 'reactSelect',
  useMenuPortalTarget: true,
  options: GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID,
  fieldName: GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID,
  label: ['titles:dispatch-document', 'Dispatch Document'],
};

export const mailSendingFields = [
  {
    inputWrapperStyles: {
      ...inputWrapperStyles,
      mt: 15,
      width: 190,
    },
    type: 'reactSelect',
    options: DEFAULT_REPLY_TO,
    label: ['titles:reply-to', 'Reply To'],
    fieldName: GC.FIELD_MAIL_SENDING_REPLY_TO,
    customDisabledFunction: 'handleDisableReplyToField',
  },
  {
    inputWrapperStyles: {
      ...inputWrapperStyles,
      mt: 15,
      width: 190,
    },
    type: 'text',
    label: ['titles:subject', 'Subject'],
    fieldName: GC.FIELD_MAIL_SENDING_SUBJECT,
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS,
    fieldName: GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS,
    customDisabledFunction: 'handleDisableAttachedDocumentGuids',
    label: ['titles:attach-additional-documents', 'Attach Additional Documents'],
  },
  {
    minHeight: 90,
    type: 'textarea',
    inputWrapperStyles,
    label: ['titles:message', 'Message'],
    fieldName: GC.FIELD_MAIL_SENDING_MESSAGE,
  },
  documentTemplateField,
];

export const mailSendingValidationSchemaObject = {
  [GC.FIELD_MAIL_SENDING_TO]: G.yupArrayRequired,
  [GC.FIELD_MAIL_SENDING_SUBJECT]: Yup.string()
    .nullable(true)
    .max(150, G.getShouldBeFromToCharLocaleTxt(0, 150)),
  [GC.FIELD_MAIL_SENDING_MESSAGE]: Yup.string()
    .nullable(true)
    .max(2000, G.getShouldBeFromToCharLocaleTxt(0, 2000)),
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: Yup.string()
    .nullable(true)
    .required(G.getWindowLocale('validation:field-required', 'Field is Required')),
};

export const defaultMailSendingFields = {
  [GC.FIELD_MAIL_SENDING_TO]: null,
  [GC.FIELD_MAIL_SENDING_MESSAGE]: null,
  [GC.FIELD_MAIL_SENDING_SUBJECT]: null,
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: null,
};
