import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
// components
import { Table, EmptyList } from '../../../components';
import { getConfirmModal } from '../../../components/confirm';
import { getReportTableOrder } from '../../../common/idb/order/actions';
import { openModal, closeModal } from '../../../components/modal/actions';
import { makeSelectOrderTable } from '../../../common/idb/order/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Text } from '../../../ui';
// feature load-board
import ColumnActions from './column-actions';
import SearchFilterForm from './search-filter-form';
import { getFilterInitialValuesFromData } from '../settings/search-filter-form';
import { makeSelectSearchFilters, makeSelectSearchFilterStates } from '../selectors';
import {
  report,
  tableSettings,
  columnSettings,
  searchFilterOrderParams,
} from '../settings/filter-list';
import {
  toggleSelectSearchFilter,
  toggleSearchFilterDetails,
  deleteSearchFilterRequest,
  createSearchFilterRequest,
  updateSearchFilterRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenEditOrder: (props: Object) => () => {
      const {
        handleEditOrder,
        columnsOrderList,
      } = props;

      const guid = G.getGuidFromObject(report);
      const columnsOrder = R.prop(guid, columnsOrderList);

      const editReport = {
        ...report,
        fields: G.ifElse(G.isNotNilAndNotEmpty(columnsOrder), columnsOrder, report.fields),
      };

      handleEditOrder(editReport, searchFilterOrderParams);
    },
  }),
  withHandlers({
    handleResetAlternative: (props: Object) => (tableProps: Object, currentTarget: Object) => {
      const {
        report,
        openFixedPopup,
        closeFixedPopup,
        handleClickResetByGuid,
      } = tableProps;

      return openFixedPopup({
        zIndex: 1300,
        position: 'right',
        el: currentTarget,
        content: (
          <ColumnActions
            closeFixedPopup={closeFixedPopup}
            reportType={R.prop(GC.FIELD_TYPE, report)}
            handleClickResetByGuid={handleClickResetByGuid}
            handleOpenEditOrder={props.handleOpenEditOrder}
          />
        ),
      });
    },
    handleDeleteFilter: (props: Object) => (guid: string) => {
      const { openModal, closeModal, deleteSearchFilterRequest } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteSearchFilterRequest(guid),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: (
          <Box>
            {
              `${G.getWindowLocale(
                'messages:delete-confirmation-text-double',
                'Are you sure you want to delete',
              )} ${G.getWindowLocale('titles:search-filter', 'Search Filter')}?`
            }
            <Text mt='8px' mb='4px' fontSize={14}>
              {
                `${G.getWindowLocale(
                  'messages:saved-shipments-availability',
                  'Shipments from this filter will still be available in "Saved Shipments"',
                )}`
              }
            </Text>
          </Box>
        ),
      });

      openModal(modalContent);
    },
    handleEditFilter: (props: Object) => (id: string, filter: Object) => {
      const {
        templates,
        openModal,
        closeModal,
        filtersStates,
        handleSaveTemplate,
        availableLoadBoards,
        updateSearchFilterRequest,
        createSearchFilterRequest,
      } = props;

      const templateOptions = R.map(
        ({ name, guid }: Object) => ({ label: name, value: guid }),
      )(templates);

      const loadBoardOptions = R.filter(
        ({ value }: Object) => G.isNotNil(R.find(
          R.propEq(value, GC.FIELD_TYPE),
          availableLoadBoards,
        )),
        GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
      );

      const modal = {
        fixedWidth: true,
        isExpandedContainer: true,
        wrapperStyles: { width: 490 },
        title: G.getWindowLocale('actions:post-equipment', 'Post Equipment'),
        component: (
          <SearchFilterForm
            isFilterEdit={true}
            cancelAction={closeModal}
            templateOptions={templateOptions}
            saveTemplate={handleSaveTemplate}
            loadBoardOptions={loadBoardOptions}
            templates={G.indexByGuid(templates)}
            initialValues={getFilterInitialValuesFromData(filter, filtersStates)}
            handleSave={(values: Object, isUpdate: boolean = false) => {
              if (isUpdate) return updateSearchFilterRequest(values);

              createSearchFilterRequest(values);
            }}
          />
        ),
        options: {
          minWidth: 490,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width: 490, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const FilterList = enhance(({
  itemList,
  columnsOrderList,
  handleEditFilter,
  handleDeleteFilter,
  getReportTableOrder,
  handleResetAlternative,
  toggleSelectSearchFilter,
  toggleSearchFilterDetails,
}: Object) => {
  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEditFilter,
    },
    {
      iconName: 'trash',
      action: handleDeleteFilter,
    },
  ];

  const usedReport = R.clone(report);
  const guid = G.getGuidFromObject(usedReport);
  const columnsOrder = R.prop(guid, columnsOrderList);

  if (G.isNotNilAndNotEmpty(columnsOrder)) {
    usedReport.fields = columnsOrder;
  }

  usedReport.fields = R.prepend({ name: 'notificationsShipments', sequence: 0, freezed: true }, usedReport.fields);

  useEffect(() => {
    getReportTableOrder(guid);
  }, []);

  return (
      G.isNotNilAndNotEmpty(itemList) ? (
        <Table
          loading={false}
          report={usedReport}
          itemList={itemList}
          hasSelectable={false}
          tableSettings={tableSettings}
          actionButtons={actionButtons}
          totalCount={R.length(itemList)}
          columnSettings={columnSettings}
          allChecked={G.isAllChecked(itemList)}
          onOptionClick={toggleSelectSearchFilter}
          handleResetAlternative={handleResetAlternative}
          withResizableColumns={tableSettings.withResizableColumns}
          toggle={({ guid }: Object) => toggleSearchFilterDetails(guid)}
        />
      ) : <EmptyList>{G.getWindowLocale('titles:empty-list', 'Empty List')}</EmptyList>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectSearchFilters(state),
  columnsOrderList: makeSelectOrderTable(state),
  filtersStates: makeSelectSearchFilterStates(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  getReportTableOrder,
  toggleSelectSearchFilter,
  toggleSearchFilterDetails,
  deleteSearchFilterRequest,
  createSearchFilterRequest,
  updateSearchFilterRequest,
})(FilterList);
