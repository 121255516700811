import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { AsyncDocumentPreview } from '../../../components/document-preview';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature master-invoice
import { downloadDocument } from '../actions';
import DocumentNameComponent from './document-name-component';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handlePreviewDocument: (props: Object) => (document: Object) => {
      const fileName = document[GC.FIELD_DOCUMENT_FILE_NAME];
      const fileType = R.last(R.split('.', fileName));
      let modal;
      let component;

      if (G.notContain(fileType, GC.FILE_PREVIEW_SUPPORTED_EXTENSIONS)) {
        component = (
          <Box fontSize={14}>
            {
              G.getWindowLocale(
                'messages:file-not-supported-for-preview',
                'Sorry, file is not supported for preview',
              )
            }
          </Box>
        );
        modal = {
          p: 15,
          component,
          options: {
            width: 400,
            outsideCloseButton: true,
          },
        };
      } else {
        const data = {
          [GC.FIELD_FILE_NAME]: document[GC.FIELD_DOCUMENT_FILE_NAME],
          [GC.FIELD_PRIMARY_OBJECT_GUID]: document[GC.FIELD_PRIMARY_OBJECT_GUID],
        };
        const endpoint = G.ifElse(
          G.isLoadTypeClo(document),
          endpointsMap.cloDocumentDownloadFile,
          endpointsMap.telDocumentDownloadFile,
        );

        component = (
          <AsyncDocumentPreview
            fileType={fileType}
            asyncEndpoint={endpoint}
            asyncOptions={{ params: data, resType: 'arraybuffer' }}
          />
        );

        modal = {
          p: '0',
          component,
          options: {
            width: '95vw',
            height: '95vh',
            title: fileName,
            overflow: 'auto',
            minWidth: '800px',
            outsideCloseButton: true,
          },
        };
      }

      if (G.isFunction(props.onClickCallback)) props.onClickCallback();

      props.openModal(modal);
    },
  }),
  pure,
);

const DocumentList = enhance((props: Object) => {
  const { documents, downloadDocument, handlePreviewDocument } = props;

  return (
    <Flex pb='8px' alignItems='start' flexDirection='column'>
      {
        G.isNotNilAndNotEmpty(documents) &&
        documents.map((document: Object, i: number) => (
          <DocumentNameComponent
            key={i}
            document={document}
            downloadDocument={downloadDocument}
            handlePreviewDocument={handlePreviewDocument}
          />
        ))
      }
    </Flex>
  );
});

export default connect(null, {
  openModal,
  closeModal,
  downloadDocument,
})(DocumentList);
