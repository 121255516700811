import React from 'react';
import * as R from 'ramda';
// components
import { Label } from '../../../components/label';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, CancelButton, ActionButton } from '../../../ui';
//////////////////////////////////////////////////

const commonBtnStyles = {
  ml: 20,
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const getCancelStyles = () => ({
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
});

const getSubmitStyles = () => ({
  ...commonBtnStyles,
  textColor: G.getTheme('colors.white'),
  bgColor: G.getTheme('colors.dark.blue'),
});

export const AlternativeReportButtons = (props: Object) => (
  <Flex
    left='0px'
    height={60}
    bottom='0px'
    width='100%'
    position='fixed'
    style={props.style}
    borderTop='1px solid'
    pl={R.or(props.pl, 60)}
    pr={R.or(props.pr, 15)}
    transition='all .2s linear'
    justifyContent='space-between'
    zIndex={R.or(props.zIndex, 12)}
    bg={G.getTheme('forms.actionsFooter.bgColor')}
    borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
  >
    <Flex>
      <ActionButton
        {...getSubmitStyles()}
        onClick={props.handleUseAction}
      >
        <Label frontIcon={I.play(G.getTheme('icons.iconColorWhite'))} >
          {G.getWindowLocale('actions:use', 'Use')}
        </Label>
      </ActionButton>
      <ActionButton
        {...getSubmitStyles()}
        onClick={props.resetReport}
      >
        {G.getWindowLocale('actions:reset', 'Reset')}
      </ActionButton>
    </Flex>
    <Box>
      <CancelButton {...getCancelStyles()} onClick={props.handleClickCancel}>
        {G.getWindowLocale('actions:cancel', 'Cancel')}
      </CancelButton>
    </Box>
  </Flex>
);
