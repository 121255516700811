export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  useMainColors: true,
  checkBoxCellWidth: 0,
  searchableTitles: true,
  allowSelectItems: false,
};
