import React from 'react';
import * as R from 'ramda';
// features
import { TelDetails } from '../../dispatch-planner-events/components/event-details';
import { sortTelEventsByDate, getNewTelEventsFromRouteTemplate } from '../../dispatch-planner-events/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  checkBoxCellWidth: 85,
  expandableItems: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 100px)',
  checkBoxCellJustifyContent: 'flex-start',
  expandedDetailsComponent: ({ parentProps, callbackData }: Object) => {
    const tels = R.compose(
      R.map((tel: Object) => {
        const telGuid = G.generateGuid();

        const events = getNewTelEventsFromRouteTemplate({
          tel,
          telGuid,
          locations: R.pathOr({}, ['locationList'], callbackData),
        });

        return {
          telGuid,
          [GC.FIELD_LOAD_STOPS]: R.values(sortTelEventsByDate(events)),
        };
      }),
    )(R.pathOr([], [GC.SYSTEM_LIST_TELS], parentProps));

    const telDetails = R.map(
      (tel: Object) => <TelDetails data={tel} key={G.getPropFromObject(GC.FIELD_TEL_GUID, tel)} />,
      tels,
    );

    return <Box>{telDetails}</Box>;
  },
};
