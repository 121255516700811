import * as R from 'ramda';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
/////////////////////////////////////////////////

export const withAsyncGetFleetVendorPayToLocation = compose(
  withHandlers({
    handleGetFleetVendorPayToLocation: (props: Object) => async () => {
      const { selectedRate, setFieldValue } = props;

      const fleetVendorGuid = R.path(['fleetAssignment', 'truck', 'fleetVendorGuid'], selectedRate);

      if (G.isNilOrEmpty(fleetVendorGuid)) return;

      const options = {
        params: {
          fleetVendorGuid,
        },
      };

      const res = await sendRequest('get', endpointsMap.vendorPayToLocation, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setFieldValue(GC.FIELD_PAY_TYPE, G.getPropFromObject(GC.FIELD_PAY_TYPE, data));
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetFleetVendorPayToLocation();
    },
  }),
);
