import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  printByReportRequest,
  setIgnorePromptStatus,
  getXMLByReportRequest,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const getConfigsRequest = createAction('getConfigsRequest');
const getConfigsSuccess = createAction('getConfigsSuccess');
const changeInvoicesRequest = createAction('changeInvoicesRequest');
const changeInvoicesSuccess = createAction('changeInvoicesSuccess');
const createReferenceRequest = createAction('createReferenceRequest');
const createReferenceSuccess = createAction('createReferenceSuccess');
const sendToFinancialRequest = createAction('sendToFinancialRequest');
const updateDriverInvoiceRequest = createAction('updateDriverInvoiceRequest');

export {
  // report
  setReports,
  selectItem,
  printRequest,
  getXMLRequest,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  printByReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  getXMLByReportRequest,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  getConfigsRequest,
  getConfigsSuccess,
  changeInvoicesRequest,
  changeInvoicesSuccess,
  createReferenceRequest,
  createReferenceSuccess,
  sendToFinancialRequest,
  updateDriverInvoiceRequest,
};
