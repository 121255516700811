import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import DimmerComponent from '../../components/loader/dimmer';
import { makeSelectLoader } from '../../components/loader/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { getBranchConfigsByNamesRequest } from '../branch/actions';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectExpandedContainerOptions } from '../expanded-container/selectors';
import { makeSelectMasterSettings, makeSelectCurrentBranchGuid } from '../branch/selectors';
// feature new-do
import ErrorsComponent from '../new-do/components/errors';
import { withNextStartDatesForm } from '../new-do/forms/next-start-dates';
import RouteTelForm, { getTelRouteModalOptions } from '../new-do/forms/route-tel-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import SaveOrderTemplateForm from '../../forms/forms/save-order-template-form';
// ui
import { Box, Flex, PageWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature lite-new-do
import Header from './components/header';
import Footer from './components/footer';
import LeftSection from './components/left-section';
import RightSection from './components/right-section';
import {
  setBranchInfo,
  toggleHotOrder,
  setDivisionGuid,
  cleanNewDOStore,
  setValueToStore,
  setActiveLeftTab,
  setNumberOfLoads,
  setFormDataToStop,
  addNewStopToStore,
  setActiveRightTab,
  setIgnoreWarnings,
  aiRecognizeRequest,
  setFormDataToStore,
  setRatePreviewFiles,
  removeItemFromStore,
  setPrimaryReference,
  removeStopFromStore,
  getBranchDataRequest,
  getBranchListRequest,
  sendDataToApiRequest,
  setDriverRateToStore,
  setCustomerLoadNumber,
  saveAsTemplateRequest,
  removeValidationErrors,
  copyTemplateDataToStore,
  removeValidationWarnings,
  applyOrderRateUpliftRequest,
  recalculateLoadDistancesRequest,
  recalculateLoadDistancesSuccess,
} from './actions';
import {
  makeSelectRate,
  makeSelectStops,
  makeSelectBillTo,
  makeSelectHotOrder,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectDivision,
  makeSelectLoadTotals,
  makeSelectSourceType,
  makeSelectBranchList,
  makeSelectBranchInfo,
  makeSelectBranchGuid,
  makeSelectDriverRate,
  makeSelectAiRecognize,
  makeSelectDivisionGuid,
  makeSelectTemplateData,
  makeSelectMapLocations,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectNumberOfLoads,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectRouteTelConfigs,
  makeSelectPrimaryReference,
  makeSelectValidationErrors,
  makeSelectRatePreviewFiles,
  makeSelectStopPointsStrings,
  makeSelectRouteTemplateGuid,
  makeSelectReferenceFormData,
  makeSelectCustomerLoadNumber,
  makeSelectPrimaryRefTypeGuid,
  makeSelectPrimaryRefTypeName,
  makeSelectValidationWarnings,
  makeSelectCustomerRefTypeName,
  makeSelectSaveAndDuplicateQty,
  makeSelectPrimaryRefSequenceValue,
} from './selectors';
//////////////////////////////////////////////////

const Body = (props: Object) => (
  <Flex
    p='15px 25px'
    flexWrap='wrap'
    alignItems='flex-start'
  >
    <LeftSection {...props} />
    <RightSection {...props} />
  </Flex>
);

const LiteNewDOComponent = (props: Object) => {
  const {
    loader,
    validationErrors,
    setIgnoreWarnings,
    validationWarnings,
  } = props;

  return (
    <PageWrapper
      pb='0'
      overflow='hidden'
      bgColor={G.getTheme('colors.bgGrey')}
      pl={R.pathOr(51, ['pageWrapperPaddingLeft'], props)}
    >
      <Box>
        {loader.showDimmer && <DimmerComponent tableCount={3} rectangleCount={4} />}
        <Box overflow='auto' height='calc(100vh - 60px)'>
          <Header {...props} />
          {
            G.isNotNilAndNotEmpty(validationErrors) &&
            <ErrorsComponent
              type='error'
              errors={validationErrors}
              remove={removeValidationErrors}
              // TODO: check title with Mark ;)
              title='Oops! Looks like there are some mistakes, check the following:'
            />
          }
          {
            G.isNotNilAndNotEmpty(validationWarnings) &&
            <ErrorsComponent
              type='warning'
              errors={validationWarnings}
              remove={removeValidationWarnings}
              showActions={G.isNilOrEmpty(validationErrors)}
              title={G.getWindowLocale('titles:warnings', 'Warnings')}
              actions={[
                {
                  action: () => setIgnoreWarnings(true),
                  text: G.getWindowLocale('actions:ignore-warnings', 'Ignore Warnings'),
                },
              ]}
            />
          }
          <Body {...props} />
        </Box>
        <Footer {...props} />
      </Box>
    </PageWrapper>
  );
};

const enhance = compose(
  withNextStartDatesForm,
  withHandlers({
    handleClickSave: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        branchGuid,
        routeTelConfigs,
        customerLoadNumber,
        sendDataToApiRequest,
      } = props;

      const { autoRouteName, autoTelPrimaryRef, telPrimCopyFromClo } = routeTelConfigs;

      if (G.shouldHideRouteTelModalOnCreateClo(routeTelConfigs)) {
        return sendDataToApiRequest();
      }

      const modalContent = (
        <RouteTelForm
          closeModal={closeModal}
          branchGuid={branchGuid}
          autoRouteName={autoRouteName}
          scopeName={GC.REF_SCOPE_NAME_TEL}
          autoTelPrimaryRef={autoTelPrimaryRef}
          telPrimCopyFromClo={telPrimCopyFromClo}
          customerLoadNumber={customerLoadNumber}
          createLoadRequest={sendDataToApiRequest}
        />
      );

      const modal = getTelRouteModalOptions(modalContent);

      return openModal(modal);
    },
    handleSaveAsTemplate: (props: Object) => () => {
      const { openModal, closeModal, saveAsTemplateRequest } = props;

      const component = (
        <SaveOrderTemplateForm
          submitAction={(data: Object) => {
            closeModal();
            saveAsTemplateRequest(data);
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('actions:create-clo-template', 'Create Order Template'),
        },
      };

      openModal(modal);
    },
    handleClickCancel: (props: Object) => () => {
      const { expandedContainerOptions, closeExpandedContainer } = props;

      if (R.pathEq(true, ['opened'], expandedContainerOptions)) {
        G.callFunctionWithArgs(closeExpandedContainer, true);
      } else {
        G.goToRoute(routesMap.routeListOrder);
      }
    },
    handleChangeSearchInput: (props: Object) => (select: any) => {
      const { cleanNewDOStore, getBranchDataRequest } = props;

      if (R.isNil(select)) {
        return cleanNewDOStore({ sourceType: GC.CREATE_DO_SOURCE_TYPE_MANUAL });
      }

      const branchGuid = select.value;

      cleanNewDOStore({
        [GC.FIELD_GUID]: branchGuid,
        [GC.FIELD_BRANCH_NAME]: select.label,
        sourceType: GC.CREATE_DO_SOURCE_TYPE_MANUAL,
      });

      getBranchDataRequest(branchGuid);
    },
    handleChangeBranchRefTypeInput: ({ setPrimaryReference }: Object) => (select: any) => (
      setPrimaryReference(select)
    ),
    handleChangeCustomerLoadNumberInput: ({ setCustomerLoadNumber }: Object) => (value: string) => (
      setCustomerLoadNumber(value)
    ),
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  rate: makeSelectRate(state),
  stops: makeSelectStops(state),
  billTo: makeSelectBillTo(state),
  loader: makeSelectLoader(state),
  loadType: makeSelectLoadType(state),
  pageType: makeSelectPageType(state),
  division: makeSelectDivision(state),
  hotOrder: makeSelectHotOrder(state),
  branchGuid: makeSelectBranchGuid(state),
  loadTotals: makeSelectLoadTotals(state),
  branchList: makeSelectBranchList(state),
  branchInfo: makeSelectBranchInfo(state),
  sourceType: makeSelectSourceType(state),
  driverRate: makeSelectDriverRate(state),
  aiRecognize: makeSelectAiRecognize(state),
  divisionGuid: makeSelectDivisionGuid(state),
  templateData: makeSelectTemplateData(state),
  mapLocations: makeSelectMapLocations(state),
  numberOfLoads: makeSelectNumberOfLoads(state),
  leftActiveTad: makeSelectLeftActiveTad(state),
  branchConfigs: makeSelectBranchConfigs(state),
  masterSettings: makeSelectMasterSettings(state),
  rightActiveTad: makeSelectRightActiveTad(state),
  branchRefTypes: makeSelectBranchRefTypes(state),
  routeTelConfigs: makeSelectRouteTelConfigs(state),
  validationErrors: makeSelectValidationErrors(state),
  primaryReference: makeSelectPrimaryReference(state),
  ratePreviewFiles: makeSelectRatePreviewFiles(state),
  stopPointsStrings: makeSelectStopPointsStrings(state),
  routeTemplateGuid: makeSelectRouteTemplateGuid(state),
  referenceFormData: makeSelectReferenceFormData(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  customerLoadNumber: makeSelectCustomerLoadNumber(state),
  primaryRefTypeGuid: makeSelectPrimaryRefTypeGuid(state),
  primaryRefTypeName: makeSelectPrimaryRefTypeName(state),
  validationWarnings: makeSelectValidationWarnings(state),
  saveAndDuplicateQty: makeSelectSaveAndDuplicateQty(state),
  customerRefTypeName: makeSelectCustomerRefTypeName(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  primaryRefSequenceValue: makeSelectPrimaryRefSequenceValue(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  setBranchInfo,
  toggleHotOrder,
  setDivisionGuid,
  setValueToStore,
  cleanNewDOStore,
  setActiveLeftTab,
  setNumberOfLoads,
  setFormDataToStop,
  addNewStopToStore,
  setActiveRightTab,
  setIgnoreWarnings,
  aiRecognizeRequest,
  setFormDataToStore,
  setRatePreviewFiles,
  setPrimaryReference,
  removeStopFromStore,
  removeItemFromStore,
  getBranchListRequest,
  sendDataToApiRequest,
  getBranchDataRequest,
  setDriverRateToStore,
  setCustomerLoadNumber,
  saveAsTemplateRequest,
  removeValidationErrors,
  copyTemplateDataToStore,
  removeValidationWarnings,
  applyOrderRateUpliftRequest,
  getBranchConfigsByNamesRequest,
  recalculateLoadDistancesRequest,
  recalculateLoadDistancesSuccess,
})(enhance(LiteNewDOComponent));
