import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as GC from '../../../../constants';
// ui
import { Flex } from '../../../../ui';
//////////////////////////////////////////////////

const RowContent = (props: Object) => (
  <Flex
    p={props.p}
    width='100%'
    fontSize={11}
    mb={R.or(props.mb, '6px')}
    justifyContent={R.or(props.jc, 'space-between')}
  >
    {
      props.options.map((option: Object, index: number) => (
        <TextComponent
          title={option.text}
          withEllipsis={true}
          {...option.textProps}
          key={`${option.text}-${index}`}
        >
          {R.pathOr(option.text, ['text', GC.FIELD_DISPLAYED_VALUE], option) }
        </TextComponent>
      ))
    }
  </Flex>
);

export default RowContent;
