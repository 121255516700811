import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../../components/table';
import { FormFooter2 } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { MAIL_SENDING_REPLY_TO_OPTIONS } from '../../../../helpers/options';
// hocs
import {
  withAsyncConfigs,
  withAsyncAvailableDocumentTypes,
  withAsyncMailSendingIntegrationExists,
} from '../../../../hocs';
// forms
import { Fieldset2 } from '../../../../forms';
import MultiEmailsForm from '../../../../forms/forms/multi-emails-form';
// ui
import { RelativeBox } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature customer-invoice
import { printAndSendEmailsColumnSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const fields = [
  `${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
  GC.FIELD_INVOICE_NUMBER,
  GC.FIELD_EMAILS,
  GC.FIELD_INVOICE_DATE,
  GC.GRC.STATUS_DISPLAYED_VALUE,
  GC.GRC.BILL_TO_PAYMENT_TERM,
  GC.FIELD_TOTAL,
  GC.FIELD_TOTAL_TRIP_DISTANCE,
  GC.FIELD_TOTAL_TRIP_WEIGHT,
  GC.GRC.MODE_DISPLAYED_VALUE,
  GC.SYSTEM_OBJECT_FIRST_EVENT,
  GC.SYSTEM_OBJECT_LAST_EVENT,
  GC.FIELD_COMMENTS,
];

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), fields),
};

const tableSettings = {
  tableRowHeight: 30,
  titleRowHeight: 30,
  useMainColors: true,
  checkBoxCellWidth: 50,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 350px)',
};

const inputWrapperStyles = {
  mr: 25,
  width: 200,
};

const getFieldSettings = (disabledSendEmails: boolean) => [
  {
    type: 'toggle',
    disabled: disabledSendEmails,
    label: ['titles:send-emails'],
    inputWrapperStyles: { mr: 25 },
    fieldName: GC.FIELD_SEND_EMAILS,
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:template'],
    fieldName: GC.FIELD_TEMPLATE_GUID,
    options: 'documentTemplateOptions',
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    useMultiSelectMaxHeight: true,
    options: 'documentTypeOptions',
    fieldName: GC.FIELD_DOCUMENT_TYPE_GUIDS,
    label: ['titles:default-document-types'],
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'vendorDocumentTypeOptions',
    label: ['titles:vendor-document-type'],
    fieldName: GC.FIELD_VENDOR_DOCUMENT_TYPE_GUIDS,
  },
  {
    type: 'select',
    label: ['titles:reply-to'],
    fieldName: GC.FIELD_MAIL_SENDING_REPLY_TO,
    options: G.addEmptyOptionToDropDown(MAIL_SENDING_REPLY_TO_OPTIONS),
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: (props: Object) => G.ifElse(
        R.pathEq(true, ['values', GC.FIELD_SEND_EMAILS], props),
        'block',
        'none',
      ),
    },
  },
];

const InvoicesTable = (props: Object) => {
  const {
    itemList,
    sendEmails,
    handleChangeEmails,
  } = props;

  const callbackData = {
    sendEmails,
    handleChangeEmails,
  };

  const data = {
    report,
    itemList,
    callbackData,
    tableSettings,
    columnSettings: printAndSendEmailsColumnSettings,
  };

  return <RelativeBox zIndex='0'><Table {...data} /></RelativeBox>;
};

const PrintAndSendEmails = (props: Object) => {
  const {
    values,
    closeModal,
    handleSubmit,
    asyncConfigs,
    handleChangeEmails,
    documentTemplateOptions,
    mailSendingIntegrationExists,
    availableDocumentTypesOptionsAsync,
  } = props;

  const disabledSendEmails = G.isNilOrEmpty(mailSendingIntegrationExists);

  const sendEmails = R.and(R.prop(GC.FIELD_SEND_EMAILS, values), G.isFalse(disabledSendEmails));

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getFieldSettings(disabledSendEmails)}
        documentTemplateOptions={documentTemplateOptions}
        fieldsWrapperStyles={{ p: '25px 20px 15px 20px' }}
        documentTypeOptions={availableDocumentTypesOptionsAsync}
        vendorDocumentTypeOptions={G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
          asyncConfigs,
          GC.CARRIER_DOCUMENT_TYPE,
        )}
      />
      <InvoicesTable
        closeModal={closeModal}
        sendEmails={sendEmails}
        handleChangeEmails={handleChangeEmails}
        itemList={R.propOr([], 'invoiceList', values)}
      />
      <FormFooter2
        boxStyles={{ p: '10px 30px' }}
        submitBtnText={G.getWindowLocale(...G.ifElse(
          sendEmails,
          ['titles:send-emails', 'Send Emails'],
          ['titles:download', 'Download'],
        ))}
      />
    </form>
  );
};

const enhance = compose(
  withAsyncConfigs,
  withAsyncAvailableDocumentTypes,
  withAsyncMailSendingIntegrationExists,
  withFormik({
    mapPropsToValues: () => ({
      [GC.FIELD_TEMPLATE_GUID]: null,
    }),
    validationSchema: Yup.object().shape({
      [GC.FIELD_TEMPLATE_GUID]: G.yupStringRequired,
    }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      const { invoiceList } = values;

      const invoiceGuids = R.map(G.getGuidFromObject, invoiceList);

      const emailsMap = R.reduce(
        (acc: Object, { guid, emails }: Object) => R.assoc(guid, emails, acc),
        {},
        invoiceList,
      );

      const data = R.mergeRight(R.dissoc('invoiceList', values), { emailsMap, invoiceGuids });

      submitAction(data);
    },
  }),
  withHandlers({
    handleChangeEmails: (props: Object) => ({ emails, rowIndex }: Object) => {
      const { openModal, closeModal, setFieldValue } = props;

      const submitAction = ({ emails }: Object) => {
        closeModal();
        setFieldValue(`invoiceList.${rowIndex}.emails`, emails);
      };

      const component = (
        <MultiEmailsForm initialValues={{ emails }} submitAction={submitAction} />
      );

      const modal = {
        p: 15,
        component,
        options: {
          maxHeight: '87vh',
        },
      };

      openModal(modal);
    },
    getCustomerInvoiceListByGuids: (props: Object) => async () => {
      const { guids, openLoader, closeLoader, setFieldValue } = props;

      openLoader();

      const fields = [
        GC.FIELD_TOTAL,
        GC.FIELD_COMMENTS,
        GC.FIELD_CURRENCY,
        GC.FIELD_CLO_GUID,
        'billTo.emailsString',
        GC.FIELD_INVOICE_DATE,
        GC.FIELD_INVOICE_NUMBER,
        GC.FIELD_TOTAL_TRIP_WEIGHT,
        GC.GRC.MODE_DISPLAYED_VALUE,
        GC.GRC.BILL_TO_PAYMENT_TERM,
        GC.FIELD_TOTAL_TRIP_DISTANCE,
        GC.GRC.STATUS_DISPLAYED_VALUE,
        GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
        GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
        GC.GRC.CLO_LAST_EVENT_LOCATION_CITY,
        GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY,
        GC.GRC.CLO_LAST_EVENT_LOCATION_STATE,
        GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE,
        `${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
      ];

      const options = {
        data: {
          guids,
          searchCriteria: [],
          fields: R.map((name: string) => ({ name }), fields),
          [GC.FIELD_CURRENT_BRANCH]: G.getAmousCurrentBranchGuidFromWindow(),
        },
      };

      const res = await sendRequest('post', endpointsMap.customerInvoiceList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const mappedList = R.map((item: Object) => {
          const emailsString = R.prop('billTo.emailsString', item);

          let emails = [];

          if (G.isString(emailsString)) emails = R.split(',', emailsString);

          return R.assoc(GC.FIELD_EMAILS, emails, item);
        }, R.propOr([], 'results', data));

        setFieldValue('invoiceList', mappedList);
      } else {
        G.handleFailResponseSimple(res, 'getCustomerInvoiceListByGuids fail');
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getCustomerInvoiceListByGuids();
    },
  }),
  pure,
);

export default enhance(PrintAndSendEmails);
