import React from 'react';
// helpers
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const TotalRow = ({ label, rowTotal = 0, wrapperStyles, payrollCurrency }: Object) => (
  <Flex {...wrapperStyles}>
    <Box width={170}>{`${label}:`}</Box>
    {
      G.isNotNil(rowTotal) &&
      <Box ml='auto' width={100}>
        {payrollCurrency} {G.toFixed(rowTotal)}
      </Box>
    }
  </Flex>
);

export default TotalRow;
