import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms/formik';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature lite-new-do
import { contactFieldSettings, getDefaultContactFields } from '../settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const Header = ({ push }: Object) => (
  <Flex px={10} height={30} bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:contacts', 'Contacts')}
    </Box>
    <Box
      mx={10}
      height={15}
      cursor='pointer'
      onClick={() => push(getDefaultContactFields())}
      title={G.getWindowLocale('titles:add-new-item', 'Add new item')}
    >
      {I.plusRound(darkBlueColor)}
    </Box>
  </Flex>
);

const ContactRow = (props: Object) => {
  const {
    remove,
    itemIndex,
    arrayLength,
  } = props;

  return (
    <Flex my={25}>
      <Box>
        <Flex
          mx={10}
          display='flex'
          cursor='pointer'
          alignItems='center'
          onClick={() => remove(itemIndex)}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(darkBlueColor)}
        </Flex>
      </Box>
      <Fieldset2
        {...G.getFormikProps(props)}
        {...G.getArrayFormikProps(props)}
        arrayLength={arrayLength}
        fields={contactFieldSettings}
        arrayName={GC.FIELD_CONTACTS}
      />
    </Flex>
  );
};

const ContactsArray = (props: Object) => {
  const { contacts } = props;

  return (
    <Box mt={20}>
      <FieldArray
        name={GC.FIELD_CONTACTS}
        render={(arrayHelpers: Object) => (
          <Box>
            <Header push={arrayHelpers.push} />
            {
              G.isNotNilAndNotEmpty(contacts) &&
              contacts.map((_: any, index: number) => (
                <ContactRow
                  {...props}
                  {...arrayHelpers}
                  key={index}
                  itemIndex={index}
                  arrayLength={R.length(contacts)}
                />
              ))
            }
          </Box>
        )}
      />
    </Box>
  );
};

export default ContactsArray;
