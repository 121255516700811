import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withAsyncInvoiceStatusConfigs } from '../../../hocs';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleSubmit: (props: Object) => (status: string) => {
      props.submitAction({ status });
      props.closeModal();
    },
  }),
  withAsyncInvoiceStatusConfigs,
  pure,
);

const StatusForm = ({ optionsName, handleSubmit, ...props }: Object) => (
  <SelectDropdownForm
    {...props}
    submitAction={handleSubmit}
    fieldLabel={['titles:status']}
    options={G.mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject(R.propOr([], optionsName, props))}
  />
);

export default enhance(StatusForm);
