import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectMenuStore = (state: Object) => state.menu;

const makeSelectNavigation = () => createSelector(
  selectMenuStore,
  ({ menus }: Object) => menus.navigationMenuItems,
);

const makeSelectSidebar = () => createSelector(
  selectMenuStore,
  ({ menus }: Object) => menus.sideMenuItems,
);

const makeSelectOpenedSidebar = () => createSelector(
  selectMenuStore,
  ({ openedSidebar }: Object) => openedSidebar,
);

const makeSelectNavItems = () => createSelector(
  selectMenuStore,
  ({ navItems }: Object) => navItems,
);

const makeSelectDropdownItems = () => createSelector(
  selectMenuStore,
  ({ dropdownItems }: Object) => dropdownItems,
);

const makeSelectSubmenusStatus = () => createSelector(
  selectMenuStore,
  ({ subMenus }: Object) => subMenus,
);


export {
  selectMenuStore,
  makeSelectSidebar,
  makeSelectNavItems,
  makeSelectNavigation,
  makeSelectOpenedSidebar,
  makeSelectDropdownItems,
  makeSelectSubmenusStatus,
};
