import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
// feature load-planner-template
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  itemList: {},
  locations: {},
  loading: false,
};

const setInitialState = () => initialState;

const getItemListSuccess = (state: Object, data: Array) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    G.indexByGuid,
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        index,
        expanded: false,
        disabled: G.isFalse(G.getPropFromObject('editable', item)),
      },
    )),
  )(data);

  return P.$set('itemList', list, state);
};

const removeLoadPlannerTemplateSuccess = (state: Object, guid: string) => (
  P.$drop(`itemList.${guid}`, state)
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const toggleLoadPlannerTemplateDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`itemList.${guid}.expanded`, state)
);

const getCrossDockLocationsSuccess = (state: Object, data: Array) => {
  if (G.isNilOrEmpty(data)) return state;

  const locations = G.indexByGuid(data);

  return P.$set('locations', locations, state);
};

export default createReducer({
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.getCrossDockLocationsSuccess]: getCrossDockLocationsSuccess,
  [A.toggleLoadPlannerTemplateDetails]: toggleLoadPlannerTemplateDetails,
  [A.removeLoadPlannerTemplateSuccess]: removeLoadPlannerTemplateSuccess,
}, initialState);
