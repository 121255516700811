import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement, ActionsElement2 } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withHandlers({
    handleEditInvoices: ({ openUpdateInvoices, closeFixedPopup }: Object) => () => {
      openUpdateInvoices();
      closeFixedPopup();
    },
    handleGetXML: ({ data, handleGetXMLRequest, closeFixedPopup }: Object) => () => {
      handleGetXMLRequest({
        masterInvoiceGuid: data.guid,
        fileName: data.masterInvoiceNumber,
      });
      closeFixedPopup();
    },
    handleSendToQuickBook: ({ handleSendToQuickBookRequest, closeFixedPopup }: Object) => () => {
      handleSendToQuickBookRequest();
      closeFixedPopup();
    },
    handleSendToSageIntacctRequest: ({ data, closeFixedPopup, sendToSageIntacctRequest }: Object) => () => {
      sendToSageIntacctRequest(G.getGuidFromObject(data));
      closeFixedPopup();
    },
    handleQBIIFImport: ({ closeFixedPopup, qbIIFImportRequest }: Object) => () => {
      qbIIFImportRequest();
      closeFixedPopup();
    },
    handlePrint: ({ closeFixedPopup, handlePrintMasterInvoice }: Object) => () => {
      handlePrintMasterInvoice();
      closeFixedPopup();
    },
    handlePrintInvoices: ({ closeFixedPopup, handlePrintCloInvoices }: Object) => () => {
      handlePrintCloInvoices();
      closeFixedPopup();
    },
    handleGetTriumphExcel: ({ data, closeFixedPopup, handleGenerateFactoringFileByFactoringType }: Object) => () => {
      handleGenerateFactoringFileByFactoringType({
        masterInvoiceGuid: data.guid,
        fileExtension: GC.EXTENSION_XLSX,
        fileName: data.masterInvoiceNumber,
        factoringType: GC.FACTORING_COMPANY_TYPE_TRIUMPH,
      });
      closeFixedPopup();
    },
    handleGetCompassExcel: ({ data, closeFixedPopup, handleGenerateFactoringFileByFactoringType }: Object) => () => {
      handleGenerateFactoringFileByFactoringType({
        masterInvoiceGuid: data.guid,
        fileExtension: GC.EXTENSION_XLSX,
        fileName: data.masterInvoiceNumber,
        factoringType: GC.FACTORING_COMPANY_TYPE_COMPASS,
      });
      closeFixedPopup();
    },
    handleGetOtrCapitalCsv: ({ data, closeFixedPopup, handleGenerateFactoringFileByFactoringType }: Object) => () => {
      handleGenerateFactoringFileByFactoringType({
        masterInvoiceGuid: data.guid,
        fileExtension: GC.EXTENSION_CSV,
        fileName: data.masterInvoiceNumber,
        factoringType: GC.FACTORING_COMPANY_TYPE_OTR_CAPITAL,
      });
      closeFixedPopup();
    },
    handleGetRTSCsv: ({ data, closeFixedPopup, handleGenerateFactoringFileByFactoringType }: Object) => () => {
      handleGenerateFactoringFileByFactoringType({
        fileExtension: GC.EXTENSION_CSV,
        fileName: data.masterInvoiceNumber,
        masterInvoiceGuid: G.getGuidFromObject(data),
        factoringType: GC.FACTORING_COMPANY_TYPE_RTS_FINANCIAL,
      });

      closeFixedPopup();
    },
    handleShowDocuments: ({ closeFixedPopup, showDocuments }: Object) => () => {
      showDocuments();
      closeFixedPopup();
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    handlePrint,
    handleGetXML,
    handleShowAudit,
    handleGetRTSCsv,
    handleQBIIFImport,
    handleEditInvoices,
    handlePrintInvoices,
    handleShowDocuments,
    handleSendToQuickBook,
    handleGetTriumphExcel,
    handleGetCompassExcel,
    handleGetOtrCapitalCsv,
    handleSendToSageIntacctRequest,
    handleExportCustomerMasterInvoiceToEDI,
  } = props;

  const iconStyles = [iconColor, 15, 17];

  const permissions = G.ifElse(
    G.isTrue(R.path(['data', 'completed'], props)),
    [PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE],
    [PC.CLO_MASTER_INVOICE_WRITE, PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE],
  );

  return ([
    {
      permissions,
      action: handleEditInvoices,
      frontIcon: I.pencil(iconColor),
      text: G.getWindowLocale('actions:update-invoices', 'Update Invoices'),
    },
    {
      action: handleGetXML,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      action: handlePrint,
      frontIcon: I.printer(iconColor),
      text: G.getWindowLocale('actions:print-master-invoice', 'Print Master Invoice'),
    },
    {
      action: handlePrintInvoices,
      frontIcon: I.printer(iconColor),
      text: G.getWindowLocale('actions:print-invoices', 'Print Invoices'),
    },
    {
      action: handleSendToQuickBook,
      frontIcon: I.quickbook(iconColor),
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      frontIcon: I.gear(iconColor, 16, 16),
      action: handleSendToSageIntacctRequest,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
      permissions: [
        PC.CLO_MASTER_INVOICE_WRITE,
        PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      action: handleQBIIFImport,
      frontIcon: I.quickbook(iconColor, 16, 16),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
      permissions: [
        PC.CLO_MASTER_INVOICE_READ,
        PC.CLO_MASTER_INVOICE_WRITE,
        PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      frontIcon: I.gear(iconColor, 16, 16),
      action: handleExportCustomerMasterInvoiceToEDI,
      text: G.getWindowLocale('actions:export-to-edi', 'Export to EDI'),
      permissions: [
        PC.CLO_MASTER_INVOICE_READ,
        PC.CLO_MASTER_INVOICE_WRITE,
        PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      action: handleGetTriumphExcel,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:generate-triumph-excel', 'Generate Triumph Excel'),
    },
    {
      action: handleGetCompassExcel,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:generate-compass-excel', 'Generate Compass Excel'),
    },
    {
      action: handleGetOtrCapitalCsv,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:otr-capital-csv', 'OTR Capital CSV'),
    },
    {
      action: handleGetRTSCsv,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:rts-csv', 'RTS Financial CSV'),
    },
    {
      action: handleShowDocuments,
      frontIcon: I.documents(iconColor, 15, 17),
      text: G.getWindowLocale('actions:show-documents', 'Show Documents'),
    },
    {
      action: handleShowAudit,
      text: G.getWindowLocale('titles:audit', 'Audit'),
      frontIcon: I.renderAuditHistoryIcon(iconColor, 15, 17),
    },
  ]);
};

const getHeaderOptions = (props: Object) => {
  const {
    handleShowAudit,
    handleGetXMLRequest,
    handlePrintMasterInvoice,
  } = props;

  const iconStyles = [iconColor, 15, 17];

  const options = [
    {
      iconMl: 10,
      withTitle: true,
      action: handlePrintMasterInvoice,
      frontIcon: I.printer(iconColor),
      text: G.getWindowLocale('actions:print-master-invoice', 'Print Master Invoice'),
    },
    {
      action: handleGetXMLRequest,
      frontIcon: I.downloadTemplate(...iconStyles),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      action: handleShowAudit,
      text: G.getWindowLocale('titles:audit', 'Audit'),
      frontIcon: I.renderAuditHistoryIcon(iconColor, 15, 17),
    },
  ];

  return options;
};

const HeaderActions = (props: Object) => <ActionsElement2 options={getHeaderOptions(props)} />;

const RowActions = enhance((props: Object) => (
  <ActionsElement options={getOptions(props)} />
));

export {
  RowActions,
  HeaderActions,
};
