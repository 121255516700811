import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
// components
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// features
import PC from '../../permission/role-permission';
// feature load-board
import LBResultsList from './lb-results-list';
import { deleteSavedShipmentRequest } from '../actions';
import { savedShipmentReport } from '../settings/field-settings';
import { bookmarksTableSettings } from '../settings/table-settings';
import { searchResultsColumnSettings } from '../settings/result-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleDeleteSavedShipment: (props: Object) => (shipment: Object) => {
      const { openModal, closeModal, deleteSavedShipmentRequest } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteSavedShipmentRequest(shipment),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getWindowLocale('titles:saved-shipment', 'Saved Shipment')}?`,
      });

      openModal(modalContent);
    },
  }),
  pure,
);
const SavedShipments = enhance((props: Object) => {
  const {
    itemList,
    handleDeleteSavedShipment,
  } = props;

  const actionButtons = [
    {
      iconName: 'trash',
      permissions: [PC.ANY],
      action: (guid: string, shipment: Object) => handleDeleteSavedShipment(shipment),
    },
  ];

  const tableSettings = {
    ...bookmarksTableSettings,
    actionButtons,
    withCheckbox: false,
    withLeftActions: true,
  };

  const bookingEnabled = R.any(
    R.compose(
      R.flip(R.includes)(GC.EXTERNAL_LOAD_BOARDS_TO_BOOK),
      R.prop(GC.FIELD_TYPE),
    ),
    itemList,
  );

  const report = R.clone(savedShipmentReport);

  if (bookingEnabled) {
    report.fields.unshift({ name: 'bookingAction', sequence: 0 });
  }

  return (
    <LBResultsList
      loading={false}
      report={report}
      itemList={itemList}
      hasSelectable={false}
      tableSettings={tableSettings}
      totalCount={R.length(itemList)}
      columnSettings={searchResultsColumnSettings}
    />
  );
});

export default connect(null, {
  openModal,
  closeModal,
  deleteSavedShipmentRequest,
})(SavedShipments);

