import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature master-invoice
import * as LC from './constants';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
import { getChargeFields } from './components/charges';
import { chargeInitFields } from './settings/master-invoice-with-charges-settings';
//////////////////////////////////////////////////

export const createCharge = () => ({
  id: G.genShortId(),
  ...chargeInitFields,
});

export const getChargeFieldName = R.compose(
  R.last(),
  R.split('.'),
);

export const isRateOrQuantityChargeField = (fieldName: string) => R.or(
  R.equals(GC.FIELD_CHARGE_RATE, fieldName),
  R.equals(GC.FIELD_CHARGE_QUANTITY, fieldName),
);

export const recalculateChargesOnChangeAccessorial = ({
  value,
  values,
  chargeIndex,
  glCodeMappings,
  initChargeFields,
  accessorialsConfigs,
}: Object) => R.update(
  chargeIndex,
  getChargeFields(value, accessorialsConfigs, R.mergeRight(createCharge(), initChargeFields), glCodeMappings),
  R.pathOr([], [GC.FIELD_MASTER_INVOICE_CHARGES], values),
);

export const getInitialDates = () => ({
  dateTo: G.getCurrentDay(),
  dateFrom: G.getStartOfMonthDay(),
});

export const loadInvoices = async function (props: Object, group: Object, documentTypeGuids: Array) {
  const options = {
    data: {
      documentTypeGuids,
      invoiceGuids: group.invoiceGuids,
      [GC.FIELD_BRANCH_GUID]: props.branchGuid,
    },
  };
  const res = await sendRequest('post', endpointsMap.customerMasterInvoiceInvoices, options);
  const { data, status } = res;
  let groupState = group;
  if (G.isResponseSuccess(status)) {
    const initialInvoicesProps = [{
      name: 'selected',
      value: G.isTrue(group.selected),
    }];
    groupState = R.mergeRight(
      groupState,
      {
        invoicesLoading: false,
        invoices: R.indexBy(
          R.prop('guid'),
          G.setPropsWithValuesToArrayItems(
            data,
            initialInvoicesProps,
          ),
        ),
      },
    );
  } else {
    groupState = R.mergeRight(
      groupState,
      {
        invoicesError: true,
        invoicesLoading: false,
      },
    );
  }
  props.updateGroups(R.assoc(group.groupName, groupState, props.groups));
};

export const getGroupsOnToggleGroupSelect = (group: Object, groups: Object) => R.assocPath(
  [group.groupName, 'selected'],
  R.not(group.selected),
  groups,
);

export const getGroupsOnToggleSelectAll = (groups: Object, checked: boolean) => R.indexBy(
  R.prop('groupName'), R.map(
    (group: Object) => ({
      ...group,
      'selected': R.not(checked),
    }),
    R.values(groups),
));

export const getGroupedDataOnToggleInvoiceSelect = (data: string, invoice: Object, invoicesData: Object) => {
  let updatedInvoicesData;
  if (R.equals(data, 'all')) {
    const allSelected = R.all(
      R.prop('selected'),
      R.values(invoicesData.invoices),
    );
    updatedInvoicesData = R.assoc(
      'invoices',
      R.map(
        R.assoc('selected', R.not(allSelected)),
        invoicesData.invoices,
      ),
      invoicesData,
    );
  } else {
    updatedInvoicesData = R.assocPath(
      ['invoices', invoice.guid, 'selected'],
      R.not(invoice.selected),
      invoicesData,
    );
  }
  return R.mergeRight(
    updatedInvoicesData,
    {
      allSelected: R.all(
        R.prop('selected'),
        R.values(updatedInvoicesData.invoices),
      ),
      invoicesTotal: R.sum(R.map(
        (inv: Object) => G.ifElse(
          inv.selected,
          inv.total,
          0,
        ),
        R.values(updatedInvoicesData.invoices),
      )),
    },
  );
};

export const getUpdatedInvoices = ({ invoices }: Object, updatedInvoice: Object) => R.map(
  (invoice: Object) => {
    if (R.eqProps('guid', invoice, updatedInvoice)) {
      return R.mergeRight(
        invoice,
        R.pick(
          [
            GC.FIELD_MODE,
            GC.FIELD_CURRENCY,
            GC.FIELD_INVOICE_DATE,
            GC.FIELD_CHARGE_TOTAL,
            GC.FIELD_INVOICE_STATUS,
            GC.FIELD_TOTAL_TRIP_WEIGHT,
            GC.FIELD_TOTAL_TRIP_DISTANCE,
            GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
            GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
          ],
          updatedInvoice,
        ),
      );
    }
    return invoice;
  },
  invoices,
);

export const recalculateGroupInvoices = (groups: Array, updatedInvoice: Object) => R.map(
  (group: Object) => {
    if (group.expanded) {
      const invoices = getUpdatedInvoices(group, updatedInvoice);
      const invoicesTotal = R.sum(R.map(
        (inv: Object) => G.ifElse(
          inv.selected,
          inv.total,
          0,
        ),
        R.values(invoices),
      ));

      return R.mergeRight(group, { invoices, invoicesTotal });
    }
    return group;
  },
  groups,
);

export const convertMasterInvoice = (group: Object) => R.assoc(
  'invoiceGuids',
  G.ifElse(
    group.allSelected,
    group.invoiceGuids,
    R.map(
      (p: Object) => p.guid,
      R.filter(
        (invoice: Object) => invoice.selected,
        R.values(group.invoices),
      ),
    ),
  ),
  R.pick([
    GC.BRANCH_GUID,
    GC.FIELD_DATE_TO,
    GC.FIELD_GROUP_BY,
    GC.FIELD_DATE_FROM,
    LC.FIELD_GROUP_NAME,
    GC.FIELD_MASTER_INVOICE_NUMBER,
    LC.FIELD_GROUP_BY_REFERENCE_VALUE,
  ], group),
);

export const getMasterInvoiceRequestData = (groups: Object) => (
  R.map(convertMasterInvoice, groups)
);

export const validateCreateMasterInvoice = (groups: Object) => R.all(
  (group: Object) => R.or(group.autoNumber, G.isNotNilAndNotEmpty(group.masterInvoiceNumber)),
  groups,
);

export const getUpdateMasterInvoiceRequestData = (data: Object) => (
  {
    masterInvoiceGuid: data.guid,
    invoiceGuids: R.map(
      (invoice: Object) => invoice.guid,
      R.filter(({ selected }: Object) => selected, R.values(data.invoices)),
    ),
  }
);

export const composeObjectPropsToString = (
  omitFields: Array = [],
  withoutValues: Array = [],
  joiner: string = '',
) => R.compose(
  R.toLower(),
  R.join(joiner),
  R.without(withoutValues),
  R.values(),
  R.omit(omitFields),
);

export const mapListObjectPropsToPropString = (filterPropName: string, composeProps: Object) => R.map(
  (item: Object) => R.assoc(
    filterPropName,
    composeObjectPropsToString(
      R.prop('omitFields', composeProps),
      R.prop('withoutValues', composeProps),
      R.prop('joiner', composeProps),
    )(R.values(item)),
    item,
  ),
);

export const filterListOfObjectsByPropContains = (
  filterString: string,
  filterPropName: string,
) => R.filter(
  (item: Object) => R.propSatisfies((prop: string) => R.includes(R.toLower(filterString), prop), filterPropName, item),
);

export const getInvoiceChargesFromResponse = (charges: Array = []) => ({
  mainChargesTotal: G.getChargeTotalFromCharges(charges, GC.CHARGE_TYPE_MAIN),
  fuelChargesTotal: G.getChargeTotalFromCharges(charges, GC.CHARGE_TYPE_FUEL),
  additionalChargesTotal: G.getChargeTotalFromCharges(charges, GC.CHARGE_TYPE_ADDITIONAL),
});

const initialGroupProps = [
  { name: 'selected', value: true },
  { name: 'expanded', value: false },
  { name: 'allSelected', value: true },
  { name: 'invoicesError', value: false },
  { name: 'invoicesLoading', value: false },
  { name: 'willNotBeAddedText', value: null },
  { name: 'masterInvoiceNumber', value: null },
];

const createNoGroupData = (invoices: Array, { dateTo, dateFrom }: Object, branchGuid: string, autoNumber: boolean) => ({
  [LC.GROUP_BY_NO_GROUP]: {
    dateTo,
    dateFrom,
    autoNumber,
    expanded: true,
    selected: true,
    allSelected: true,
    invoicesError: false,
    invoicesLoading: false,
    willNotBeAddedText: null,
    masterInvoiceNumber: null,
    groupBy: LC.GROUP_BY_NO_GROUP,
    groupName: LC.GROUP_BY_NO_GROUP,
    invoicesCount: R.length(invoices),
    [GC.FIELD_BRANCH_GUID]: branchGuid,
    invoiceGuids: R.map(
      ({ guid }: Object) => guid,
      invoices,
    ),
    invoicesTotal: R.sum(R.map(
      (invoice: Object) => invoice.total,
      invoices,
    )),
    invoices: R.indexBy(
      R.prop('guid'),
      G.setPropsWithValuesToArrayItems(
        invoices,
        [{ name: 'selected', value: true }],
      ),
    ),
  },
});

export const createInitialData = (
  data: Array,
  groupBy: string,
  { dateTo, dateFrom }: Object,
  branchGuid: string,
  configs: Object,
  autoNumber: boolean,
) => {
  if (R.isNil(data)) return data;
  if (R.equals(groupBy, LC.GROUP_BY_NO_GROUP)) {
    return createNoGroupData(data, { dateTo, dateFrom }, branchGuid, autoNumber);
  }
  return R.indexBy(
    R.prop('groupName'),
    G.setPropsWithValuesToArrayItems(
      data.map((group: Object, i: number) => (
        R.mergeRight(
          group,
          {
            dateTo,
            dateFrom,
            groupName: `${i}-${R.prop('groupName', group)}`,
            autoNumber: R.path(
              [group[GC.FIELD_BRANCH_GUID], GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED],
              configs,
            ),
          },
        )
      )),
      R.append({ name: 'groupBy', value: groupBy }, initialGroupProps),
    ),
  );
};

export const getMissingDocumentTypes = (documentTypes: Array, documents: Array) => {
  if (G.isNilOrEmpty(documentTypes)) return null;
  if (G.isNilOrEmpty(documents)) {
    return R.map(({ label, displayedValue }: Object) => R.or(displayedValue, label), documentTypes);
  }
  return R.compose(
    R.map(({ label, displayedValue }: Object) => R.or(displayedValue, label)),
    R.filter(({ value }: Object) => G.isNilOrEmpty(R.find(
      ({ documentType }: Object) => R.equals(value, documentType.dropdownOptionGuid),
      documents,
    ))),
  )(documentTypes);
};

export const calculateChargesTotal = (charges: Object) => {
  if (G.isNilOrEmpty(charges)) return 0;
  return R.reduce((acc: number, item: Object) => {
    const rate = R.prop(GC.FIELD_CHARGE_TOTAL, item);
    if (R.isNil(rate)) return acc;
    if (G.isTrue(R.prop(GC.FIELD_CHARGE_DISCOUNT, item))) {
      return R.subtract(acc, rate);
    }
    return R.add(acc, rate);
  }, 0, charges);
};

export const getMasterInvoicesTotals = (list: Array, empty: boolean) => {
  const masterInvoicesTotal = G.ifElse(
    empty,
    0,
    R.compose(
      R.reduce((acc: number, mi: Object) => R.add(acc, R.prop('invoicesTotal', mi)), 0),
      R.filter(R.prop('selected')),
    )(list),
  );
  const invoicesCount = R.compose(
    R.sum,
    R.map(R.prop('invoicesCount')),
  )(list);
  const selectedInvoicesCount = R.compose(
    R.reduce((acc: number, mi: Object) => {
      if (R.isNil(mi.invoices)) return R.add(acc, R.prop('invoicesCount', mi));

      return R.compose(
        R.add(acc),
        R.length,
        R.filter(R.prop('selected')),
        R.values,
        R.prop('invoices'),
      )(mi);
    }, 0),
    R.filter(R.prop('selected')),
  )(list);

  return {
    invoicesCount,
    masterInvoicesTotal,
    selectedInvoicesCount,
  };
};
