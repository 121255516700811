import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withHandlers,
} from 'react-recompose';
// components
import { TextComponent } from '../../../components';
import { openModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Span } from '../../../ui';
// feature load-board
import LoadBoardStates from './load-board-states';
import { makeSelectSearchFilterStates } from '../selectors';
import { getLBDisplayValue } from '../helpers';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleShowLBStates: ({ openModal }: Object) => (filter: Array) => {
      const modal = {
        p: 15,
        component: (
          <LoadBoardStates filter={filter} />
        ),
        options: {
          width: 500,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
          withCloseIcon: true,
          title: G.getWindowLocale('actions:configure-load-boards', 'Load Boards'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const FilterLoadBoards = enhance(({
  filter,
  states,
  handleShowLBStates,
}: Object) => {
  const lbStates = R.propOr([], G.getGuidFromObject(filter), states);

  const hasFailed = R.any(
    R.propEq(GC.LB_STATUS_FAILED, GC.FIELD_STATUS),
    lbStates,
  );

  const text = R.compose(
    R.join(', '),
    R.map(({ type, status }: Object) => `${getLBDisplayValue(type)} (${G.getEnumLocale(status)})`),
  )(lbStates);

  const component = R.addIndex(R.map)(
    ({ guid, type, status }: Object, index: number) => ([
      <Span
        key={guid}
        {...R.equals(status, GC.LB_STATUS_DISABLED) ? { textDecoration: 'line-through' } : {}}
      >{getLBDisplayValue(type)}</Span>,
      R.not(R.equals(R.inc(index), R.length(lbStates))) && ', ',
    ]),
    lbStates);

  const widthSubtract = G.ifElse(hasFailed, 35, 16);

  return (
    <Flex justifyContent='space-between'>
      <TextComponent
        title={text}
        display='block'
        withEllipsis={true}
        maxWidth={`calc(100% - ${widthSubtract}px)`}
      >
        {component}
      </TextComponent>
      {
        hasFailed &&
        <Box data-testid='filter-load-board-btn' onClick={() => handleShowLBStates(filter)}>
          {I.warningIcon(G.getTheme('colors.black'), 14, 14, G.getTheme('colors.light.yellow'))}
        </Box>
      }
    </Flex>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  states: makeSelectSearchFilterStates(state),
});

export default connect(mapStateToProps, {
  openModal,
})(FilterLoadBoards);
