import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
import {
  sendInvoiceToBCByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
} from '../../../common/actions';
// components
import { Table } from '../../../components/table';
import { EditReport } from '../../../components/edit-report';
import { TitlePanel } from '../../../components/title-panel';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { ActionsElement } from '../../../components/actions-element';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import ReferenceFormComponent from '../../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
// forms
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { withMassActionSubmit } from '../../../hocs/with-mass-action-submit';
import { withAsyncEditDriverInvoice } from '../../../hocs/with-async-edit-driver-invoice';
// report-common
import { reportEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import { Box, IconWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature invoice
import ActionForm from '../components/action-form';
import { printField } from '../settings/fields-settings';
import { tableSettings } from '../settings/table-settings';
import { getChangeInvoicesTitle, getGuidsFromStringOrSelectedList } from '../helpers';
// feature invoice/vendor
import { FILTER_PARAMS } from './settings/filter-params';
import { columnSettings } from './settings/column-settings';
import {
  makeSelectConfigs,
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  printRequest,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  printByReportRequest,
  changeInvoicesRequest,
  getXMLByReportRequest,
  createReferenceRequest,
  resetListAndPagination,
  sendToFinancialRequest,
  exportReportDataRequest,
  changeDefaultReportRequest,
  updateVendorInvoiceRequest,
} from './actions';
//////////////////////////////////////////////////

const selectInvoiceLocaleTxt = G.getWindowLocale(
  'messages:select-invoice',
  'Please, select an Invoice!',
);

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const setElementActions = (props: Object, invoice: Object) => {
  const {
    configs,
    closeFixedPopup,
    handlePrintInvoice,
    handleDeleteInvoice,
    handleChangeInvoices,
    handleQBIIFImportList,
    handleSendInvoiceListToBC,
    handleSendInvoicesToFinancial,
    handleSendInvoicesToSageIntacct,
  } = props;

  const guid = G.getGuidFromObject(invoice);
  const iconColor = G.getTheme('colors.dark.blue');

  const actions = [
    {
      permissions: [PC.TEL_WRITE],
      frontIcon: I.routesLoads(iconColor),
      text: G.getWindowLocale('actions:go-to-tel', 'Go to TEL'),
      action: () => G.goToLoadDetailsByConfigAndLoadType(routesMap, R.prop(GC.FIELD_TEL_GUID, invoice)),
    },
    {
      frontIcon: I.pencil(iconColor),
      text: G.getWindowLocale('actions:change-invoice', 'Change Invoice'),
      permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      action: () => {
        handleChangeInvoices(guid);
        closeFixedPopup();
      },
    },
    {
      frontIcon: I.printer(iconColor),
      action: () => handlePrintInvoice(guid),
      text: G.getWindowLocale('actions:print-invoice', 'Print Invoice'),
      permissions: [
        PC.TEL_FLEET_INVOICE_READ,
        PC.TEL_FLEET_INVOICE_WRITE,
        PC.TEL_INVOICE_OVERWRITE_EXECUTE,
      ],
    },
    {
      frontIcon: I.trash(iconColor),
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      action: () => {
        handleDeleteInvoice(guid);
        closeFixedPopup();
      },
    },
  ];

  if (G.isTrue(G.getPropFromObject('allowExportDriverInvoice', configs))) {
    const qbActions = [
      {
        frontIcon: I.quickbook(iconColor, 16, 16),
        action: () => handleSendInvoicesToFinancial(guid),
        text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
        permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      },
      {
        frontIcon: I.gear(iconColor),
        action: () => handleSendInvoicesToSageIntacct(guid),
        permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
        text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send to Sage Intacct'),
      },
      {
        frontIcon: I.quickbook(iconColor, 16, 16),
        action: () => handleQBIIFImportList(guid),
        text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
        permissions: [
          PC.TEL_FLEET_INVOICE_READ,
          PC.TEL_FLEET_INVOICE_WRITE,
          PC.TEL_INVOICE_OVERWRITE_EXECUTE,
        ],
      },
      {
        frontIcon: I.gear(iconColor),
        action: () => handleSendInvoiceListToBC(guid),
        permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
        text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
      },
    ];

    return R.insertAll(3, qbActions, actions);
  }

  return actions;
};

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withMassActionSubmit,
  withAsyncEditDriverInvoice({ updateFrom: 'vendorInvoiceList' }),
  withHandlers({
    handleDeleteInvoice: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        deleteItemRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: G.getWindowLocale('titles:vendor-invoices', 'Vendor Invoice(s)'),
        submitAction: () => {
          deleteItemRequest(R.of(Array, guid));
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleOpenSubmitDeleteInvoices: (props: Object) => () => {
      const {
        itemList,
        openModal,
        closeModal,
        deleteItemRequest,
      } = props;

      const selectedList = R.filter(
        R.prop('selected'),
        itemList,
      );

      if (R.propEq(0, 'length', selectedList)) return;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: `${R.length(selectedList)} ${
          G.getWindowLocale('titles:vendor-invoices', 'Vendor Invoice(s)')
        }`,
        submitAction: () => {
          deleteItemRequest(R.map(
            (invoice: Object) => invoice.guid,
            selectedList,
          ));

          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleChangeInvoices: (props: Object) => (invoiceGuid: string) => {
      const {
        configs,
        itemList,
        openModal,
        closeModal,
        branchGuid,
        changeInvoicesRequest,
      } = props;

      const invoiceGuids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);
      const title = getChangeInvoicesTitle(invoiceGuid);

      if (R.isEmpty(invoiceGuids)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      const component = (
        <ActionForm
          configs={configs}
          branchGuid={branchGuid}
          closeModal={closeModal}
          invoiceGuids={invoiceGuids}
          statusConfigName='DPStatusConfig'
          submitAction={changeInvoicesRequest}
        />
      );

      const modal = G.getDefaultModalOptions(component, title);
      openModal(modal);
    },
    handleSendInvoicesToFinancial: (props: Object) => (invoiceGuid: string) => {
      const { itemList, sendToFinancialRequest } = props;

      const invoiceGuids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(invoiceGuids)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      sendToFinancialRequest(invoiceGuids);
    },
    handleSendInvoicesToSageIntacct: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        filterParams,
        selectedReport,
        closeFixedPopup,
        additionalFilters,
        handleMassActionSubmit,
        sendInvoiceToSageByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (G.isString(invoiceGuid)) {
        return sendInvoiceToSageByInvoiceTypeRequest({
          guids: R.of(Array, invoiceGuid),
          invoiceType: 'vendorInvoice',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;
        let dataToUse = {
          ...data,
          invoiceType: 'vendorInvoice',
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };
          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        sendInvoiceToSageByInvoiceTypeRequest(dataToUse);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
    handlePrintInvoice: (props: Object) => (invoiceGuid: string) => {
      const {
        configs,
        openModal,
        closeModal,
        printRequest,
        documentTemplates,
      } = props;

      const invoiceTemplates = R.pathOr([], [GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE], documentTemplates);

      const component = (
        <ActionForm
          configs={configs}
          fields={printField}
          closeModal={closeModal}
          submitAction={printRequest}
          invoiceGuids={R.of(Array, invoiceGuid)}
          options={G.addEmptyOptionToDropDown(invoiceTemplates)}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-invoice', 'Print Invoice'));

      openModal(modal);
    },
    handlePrintInvoicesByReport: ({ openModal, printByReportRequest }: Object) => () => {
      const component = (
        <SelectPrintTemplateForm
          submitAction={(values: Object) => printByReportRequest(values)}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE_REPORT}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:print-invoice', 'Print Invoice'));

      openModal(modal);
    },
    handleQBIIFImportList: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        getItemListRequest,
        resetListAndPagination,
        createQBIIFImportByInvoiceTypeRequest,
      } = props;

      const guids = getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      const callback = () => {
        resetListAndPagination();
        getItemListRequest(true);
      };

      createQBIIFImportByInvoiceTypeRequest({ guids, callback, type: 'vendor' });
    },
    handleSendInvoiceListToBC: (props: Object) => (invoiceGuid: string) => {
      const {
        itemList,
        closeModal,
        filterParams,
        selectedReport,
        closeFixedPopup,
        handleMassActionSubmit,
        sendInvoiceToBCByInvoiceTypeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (G.isString(invoiceGuid)) {
        return sendInvoiceToBCByInvoiceTypeRequest({
          guids: R.of(Array, invoiceGuid),
          invoiceType: 'vendorInvoice',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(itemList);

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let dataToUse = {
          ...data,
          invoiceType: 'vendorInvoice',
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...dataToUse,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };

          dataToUse = G.setSearchCriteria({ reqBody, filterParams });
        }

        sendInvoiceToBCByInvoiceTypeRequest(dataToUse);
        closeModal();
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
  }),
  withHandlers({
    handleAddReferenceList: (props: Object) => () => {
      const { itemList, openModal, createReferenceRequest } = props;

      const isSelected = (item: Object) => item.selected;

      const selectedList = R.map(
        (item: Object) => item.guid,
        R.filter(isSelected, itemList),
      );

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple('info', selectInvoiceLocaleTxt);
      }

      const multiAddingEntRef = (values: Object) => {
        const params = R.assoc('primaryObjectGuids', selectedList, values);
        createReferenceRequest(params);
      };

      const modalContent = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_FLEET_INVOICE}
          submitAction={(params: Object) => multiAddingEntRef(params)}
        />
      );

      const modal = G.createAddReferenceModalOptions(modalContent);
      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (e: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ActionsElement
            entity={entity}
            closeFixedPopup={props.closeFixedPopup}
            options={setElementActions(props, entity)}
          />
        ),
      })
    ),
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleDeleteInvoice,
    handleTableTitleFilter,
    handleEditDriverInvoice,
  } = props;

  if (R.not(selectedReport)) return null;

  const elementActionsComponent = (entity: Object) => (
    <AuthWrapper
      has={[
        PC.TEL_FLEET_INVOICE_READ,
        PC.TEL_FLEET_INVOICE_WRITE,
        PC.TEL_INVOICE_OVERWRITE_EXECUTE,
      ]}
    >
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, entity)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  const actionButtons = [
    {
      iconName: 'pencil',
      permissions: [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      action: (_: string, invoice: Object) => handleEditDriverInvoice(
        R.assoc(GC.FIELD_TYPE, GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE, invoice),
      ),
    },
    {
      iconName: 'trash',
      action: handleDeleteInvoice,
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
    },
  ];

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    report: selectedReport,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const VendorInvoicesComponent = (props: Object) => {
  const {
    configs,
    itemList,
    handleChangeInvoices,
    getXMLByReportRequest,
    handleQBIIFImportList,
    handleSendInvoiceListToBC,
    handlePrintInvoicesByReport,
    handleSendInvoicesToFinancial,
    handleOpenSubmitDeleteInvoices,
    handleSendInvoicesToSageIntacct,
  } = props;

  const permissions = [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE];
  const mainLightColor = G.getTheme('colors.white');

  const listActionsOpt = [
    {
      permissions,
      action: getXMLByReportRequest,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    // TODO: uncomment after adding logic to back-end
    // {
    //   permissions,
    //   type: 'massAction',
    //   action: handleAddReferenceList,
    //   icon: I.plusRound(mainLightColor, 25, 25),
    //   text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
    // },
    {
      permissions,
      type: 'massAction',
      action: handleChangeInvoices,
      icon: I.pencil(mainLightColor, 20, 20),
      text: G.getWindowLocale('actions:change-invoices', 'Change Invoices'),
    },
    {
      permissions,
      type: 'massAction',
      action: handleSendInvoicesToFinancial,
      icon: I.quickbook('transparent', 25, 25),
      isVisible: configs.allowExportDriverInvoice,
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      permissions,
      type: 'massAction',
      icon: I.gear(mainLightColor, 25, 25),
      action: handleSendInvoicesToSageIntacct,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
    },
    {
      type: 'massAction',
      action: handleQBIIFImportList,
      icon: I.quickbook('transparent', 25, 25),
      permissions: R.prepend(PC.TEL_FLEET_INVOICE_READ, permissions),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
    },
    {
      type: 'massAction',
      action: handleSendInvoiceListToBC,
      icon: I.gear(mainLightColor, 25, 25),
      permissions: [PC.SERVICE_VENDOR_WRITE],
      text: G.getWindowLocale('actions:send-to-business-central', 'Send to Business Central'),
    },
    {
      type: 'massAction',
      icon: I.trash(mainLightColor, 20, 20),
      action: handleOpenSubmitDeleteInvoices,
      permissions: [PC.TEL_INVOICE_DELETE_EXECUTE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
  ];

  return (
    <Box p={15}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          type={GC.VENDOR_INVOICE_REPORT}
          title={G.getWindowLocale('titles:vendor-invoices', 'Vendor Invoice(s)')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={permissions}>
        <PageActions
          listActions={listActionsOpt}
          shadowColor={G.getTheme('createButton.shadowColor')}
          count={R.length(R.filter((item: Object) => item.selected, R.or(itemList, [])))}
          mainAction={{
            permissions,
            action: handlePrintInvoicesByReport,
            text: G.getWindowLocale('actions:print', 'Print'),
          }}
        />
      </AuthWrapper>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  configs: makeSelectConfigs(state),
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  selectItem,
  printRequest,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  printByReportRequest,
  changeInvoicesRequest,
  getXMLByReportRequest,
  createReferenceRequest,
  resetListAndPagination,
  sendToFinancialRequest,
  exportReportDataRequest,
  changeDefaultReportRequest,
  sendInvoiceToBCByInvoiceTypeRequest,
  createQBIIFImportByInvoiceTypeRequest,
  sendInvoiceToSageByInvoiceTypeRequest,
  updateDriverInvoiceRequest: updateVendorInvoiceRequest,
})(enhance(VendorInvoicesComponent));
