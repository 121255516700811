import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const acceptLosSubscriptionRequest = createAction('acceptLosSubscriptionRequest');
const acceptLosSubscriptionSuccess = createAction('acceptLosSubscriptionSuccess');
const rejectLosSubscriptionRequest = createAction('rejectLosSubscriptionRequest');
const rejectLosSubscriptionSuccess = createAction('rejectLosSubscriptionSuccess');
const getMasterEnterpriseListRequest = createAction('getMasterEnterpriseListRequest');
const getMasterEnterpriseListSuccess = createAction('getMasterEnterpriseListSuccess');

export {
  // report
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  acceptLosSubscriptionRequest,
  rejectLosSubscriptionRequest,
  acceptLosSubscriptionSuccess,
  rejectLosSubscriptionSuccess,
  getMasterEnterpriseListRequest,
  getMasterEnterpriseListSuccess,
};
