import * as R from 'ramda';
import { format } from 'date-fns';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature load-board
import { searchResultsFilterParams } from './settings/field-settings';
//////////////////////////////////////////////////

export const getTimeFrom = (date: string) => {
  const difference = Math.abs(R.subtract(new Date(), new Date(date)));
  const minutesDifference = Math.floor(R.divide(R.divide(difference, 1000), 60));
  const hours = Math.floor(R.divide(minutesDifference, 60));
  const minutes = Math.floor(R.subtract(minutesDifference, R.multiply(hours, 60)));
  return `${G.ifElse(R.lt(hours, 10), `0${hours}`, hours)}:${G.ifElse(R.lt(minutes, 10), `0${minutes}`, minutes)}`;
};

const getCoords = async (address: string, latLng: Object) => {
  if (R.and(
    G.isNotNilAndNotEmpty(R.prop(GC.FIELD_LATITUDE, latLng)),
    G.isNotNilAndNotEmpty(R.prop(GC.FIELD_LONGITUDE, latLng)),
  )) {
    return {
      lat: latLng[GC.FIELD_LATITUDE],
      lng: latLng[GC.FIELD_LONGITUDE],
    };
  }

  G.logToSystem(GC.LOG_MESSAGE_TYPE_GOOGLE_GEOCODE_API, { from: 'load-board getAsyncStopsWithCoords' });

  const {
    latitude,
    longitude,
  } = await G.geocodeByPlaceAddress(address, 'load-board getAsyncStopsWithCoords');

  return { lat: latitude, lng: longitude };
};

export const getAsyncStopsWithCoords = async (data: Object, searchFilterId: string, searchFilters: Object) => {
  const startLocation = R.propOr(
    R.path([searchFilterId, GC.FIELD_ORIGIN], searchFilters),
    'startLocation',
    data,
  );
  // const searchFilterOrigin = R.path([searchFilterId, GC.FIELD_ORIGIN], searchFilters);

  const [equipment, origin, destination] = await Promise.all([
    getCoords(
      `${R.prop(GC.FIELD_CITY, startLocation)} ${
        R.prop(GC.FIELD_STATE, startLocation)}`,
        startLocation,
    ),
    getCoords(
      `${R.pathOr('', [GC.FIELD_LOAD_BOARD_ORIGIN, GC.FIELD_CITY], data)} ${
        R.pathOr('', [GC.FIELD_LOAD_BOARD_ORIGIN, GC.FIELD_STATE], data)}`,
      data[GC.FIELD_LOAD_BOARD_ORIGIN],
    ),
    getCoords(
      `${R.pathOr('', [GC.FIELD_LOAD_BOARD_DESTINATION, GC.FIELD_CITY], data)} ${
        R.pathOr('', [GC.FIELD_LOAD_BOARD_DESTINATION, GC.FIELD_STATE], data)}`,
      data[GC.FIELD_LOAD_BOARD_DESTINATION],
    ),
  ]);

  const locations = R.filter(
    (location: Array) => R.and(G.isNotNil(location.lat), G.isNotNil(location.lng)),
    [equipment, origin, destination],
  );

  return {
    locations: locations,
    stops: [
      ... R.and(G.isNotNil(equipment.lat), G.isNotNil(equipment.lng)) ? [
        R.mergeRight(startLocation, {
          latLng: equipment,
          title: G.getWindowLocale('titles:equipment', 'Equipment'),
        }),
      ] : [],
      R.mergeRight(data[GC.FIELD_LOAD_BOARD_ORIGIN], {
        latLng: origin,
        title: G.getWindowLocale('titles:origin', 'Origin'),
      }),
      R.mergeRight(data[GC.FIELD_LOAD_BOARD_DESTINATION], {
        latLng: destination,
        title: G.getWindowLocale('titles:destination', 'Destination'),
      }),
    ],
  };
};

export const remapToCommit = (result: Object, filters: Object, status: string) => {
  const {
    guid,
    type,
    notes,
    shipment,
    negotiatedRate,
    personTalkedTo,
    searchFilterGuid,
    negotiatedRateCurrency,
  } = result;

  const {
    id,
    rate,
    origin,
    company,
    contact,
    comments,
    truckType,
    dimensions,
    pickupDate,
    destination,
    referenceId,
    distanceInfo,
    deliveryDate,
    brokerMcNumbers,
  } = shipment;

  let filter = filters[searchFilterGuid];

  if (!origin.country) origin.country = C.ENUM_US;
  if (!destination.country) destination.country = C.ENUM_US;

  const filterOrigin = R.clone(filter?.origin);

  if (filterOrigin) delete filterOrigin.id;

  const defaultRate = {
    rate: null,
    negotiatedRate,
    rateUnit: null,
    rateCurrency: null,
    negotiatedRateCurrency,
  };

  const data = R.propOr({}, 'data', shipment);

  const remapped = {
    id,
    data,
    notes,
    status,
    origin,
    contact,
    comments,
    truckType,
    pickupDate,
    dimensions,
    destination,
    referenceId: referenceId || '12345678',
    source: type,
    deliveryDate,
    distanceInfo,
    personTalkedTo,
    loadNumber: id,
    brokerMcNumbers,
    company: company,
    searchFilterGuid,
    shipmentId: guid,
    startLocation: filterOrigin,
    rate: R.mergeRight(defaultRate, rate),
  };

  return remapped;
};

export const remapFromCommit = (data) => {
  const source = R.prop(GC.FIELD_LOAD_BOARD_SOURCE, data);

  const fields = R.pick(['userGuid', 'shipmentId', 'enterpriseGuid', 'searchFilterGuid'], data)
  const shipment = R.omit(['source', 'userGuid', 'shipmentId', 'enterpriseGuid', 'searchFilterGuid'], data);

  return {
    ...fields,
    shipment,
    type: source,
  };
};

export const remapToUpdateShipment = (data) => {
  const {
    notes,
    shipment,
    negotiatedRate,
    personTalkedTo,
    negotiatedRateCurrency,
  } = data;

  const { guid } = shipment;

  return {
    guid,
    notes,
    personTalkedTo,
    rate: {
      negotiatedRate,
      negotiatedRateCurrency,
    },
    status: GC.LB_SHIPMENT_STATUS_SAVED,
  };
};

export const getBookingRequestData = (payload: Object, currentUserData: Object) => {
  const { shipment } = payload;

  const committedShipmentGuid = G.getGuidFromObject(shipment);
  const additionalData = R.propOr({}, 'data', shipment);

  const { email, phoneNumber } = currentUserData;
  const { firstLastNames } = G.getUserInfo(currentUserData);

  return {
    email,
    phone: phoneNumber,
    data: additionalData,
    committedShipmentGuid,
    contactName: firstLastNames,
  };
};

export const checkRequiredBookingUserFields = (integrationType: string, currentUserData: Object) => {
  const { lastName, firstName } = currentUserData;

  if (R.or(
    G.isOneNotNilOrNotEmpty([lastName, firstName]),
    R.not(R.equals(integrationType, GC.EXTERNAL_LOAD_BOARD_CH_ROBINSON)),
  )) return null;

  const requiredMessage = G.getWindowLocale(
    'titles:user-profile-fields-required',
    'Please fill in first name, last name in your profile to be able to book shipments',
  );

  return requiredMessage;
};

const titles = {
  active: G.getWindowLocale(
    'lables:configure-load-boards-active',
    'Load board is active',
  ),
  failed: G.getWindowLocale(
    'lables:configure-load-boards-failed',
    'Load board is failed!',
  ),
  needsConfigure: G.getWindowLocale(
    'lables:configure-load-boards-disabled',
    'Load board isn\'t configured!',
  ),
  mustBeConfigured: G.getWindowLocale(
    'lables:configure-load-boards-first',
    'Load board must be configured first!',
  ),
  needsLogin: G.getWindowLocale(
    'lables:configure-load-boards-disabled',
    'Load board isn\'t logged in!',
  ),
};

export const getLoadBoardConfigurations = (configuredLoadBoards: Array) => R.compose(
  R.sortBy(R.prop(GC.FIELD_STATUS)),
  R.map((loadBoard: Object) => {
    const type = R.prop('value', loadBoard);
    const label = R.prop(GC.FIELD_LABEL, loadBoard);
  
    const configured = R.find(R.propEq(
      type,
      GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE,
    ), configuredLoadBoards);
  
    const description = R.prop(GC.FIELD_DESCRIPTION, configured);
    const status = R.propOr(GC.LB_STATUS_DISABLED, GC.FIELD_STATUS, configured);
  
    const confTitlesMap = {
      [GC.LB_STATUS_ACTIVE]: 'active',
      [GC.LB_STATUS_FAILED]: 'failed',
      [GC.LB_STATUS_DISABLED]: 'needsConfigure',
    };
  
    const title = R.or(description, R.prop(R.prop(status, confTitlesMap), titles));
  
    return {
      type,
      label,
      title,
      status,
    };
  }),
)(R.tail(GC.EXTERNAL_LOAD_BOARD_CONFIGURATION_LIST_OPTIONS));

export const getLoadBoardLogins = (
  authorizedLoadBoards: Array,
  configuredLoadBoards: Array,
) => R.compose(
  R.sortBy(R.prop('sortProp')),
  R.map((loadBoard: Object) => {
    const type = R.prop(GC.FIELD_VALUE, loadBoard);
    const label = R.prop(GC.FIELD_LABEL, loadBoard);
  
    const configured = R.find(R.propEq(
      type,
      GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE,
    ), authorizedLoadBoards);
  
    const description = R.prop(GC.FIELD_DESCRIPTION, configured);
  
    const defaultStatus = G.ifElse(
      R.equals(GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS, type) &&
      R.none(
        R.propEq(
          type,
          GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE,
        ),
        configuredLoadBoards,
      ),
      GC.LB_STATUS_MUST_BE_CONFIGURED,
      GC.LB_STATUS_DISABLED,
    );
  
    const status = R.propOr(defaultStatus, GC.FIELD_STATUS, configured);
  
    const confTitlesMap = {
      [GC.LB_STATUS_ACTIVE]: 'active',
      [GC.LB_STATUS_FAILED]: 'failed',
      [GC.LB_STATUS_DISABLED]: 'needsLogin',
      [GC.LB_STATUS_MUST_BE_CONFIGURED]: 'mustBeConfigured',
    };
  
    const title = R.or(description, R.prop(R.prop(status, confTitlesMap), titles));
  
    return {
      type,
      label,
      title,
      status,
      sortProp: G.isNotNil(configured) ? 'a' : 'b',
    };
  }),
)(R.tail(GC.EXTERNAL_LOGIN_LOAD_BOARD_LIST_OPTIONS));

export const getStateAbbreviation = (location: Object) => R.pathOr(
  '',
  [R.or(R.prop(GC.FIELD_COUNTRY, location), 'US'), R.prop(GC.FIELD_STATE, location)],
  GC.STATE_ABBREVIATION_MAP,
);

export const getLocationDisplayValue = (location: Object) => {
  if (G.isNilOrEmpty(location)) return null;

  const { city, state, country } = location;

  const noCity = G.isNilOrEmpty(city);
  const noState = G.isNilOrEmpty(state);

  if (R.and(noCity, noState)) return country;

  const stateAbbr = getStateAbbreviation(location);

  return R.compose(
    R.join(', '),
    R.filter(G.isNotNilAndNotEmpty),
  )([city, stateAbbr, R.pathOr(country, [country], GC.COUNTRY_DISPLAY_VALUE_MAP)]);
};

export const getCityStateFromLocation = (location: Object) => {
  if (G.isNilOrEmpty(location)) return 'No location';

  const { city } = location;

  const state = getStateAbbreviation(location);

  if (R.and(G.isNilOrEmpty(city), G.isNilOrEmpty(state))) return 'No location';

  return `${R.or(city, '')} ${R.or(state, '')}`;
};

export const getLBDisplayValue = (type: string) => R.propOr(type, type, GC.EXTERNAL_LOAD_BOARDS_DISPLAY_VALUES_MAP);

export const getSortedShipments = (sortValues: Object, shipments: Array) => {
  const sortingList = R.sortBy(R.prop('sequence'), R.values(sortValues));

  const sortingCriteria = R.map((sortOption: Object) => {
    const { name, order } = sortOption;

    const filterParams = R.find(R.propEq(name, 'value'), searchResultsFilterParams);

    if (!filterParams) return () => {};

    const { path, type } = filterParams;
    const orderFn = G.ifElse(R.equals(order, 'DESC'), R.descend, R.ascend);

    return orderFn((arg: Object) => {
      const value = R.pathOr('', path, arg);

      switch(type) {
        case 'date':
          if (R.equals(name, GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE)) return Math.abs(R.subtract(new Date(), new Date(value)));

          return new Date(value).getTime();
        case 'number':
          return +(value || 0);
        default:
          return value;
      }
    });
  }, sortingList);

  return R.sortWith(sortingCriteria, shipments);
};

// TODO: filter by type not always by string
// TODO: fix issue when after clear filter user quickly move to another cell and type filter is not cleared
export const getFilteredShipments = (filterValues: Object, shipments: Array) => {
  const filteringList = R.values(filterValues);

  let forFiltering = shipments;

  R.forEach((filterOption: Object) => {
    const {
      from,
      dataType,
      stringValue,
      numberValue,
      propertyName,
    } = filterOption; //use? operation, "contain", date-range, "equal"

    const filterParams = R.find(R.propEq(propertyName, 'value'), searchResultsFilterParams);

    if (!filterParams) return () => {};

    const { path } = filterParams;

    let toCompare;

    switch (dataType) {
      case 'string':
        toCompare = R.toLower(stringValue);
        break;
      case 'number':
        toCompare = +numberValue;
        break;
      case 'date':
        toCompare = format(new Date(from), 'yyyy-MM-dd');
        break;
    }

    forFiltering = R.filter((item: Object) => {
      const value = R.path(path, item);

      switch (dataType) {
        case 'string':
          return R.gt(R.toLower(R.or(value, '')).indexOf(toCompare), -1);
        case 'number':
          return R.equals(value, toCompare);
        case 'date':
          return R.equals(toCompare, value);
      }
    }, forFiltering);
  }, filteringList);

  return forFiltering;
};

export const get123LBDetails = async (id: string) => {
  try {
    const params = { id };

    const res = await sendRequest('get', endpointsMap.loadBoard123Details, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    G.handleException('error', 'get123LBDetails exception');
  }
}
