import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Flex } from '../../../../ui';
//////////////////////////////////////////////////

const getTotalByPropName = (list: Array, propName: string) => R.compose(
  R.sum,
  R.map(R.prop(propName)),
)(list);

const SelectedListTotals = (props: Object) => {
  const { list } = props;

  let currency = R.or(
    G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
    GC.DEFAULT_UI_CURRENCY,
  );

  if (R.equals(R.length(list), 1)) {
    currency = R.path([0, GC.FIELD_CURRENCY], list);
  }

  const currencySymbol = G.getCurrencySymbol(currency);

  const invoicesCount = R.length(list);

  const grossTotal = getTotalByPropName(list, GC.GRC.NORMALIZED_TOTAL_TOTAL);

  return (
    <Flex mb={10} ml={15}>
      <TextComponent
        mr={15}
        p='2px 10px'
        fontSize={14}
        display='block'
        fontWeight={700}
        borderRadius='3px'
        color={G.getTheme('colors.white')}
        bg={G.getTheme('colors.light.blueGrey')}
      >
        {
          `${G.getWindowLocale('titles:invoices-count', 'Invoices Count')}: ${invoicesCount}`
        }
      </TextComponent>
      {
        G.isNotNaN(grossTotal) && G.isNotNilAndNotEmpty(grossTotal) &&
        <TextComponent
          p='2px 10px'
          fontSize={14}
          fontWeight={700}
          borderRadius='3px'
          display='inline-block'
          color={G.getTheme('colors.white')}
          bg={G.getTheme('colors.light.blue')}
        >
          {
            `${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${currencySymbol} ${G.toFixed(grossTotal)}`
          }
        </TextComponent>
      }
    </Flex>
  );
};

export default SelectedListTotals;
