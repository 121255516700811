import * as R from 'ramda';
import { space, width } from 'styled-system';
import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { RelativeBox } from '../../ui';
//////////////////////////////////////////////////

export const NavItem = styled.div`
  width: 60px;
  padding: 11px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;

  ${({ active }: Object) => (
    R.and(
      active,
      `&:after {
        left: 0;
        content: '';
        height: 3px;
        width: 100%;
        bottom: -3px;
        position: absolute;

        background-color: ${G.getTheme('menus.sidebar.itemTextColorHovered')};
      }`,
    )
  )}

  ${
    ({ active, isSideBar, afterColor }: Object) => (
      R.and(
        R.and(active, isSideBar),
        `&:after {
          left: 0;
          width: 3px;
          content: '';
          height: 100%;
          position: absolute;

          background-color: ${afterColor || G.getTheme('menus.sidebar.activeItemBg')};
        }`,
      )
    )
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NavItemPinned = styled(NavItem)`
  & > div {
    top: 0;
    right: 0px;
    width: 18px;
    height: 18px;
    margin: 3px 0;
    padding: 0px 3px;
    position: absolute;
    border-radius: 50%;

    background: ${G.getTheme('colors.light.mainLight')};
    border: 1px solid ${G.getTheme('colors.light.darkRed')};

    & svg {
      width: 10px;
      height: 16px;
    }
  }
`;


const renderSidebarGroupWrapperBgColor = (opened: Object) => (
  G.ifElse(
    G.isTrue(opened),
    'rgba(0, 0, 0, 0.4)',
    'rgba(0, 0, 0, 0.2)',
  )
);

export const SidebarGroupWrapper = styled.div`
  overflow: hidden;
  transition: 0.5s;
  max-height: ${({ opened }: Object) => G.ifElse(opened, '1500px', '40px')};
  background-color: ${({ opened }: Object) => renderSidebarGroupWrapperBgColor(opened)};
`;

export const SidebarItem = styled.div`
  height: 40px;
  display: flex;
  cursor: pointer;
  padding: 0 10px;
  position: relative;
  align-items: center;
  ${({ color, active }: Object) => (
    R.and(
      active,
      `&:before {
        left: 0;
        width: 3px;
        content: '';
        height: 100%;
        position: absolute;
        background-color: ${color || G.getTheme('menus.sidebar.itemTextColor')};
      }`,
    )
  )}
  border-left: ${({ opened }: Object) => (
    G.ifElse(
      G.isTrue(opened),
      `2px solid  ${G.getTheme('menus.sidebar.itemTextColorHovered')}`,
      G.getTheme('menus.sidebar.itemTextColor'),
      )
  )};
  font-weight: ${({ opened, active }: boolean) => R.and(opened || active, 'bold')};
  color: ${({ color }: Object) => color || G.getTheme('menus.sidebar.itemTextColor')};
  & > div {
    margin: 5px;
    display: inline-flex;
  }
  &:hover {
    font-weight: bold;
  }
`;

export const SidebarItemRightPlusIcon = styled.div`
  right: 0;
  top: 30%;
  font-weight: bold;
  margin-right: 12px;
  position: absolute;
`;

export const SidebarItemRightArrowIcon = styled.div`
  right: 0;
  top: 30%;
  margin-right: 10px;
  position: absolute;
`;

export const EditNavPanel = styled.div`
  ${space}

  bottom: 0;
  width: 60px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;

  background: ${({ bg, navState }: Object) => (
    G.ifElse(
      navState,
      G.getTheme('colors.light.light.lightGrey'),
      bg || G.getTheme('colors.light.darkRed'),
    )
  )};
`;

export const dropdown = css`
  padding: 5px 25px 5px 5px;
  & > div {
    right: 5px;
  }
`;

export const IconWrapper = styled.div`
  ${space}

  display: flex;
  align-items: center;
  width: ${({ w }: Object) => w || '24px'};
  height: ${({ h }: Object) => h || '18px'};
`;

export const MenuWrapper = styled.div`
  top: 0;
  z-index: 11;
  width: 60px;
  height: 100vh;
  position: fixed;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
  background-color: ${({ bg }: Object) => bg || G.getTheme('colors.light.darkRed')};
`;

export const LgMenuWrapper = styled.div`
  top: 0;
  width: 250px;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  transition: left .2s linear;
  justify-content: space-between;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  left: ${({ isMenuOpen }: Object) => G.ifElse(isMenuOpen, '60px', '-250px')};
  background: ${({ bg }: Object) => bg || G.getTheme('colors.light.mainLight')};
`;

export const Logo = styled.img`
  max-width: 100%;
  vertical-align: middle;
  max-height: ${({ maxHeight }: Object) => maxHeight || '50px'};
`;

export const BranchDropDownWrapper = styled.div`
  width: 100%;
  height: 86px;
  overflow-y: auto;
  padding: 5px 10px 0;
  color: ${({ color }: Object) => color || G.getTheme('header.textColor')};
  & > div {
    display: flex;
    padding: 3px 0;
    align-items: center;
    &:hover {
      cursor: pointer;
      font-weight: bold;
      color: ${({ color }: Object) => color || G.getTheme('colors.light.darkRed')};
    }
    & svg {
      width: 18px;
      height: 18px;
    }
    & > span:last-child {
      padding: 0 5px;
    }
  }
`;

export const HrDivider = styled.hr`
  ${space}
  ${width}
`;

export const ReleaseVersionText = styled.div`
  ${space}

  &:hover {
    font-weight: ${({ withHover }: Object) => R.and(withHover, 'bold')};
    cursor: ${({ withHover }: Object) => G.ifElse(withHover, 'pointer', 'unset')};
  }
`;

export const PinedL = styled.div`
  ${space}

  overflow-y: auto;
  box-shadow: -2px 0 3px 1px rgba(0, 0, 0, 0.2);

  height: ${({ height }: Object) => height || 'calc(100% - 50px)'};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ReleaseVersionLink = styled.a`
  ${space}

  color: inherit;
  text-decoration: inherit;

  &:hover {
    font-weight: ${({ withHover }: Object) => R.and(withHover, 'bold')};
    cursor: ${({ withHover }: Object) => G.ifElse(withHover, 'pointer', 'unset')};
  }
`;

export const LogoMenuBox = styled(RelativeBox)`
  & > *:first-child {
    left: -60px;
    position: absolute;
    transition: 0.3s left linear;
  }
  & > *:last-child {
    left: 0;
    position: absolute;
    transition: 0.3s left linear;
  }
  &:hover {
    & > *:first-child {
      left: 0;
    }
    & > *:last-child {
      left: 60px;
    }
  }
`;
