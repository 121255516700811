import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
// forms
import { Form, FieldsetComponent } from '../../../../forms';
// icons
import * as I from '../../../../svgs';
// ui
import { AbsoluteBox, CustomButton } from '../../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({ statusReason: '' }),
    validationSchema: Yup.object().shape({ statusReason: Yup.string().required(G.getRequiredLocaleTxt()) }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { statusReason } = values;
      const { actionType, submitAction, handleClosePopup } = props;

      submitAction(actionType, statusReason);
      handleClosePopup();
    },
  }),
  pure,
);

const StatusReason = enhance((props: Object) => (
  <AbsoluteBox
    zIndex={13}
    width='100%'
    height='100%'
    flexDirection='column'
    justifyContent='center'
    background='rgba(0, 0, 0, 0.5)'
  >
    <AbsoluteBox
      top={20}
      right={20}
      zIndex={13}
      cursor='pointer'
      onClick={props.handleClosePopup}
    >
      {I.renderCancelRecording()}
    </AbsoluteBox>
    <Form width='100%' minHeight='unset' onSubmit={props.handleSubmit}>
      <FieldsetComponent
        {...props}
        justifyContent='center'
        fields={[
          {
            zIndex: 12,
            width: '250px',
            shouldHandle: true,
            type: 'searchselect',
            fieldName: 'statusReason',
            options: props.optionsForSelect,
            placeholder: G.getWindowLocale(props.placeholder, 'Status Reason'),
          },
        ]}
      />
      <CustomButton m='auto' p='0 16px' height={30} width='fit-content'>
        {G.getWindowLocale('actions:submit', 'Submit')}
      </CustomButton>
    </Form>
  </AbsoluteBox>
));

export default StatusReason;
