import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature load-board
// import { getFieldsBySequence } from '../helpers';
//////////////////////////////////////////////////

const searchResultFields = [
  { name: GC.FIELD_LOAD_BOARD_SOURCE, sequence: 1 },
  { name: GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE, sequence: 2 },
  { name: GC.FIELD_LOAD_BOARD_REFERENCE_ID, sequence: 3 },
  { name: GC.FIELD_LOAD_BOARD_PHONE, sequence: 4 },
  { name: GC.FIELD_LOAD_BOARD_EMAIL, sequence: 5 },
  { name: GC.FIELD_LOAD_BOARD_CONTACT, sequence: 6 },
  { name: GC.FIELD_LOAD_BOARD_COMPANY, sequence: 7 },
  { name: GC.FIELD_LOAD_BOARD_PICKUP_DATE, sequence: 8 },
  { name: GC.FIELD_LOAD_BOARD_RATE, sequence: 9 },
  { name: GC.FIELD_LOAD_BOARD_TRUCK, sequence: 11 },
  { name: GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD, sequence: 12 },
  { name: GC.FIELD_LOAD_BOARD_ORIGIN, sequence: 13 },
  { name: GC.FIELD_LOAD_BOARD_DISTANCE, sequence: 14 },
  { name: GC.FIELD_LOAD_BOARD_DESTINATION, sequence: 15 },
  { name: GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD, sequence: 16 },
  { name: GC.FIELD_LOAD_BOARD_LENGTH, sequence: 17 },
  { name: GC.FIELD_LOAD_BOARD_WEIGHT, sequence: 18 },
  // { name: GC.FIELD_LOAD_BOARD_CREDIT_SCORE, sequence: 19, freezed: false },
  { name: GC.FIELD_LOAD_BOARD_COMMENTS, sequence: 19, freezed: false },
];

export const searchResultsReport = {
  fields: searchResultFields,
  name: 'Search-results-report',
  type: GC.EXTERNAL_LOAD_BOARD_REPORT,
};

export const savedShipmentReport = {
  name: 'Saved-results-report',
  type: GC.EXTERNAL_LOAD_BOARD_REPORT,
  fields: R.sortBy(
    R.prop('sequence'),
    R.concat(
      searchResultFields,
      [
        { name: GC.FIELD_LOAD_BOARD_NOTE, sequence: 20 },
        { name: GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE, sequence: 10 },
      ],
    ),
  ),
};

export const bookedShipmentReport = {
  name: 'Booked-results-report',
  type: GC.EXTERNAL_LOAD_BOARD_REPORT,
  fields: R.sortBy(
    R.prop('sequence'),
    R.concat(
      searchResultFields,
      [
        { name: GC.FIELD_STATUS, sequence: 0 },
        { name: GC.FIELD_LOAD_BOARD_NOTE, sequence: 20 },
        { name: GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE, sequence: 10 },
      ],
    ),
  ),
};

export const searchResultsFilterParams = [
  {
    type: 'selectMultiple',
    path: [GC.FIELD_LOAD_BOARD_TYPE],
    value: GC.FIELD_LOAD_BOARD_SOURCE,
    options: GC.EXTERNAL_LOAD_BOARDS_TO_SEARCH_OPTIONS,
    name: G.getWindowLocale('titles:source', 'Source'),
  },
  {
    type: 'string',
    disableFilter: true,
    name: G.getWindowLocale('titles:age', 'Age'),
    value: GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE,
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE],
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_BOARD_REFERENCE_ID,
    name: G.getWindowLocale('titles:load-number', 'Load Number'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_REFERENCE_ID],
  },
  // {
  //   type: 'string',
  //   value: GC.FIELD_LOAD_BOARD_PHONE,
  //   name: G.getWindowLocale('titles:phone', 'Phone'),
  //   path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_CONTACT, GC.FIELD_LOAD_BOARD_PHONE],
  // },
  // {
  //   type: 'string',
  //   value: GC.FIELD_LOAD_BOARD_EMAIL,
  //   name: G.getWindowLocale('titles:email', 'Email'),
  //   path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_CONTACT, GC.FIELD_LOAD_BOARD_EMAIL],
  // },
  {
    type: 'string',
    value: GC.FIELD_LOAD_BOARD_CONTACT,
    name: G.getWindowLocale('titles:contact', 'Contact'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_CONTACT, GC.FIELD_CONTACT_NAME],
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_BOARD_COMPANY,
    name: G.getWindowLocale('titles:company', 'Company'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMPANY, GC.FIELD_LOAD_BOARD_COMPANY],
  },
  {
    type: 'date',
    value: GC.FIELD_LOAD_BOARD_PICKUP_DATE,
    name: G.getWindowLocale('titles:pickup-date', 'Pickup Date'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_PICKUP_DATE],
  },
  {
    type: 'number',
    value: GC.FIELD_LOAD_BOARD_RATE,
    name: G.getWindowLocale('titles:pay-rate', 'Pay Rate'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_RATE, GC.FIELD_LOAD_BOARD_RATE],
  },
  {
    type: 'selectMultiple',
    options: GC.truckOptions,
    value: GC.FIELD_LOAD_BOARD_TRUCK,
    name: G.getWindowLocale('titles:equipment', 'Equipment'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_TRUCK],
  },
  {
    type: 'number',
    value: GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD,
    name: G.getWindowLocale('titles:origin-deadhead', 'DH-O'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO, GC.FIELD_LOAD_BOARD_ORIGIN_DEADHEAD],
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_BOARD_ORIGIN,
    name: G.getWindowLocale('titles:origin', 'Origin'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_ORIGIN, GC.FIELD_CITY],
  },
  {
    type: 'number',
    value: GC.FIELD_LOAD_BOARD_DISTANCE,
    name: G.getWindowLocale('titles:distance', 'Distance'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO, GC.FIELD_LOAD_BOARD_DISTANCE],
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_BOARD_DESTINATION,
    name: G.getWindowLocale('titles:destination', 'Destination'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DESTINATION, GC.FIELD_CITY],
  },
  {
    type: 'number',
    value: GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD,
    name: G.getWindowLocale('titles:destination-deadhead', 'DH-D'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DISTANCE_INFO, GC.FIELD_LOAD_BOARD_DESTINATION_DEADHEAD],
  },
  {
    type: 'number',
    value: GC.FIELD_LOAD_BOARD_LENGTH,
    name: G.getWindowLocale('titles:length', 'Length'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DIMENSIONS, GC.FIELD_LOAD_BOARD_LENGTH],
  },
  {
    type: 'number',
    value: GC.FIELD_LOAD_BOARD_WEIGHT,
    name: G.getWindowLocale('titles:weight', 'Weight'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_DIMENSIONS, GC.FIELD_LOAD_BOARD_WEIGHT],
  },
  {
    type: 'string',
    value: GC.FIELD_LOAD_BOARD_COMMENTS,
    name: G.getWindowLocale('titles:comments', 'Comments'),
    path: [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMMENTS, 0],
  },
  {
    type: 'string',
    value: 'summary',
    disableSort: true,
    disableFilter: true,
    name: G.getWindowLocale('titles:summary', 'Summary'),
  },
];
