import React from 'react';
import * as R from 'ramda';
// feature new-do
import {
  LeftSectionSkeleton,
  LeftSectionEmptyInfo,
} from '../../new-do/components/left-section';
import {
  makeReqStops,
  getDataFromStops,
  getLeftSectionWidth,
  getItemsForDropOptions,
  renderLeftRightContent,
  renderLeftRightEmptyInfo,
  getContainersForDropOptions,
  getContainersForPickupItemOptions,
} from '../../new-do/helpers';
import {
  TAB_NAME_PRICING,
  TAB_NAME_REFERENCE,
  TAB_NAME_TRIP_PRICING,
} from '../../new-do/constants';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncGetTrailersInService } from '../../../hocs/with-async-get-endpoint-hocs';
// ui
import { Box } from '../../../ui';
// feature lite-new-do
import DropForm from '../forms/drop-form';
import PickupForm from '../forms/pickup-form';
import TripPricing from '../forms/trip-pricing';
import ReferenceForm from '../forms/reference-form';
import CustomerRateForm from '../forms/customer-rate-form';
//////////////////////////////////////////////////

const renderForm = (props: Object) => {
  const {
    rate,
    stops,
    loadType,
    branchGuid,
    loadTotals,
    branchConfigs,
    leftActiveTad,
    branchRefTypes,
    stopPointsStrings,
    referenceFormData,
    recalculateLoadDistancesSuccess,
  } = props;

  const stop = stops[leftActiveTad];
  const referenceTypes = G.mapNameGuidToLabelValue(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], branchRefTypes));
  const services = R.prop(GC.FIELD_LOAD_SERVICES, referenceFormData);

  if (G.isNotNilAndNotEmpty(stop)) {
    const { id, order, formData, eventType } = stop;

    if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
      const dropContainerOptions = getContainersForDropOptions(stops, stop);
      const itemContainerOptions = G.addEmptyOptionToDropDown(getContainersForPickupItemOptions(stops, stop));

      return (
        <PickupForm
          {...props}
          stop={stop}
          stopId={id}
          stopOrder={order}
          eventType={eventType}
          initialValues={formData}
          referenceTypes={referenceTypes}
          dropContainerOptions={dropContainerOptions}
          itemContainerOptions={itemContainerOptions}
        />
      );
    }

    const items = getItemsForDropOptions(stops, formData.items);
    const dropContainerOptions = getContainersForDropOptions(stops, stop);

    return (
      <DropForm
        {...props}
        stop={stop}
        stopId={id}
        items={items}
        stopOrder={order}
        eventType={eventType}
        initialValues={formData}
        referenceTypes={referenceTypes}
        dropContainerOptions={dropContainerOptions}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_REFERENCE)) {
    return (
      <ReferenceForm
        {...props}
        referenceTypes={referenceTypes}
        initialValues={referenceFormData}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_PRICING)) {
    const { totalWeight, totalVolume, totalDistance } = loadTotals;

    const totalTripDistance = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], totalDistance);
    const totalTripDistanceUom = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], totalDistance);

    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const stopsQuantity = R.length(R.values(stops));

    const events = makeReqStops(stops, branchRefTypes, pickedItems, pickedUpContainers, true);

    const loadData = { events, services };

    const equipment = R.prop(GC.FIELD_LOAD_EQUIPMENT, referenceFormData);
    const equipments = G.ifElse(G.isNilOrEmpty(equipment), [], R.of(Array, equipment));

    const getAsyncLoadDistancesData = {
      stops,
      loadType,
      branchGuid,
      branchConfigs,
      stopPointsStrings,
      asyncLoadDistancesCallback: recalculateLoadDistancesSuccess,
    };

    const charges = G.getPropFromObject(GC.FIELD_CHARGES, rate);

    const customerRateDefaultProps = {
      ...props,
      loadData,
      services,
      equipments,
      totalWeight,
      totalDistance,
      stopsQuantity,
      totalTripDistance,
      totalTripDistanceUom,
      itemsVolume: totalVolume,
      stopCount: stopsQuantity,
      getAsyncLoadDistancesData,
    };

    const customerRateProps = G.ifElse(
      R.or(G.isNilOrEmpty(rate), G.isNilOrEmpty(charges)),
      R.assoc('isCreateMode', true, customerRateDefaultProps),
      R.mergeRight({ isEditMode: true, initialValues: rate }, customerRateDefaultProps),
    );

    return <CustomerRateForm {...customerRateProps} />;
  }

  if (R.equals(leftActiveTad, TAB_NAME_TRIP_PRICING)) {
    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const loadData = {
      cloRate: rate,
      events: makeReqStops(stops, branchRefTypes, pickedItems, pickedUpContainers, true),
    };

    return (
      <TripPricing {...props} loadData={loadData} />
    );
  }

  return null;
};

const LeftSection = (props: Object) => {
  const { aiRecognize, leftActiveTad } = props;

  const showSkeleton = R.path(['loading'], aiRecognize);

  const width = getLeftSectionWidth(leftActiveTad);

  return (
    <Box
      mb={20}
      mr={25}
      width={width}
      flexShrink={0}
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
    >
      {showSkeleton && <LeftSectionSkeleton />}
      {!showSkeleton && renderLeftRightEmptyInfo(props) && <LeftSectionEmptyInfo />}
      {!showSkeleton && renderLeftRightContent(props) && renderForm(props)}
    </Box>
  );
};

export default withAsyncGetTrailersInService()(LeftSection);
