import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// features
import PC from '../permission/role-permission';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers
import * as G from '../../helpers';
// feature load-board
import { setInitialState, startLoadSearchPage } from './actions';
//////////////////////////////////////////////////

const handleSwitchBranchLogic = createLogic({
  type: [receivedSwitchBranchSuccess],
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(setInitialState());

    if (R.or(
      G.isCurrentBranchTypeCustomer(),
      R.not(G.hasAmousCurrentUserPermissions([PC.EXTERNAL_LOAD_BOARD_EXECUTE])),
    )) return done();

    dispatch(startLoadSearchPage());

    done();
  },
});

export default [handleSwitchBranchLogic];
