import React from 'react';
import * as R from 'ramda';
// components
import { EmptyList, TextComponent } from '../../../components';
// constants/helpers
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedBox } from '../../../ui';
// feature load-board
import * as C from '../constants';
import SavedShipments from '../components/saved-shipments';
import FilterLoadBoards from '../components/filter-load-boards';
import { ShipmentsNotifications } from '../components/table-cells';
import { getStateAbbreviation, getLocationDisplayValue } from '../helpers';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = 200;

export const templateTableSettings = {
  minHeight: 200,
  maxHeight: 400,
  allowEditBtn: true,
  checkBoxCellWidth: 60,
  allowSelectItems: false,
  tableRowHeight: rowHeight,
  titleRowHeight: rowHeight,
  withResizableColumns: true,
  fixLocalLoaderHeight: false,
  checkBoxCellJustifyContent: 'flex-start',
};

export const tableSettings = {
  ...templateTableSettings,
  expandableItems: true,
  checkBoxCellWidth: 110,
  allowSelectItems: true,
  expandedDetailsComponent: ({ parentProps }: Object) => {
    const { savedShipments } = parentProps;
    const notEmpty = G.isNotNilAndNotEmpty(savedShipments);

    return (
      <StickedBox left='0px' maxWidth='calc(100vw - 75px)'>
        {
          notEmpty ?
            <SavedShipments itemList={savedShipments} /> :
            <EmptyList>{G.getWindowLocale('titles:empty-list', 'Empty List')}</EmptyList>
        }
      </StickedBox>
    );
  },
};

export const report = {
  name: 'Search-filters-report',
  guid: 'Search-filters-report',
  type: GC.LOAD_BOARD_FILTER_REPORT,
  fields: [
    { name: GC.FIELD_TRUCK_TYPES, sequence: 1 },
    { name: GC.FIELD_LOAD_BOARD_TYPES, sequence: 2 },
    { name: GC.FIELD_LOAD_BOARD_CAPACITY, sequence: 3 },
    { name: GC.FIELD_LOAD_BOARD_DH_ORIGIN, sequence: 4 },
    { name: GC.FIELD_LOAD_BOARD_ORIGIN, sequence: 5 },
    { name: GC.FIELD_LOAD_BOARD_DH_DESTINATION, sequence: 6 },
    { name: C.FIELD_DESTINATIONS, sequence: 7 },
    { name: GC.FIELD_LOAD_BOARD_DATE_EARLIEST, sequence: 8 },
    { name: GC.FIELD_LOAD_BOARD_DATE_LATEST, sequence: 9 },
    { name: GC.FIELD_LOAD_BOARD_LENGTH, sequence: 10 },
    { name: GC.FIELD_LOAD_BOARD_WEIGHT, sequence: 11 },
    { name: GC.FIELD_LOAD_BOARD_COMMENT, sequence: 12 },
  ],
};

export const searchFilterOrderParams = [
  {
    value: GC.FIELD_TRUCK_TYPES,
    name: G.getWindowLocale('titles:equipment', 'Equipment'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_TYPES,
    name: G.getWindowLocale('titles:source', 'Source'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_CAPACITY,
    name: G.getWindowLocale('titles:capacity', 'Capacity'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_DH_ORIGIN,
    name: G.getWindowLocale('titles:origin-deadhead', 'DH-O'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_ORIGIN,
    name: G.getWindowLocale('titles:origin', 'Origin'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_DH_DESTINATION,
    name: G.getWindowLocale('titles:destination-deadhead', 'DH-D'),
  },
  {
    value: C.FIELD_DESTINATIONS,
    name: G.getWindowLocale('titles:destination', 'Destination'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_DATE_EARLIEST,
    name: G.getWindowLocale('titles:earliest-date', 'Earliest date'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_DATE_LATEST,
    name: G.getWindowLocale('titles:latest-date', 'Latest date'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_LENGTH,
    name: G.getWindowLocale('titles:length', 'Length'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_WEIGHT,
    name: G.getWindowLocale('titles:weight', 'Weight'),
  },
  {
    value: GC.FIELD_LOAD_BOARD_COMMENT,
    name: G.getWindowLocale('titles:note', 'Note'),
  },
];

export const templatesReport = {
  name: 'Search-template-report',
  guid: 'Search-template-report',
  type: GC.LOAD_BOARD_FILTER_REPORT,
  fields: [
    { name: GC.FIELD_NAME, sequence: 0 },
    { name: GC.FIELD_TRUCK_TYPES, sequence: 1 },
    { name: GC.FIELD_LOAD_BOARD_CAPACITY, sequence: 2 },
    { name: GC.FIELD_LOAD_BOARD_DH_ORIGIN, sequence: 3 },
    { name: GC.FIELD_LOAD_BOARD_ORIGIN, sequence: 4 },
    { name: GC.FIELD_LOAD_BOARD_DH_DESTINATION, sequence: 5 },
    { name: C.FIELD_DESTINATIONS, sequence: 6 },
    { name: GC.FIELD_LOAD_BOARD_LENGTH, sequence: 9 },
    { name: GC.FIELD_LOAD_BOARD_WEIGHT, sequence: 10 },
    { name: GC.FIELD_LOAD_BOARD_COMMENT, sequence: 11 },
  ],
};

export const columnSettings = {
  notificationsShipments: {
    width: 80,
    notResizable: true,
    customComponent: ({ data }: Object) => <ShipmentsNotifications data={data} />,
  },
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_TRUCK_TYPES]: {
    width: 120,
    name: 'titles:equipment',
    customComponent: ({ data }: Object) => {
      const value = R.compose(
        R.join(', '),
        R.map((truckName: string) => R.pathOr(truckName, [truckName, GC.FIELD_LABEL], GC.indexedTruckOptions)),
        R.propOr([], GC.FIELD_TRUCK_TYPES),
      )(data);

      return <Box>{value}</Box>;
    },
  },
  [GC.FIELD_LOAD_BOARD_CAPACITY]: {
    width: 80,
    type: 'enum',
    name: 'titles:full-partial-short',
  },
  [GC.FIELD_LOAD_BOARD_DH_ORIGIN]: {
    width: 100,
    name: 'titles:origin-radius',
    customComponent: ({ data }: Object) => {
      const dho = R.prop(GC.FIELD_LOAD_BOARD_DH_ORIGIN, data);
      const displayText = G.ifElse(R.isNil(dho), '-', `${dho} ${R.prop(GC.FIELD_LOAD_BOARD_DISTANCE_UOM, data)}`);

      return (
        <Box>
          {displayText}
        </Box>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_ORIGIN]: {
    width: 200,
    name: 'titles:origin',
    customComponent: ({ data }: Object) => {
      const origin = R.prop(GC.FIELD_ORIGIN, data);
      const originText = getLocationDisplayValue(origin);

      return (
        G.isNotNil(origin) &&
        <TextComponent
          title={originText}
          withEllipsis={true}
          width='calc(100% - 16px)'
        >
          {originText}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_DH_DESTINATION]: {
    width: 100,
    name: 'titles:destination-radius',
    customComponent: ({ data }: Object) => {
      const dhd = R.prop(GC.FIELD_LOAD_BOARD_DH_DESTINATION, data);
      const displayText = G.ifElse(R.isNil(dhd), '-', `${dhd} ${R.prop(GC.FIELD_LOAD_BOARD_DISTANCE_UOM, data)}`);

      return (
        <Box>
          {displayText}
        </Box>
      );
    },
  },
  [C.FIELD_DESTINATIONS]: {
    width: 200,
    name: 'titles:destinations',
    customComponent: ({ data }: Object) => {
      const destinations = R.prop(C.FIELD_DESTINATIONS, data);

      let text = '';

      if (R.lt(R.length(destinations), 2)) {
        text = getLocationDisplayValue(destinations.at(0));
      } else {
        text = R.join(', ', R.filter(G.isNotNil, R.map(getStateAbbreviation, destinations)));
      }

      return (
        <TextComponent
          title={text}
          withEllipsis={true}
          width='calc(100% - 16px)'
        >
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_LOAD_BOARD_DATE_EARLIEST]: {
    width: 120,
    name: 'titles:earliest-date',
    customComponent: ({ data }: Object) => (
      G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_DATE_EARLIEST, data)) &&
      <Flex>
        <Box mr='2px'>{I.calendar(G.getTheme('colors.mainBlue'), 16, 15)}</Box>
        {R.prop(GC.FIELD_LOAD_BOARD_DATE_EARLIEST, data)}
      </Flex>
    ),
  },
  [GC.FIELD_LOAD_BOARD_DATE_LATEST]: {
    width: 120,
    name: 'titles:latest-date',
    customComponent: ({ data }: Object) => (
      G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_DATE_LATEST, data)) &&
      <Flex>
        <Box mr='2px'>{I.calendar(G.getTheme('colors.mainBlue'), 16, 15)}</Box>
        {R.prop(GC.FIELD_LOAD_BOARD_DATE_LATEST, data)}
      </Flex>
    ),
  },
  [GC.FIELD_LOAD_BOARD_LENGTH]: {
    width: 100,
    name: 'titles:length',
    customComponent: ({ data }: Object) => (
      G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_LENGTH, data)) &&
      <Box>
        {`${R.prop(GC.FIELD_LOAD_BOARD_LENGTH, data)} ${R.prop(GC.FIELD_LOAD_BOARD_LENGTH_UOM, data)}`}
      </Box>
    ),
  },
  [GC.FIELD_LOAD_BOARD_WEIGHT]: {
    width: 100,
    name: 'titles:weight',
    customComponent: ({ data }: Object) => (
      G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_WEIGHT, data)) &&
      <Box>
        {`${R.prop(GC.FIELD_LOAD_BOARD_WEIGHT, data)} ${R.prop(GC.FIELD_LOAD_BOARD_WEIGHT_UOM, data)}`}
      </Box>
    ),
  },
  [GC.FIELD_LOAD_BOARD_COMMENT]: {
    width: 150,
    name: 'titles:note',
  },
  [GC.FIELD_LOAD_BOARD_TYPES]: {
    width: 200,
    name: 'titles:load-boards',
    customComponent: ({ data }: Object) => <FilterLoadBoards filter={data} />,
  },
};
