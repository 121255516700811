import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import SingleTextareaForm from '../../../forms/forms/single-textarea-form';
// ui
import { Box, Flex } from '../../../ui';
// feature los-subscription
import { AcceptForm } from './accept-form';
import { acceptLosSubscriptionRequest, rejectLosSubscriptionRequest } from '../actions';
//////////////////////////////////////////////////

const ConfirmAcceptComponent = ({ masterEnterpriseName }: Object) => (
  <Flex fontSize={16} textAlign='center' flexDirection='column'>
    {
      G.isNotNilAndNotEmpty(masterEnterpriseName) &&
      <Flex mb={15}>
        {G.getWindowLocale('titles:master-enterprise-name', 'Master Enterprise Name')}: {masterEnterpriseName}
      </Flex>
    }
    <Flex>{G.getWindowLocale('messages:before-accept', 'Are you sure you want to accept')}?</Flex>
  </Flex>
);

const enhance = compose(
  connect(null, { openModal, closeModal, acceptLosSubscriptionRequest, rejectLosSubscriptionRequest }),
  withHandlers({
    handleAcceptLosSubscription: (props: Object) => () => {
      const { entity, openModal, acceptLosSubscriptionRequest } = props;

      const { guid, linked, masterEnterpriseName } = entity;

      if (linked) {
        const component = <ConfirmAcceptComponent masterEnterpriseName={masterEnterpriseName} />;

        const modal = {
          component,
          options: {
            width: 450,
            controlButtons: [
              {
                type: 'button',
                name: G.getWindowLocale('actions:accept', 'Accept'),
                action: () => acceptLosSubscriptionRequest({ guid }),
              },
            ],
          },
        };

        return openModal(modal);
      }

      const component = (
        <AcceptForm
          initialValues={R.pick([GC.FIELD_MASTER_ENTERPRISE_GUID, GC.FIELD_USE_EXISTENT_ENTERPRISE], entity)}
          submitAction={(values: Object) => acceptLosSubscriptionRequest(R.assoc(GC.FIELD_GUID, guid, values))}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getWindowLocale('titles:accept', 'Accept'));

      openModal(modal);
    },
    handleRejectLosSubscription: (props: Object) => () => {
      const {
        entity,
        openModal,
        closeModal,
        rejectLosSubscriptionRequest,
      } = props;

      const guid = G.getGuidFromObject(entity);

      const rejectReasonValidationSchema = Yup.object().shape({
        value: Yup.string()
          .nullable(true)
          .required(G.getRequiredLocaleTxt())
          .min(0, G.getShouldBeFromToLocaleTxt(0, 1000))
          .max(1000, G.getShouldBeFromToLocaleTxt(0, 1000)),
      });

      const modal = {
        p: 15,
        component: (
          <SingleTextareaForm
            closeModal={closeModal}
            fieldsGroupWidth='min-content'
            labelLocale='titles:reject-reason'
            validationSchema={rejectReasonValidationSchema}
            submitHandler={(rejectReason: string) => {
              rejectLosSubscriptionRequest({ guid, message: rejectReason });
              closeModal();
            }}
          />
        ),
        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: `${G.getWindowLocale('messages:before-reject', 'Are you sure you want to reject')}?`,
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const AcceptDeclineLosSubscriptionComponent = (props: Object) => {
  const {
    entity,
    handleAcceptLosSubscription,
    handleRejectLosSubscription,
  } = props;

  const showAcceptDecline = R.pathEq('DRAFT', [GC.FIELD_STATUS], entity);

  return (
    <Flex
      alignItems='center'
      height='max-content'
      color={G.getTheme('colors.dark.blue')}
    >
      {showAcceptDecline &&
        <Box
          cursor='pointer'
          textDecoration='underline'
          onClick={handleAcceptLosSubscription}
        >
          {G.getWindowLocale('titles:accept', 'Accept')}
        </Box>
      }

      {showAcceptDecline &&
        <Box
          mx={10}
          cursor='pointer'
          textDecoration='underline'
          onClick={handleRejectLosSubscription}
        >
          {G.getWindowLocale('titles:reject', 'Reject')}
        </Box>
      }
    </Flex>
  );
};

export default enhance(AcceptDeclineLosSubscriptionComponent);
