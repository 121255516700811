import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
/////////////////////////////////////////////////

export const withAsyncTelInvoiceAssignments = compose(
  withState('invoiceAssignments', 'setInvoiceAssignments', []),
  withHandlers({
    getInvoiceAssignments: (props: Object) => async () => {
      const { telGuid, setInvoiceAssignments } = props;

      const params = { telGuid };

      const res = await sendRequest('get', endpointsMap.telInvoiceAssignments, { params });

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInvoiceAssignments(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getInvoiceAssignments();
    },
  }),
);
