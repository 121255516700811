import * as Yup from 'yup';
// features
import { validationSchemaChargeObject } from '../payroll/validators';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const validationSchemaMasterInvoiceObject = {
  [GC.FIELD_MASTER_INVOICE_STATUS]: Yup.string()
    .notRequired()
    .nullable(true),
  [GC.FIELD_MASTER_INVOICE_CURRENCY]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_MASTER_INVOICE_DATE_FROM]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_MASTER_INVOICE_DATE_TO]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_MASTER_INVOICE_NET_DAYS]: Yup.number()
    .nullable(true)
    .notRequired()
    .integer(G.getShouldBeIntegerLocaleTxt())
    .min(0, G.getShouldBePositiveLocaleTxt())
    .max(365, G.getShouldBeFromToLocaleTxt(0, 365)),
  [GC.FIELD_MASTER_INVOICE_DUE_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_MASTER_INVOICE_CHECK_NUMBER]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(30, G.getShouldBeFromToLocaleTxt(0, 30)),
  [GC.FIELD_MASTER_INVOICE_CHECK_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_MASTER_INVOICE_CHECK_AMOUNT]: Yup.number()
    .nullable(true)
    .notRequired()
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(12000000, G.getShouldBeFromToLocaleTxt(0, 12000000)),
  [GC.FIELD_MASTER_INVOICE_DEPOSIT_DATE]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_MASTER_INVOICE_CHARGES]: Yup.array()
    .of(validationSchemaChargeObject)
    .nullable(true)
    .notRequired(),
};

export const validationSchemaMasterInvoiceObjectNumber = {
  [GC.FIELD_MASTER_INVOICE_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(50, G.getShouldBeFromToLocaleTxt(0, 50)),
};
