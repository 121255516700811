import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
} from 'react-recompose';
// components
import { FormFooter } from '../../../components';
import { getConfirmModal } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
// forms
import { Form } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedBox, StickedFlex } from '../../../ui';
// feature load-board
import * as H from '../helpers';
import DetailsMap from './details-map';
import ShipmentForm from './shipment-form';
import TripInfo from './details-trip-info';
import AutodialButton from './autodial-button';
import { defaultValues } from '../settings/shipment-form';
import { makeSelectAutodialConfigs, makeSelectIndexedSearchFilterList } from '../selectors';
import {
  toggleAutodial,
  saveShipmentRequest,
  updateShipmentRequest,
  addCompanyFilterRequest,
} from '../actions';
//////////////////////////////////////////////////

const getInitialShipmentValues = (data: Object) => {
  const rate = R.propOr({}, GC.FIELD_LOAD_BOARD_RATE, data);
  const values = {
    ...R.pick(
      [
        GC.FIELD_LOAD_BOARD_NOTE,
        GC.FIELD_LOAD_BOARD_PERSON_TALKED_TO,
      ],
      data,
    ),
    ...R.pick(
      [
        GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE,
        GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY,
      ],
      rate,
    ),
  };

  if (G.isNilOrEmpty(values[GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY])) {
    values[GC.FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY] = GC.DEFAULT_UI_CURRENCY;
  }

  return values;
};

const mainLightColor = G.getTheme('colors.light.mainLight');
const blueColor = G.getTheme('colors.dark.blue');

const HeaderBlock = ({ text, hasCompany, handleAddToFilters }: Object) => (
  <StickedFlex
    px={15}
    left='0'
    height={60}
    bg={G.getTheme('colors.white')}
  >
    <Box fontSize={16} fontWeight='bold'>{text}</Box>
    {
      hasCompany &&
      <Box
        ml={10}
        cursor='pointer'
        onClick={handleAddToFilters}
        title={G.getWindowLocale('titles:add-company-filter', 'Add Company Filter')}
      >
        {I.simpleFilter(blueColor)}
      </Box>
    }
    <AutodialButton styles={{ margin: '0 0 0 auto' }} />
  </StickedFlex>
);

const enhance = compose(
  withProps(({ data, searchFilters }: Object) => ({
    isBookmark: R.or(
      G.isNotNil(R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, 'startLocation'], data)),
      R.any(
        R.compose(
          R.propEq(R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_GUID], data), GC.FIELD_GUID),
          R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT),
        ),
        R.propOr([], 'savedShipments', R.prop(R.prop('searchFilterGuid', data), searchFilters)),
      ),
    ),
  })),
  withState('mapData', 'setMapData', null),
  withState('mapShown', 'setMapShown', true),
  withHandlers({
    submitShipment: ({
      data,
      isBookmark,
      saveShipmentRequest,
      updateShipmentRequest,
    }: Object) => (values: Object) => {
      const submitData = R.mergeRight(data, values);

      if (isBookmark) {
        updateShipmentRequest(submitData);
      } else {
        saveShipmentRequest(submitData);
      }
    },
    handleShowOrHideMap: (props: Object) => () => {
      const { mapShown, setMapShown } = props;

      setMapShown(R.not(mapShown));
    },
    callAutodial: ({ data, configs }: Object) => () => {
      const { autodialApp } = configs;
      const phone = R.prop(GC.FIELD_LOAD_BOARD_PHONE, data);
      const phoneExt = R.prop(GC.FIELD_LOAD_BOARD_PHONE_EXT, data);

      const phoneExtToUse = G.ifElse(G.isNotNilAndNotEmpty(phoneExt), `,,${phoneExt}`, '');

      window.open(G.getCallUrl(autodialApp, `${phone}${phoneExtToUse}`), '_self');
    },
    handleAddToFilters: ({ data, openModal, closeModal, addCompanyFilterRequest }: Object) => () => {
      const company = R.path(
        [GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_COMPANY, GC.FIELD_LOAD_BOARD_COMPANY], data,
      );

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => {
          closeModal();
          addCompanyFilterRequest({
            [GC.FIELD_NAME]: company,
            [GC.FIELD_TYPE]: R.prop(GC.FIELD_TYPE, data),
          });
          closeModal();
        },
        text: `${G.getWindowLocale(
          'messages:block-company-confirmation-text',
          'Are you sure you want to block the company',
        )} - ${company}?`,
      });

      openModal(modalContent);
    },
  }),
  withFormik({
    enableReinitialize: true,
    displayName: 'SHIPMENT_FORM',
    mapPropsToValues: ({ data }: Object) => G.setInitialFormikValues(
      defaultValues,
      getInitialShipmentValues(R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data)),
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitShipment(values),
  }),
  lifecycle({
    componentDidMount() {
      const {
        data,
        configs,
        setMapData,
        callAutodial,
        searchFilters,
        openedFromCell,
      } = this.props;

      const { shipment, searchFilterGuid } = data;
      const { autodialActionOn } = configs;

      const condition = R.or(
        R.equals(openedFromCell, GC.FIELD_LOAD_BOARD_PHONE),
        R.and(autodialActionOn, R.equals(openedFromCell, 'notification')),
      );

      if (condition) callAutodial();

      H.getAsyncStopsWithCoords(shipment, searchFilterGuid, searchFilters).then((mapData: Object) => {
        setMapData(mapData);
      });
    },
  }),
  pure,
);

const ShipmentDetails = enhance((props: Object) => {
  const {
    data,
    mapData,
    mapShown,
    closeModal,
    isBookmark,
    callAutodial,
    handleSubmit,
    searchFilters,
    handleAddToFilters,
    handleShowOrHideMap,
  } = props;

  const type = R.prop(GC.FIELD_LOAD_BOARD_TYPE, data);
  const shipment = R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data);

  const {
    company,
  } = shipment;

  const submitBtnText = isBookmark ?
    G.getWindowLocale('actions:update', 'Update') :
    G.getWindowLocale('actions:save-for-later', 'Save For Later');

  const blockStyles = {
    p: 15,
    width: 525,
    height: 270,
    bg: mainLightColor,
    borderRadius: '4px',
    border: `1px solid ${G.getTheme('colors.borderGray')}`,
  };

  const companyTitle = G.ifElse(
    R.equals(type, GC.EXTERNAL_LOAD_BOARD_CH_ROBINSON),
    H.getLBDisplayValue(type),
    R.propOr(
      G.getWindowLocale('titles:no-company', 'No company'),
      GC.FIELD_LOAD_BOARD_COMPANY,
      company,
    ),
  );

  return (
    <Form
      width='100%'
      height='100%'
      overflow='auto'
      onSubmit={handleSubmit}
      data-testid='load-board-shipment-details-submit'
    >
      <HeaderBlock
        text={companyTitle}
        handleAddToFilters={handleAddToFilters}
        hasCompany={G.isNotNil(R.prop(GC.FIELD_LOAD_BOARD_COMPANY, company))}
      />
      <Flex
        p={15}
        gap={15}
        width={1088}
      >
        <Box {...blockStyles}>
          <TripInfo
            data={data}
            callAutodial={callAutodial}
            searchFilters={searchFilters}
          />
        </Box>
        <Box {...blockStyles}>
          <ShipmentForm {...props} />
        </Box>
      </Flex>
      <StickedBox
        left='0'
        cursor='pointer'
        color={blueColor}
        p='0 20px 5px 20px'
        width='fit-content'
        onClick={(handleShowOrHideMap)}
        data-testid='load-board-shipment-details-toggle-map'
      >
        {G.ifElse(mapShown, 'Hide map', 'Show map')}
      </StickedBox>
      {
        R.and(mapShown, G.isNotNil(mapData)) &&
        <DetailsMap {...mapData} />
      }
      <FormFooter
        closeModal={closeModal}
        submitBtnText={submitBtnText}
        submitStyles={{ width: 'auto' }}
        boxStyles={{
          left: '0',
          mt: 'auto',
          p: '10px 15px',
          position: 'sticky',
          bg: G.getTheme('colors.white'),
        }}
      />
    </Form>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  configs: makeSelectAutodialConfigs(state),
  searchFilters: makeSelectIndexedSearchFilterList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  toggleAutodial,
  saveShipmentRequest,
  updateShipmentRequest,
  addCompanyFilterRequest,
})(ShipmentDetails);
