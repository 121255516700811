import * as R from 'ramda';
import { FieldArray } from 'formik';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Span, Flex, MainActionButton } from '../../../ui';
// feature master-invoice
import PC from '../../permission/role-permission';
import { ChargeAdditionInfo } from './charges-ui';
import { masterInvoiceChargeFieldset } from '../settings/master-invoice-with-charges-settings-new';
import {
  createCharge,
  getChargeFieldName,
  isRateOrQuantityChargeField,
  recalculateChargesOnChangeAccessorial,
} from '../helpers';
//////////////////////////////////////////////////

const chargesArrayName = GC.FIELD_MASTER_INVOICE_CHARGES;

const recalculateChargesOnChangeRateOrQuantity = ({
  value,
  values,
  chargeIndex,
  chargeFieldName,
}: Object) => {
  const prevCharges = R.pathOr([], [chargesArrayName], values);

  const currentChargeWithValue = R.assoc(chargeFieldName, value, R.nth(chargeIndex, prevCharges));

  const currentChargeWithTotal = R.assoc(
    GC.FIELD_CHARGE_TOTAL,
    R.multiply(
      R.propOr(1, GC.FIELD_CHARGE_RATE, currentChargeWithValue),
      R.propOr(1, GC.FIELD_CHARGE_QUANTITY, currentChargeWithValue),
    ),
    currentChargeWithValue,
  );

  return R.update(chargeIndex, currentChargeWithTotal, prevCharges);
};

const getChargeIndexAndCurrentCharges = (props: Object, charge: Object) => {
  const { values } = props;

  const currentCharges = R.pathOr([], [chargesArrayName], values);

  const chargeIdOrGuid = G.getIdOrGuidFromObject(charge);

  const index = R.findIndex(
    (item: Object) => R.or(
      R.propEq(chargeIdOrGuid, GC.FIELD_ID, item),
      R.propEq(chargeIdOrGuid, GC.FIELD_GUID, item),
    ),
    currentCharges,
  );

  return { index, currentCharges };
};

const enhanceChargeFields = compose(
  withHandlers({
    handleRemoveCharge: (props: Object) => (charge: Object) => {
      const { setFieldValue } = props;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.remove(index, 1, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
    handleChangeChargeAccessorial: (props: Object) => (value: string, charge: Object) => {
      const { values, setFieldValue, glCodeMappings, accessorialsConfigs } = props;

      const { index } = getChargeIndexAndCurrentCharges(props, charge);

      const initChargeFields = R.pick([GC.FIELD_COMMENTS], charge);

      setFieldValue(
        chargesArrayName,
        recalculateChargesOnChangeAccessorial({
          value,
          values,
          glCodeMappings,
          initChargeFields,
          chargeIndex: index,
          accessorialsConfigs,
        }),
      );
    },
    handleChangeCharge: (props: Object) => (event: Object, charge: Object) => {
      const { values, setFieldValue, accessorialsConfigs } = props;

      const value = G.getEventTargetValue(event);

      const chargeFieldName = getChargeFieldName(G.getEventTargetName(event));

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      if (R.equals(chargeFieldName, GC.FIELD_CHARGE_RATE_NAME)) {
        return setFieldValue(
          chargesArrayName,
          recalculateChargesOnChangeAccessorial({ value, values, accessorialsConfigs, chargeIndex: index }),
        );
      } else if (isRateOrQuantityChargeField(chargeFieldName)) {
        return setFieldValue(
          chargesArrayName,
          recalculateChargesOnChangeRateOrQuantity({ value, values, chargeFieldName, chargeIndex: index }),
        );
      }

      if (isRateOrQuantityChargeField(chargeFieldName)) {
        return setFieldValue(
          chargesArrayName,
          recalculateChargesOnChangeRateOrQuantity({ value, values, chargeFieldName, chargeIndex: index }),
        );
      }

      const newCharge = R.assoc(chargeFieldName, value, charge);

      const updatedItems = R.update(index, newCharge, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
    handleAddChargeComment: (props: Object) => (charge: Object) => {
      const { setFieldValue } = props;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.update(index, charge, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
  }),
  withChargeComments(),
  pure,
);

const getOptionsFromAccessorials = R.map((item: Object) => ({
  value: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
  label: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
}));

const Header = ({ unshift }: Object) => (
  <Box mt={15} mb={10}>
    <MainActionButton
      mr={15}
      height={30}
      width={120}
      type='button'
      onClick={() => unshift(createCharge())}
    >
      {G.getWindowLocale('titles:add-new-charge', 'Add New Charge')}
    </MainActionButton>
  </Box>
);

const ChargeItem = enhanceChargeFields((props: Object) => {
  const {
    charge,
    currency,
    glDisabled,
    chargeIndex,
    glCodeOptions,
    handleRemoveCharge,
    accessorialsConfigs,
    handleDisableGlCode,
  } = props;

  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Box mt={10} mb={30} width={1700}>
      <Flex>
        <Flex>
          <Flex flexGrow={1}>
            <Span
              mr={10}
              cursor='pointer'
              onClick={() => handleRemoveCharge(charge)}
              title={G.getWindowLocale('titles:remove-charge', 'Remove Charge')}
            >
              {I.trash(darkBlueColor)}
            </Span>
          </Flex>
        </Flex>
        <Fieldset2
          {...props}
          {...G.getArrayFormikProps(props)}
          arrayItem={charge}
          fieldsetType='array'
          itemIndex={chargeIndex}
          glDisabled={glDisabled}
          payrollCurrency={currency}
          arrayName={chargesArrayName}
          fields={masterInvoiceChargeFieldset}
          glCodeOptions={R.or(glCodeOptions, [])}
          currencyOptions={GC.CURRENCY_OPTIONS_2}
          handleDisableGlCode={handleDisableGlCode}
          additionFieldComponentProps={['payrollCurrency']}
          fieldsWrapperStyles={{ width: '100%', flexWrap: 'nowrap' }}
          accessorialsOptions={getOptionsFromAccessorials(R.or(accessorialsConfigs, []))}
        />
        <ChargeAdditionInfo {...props} />
      </Flex>
    </Box>
  );
});

const MasterInvoiceCharges = (props: Object) => {
  const {
    width,
    authorities,
    asyncConfigs,
    glCodeMappings,
    handleRemoveCharge,
    handleChangeCharge,
    accessorialsConfigs,
    handleAddChargeComment,
    handleOpenChargeComment,
    handleChangeChargeAccessorial,
  } = props;

  const glDisabled = G.notContain(PC.GL_CODE_WRITE, authorities);
  const pathToCharges = ['values', chargesArrayName];
  const charges = R.pathOr([], pathToCharges, props);

  const currency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['values', GC.FIELD_CURRENCY],
    props,
  ));

  const glCodeOptions = G.addEmptyOptionToDropDown(
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.INVOICE_GL_CODE,
    ),
    G.getWindowLocale('titles:gl-code', 'GL Code'),
  );

  return (
    <Box
      px={15}
      width={width}
      borderTop='1px solid'
      borderColor={G.getTheme('colors.bgLightGrey')}
    >
      <FieldArray
        name={chargesArrayName}
        render={(formikArrayProps: Object) => (
          <Fragment>
            <Header {...props} unshift={formikArrayProps.unshift} />
            <Box p='5px'>
              {
                R.gt(R.length(charges), 0) &&
                charges.map((charge: string, i: string) => (
                  <ChargeItem
                    {...formikArrayProps}
                    {...G.getFormikProps(props)}
                    key={charge.id}
                    chargeIndex={i}
                    charge={charge}
                    currency={currency}
                    glDisabled={glDisabled}
                    glCodeOptions={glCodeOptions}
                    glCodeMappings={glCodeMappings}
                    handleDisableGlCode={() => glDisabled}
                    handleChangeCharge={handleChangeCharge}
                    handleRemoveCharge={handleRemoveCharge}
                    accessorialsConfigs={accessorialsConfigs}
                    handleAddChargeComment={handleAddChargeComment}
                    handleOpenChargeComment={handleOpenChargeComment}
                    handleChangeChargeAccessorial={handleChangeChargeAccessorial}
                  />
                ))
              }
            </Box>
          </Fragment>
        )}
      />
    </Box>
  );
};

export default MasterInvoiceCharges;
