import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withState,
  lifecycle,
  withProps,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
import { SearchEmail } from '../../components/search-email';
import { closeModal } from '../../components/modal/actions';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncInitialDataOnDidMount,
  withConnectModalAndLoaderActions,
} from '../../hocs';
// ui
import { Box } from '../../ui';
// features mail-sending
import { makeSelectContactList } from './selectors';
import {
  mailSendingFields,
  defaultMailSendingFields,
  mailSendingValidationSchemaObject,
} from './settings/field-settings';
import {
  getContactListRequest,
  getMailSendingRequest,
  createMailSendingRequest,
  updateMailSendingRequest,
} from './actions';
//////////////////////////////////////////////////

const getReplyToFromConfigs = (asyncConfigs: Object) => G.getConfigValueFromStore(
  GC.COMMUNICATION_MAILING_DEFAULT_REPLY_TO,
  asyncConfigs,
);

const getDisableReplyToFromConfigs = (asyncConfigs: Object) => {
  const value = G.getConfigValueFromStore(
    GC.COMMUNICATION_MAILING_LOCK_DEFAULT_REPLY_TO,
    asyncConfigs,
  );

  return G.isTrue(value);
};

const getMapPropsToValues = (props: Object) => {
  const { asyncConfigs, initialValues } = props;

  const attachedDocumentGuids = R.compose(
    R.map(({ guid }: Object) => guid),
    R.pathOr([], ['initialValues', GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS]),
  )(props);

  const replyTo = getReplyToFromConfigs(asyncConfigs);
  const initialMailSendingValues = R.mergeRight(initialValues, { replyTo, attachedDocumentGuids });

  return G.setInitialFormikValues(
    defaultMailSendingFields,
    initialMailSendingValues,
  );
};

export const enhanceCarrierDispatch = compose(
  withProps((props: Object) => {
    const { terminal, asyncConfigs, selectedRate } = props;

    const carrierEmail = R.compose(
      R.filter(G.isNotNilAndNotEmpty),
      R.append(R.path([GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_CARRIER_EMAIL], selectedRate)),
      R.pathOr([], [GC.FIELD_DISPATCH_EMAILS]),
    )(selectedRate);

    if (R.isNil(terminal)) {
      const initialValues = {
        [GC.FIELD_MAIL_SENDING_TO]: carrierEmail,
        [GC.FIELD_MESSAGE_SUBJECT]: G.getConfigValueFromStore(GC.CARRIER_DISPATCH_PROCESS_SUBJECT, asyncConfigs),
        [GC.FIELD_MAIL_SENDING_MESSAGE]: G.getConfigValueFromStore(GC.CARRIER_DISPATCH_PROCESS_MESSAGE, asyncConfigs),
        [GC.FIELD_MAIL_SENDING_REPLY_TO]: G.getConfigValueFromStore(GC.CARRIER_DISPATCH_PROCESS_REPLY_TO, asyncConfigs),
        [GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID]: G.getConfigValueFromStore(
          GC.CARRIER_DISPATCH_PROCESS_DEFAULT_DOCUMENT_TEMPLATE,
          asyncConfigs,
        ),
      };

      return { initialValues };
    }

    if (R.isEmpty(terminal)) {
      const dispatchMessage = G.getWindowLocale(
        'messages:dispatch-process',
        'TEL Carrier terminal email dispatch is not configured',
      );

      const initialValues = { [GC.FIELD_MAIL_SENDING_TO]: carrierEmail };

      return { initialValues, dispatchMessage };
    }

    const fieldEmailTo = R.path([GC.FIELD_EMAIL_TO], terminal);
    const mailSendingTo = G.ifElse(G.isNilOrEmpty(fieldEmailTo), carrierEmail, fieldEmailTo);

    const initialValues = R.mergeRight(
      terminal,
      {
        [GC.FIELD_MAIL_SENDING_TO]: mailSendingTo,
        [GC.FIELD_MAIL_SENDING_MESSAGE]: R.prop(GC.FIELD_MESSAGE, terminal),
      },
    );

    const documents = R.compose(
      R.map(R.prop(GC.FIELD_GUID)),
      R.pathOr([], [GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS]),
    )(initialValues);

    return {
      documents,
      initialValues,
      emails: mailSendingTo,
    };
  }),
  withState('isValidEmails', 'setIsValidEmails', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultMailSendingFields,
      initialValues,
    ),
    validationSchema: Yup.object().shape(mailSendingValidationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => {
      const { customHandleSubmit, createMailSendingRequest } = props;

      if (G.isNotNil(customHandleSubmit)) return customHandleSubmit(values);

      createMailSendingRequest(values);
    },
  }),
  withHandlers({
    handleDisableAttachedDocumentGuids: ({ isMulti }: Object) => () => R.not(isMulti),
    handleBlur: ({ id, setFieldTouched }: Object) => () => setFieldTouched(id, true, false),
    handleDisableReplyToField: ({ asyncConfigs }: Object) => () =>
      getDisableReplyToFromConfigs(asyncConfigs),
    handleChange: ({ setFieldValue }: Object) => (event: Object) =>
      setFieldValue(R.path(['target', 'id'], event), R.path(['target', 'value'], event), false),
  }),
  lifecycle({
    componentDidMount() {
      const { branchGuid, getContactListRequest } = this.props;

      getContactListRequest(branchGuid);
    },
  }),
  pure,
);

const enhanceQuoteMailing = compose(
  withConnectModalAndLoaderActions,
  withProps(({ branchGuid }: Object) => {
    const configsNamesArray = [
      GC.CLO_QUOTE_DEFAULT_EMAIL,
      GC.CLO_QUOTE_DEFAULT_SUBJECT,
      GC.CLO_QUOTE_DEFAULT_MESSAGE,
      GC.COMMUNICATION_MAILING_DEFAULT_REPLY_TO,
    ];

    return {
      branchGuid,
      configsNamesArray,
    };
  }),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    if (G.isNilOrEmpty(asyncConfigs)) return;

    const defaultEmail = G.getConfigValueFromStore(GC.CLO_QUOTE_DEFAULT_EMAIL, asyncConfigs);
    const mailSendingTo = G.ifElse(G.isNilOrEmpty(defaultEmail), [], R.of(Array, defaultEmail));

    const initialValues = {
      [GC.FIELD_MAIL_SENDING_TO]: mailSendingTo,
      [GC.FIELD_MESSAGE_SUBJECT]: G.getConfigValueFromStore(GC.CLO_QUOTE_DEFAULT_SUBJECT, asyncConfigs),
      [GC.FIELD_MAIL_SENDING_MESSAGE]: G.getConfigValueFromStore(GC.CLO_QUOTE_DEFAULT_MESSAGE, asyncConfigs),
      [GC.FIELD_MAIL_SENDING_REPLY_TO]:
        G.getConfigValueFromStore(GC.COMMUNICATION_MAILING_DEFAULT_REPLY_TO, asyncConfigs),
    };

    return { initialValues };
  }),
  withState('isValidEmails', 'setIsValidEmails', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultMailSendingFields,
      initialValues,
    ),
    validationSchema: Yup.object().shape(mailSendingValidationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => {
      const { customHandleSubmit } = props;

      customHandleSubmit(values);
    },
  }),
  withHandlers({
    handleDisableAttachedDocumentGuids: ({ isMulti }: Object) => () => R.not(isMulti),
    handleBlur: ({ id, setFieldTouched }: Object) => () => setFieldTouched(id, true, false),
    handleDisableReplyToField: ({ asyncConfigs }: Object) => () =>
      getDisableReplyToFromConfigs(asyncConfigs),
    handleChange: ({ setFieldValue }: Object) => (event: Object) =>
      setFieldValue(R.path(['target', 'id'], event), R.path(['target', 'value'], event), false),
  }),
  lifecycle({
    componentDidMount() {
      const { branchGuid, getContactListRequest } = this.props;

      getContactListRequest(branchGuid);
    },
  }),
  pure,
);

export const enhance = compose(
  withAsyncConfigs,
  withState('documents', 'setDocuments', []),
  withState('isValidEmails', 'setIsValidEmails', false),
  withPropsOnChange(['initialValues'], ({ setDocuments, initialValues }: Object) => {
    const attachedDocumentGuids = G.getPropFromObject(GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS, initialValues);

    if (G.isNotNilAndNotEmpty(attachedDocumentGuids)) {
      const initDocumentValue = attachedDocumentGuids.map(
        ({ guid }: Object) => guid,
      );

      setDocuments(initDocumentValue);
    }
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => getMapPropsToValues(props),
    validationSchema: Yup.object().shape(mailSendingValidationSchemaObject),
    handleSubmit: (values: Object, { props }: Object) => {
      const { customHandleSubmit, createMailSendingRequest } = props;

      if (G.isNotNil(customHandleSubmit)) return customHandleSubmit(values);

      createMailSendingRequest(values);
    },
  }),
  withHandlers({
    handleDisableAttachedDocumentGuids: ({ isMulti }: Object) => () => R.not(isMulti),
    handleBlur: ({ id, setFieldTouched }: Object) => () => setFieldTouched(id, true, false),
    handleDisableReplyToField: ({ asyncConfigs }: Object) => () =>
      getDisableReplyToFromConfigs(asyncConfigs),
    handleChange: ({ setFieldValue }: Object) => (event: Object) =>
      setFieldValue(R.path(['target', 'id'], event), R.path(['target', 'value'], event), false),
  }),
  lifecycle({
    componentDidMount() {
      const { branchGuid, getContactListRequest } = this.props;

      getContactListRequest(branchGuid);
    },
  }),
  pure,
);

export const enhanceWithAsyncData = compose(
  withAsyncInitialDataOnDidMount,
  pure,
);

const makeCreateDate = (createdDate: string) => (
  R.join(' ', R.slice(0, 2, createdDate.split(' ')))
);

const renderListOptions = (props: Object) => {
  const { documents, isCarrier, asyncInitialData, documentsForOptions } = props;

  let docsData = R.pathOr(R.or(documentsForOptions, []), ['data'], asyncInitialData);

  if (isCarrier) {
    docsData = R.filter(G.isLoadTypeTel, docsData);
  }

  const data = R.map(({ url, guid, createdDate, documentFilename }: Object) => ({
    value: guid,
    label: `${R.or(documentFilename, url)}(${makeCreateDate(createdDate)})`,
  }), docsData);

  if (G.isNotNilAndNotEmpty(documents)) {
    const initValue = R.head(documents);

    return R.filter(
      (item: Object) => G.notEquals(item.label, initValue.label),
      data,
    );
  }

  return data;
};

const getMailSendingFields = (withDispatchDocs: boolean, hiddenFields: Array) => R.filter(
  ({ fieldName }: Object) => {
    if (R.equals(fieldName, GC.FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID)) return G.isTrue(withDispatchDocs);

    return G.notContain(fieldName, hiddenFields);
  },
  mailSendingFields,
);

const MailSendingComponent = (props: Object) => {
  const {
    errors,
    emails,
    message,
    contactList,
    handleSubmit,
    hiddenFields,
    isValidEmails,
    setFieldValue,
    initialValues,
    setFieldError,
    setFieldTouched,
    templateOptions,
    withDispatchDocs,
    setIsValidEmails,
    asyncConfigsLoading,
    handleDisableReplyToField,
    handleDisableAttachedDocumentGuids,
  } = props;

  return (
    <section>
      {
        G.isNotNilAndNotEmpty(message)
        && (
          <Box
            mx='auto'
            fontSize='12px'
            fontWeight='bold'
            width='max-content'
            color={G.getTheme('colors.light.mainRed')}
          >
            {message}
          </Box>
        )
      }
      <form onSubmit={handleSubmit}>
        <Box
          pt={10}
          px={15}
          width={420}
          overflowY='auto'
          maxHeight={G.ifElse(G.isNotNilAndNotEmpty(message), '72vh', '80vh')}
        >
          {
            R.not(asyncConfigsLoading) &&
            <SearchEmail
              my='0px'
              labelPl='5px'
              borderRadius='4px'
              labelFontSize='11px'
              contactList={contactList}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setIsValidEmails={setIsValidEmails}
              fieldName={GC.FIELD_MAIL_SENDING_TO}
              fieldError={R.path([GC.FIELD_TO], errors)}
              labelColor={G.getTheme('colors.darkGrey')}
              borderColor={G.getTheme('colors.dark.grey')}
              emails={R.or(emails, R.path([GC.FIELD_TO], initialValues))}
            />
          }
          <Fieldset2
            {...G.getFormikProps(props)}
            documentTemplateGuid={templateOptions}
            attachedDocumentGuids={renderListOptions(props)}
            fieldsWrapperStyles={{ justifyContent: 'space-between' }}
            fields={getMailSendingFields(withDispatchDocs, R.or(hiddenFields, []))}
            handlers={{ handleDisableReplyToField, handleDisableAttachedDocumentGuids }}
          />
        </Box>
        <FormFooter2 boxStyles={{ p: '0 15px 15px' }} submitDisabled={R.not(isValidEmails)} />
      </form>
    </section>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  contactList: makeSelectContactList(state),
});

export const MailSendingWithConnect = connect(mapStateToProps, {
  closeModal,
  getContactListRequest,
  getMailSendingRequest,
  createMailSendingRequest,
  updateMailSendingRequest,
})(enhance(MailSendingComponent));

export const EmailSendingCarrierDispatch = connect(mapStateToProps, {
  closeModal,
  getContactListRequest,
  createMailSendingRequest,
})(enhanceCarrierDispatch(MailSendingComponent));

export const EmailSendingQuote = connect(mapStateToProps, {
  closeModal,
  getContactListRequest,
})(enhanceQuoteMailing(MailSendingComponent));

export const MailSendingCarrierDispatchWithAsyncInitialData = withAsyncInitialDataOnDidMount(
  EmailSendingCarrierDispatch,
);

export const MailSendingWithAsyncData = enhanceWithAsyncData(MailSendingWithConnect);

export default MailSendingWithConnect;
