import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// feature los-subscription
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
} from './actions';
import {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const freezedField = {
    sequence: 1,
    freezed: true,
    reference: false,
    name: GC.FIELD_ACCEPT_REJECT,
  };

  const reportToUse = G.prependFieldToTableReportFields(selectedReport, freezedField);

  const data = {
    loading,
    itemList,
    totalCount,
    pagination,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    report: reportToUse,
    handleTableTitleFilter,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const LosSubscriptionList = (props: Object) => (
  <ListWrapper p={15}>
    <ZOrderWrapper zIndex='2'>
      <TitlePanel
        {...props}
        withCount={true}
        noExportable={true}
        popperWithCount={true}
        filterProps={FILTER_PARAMS}
        hiddenRightFilterInfo={false}
        type={GC.LOS_SUBSCRIPTION_REPORT}
        title={G.getWindowLocale('titles:los-subscription', 'LOS Subscription')}
      />
    </ZOrderWrapper>
    <ZOrderWrapper zIndex='1'>
      {renderTable(props)}
    </ZOrderWrapper>
  </ListWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  changeDefaultReportRequest,
})(enhance(LosSubscriptionList));
