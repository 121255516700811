// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  COUNTRY_OPTIONS,
  ITEM_VOLUME_OPTIONS,
  getFreightClassOptions,
  ITEM_COMMODITY_OPTIONS,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
  TEMPERATURE_SENSOR_OPTIONS,
  ITEM_TEMPERATURE_TYPE_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const itemEmptyFields = {
  [GC.FIELD_ITEM_ID]: '',
  [GC.FIELD_ITEM_TYPE]: '',
  [GC.FIELD_ITEM_WEIGHT]: '',
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_ITEM_VOLUME]: '',
  [GC.FIELD_ITEM_N_M_F_C]: '',
  [GC.FIELD_ITEM_QUANTITY]: '',
  [GC.FIELD_ITEM_COMMODITY]: '',
  [GC.FIELD_ORIGIN_OF_GOODS]: '',
  [GC.FIELD_ITEM_VOLUME_UOM]: '',
  [GC.FIELD_ITEM_WEIGHT_TYPE]: '',
  [GC.FIELD_ITEM_PACKAGE_TYPE]: '',
  [GC.FIELD_ITEM_DECLARED_VALUE]: '',
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: '',
  [GC.FIELD_ITEM_MONETARY_VALUE]: '',
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: '',
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: '',
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: '',
  [GC.FIELD_ITEM_STACKABILITY]: false,
  [GC.FIELD_CONTAINER_INTERNAL_ID]: '',
  [GC.FIELD_ITEM_TEMPERATURE_HIGH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: '',
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: '',
  [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: '',
  [GC.FIELD_ITEM_STACKABILITY_CONDITION]: '',
  [GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY]: '',
  // car hauler
  [GC.FIELD_VIN]: '',
  [GC.FIELD_BAY]: '',
  [GC.FIELD_YEAR]: '',
  [GC.FIELD_MAKE]: '',
  [GC.FIELD_MODEL]: '',
  [GC.FIELD_COLOR]: '',
  [GC.FIELD_ODOMETER]: '',
  [GC.FIELD_SUB_MODEL]: '',
  [GC.FIELD_WHEELBASE]: '',
  [GC.FIELD_ODOMETER_UOM]: '',
  [GC.FIELD_MANUFACTURER]: '',
  [GC.FIELD_ORIGIN_STATE]: '',
  [GC.FIELD_WHEELBASE_UOM]: '',
  [GC.FIELD_LICENSE_PLATE]: '',
  [GC.FIELD_ORIGIN_COUNTRY]: '',
  [GC.FIELD_OPERATIONAL]: false,
};

export const getNewItemFields = (branchConfigs: Object) => {
  const branchItemValues = G.mapObjectNullFieldsToEmptyStrings(
    G.getDefaultBranchCloItemValuesFromConfigs(branchConfigs),
  );

  return {
    ...itemEmptyFields,
    ...branchItemValues,
    [GC.FIELD_ITEM_INTERNAL_ID]: G.generateGuid(),
  };
};

const itemCommonProps1 = {
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
    width: 100,
  },
};

const itemCommonProps2 = {
  width: 160,
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
    width: 160,
  },
};

const itemCommonProps3 = {
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
    width: 120,
  },
};

export const pickupItemStopGroups = {
  general: {
    fields: [
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: true,
        fieldName: GC.FIELD_ITEM_ID,
        label: G.getWindowLocale('titles:item-id', 'Item ID'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: true,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_WEIGHT,
        label: G.getWindowLocale('titles:weight', 'Weight'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: true,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_WEIGHT_TYPE,
        options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        options: 'itemContainerOptions',
        fieldName: GC.FIELD_CONTAINER_INTERNAL_ID,
        label: G.getWindowLocale('titles:container', 'Container'),
      },
      {
        ...itemCommonProps2,
        type: 'text',
        fieldName: GC.FIELD_DESCRIPTION,
        label: G.getWindowLocale('titles:description', 'Description'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_QUANTITY,
        rejectFieldName: GC.FIELD_ITEM_QUANTITY,
        label: G.getWindowLocale('titles:quantity', 'Quantity'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_PACKAGE_TYPE,
        rejectFieldName: GC.FIELD_ITEM_QUANTITY,
        options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: getFreightClassOptions(),
        fieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        rejectFieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        label: G.getWindowLocale('titles:freight-class', 'Freight Class'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        rejectFieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        label: G.getWindowLocale('titles:monetary-value', 'Monetary Value'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: GC.CURRENCY_OPTIONS_2,
        rejectFieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        fieldName: GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY,
        label: G.getWindowLocale('titles:currency', 'Currency'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_N_M_F_C,
        rejectFieldName: GC.FIELD_ITEM_N_M_F_C,
        label: G.getWindowLocale('titles:nmfc', 'NMFC'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ORIGIN_OF_GOODS,
        rejectFieldName: GC.FIELD_ORIGIN_OF_GOODS,
        options: G.prependEmptyLabelValueOption(COUNTRY_OPTIONS),
        label: G.getWindowLocale('titles:origin-of-goods', 'Origin Of Goods'),
      },
      // car hauler
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MANUFACTURER,
        rejectFieldName: GC.FIELD_MANUFACTURER,
        label: G.getWindowLocale('titles:manufacturer', 'Manufacturer'),
      },
      {
        ...itemCommonProps1,
        type: 'checkbox',
        fieldName: GC.FIELD_OPERATIONAL,
        rejectFieldName: GC.FIELD_OPERATIONAL,
        label: G.getWindowLocale('titles:operational', 'Operational'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_YEAR,
        rejectFieldName: GC.FIELD_YEAR,
        label: G.getWindowLocale('titles:year', 'Year'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MAKE,
        rejectFieldName: GC.FIELD_MAKE,
        label: G.getWindowLocale('titles:make', 'Make'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MODEL,
        rejectFieldName: GC.FIELD_MODEL,
        label: G.getWindowLocale('titles:model', 'Model'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_SUB_MODEL,
        rejectFieldName: GC.FIELD_SUB_MODEL,
        label: G.getWindowLocale('titles:sub-model', 'Submodel'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_WHEELBASE,
        rejectFieldName: GC.FIELD_WHEELBASE,
        label: G.getWindowLocale('titles:wheelbase', 'Wheelbase'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: ITEM_DIMENSIONS_OPTIONS,
        fieldName: GC.FIELD_WHEELBASE_UOM,
        rejectFieldName: GC.FIELD_WHEELBASE,
        label: G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_BAY,
        rejectFieldName: GC.FIELD_BAY,
        label: G.getWindowLocale('titles:bay', 'Bay'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_ODOMETER,
        rejectFieldName: GC.FIELD_ODOMETER,
        label: G.getWindowLocale('titles:odometer', 'Odometer'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ODOMETER_UOM,
        rejectFieldName: GC.FIELD_ODOMETER,
        options: ITEM_ODOMETER_UOM_OPTIONS,
        label: G.getWindowLocale('titles:odometer-uom', 'Odometer UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_DECLARED_VALUE,
        rejectFieldName: GC.FIELD_ITEM_DECLARED_VALUE,
        label: G.getWindowLocale('titles:declared-value', 'Declared Value'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: GC.CURRENCY_OPTIONS_2,
        fieldName: GC.FIELD_ITEM_DECLARED_CURRENCY,
        rejectFieldName: GC.FIELD_ITEM_DECLARED_VALUE,
        label: G.getWindowLocale('titles:currency', 'currency'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_COLOR,
        rejectFieldName: GC.FIELD_COLOR,
        label: G.getWindowLocale('titles:color', 'Color'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_LICENSE_PLATE,
        rejectFieldName: GC.FIELD_LICENSE_PLATE,
        label: G.getWindowLocale('titles:license-plate', 'License Plate'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_VIN,
        rejectFieldName: GC.FIELD_VIN,
        label: G.getWindowLocale('titles:vin', 'VIN'),
      },
    ],
  },
  dimensionsL: {
    rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
    fields: [
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        label: G.getWindowLocale('titles:dimensions-l', 'Dimensions L'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_WIDTH,
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        label: G.getWindowLocale('titles:dimensions-w', 'Dimensions W'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_HEIGHT,
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        label: G.getWindowLocale('titles:dimensions-l', 'Dimensions H'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        shouldCustomChange: true,
        options: ITEM_DIMENSIONS_OPTIONS,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_UOM,
        label: G.getWindowLocale('titles:uom', 'UOM'),
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
      },
    ],
  },
  temperature: {
    rejectFieldName: [GC.FIELD_ITEM_TEMPERATURE_LOW, GC.FIELD_ITEM_TEMPERATURE_TYPE],
    fields: [
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_TEMPERATURE_TYPE_OPTIONS,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_TYPE,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_TYPE,
        label: G.getWindowLocale('titles:temperature-type', 'Temperature Type'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
        label: G.getWindowLocale('titles:temperature-low', 'Temperature Low'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_HIGH,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
        label: G.getWindowLocale('titles:temperature-high', 'Temperature High'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_TEMPERATURE_OPTIONS,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_UOM,
        label: G.getWindowLocale('titles:uom', 'UOM'),
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
      },
    ],
  },
  volume: {
    fields: [
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_VOLUME,
        rejectFieldName: GC.FIELD_ITEM_VOLUME,
        label: G.getWindowLocale('titles:volume', 'Volume'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_VOLUME_OPTIONS,
        fieldName: GC.FIELD_ITEM_VOLUME_UOM,
        rejectFieldName: GC.FIELD_ITEM_VOLUME,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_COMMODITY_OPTIONS,
        fieldName: GC.FIELD_ITEM_COMMODITY,
        rejectFieldName: GC.FIELD_ITEM_COMMODITY,
        label: G.getWindowLocale('titles:commodity', 'Commodity'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
        rejectFieldName: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
        label: G.getWindowLocale('titles:part-num', 'Part Number'),
      },
    ],
  },
  stackability: {
    rejectFieldName: [GC.FIELD_ITEM_STACKABILITY, GC.FIELD_ITEM_TEMPERATURE_SENSOR],
    fields: [
      {
        ...itemCommonProps1,
        type: 'checkbox',
        fieldName: GC.FIELD_ITEM_STACKABILITY,
        rejectFieldName: GC.FIELD_ITEM_STACKABILITY,
        label: G.getWindowLocale('titles:stackability', 'Stackability'),
      },
      {
        ...itemCommonProps2,
        type: 'text',
        isRequired: false,
        rejectFieldName: GC.FIELD_ITEM_STACKABILITY,
        fieldName: GC.FIELD_ITEM_STACKABILITY_CONDITION,
        label: G.getWindowLocale('titles:stackability-condition', 'Stackability Condition'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: TEMPERATURE_SENSOR_OPTIONS,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
        label: G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor'),
      },
    ],
  },
};

export const dropItemStopFields = [
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    shouldValidate: true,
    inputWrapperStyles: {
      mr: 20,
      zIndex: 20,
      width: 300,
    },
    closeMenuOnSelect: false,
    options: GC.FIELD_LOAD_ITEMS,
    fieldName: GC.FIELD_LOAD_ITEMS,
    label: G.getWindowLocale('titles:items', 'items'),
  },
];
