import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// constants
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature los-subscription
import * as A from './actions';
//////////////////////////////////////////////////

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers();

const acceptLosSubscriptionSuccess = (state: Object, { guid, data }: Object) => {
  const { status } = data;

  return P.$set(`itemList.${guid}.${GC.FIELD_STATUS}`, status, state);
};

const rejectLosSubscriptionSuccess = (state: Object, { guid, data }: Object) => {
  const { status, rejectReason } = data;

  return P.$all(
    P.$set(`itemList.${guid}.${GC.FIELD_STATUS}`, status),
    P.$set(`itemList.${guid}.${GC.FIELD_REJECT_REASON}`, rejectReason),
    state,
  );
};

const getMasterEnterpriseListSuccess = (state: Object, data: Object) => (
  P.$set('masterEnterpriseList', data, state)
);

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.acceptLosSubscriptionSuccess]: acceptLosSubscriptionSuccess,
  [A.rejectLosSubscriptionSuccess]: rejectLosSubscriptionSuccess,
  [A.getMasterEnterpriseListSuccess]: getMasterEnterpriseListSuccess,
}, initialState);
