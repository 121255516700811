import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { ConfirmComponent } from '../../components/confirm';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { getRouteTemplateOptions } from '../dispatch-planner-events/helpers';
import { ApplyRouteTemplateForm } from '../dispatch-planner-events/components/apply-route-template-form';
import { addRouteTemplateStops, setInitPlannerDataFromTemplate } from '../dispatch-planner-events/actions';
// helpers/constants
import * as G from '../../helpers';
// ui
import { ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature load-planner-template
import { tableSettings } from './settings/table-settings';
import { report, columnSettings } from './settings/column-settings';
import { removeLoadPlannerTemplateRequest, toggleLoadPlannerTemplateDetails } from './actions';
import { makeSelectItemList, makeSelectListLoading, makeSelectCrossDockLocationList } from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleApplyRouteTemplate: (props: Object) => (templateGuid: string) => {
      const { itemList, openModal, setInitPlannerDataFromTemplate } = props;

      const submitAction = (data: Object) => {
        setInitPlannerDataFromTemplate(data);

        return G.goToRoute(routesMap.routeBuilderEvents);
      };

      const component = (
        <ApplyRouteTemplateForm
          submitAction={submitAction}
          openedFromTemplatePage={true}
          initialValues={{ templateGuid }}
          routeTemplates={getRouteTemplateOptions(itemList)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 550,
          title: G.getWindowLocale('titles:apply-route-template', 'Apply Route Template'),
        },
      };

      openModal(modal);
    },
    handleRemoveLoadPlannerTemplate: (props: Object) => (guid: string, { name }: Object) => {
      const { openModal, removeLoadPlannerTemplateRequest } = props;

      const textLocale = G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove');
      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              version: 2,
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => removeLoadPlannerTemplateRequest(guid),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ loading, initialDataLoaded }: Object) => R.or(
      G.isTrue(loading),
      R.not(initialDataLoaded),
    ),
    renderNothing,
  ),
  pure,
);

const LoadPlannerTemplateList = (props: Object) => {
  const {
    itemList,
    locationList,
    handleApplyRouteTemplate,
    handleRemoveLoadPlannerTemplate,
    toggleLoadPlannerTemplateDetails,
  } = props;

  const actionButtons = [
    {
      iconName: 'trash',
      permissions: null,
      action: handleRemoveLoadPlannerTemplate,
    },
    {
      permissions: null,
      iconName: 'routeBuilder',
      action: handleApplyRouteTemplate,
      iconColor: G.getTheme('colors.dark.blue'),
    },
  ];

  const totalCount = R.length(itemList);

  return (
    <ListWrapper p={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          totalCount={totalCount}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={true}
          title={G.getWindowLocale('titles:load-planner-templates', 'Load Planner Template(s)')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          report={report}
          loading={false}
          itemList={itemList}
          totalCount={totalCount}
          actionButtons={actionButtons}
          tableSettings={tableSettings}
          callbackData={{ locationList }}
          columnSettings={columnSettings}
          toggle={toggleLoadPlannerTemplateDetails}
        />
      </ZOrderWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  locationList: makeSelectCrossDockLocationList(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addRouteTemplateStops,
  setInitPlannerDataFromTemplate,
  toggleLoadPlannerTemplateDetails,
  removeLoadPlannerTemplateRequest,
})(enhance(LoadPlannerTemplateList));
