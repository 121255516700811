import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withVinLookup } from '../../../hocs';
// ui
import { ActionButton } from '../../../ui';
//////////////////////////////////////////////////

export const VINLookup = withVinLookup()(({ item, handleVinLookup }: Object) => (
  <ActionButton
    ml={15}
    width={90}
    height={16}
    fontSize={11}
    type='button'
    minWidth='unset'
    borderRadius='3px'
    onClick={() => handleVinLookup(item)}
  >
    {G.getWindowLocale('actions:vin-lookup', 'VIN Lookup')}
  </ActionButton>
));

export const CalculateFreightClass = ({ item, setFieldValue }: Object) => (
  <ActionButton
    ml={15}
    width={60}
    height={16}
    fontSize={11}
    type='button'
    minWidth='unset'
    borderRadius='3px'
    onClick={() => {
      const freightCalculationValues = R.pickAll(GC.GROUPED_FIELDS.FREIGHT_CLASS_CALCULATION_FIELDS, item);

      if (G.isAnyNilOrEmpty(R.values(freightCalculationValues))) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:fill-weight-dimensions', 'Please, fill the weight and dimensions fields'),
        );
      }

      setFieldValue(GC.FIELD_ITEM_FREIGHT_CLASS, G.calculateFreightClass(freightCalculationValues));
    }}
  >
    {G.getWindowLocale('Calculate', 'Calculate')}
  </ActionButton>
);
