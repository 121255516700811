import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [`${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`]: {
    width: 200,
    name: 'titles:branch',
  },
  [`${GC.FIELD_TEL}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`]: {
    width: 200,
    name: 'titles:account-number',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_TOTAL]: {
    width: 200,
    name: 'titles:invoice-total',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_STATUS]: {
    width: 200,
    name: 'titles:integration-status',
    customComponent: ({ data }: Object) => {
      const status = R.pathOr(' ', [GC.GRC.ACCOUNTING_INTEGRATION_STATUS], data);
      const text = G.getWindowLocale(GC.statusLocaleMap[status], status);
      return (
        <span title={text}>{text}</span>
      );
    },
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:integrated-by',
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:type',
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:status',
    customComponent: ({ data }: Object) => R.pathOr(
      R.prop(GC.GRC.STATUS_DISPLAYED_VALUE, data),
      [GC.FIELD_STATUS, GC.FIELD_DISPLAYED_VALUE],
      data,
    ),
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:mode',
  },
  [GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:service-type',
  },
  [GC.FIELD_SERVICE_DAYS]: {
    width: 200,
    name: 'titles:service-days',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: {
    width: 200,
    name: 'titles:total-trip-weight',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: {
    width: 200,
    name: 'titles:total-trip-weight-uom',
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    name: 'titles:total-trip-distance',
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: {
    width: 200,
    name: 'titles:total-trip-distance-uom',
  },
  [GC.GRC.TEL_TOTAL_INCOME]: {
    width: 200,
    name: 'titles:total-trip-income',
  },
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: {
    name: 'titles:payment-due-date',
  },
  [GC.FIELD_INVOICE_NET_DAYS]: {
    width: 200,
    name: 'titles:net-days',
  },
  [GC.FIELD_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:invoice-number',
  },
  [GC.GRC.GL_CODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:gl-code',
  },
  [GC.FIELD_INVOICE_DATE]: {
    name: 'titles:invoice-date',
  },
  [GC.GRC.PAYMENTS_DEPOSIT_DATE]: {
    name: 'titles:deposit-date',
  },
  [GC.GRC.PAYMENTS_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.GRC.PAYMENTS_CHECK_DATE]: {
    name: 'titles:check-date',
  },
  [GC.GRC.PAYMENTS_CHECK_AMOUNT]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:check-amount',
  },
  [GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER]: {
    width: 200,
    name: 'titles:ach-payment-confirmation',
  },
  [GC.GRC.TEL_ROUTE_NAME]: {
    width: 250,
    name: 'titles:route-name',
  },
  [GC.GRC.DRIVER_LOGIN_ID]: {
    width: 200,
    name: 'titles:login-id',
  },
  [GC.GRC.DRIVER_FIRST_NAME]: {
    width: 200,
    name: 'titles:driver-first-name',
  },
  [GC.GRC.DRIVER_LAST_NAME]: {
    width: 200,
    name: 'titles:driver-last-name',
  },
  [GC.FIELD_CREATED_DATE]: {
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.GRC.DRIVER_PAYROLL_NUMBER]: {
    width: 200,
    name: 'titles:payroll-number',
  },
  [GC.GRC.NORMALIZED_TOTAL_TOTAL]: {
    width: 200,
    name: 'titles:normalized-total',
  },
  [GC.GRC.NORMALIZED_TOTAL_CURRENCY]: {
    width: 200,
    name: 'titles:normalized-currency',
  },
  [GC.FIELD_MAIN_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:linehaul',
  },
  [GC.FIELD_DISCOUNT_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:discount',
  },
  [GC.FIELD_FUEL_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:fuel',
  },
  [GC.FIELD_ADDITIONAL_CHARGES_TOTAL]: {
    width: 150,
    name: 'titles:other-accessorials',
  },
  [GC.FIELD_BALANCE]: {
    width: 150,
    name: 'titles:balance',
  },
  [GC.GRC.TEL_CLOS_BRANCH_NAME]: {
    width: 200,
    name: ['titles:clo', 'titles:branch'],
  },
  [GC.GRC.DRIVER_FULL_NAME]: {
    width: 200,
    name: 'titles:driver-full-name',
  },
  [GC.GRC.DRIVER_FLEET_VENDOR_NAME]: {
    width: 200,
    name: 'titles:vendor-name',
  },
};
