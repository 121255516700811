import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');

const DocumentNameComponent = (props: Object) => {
  const { document, downloadDocument, handlePreviewDocument } = props;

  const documentFilename = R.prop(GC.FIELD_DOCUMENT_FILE_NAME, document);

  if (G.isNilOrEmpty(documentFilename)) {
    const url = R.prop(GC.FIELD_DOCUMENT_URL, document);

    return (
      <Box width='100%' fontSize={12} p='10px 10px 0 29px'>
        <Flex>
          <TextComponent
            maxWidth='65%'
            display='block'
            withEllipsis={true}
          >
            <StyledLink
              target='_blank'
              color={blueColor}
              href={G.makeURLString(url)}
              title={`${G.getWindowLocale('titles:click-to-see-details', 'Click to see details')} - ${url}`}
            >
              {url}
            </StyledLink>
          </TextComponent>
          <TextComponent>
            &nbsp;{`- ${R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], document)}`}
          </TextComponent>
        </Flex>
      </Box >
    );
  }

  return (
    <Flex width='100%' fontSize={12} p='6px 10px 0 10px'>
      <Box
        pr='6px'
        cursor='pointer'
        title={G.getWindowLocale('actions:download', 'Download')}
        onClick={() => downloadDocument({
          isMasterDocument: false,
          [GC.FIELD_LOAD_TYPE]: document[GC.FIELD_LOAD_TYPE],
          params: {
            [GC.FIELD_FILE_NAME]: documentFilename,
            [GC.FIELD_PRIMARY_OBJECT_GUID]: document[GC.FIELD_PRIMARY_OBJECT_GUID],
          },
        })}
      >
        {I.downloadDocument(G.getTheme('colors.darkRed'))}
      </Box>
      <Flex width='95%'>
        <TextComponent
          maxWidth='65%'
          display='block'
          cursor='pointer'
          color={blueColor}
          withEllipsis={true}
          onClick={() => handlePreviewDocument(document)}
          title={`${G.getWindowLocale('titles:click-for-preview', 'Click For Preview')} - ${documentFilename}`}
        >
          {documentFilename}
        </TextComponent>
        <TextComponent>
          &nbsp;{`- ${R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], document)}`}
        </TextComponent>
      </Flex>
    </Flex>
  );
};

export default DocumentNameComponent;
