import * as R from 'ramda';
import React from 'react';
import FileSaver from 'file-saver';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { globalCleanReports } from '../../common/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { sendLogOutRequest } from '../auth/actions';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAvailableDocumentTypes } from '../configurations/actions';
import { getAllAvailableCloRefTypesRequest } from '../reference/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature master-invoice
import * as A from './actions';
import * as H from './helpers';
import * as C from './constants';
import { report } from './settings/table-settings';
import {
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
  makeSelectMasterInvoiceConfigs,
} from './selectors';
//////////////////////////////////////////////////

const requiredFields = [
  GC.FIELD_INVOICES_COUNT,
  GC.FIELD_INVOICES_TOTAL,
  GC.GRC.BILL_TO_LOCATION_NAME,
  GC.GRC.STATUS_DISPLAYED_VALUE,
  GC.FIELD_MASTER_INVOICE_NUMBER,
  GC.FIELD_MASTER_INVOICE_DATE_TO,
  GC.FIELD_MASTER_INVOICE_DATE_FROM,
];

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );
    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );
    const reqBody = {
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      fields: G.addRequiredFields(G.getOrElse(reportParams, 'fields', []), requiredFields),
    };
    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoiceList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
      yield put(A.setListLoading(false));
      yield put(closeLoader());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
      yield put(A.setListLoading(false));
      yield put(closeLoader());
    }
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetItemListSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAddMasterInvoices({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = H.getMasterInvoiceRequestData(payload);
    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoice, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleAddMasterInvoices fail');
    }
    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleAddMasterInvoices exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateMasterInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: R.dissoc('callback', payload) };
    const res = yield call(sendRequest, 'put', endpointsMap.customerMasterInvoice, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isFunction(R.prop('callback', payload))) {
        payload.callback();
      } else {
        yield put(A.resetListAndPagination());
        yield call(handleGetItemListSaga, { payload: true });
      }
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateMasterInvoiceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateMasterInvoiceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleRefreshMasterInvoiceDetailsSaga({ payload }: Object) {
  try {
    yield put(A.setMasterInvoiceDetailsLoading(payload));
    const configs = yield select(makeSelectMasterInvoiceConfigs());
    const params = {
      [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: payload,
      [C.DOCUMENT_TYPES]: R.join(',', R.pathOr([], ['defaultDocumentTypeGuids'], configs)),
    };
    const res = yield call(sendRequest, 'get', endpointsMap.customerMasterInvoiceInvoices, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMasterInvoiceDetailsSuccess({
        guid: payload,
        details: data,
      }));
    } else {
      yield put(A.getMasterInvoiceDetailsError(payload));
      yield call(G.handleFailResponse, res, 'handleRefreshMasterInvoiceDetailsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleRefreshMasterInvoiceDetailsSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetMasterInvoiceDetails({ payload }: Object) {
  try {
    yield put(A.toggleMasterInvoiceDetails(payload.guid));
    if (R.and(R.not(payload.expanded), R.isNil(R.prop('details', payload)))) {
      yield put(A.refreshMasterInvoiceDetailsRequest(payload.guid));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetMasterInvoiceDetails exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateMasterInvoiceInvoicesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: R.dissoc('callback', payload) };
    const res = yield call(sendRequest, 'put', endpointsMap.updateCustomerMasterInvoiceInvoices, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      if (G.isFunction(R.prop('callback', payload))) {
        payload.callback();
      } else {
        yield put(A.collapseAndResetMasterInvoiceInvoices(data));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateMasterInvoiceInvoicesSaga fail');
    }
    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateMasterInvoiceInvoicesSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleRemoveInvoiceFromMasterInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.removeInvoiceFromCustomerMasterInvoice;
    const res = yield call(sendRequest, 'put', endpoint, { params: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeMasterInvoiceInvoiceFromUI({
        masterInvoice: data,
        invoiceGuid: payload.invoiceGuid,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'handleRemoveInvoiceFromMasterInvoiceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleRemoveInvoiceFromMasterInvoiceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteMasterInvoicesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guids, callback } = payload;
    const res = yield call(sendRequest, 'delete', endpointsMap.customerMasterInvoice, { data: guids });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) {
        callback();
      } else if (R.gt(guids.length, 1)) {
        yield put(A.resetListAndPagination());
        yield call(handleGetItemListSaga, { payload: true });
      } else {
        yield put(A.removeMasterInvoiceFromUI(R.head(guids)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteMasterInvoicesSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleDeleteMasterInvoicesSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAvailableReportsRequest({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const { reportType } = payload;

    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      yield put(A.setReports(reports));

      if (G.isNilOrEmpty(reports)) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.CUSTOMER_MASTER_INVOICE_REPORT)));
      }

      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(reports);
        const usedReport = G.ifElse(
          G.isNilOrEmpty(R.path(['fields'], defaultReport)),
          R.assoc('fields', report.fields, defaultReport),
          defaultReport,
        );
        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        {
          notSetUsedReport: true,
          payload: {
            reportType: GC.CUSTOMER_MASTER_INVOICE_REPORT,
            pathname: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
          },
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(data)));
      yield call(
        handleAvailableReportsRequest,
        {
          notSetUsedReport: true,
          payload: {
            reportType: GC.CUSTOMER_MASTER_INVOICE_REPORT,
            pathname: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
          },
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.CUSTOMER_MASTER_INVOICE_REPORT,
            pathname: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
          },
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());
    const reqBody = {
      fields: payload.fields,
      orderFields: payload.orderFields,
      searchCriteria: payload.searchCriteria,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };
    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ filterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoiceReportExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeBranch() {
  try {
    yield put(A.setInitialState());
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeBranch exception');
  }
}

function* handleUpdateInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { invoice, updateFrom } = payload;
    const res = yield call(sendRequest, 'put', endpointsMap.telInvoice, { data: invoice });
    const { status, data } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
      yield put(A.updateInvoiceSuccess({ invoice: data, updateFrom }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateInvoiceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateInvoiceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetMasterInvoiceXMLSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { fileName, masterInvoiceGuid } = payload;
    const options = {
      resType: 'arraybuffer',
      params: { masterInvoiceGuid },
    };
    const endpoint = endpointsMap.customerMasterInvoiceXmlFile;
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { status, data } = res;
    if (G.isResponseSuccess(status)) {
      FileDownload(data, `${fileName}.xml`);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetMultiMasterInvoiceXMLSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetMultiMasterInvoiceXMLSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleSendToQuickBookSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_CURRENT_BRANCH, currentBranchGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoiceSendToQuickBooks, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleSendToQuickBookSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleSendToQuickBookSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleSendToSageIntacctSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_CURRENT_BRANCH, currentBranchGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.sendCustomerMasterInvoiceToSageIntacct, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleSendToSageIntacctSaga fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleSendToSageIntacctSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* getConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield put(A.getConfigsByNamesSuccess(mappedConfigs));
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDownloadDocument({ payload }: Object) {
  try {
    const { params, loadType, isMasterDocument } = payload;
    const options = {
      params,
      resType: 'arraybuffer',
    };
    const endpoint = G.ifElse(
      isMasterDocument,
      endpointsMap.customerMasterInvoiceDownloadDocument,
      G.ifElse(
        R.equals(loadType, GC.LOAD_TYPE_CLO),
        endpointsMap.cloDocumentDownloadFile,
        endpointsMap.telDocumentDownloadFile,
      ),
    );
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status, headers } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob(
        R.of(Array, data),
        { type: R.pathOr('application/pdf', ['content-type'], headers) },
      );
      FileSaver.saveAs(file, params[GC.FIELD_FILE_NAME]);
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadDocument fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleDownloadDocument exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintCloInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: R.omit(['callback', GC.FIELD_INVOICE_MASTER_INVOICE_GUID], payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerInvoiceExport, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isFunction(R.path(['callback'], payload))) {
        payload.callback();
      } else {
        yield put(A.refreshMasterInvoiceDetailsRequest(payload[GC.FIELD_INVOICE_MASTER_INVOICE_GUID]));
      }
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintCloInvoiceSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handlePrintCloInvoiceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintMasterInvoiceSaga({ payload }: Object) {
  try {
    yield call(G.showToastrMessage, 'info', 'messages:downloading-file');

    yield put(closeModal());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const endpoint = G.ifElse(
      G.isNotNil(G.getPropFromObject([GC.FIELD_INVOICE_MASTER_INVOICE_GUID], payload)),
      endpointsMap.customerMasterInvoiceExport,
      endpointsMap.customerMasterInvoiceMassPrint,
    );

    const options = {
      data: {
        ...payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseFail(status)) {
      yield call(G.handleFailResponse, res, 'handlePrintMasterInvoiceSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintMasterInvoiceSaga exception');
  }
}

function* handleUploadFilesSaga(payload: Object) {
  try {
    const options = {
      data: {
        ...payload,
        format: GC.EXTENSION_PDF,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoiceInvoicesExport, options);
    const { status } = res;
    if (G.isResponseFail(status)) {
      yield call(G.handleFailResponse, res, 'handleUploadFilesSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUploadFilesSaga exception');
  }
}

function* handlePrintInvoicesSaga({ payload }: Object) {
  try {
    yield put(closeModal());
    const data = R.omit([C.INTEGRATION_TYPE], payload);
    yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    yield call(handleUploadFilesSaga, data);
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintInvoicesSaga exception');
  }
}

function* handleCreateFactoringDocument({ payload }: Object) {
  try {
    const currentBranch = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        ...R.dissoc(C.MERGE_FILES, payload),
        [GC.CURRENT_BRANCH]: currentBranch,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.createFactoringDocument, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'info', 'messages:document-generating');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateFactoringDocument fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleCreateFactoringDocument exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleExportFactoringDocument({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { documentGuid: payload.documentGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoiceFactoringExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(payload.callback)) payload.callback();
      yield put(closeLoader());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield put(closeLoader());
      yield call(G.handleFailResponse, res, 'handleExportFactoringDocument fail');
    }
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleExportFactoringDocument exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateAndExportFactoringDocument({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranch = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        ...R.dissoc(C.MERGE_FILES, payload),
        [GC.CURRENT_BRANCH]: currentBranch,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.createAndExportFactoringDocument, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(closeLoader());
      yield call(G.showToastrMessage, 'info', 'messages:document-generating');
    } else {
      yield put(closeLoader());
      yield call(G.handleFailResponse, res, 'handleCreateAndExportFactoringDocument fail');
    }
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleCreateAndExportFactoringDocument exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetFactoringCompanyFileRequestSaga({ payload }: Object) {
  try {
    const { fileName, factoringType, fileExtension, masterInvoiceGuid } = payload;

    const options = {
      resType: 'arraybuffer',
      params: { factoringType, masterInvoiceGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.exportFactoringDocument, options);

    const { data, status, request } = res;

    if (G.isResponseSuccess(status)) {
      const file = new window.Blob(R.of(Array, data));
      const filename = R.or(G.getFileNameFromResponseDispositionHeader(request), `${fileName}.${fileExtension}`);
      FileSaver.saveAs(file, filename);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetFactoringCompanyFileRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetFactoringCompanyFileRequestSaga exception');
  }
}

function* handleUpdateCloInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { invoice, updateFrom } = payload;
    const res = yield call(sendRequest, 'put', endpointsMap.cloInvoice, { data: invoice });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
      yield put(A.updateInvoiceSuccess({ updateFrom, invoice: data }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCloInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateCloInvoiceRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintByReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const options = {
      params: payload,
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoicePrintByReport, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, GC.CUSTOMER_MASTER_INVOICE_REPORT);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintByReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintByReportSaga exception');
  }
}

function* handleGetXMLByReportSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const options = {
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.customerMasterInvoiceXmlFileByReport, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, `${GC.CUSTOMER_MASTER_INVOICE_REPORT}.xml`);
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetXMLByReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetXMLByReportSaga exception');
  }
}

function* handleQbIIFImportSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      resType: 'arraybuffer',
      data: R.assoc(GC.FIELD_CURRENT_BRANCH, currentBranchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.customerMasterInvoiceExportIIF,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, `${R.head(payload)}.txt`);
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, G.convertArrayBufferFailResponse(res), 'handleQbIIFImportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleQbIIFImportSaga exception');
  }
}

function* handleCreateCloDocumentSaga({ payload }: Object) {
  const { reqData, callback, setSubmitting, masterInvoiceGuid } = payload;

  try {
    yield put(openLoader());
    const data = G.makeDataForDocument(reqData);
    const options = { data };
    const res = yield call(sendRequest, 'post', endpointsMap.cloDocument, options);
    if (G.isResponseSuccess(res.status)) {
      if (G.isFunction(callback)) {
        callback();
      } else {
        yield put(A.refreshMasterInvoiceDetailsRequest(masterInvoiceGuid));
      }
      yield put(closeModal());
    } else {
      G.callFunction(setSubmitting);
      yield call(G.handleFailResponse, res, 'handleCreateCloDocumentSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    G.callFunction(setSubmitting);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCloDocumentSaga exception');
  }
}

function* exportCustomerMasterInvoiceToEDIRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: {
        [GC.FIELD_INVOICE_MASTER_INVOICE_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.exportCustomerMasterInvoiceToEdi, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:request-processing');
    } else {
      yield call(G.handleFailResponse, res, 'exportCustomerMasterInvoiceToEDIRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'exportCustomerMasterInvoiceToEDIRequest exception');
  }
}

function* getAvailableFactoringTypesRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.getActiveFactoringIntegration, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableFactoringTypesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableFactoringTypesRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAvailableFactoringTypesRequest exception');
  }
}

const CONFIGS_ARR = [
  GC.CARRIER_DOCUMENT_TYPE,
  GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_STATUS,
  GC.INVOICE_CLO_MASTER_INVOICE_DATE_RANGE_DAYS,
  GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_GROUP_BY,
  GC.INVOICE_CLO_MASTER_INVOICE_GROUP_BY_REFERENCE,
  GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED,
  GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES,
  GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_INVOICE_PAYMENT_TERMS,
  GC.INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS,
];

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload.pathname, GC.CHECK_VISIT_CUSTOMER_MASTER_INVOICES_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield call(getAvailableFactoringTypesRequest);
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    yield put(getAllAvailableCloRefTypesRequest(branchGuid));
    yield put(A.getConfigsByNamesRequest({
      branchGuid,
      names: R.join(',', CONFIGS_ARR),
    }));
    yield put(A.setReportPending());
    yield call(handleAvailableReportsRequest, { payload });
    yield call(handleGetItemListSaga, { payload: true });
    yield put(getAvailableDocumentTypes());
    yield put(closeLoader());
    break;
  }
}

export function* CustomerMasterInvoiceWatcherSaga() {
  yield takeLatest(sendLogOutRequest, handleChangeBranch);
  yield takeLatest(globalCleanReports, handleChangeBranch);
  yield takeLatest(A.downloadDocument, handleDownloadDocument);
  yield takeLatest(A.qbIIFImportRequest, handleQbIIFImportSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.addMasterInvoices, handleAddMasterInvoices);
  yield takeLatest(A.printInvoicesRequest, handlePrintInvoicesSaga);
  yield takeLatest(A.updateInvoiceRequest, handleUpdateInvoiceSaga);
  yield takeLatest(A.printByReportRequest, handlePrintByReportSaga);
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  yield takeLatest(A.getXMLByReportRequest, handleGetXMLByReportSaga);
  yield takeLatest(A.sendToQuickBookRequest, handleSendToQuickBookSaga);
  yield takeLatest(A.printCloInvoiceRequest, handlePrintCloInvoiceSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.createCloDocumentRequest, handleCreateCloDocumentSaga);
  yield takeLatest(A.sendToSageIntacctRequest, handleSendToSageIntacctSaga);
  yield takeLatest(A.getMasterInvoiceDetails, handleGetMasterInvoiceDetails);
  yield takeLatest(A.updateCloInvoiceRequest, handleUpdateCloInvoiceRequest);
  yield takeLatest(A.createFactoringDocument, handleCreateFactoringDocument);
  yield takeLatest(A.exportFactoringDocument, handleExportFactoringDocument);
  yield takeLatest(A.printMasterInvoiceRequest, handlePrintMasterInvoiceSaga);
  yield takeLatest(A.getMasterInvoiceXMLRequest, handleGetMasterInvoiceXMLSaga);
  yield takeLatest(GC.VISIT_CUSTOMER_MASTER_INVOICES_PAGE, handleVisitPageSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.deleteMasterInvoicesRequest, handleDeleteMasterInvoicesSaga);
  yield takeLatest(A.handleUpdateMasterInvoiceRequest, handleUpdateMasterInvoiceSaga);
  yield takeLatest(A.updateMasterInvoiceInvoices, handleUpdateMasterInvoiceInvoicesSaga);
  yield takeLatest(A.getFactoringCompanyFileRequest, handleGetFactoringCompanyFileRequestSaga);
  yield takeLatest(A.removeInvoiceFromMasterInvoice, handleRemoveInvoiceFromMasterInvoiceSaga);
  yield takeLatest(A.createAndExportFactoringDocument, handleCreateAndExportFactoringDocument);
  yield takeLatest(A.refreshMasterInvoiceDetailsRequest, handleRefreshMasterInvoiceDetailsSaga);
  yield takeLatest(A.exportCustomerMasterInvoiceToEDIRequest, exportCustomerMasterInvoiceToEDIRequest);
}

export default CustomerMasterInvoiceWatcherSaga;
