import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, lifecycle, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { ConfirmComponent } from '../../../components/confirm';
import { FormSectionHeader } from '../../../components/form-section-header';
// features
import { renderConfirmationModal } from '../../template/helpers';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { ReferenceSection } from '../../reference/components/reference-section';
import { changeTemplateItemStatusRequest } from '../../template/report/actions';
// forms
import { Form, Fieldset2 } from '../../../forms';
import { ItemTemplateSearch } from '../../../forms/forms/template-search/components/item-template-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedBox } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature items
import {
  createReferenceRequest,
  getItemReferenceRequest,
  deleteItemReferenceRequest,
  updateItemReferenceRequest,
} from '../actions';
import {
  containerField,
  getDefaultFields,
  getItemStatusField,
  hazmatSectionFields,
  thirdBaseSectionFields,
  secondBaseSectionFields,
  fourthBaseSectionFields,
  getFirstBaseSectionFields,
  getCarHaulerSectionFields,
  commodityDetailsSectionFields,
  getBaseSectionValidationSchema,
  commodityDetailsValidationSchema,
  getHazmatSectionValidationSchema,
  getCarHaulerSectionValidationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const validationSchema = Yup.lazy((values: Object) => Yup.object()
  .shape(commodityDetailsValidationSchema)
  .shape(getBaseSectionValidationSchema(values))
  .shape(getHazmatSectionValidationSchema(values))
  .shape(getCarHaulerSectionValidationSchema(values)));

export const enhance = compose(
  withState('references', 'setReferences', []),
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => R.or(initialValues, null)),
  withFormik({
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      getDefaultFields(),
      initialValues,
    ),
  }),
  withHandlers({
    getItemTemplateDetails: ({ guid, openLoader, closeLoader, setInitialValues }: Object) => async () => {
      try {
        if (G.isNilOrEmpty(guid)) return;

        openLoader();

        const endpoint = endpointsMap.getCurrentItemEndpoint(guid);

        const res = await sendRequest('get', endpoint);

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          setInitialValues(data);
        } else {
          G.handleFailResponseSimple(res);
        }

        closeLoader();
      } catch (error) {
        G.catchSendRequestSimple(error, 'getItemTemplateDetails', closeLoader);
      }
    },
    handleChangeStatus: (props: Object) => (entity: Object) => {
      const { openModal } = props;
      const { active } = entity;

      const action = G.ifElse(G.isTrue(active), 'deactivate', 'activate');
      const modal = renderConfirmationModal({ ...props, entity, action });

      openModal(modal);
    },
    handleCreateReference: (props: Object) => (values: Object) => {
      const { guid, references, setReferences, createReferenceRequest } = props;

      createReferenceRequest({
        args: R.assoc('primaryObjectGuids', R.of(Array, guid), values),
        callback: (data: Object) => setReferences(R.assoc(G.getGuidFromObject(data), data, references)),
      });
    },
    handleUpdateReference: (props: Object) => (reference: Object) => {
      const { references, setReferences, updateItemReferenceRequest } = props;

      updateItemReferenceRequest({
        reference,
        callback: (data: Object) => setReferences(R.assoc(G.getGuidFromObject(data), data, references)),
      });
    },
    handleRemoveReference: (props: Object) => (guid: string) => {
      const { openModal, closeModal, references, setReferences, deleteItemReferenceRequest } = props;

      const component = (
        <ConfirmComponent
          name={R.path([guid, GC.FIELD_NAME], references)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:remove', 'Remove'),
              action: () => {
                closeModal();
                deleteItemReferenceRequest({ guid, callback: () => setReferences(R.dissoc(guid, references)) });
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleSearchItem: (props: Object) => () => {
      const { openModal, closeModal, branchGuid, initialValues, setInitialValues } = props;

      const searchTemplateRequest = (item: Object) => {
        const searchTemplateItemFieldsOmit = [
          GC.FIELD_ITEM_GUID,
          GC.FIELD_LOAD_TYPE,
          GC.FIELD_ITEM_VERSION,
          GC.FIELD_ITEM_LOAD_GUID,
          GC.FIELD_ITEM_INTERNAL_ID,
        ];

        setInitialValues(
          R.mergeRight(
            R.pick(searchTemplateItemFieldsOmit, R.or(initialValues, {})),
            R.omit(searchTemplateItemFieldsOmit, item),
          ),
        );

        closeModal();
      };

      const modalContent = (
        <ItemTemplateSearch
          closeModal={closeModal}
          branchGuid={branchGuid}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  lifecycle({
    componentDidMount() {
      const { guid, setReferences, getItemReferenceRequest, getItemTemplateDetails } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) {
        getItemTemplateDetails();
        getItemReferenceRequest({ guid, callback: setReferences });
      }
    },
  }),
  pure,
);

const fieldsWrapperStyles = { mt: 25, mb: 20, px: 15 };

const Divider = () => (
  <Box height='1px' width='100%' borderTop='1px solid' borderColor={G.getTheme('colors.dark.blue')} />
);

const TemplateSearchSection = ({ bgColor, handleSearchItem }: Object) => (
  <StickedBox top='0px' zIndex={13} width='100%' bg={bgColor}>
    <Flex width='100%' p='10px 15px' jc='flex-start'>
      <Box fontSize={14} fontWeight='bold'>
        {G.getWindowLocale('titles:item', 'Item')}
      </Box>
      <Flex ml={10} cursor='pointer' onClick={handleSearchItem}>
        {I.globalSearch2(G.getTheme('icons.iconColor'))}
      </Flex>
    </Flex>
  </StickedBox>
);

const StatusSection = (props: Object) => {
  const { initialValues, handleChangeStatus } = props;

  const itemStatus = R.path([GC.FIELD_ACTIVE], initialValues);

  return (
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={getItemStatusField(itemStatus)}
      handleCustomChange={() => handleChangeStatus(initialValues)}
      fieldsWrapperStyles={{ ...fieldsWrapperStyles, justifyContent: 'flex-end' }}
    />
  );
};

const ContainerSection = (props: Object) => (
  <FormSectionHeader
    expanded={true}
    title={G.getWindowLocale('titles:container', 'Container')}
  >
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={containerField}
      containerOptions={props.containerOptions}
      fieldsWrapperStyles={{ ...fieldsWrapperStyles, justifyContent: 'flex-start' }}
    />
  </FormSectionHeader>
);

const BaseSection = (props: Object) => (
  <FormSectionHeader
    expanded={true}
    title={G.getWindowLocale('titles:base-item', 'Base Item Field')}
  >
    <Fieldset2
      {...G.getFormikProps(props)}
      fieldsWrapperStyles={fieldsWrapperStyles}
      fields={getFirstBaseSectionFields(props.values, props.setFieldValue)}
    />
    <Divider />
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={secondBaseSectionFields}
      fieldsWrapperStyles={fieldsWrapperStyles}
    />
    <Divider />
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={thirdBaseSectionFields}
      fieldsWrapperStyles={{ ...fieldsWrapperStyles, mb: 25 }}
    />
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fourthBaseSectionFields}
      fieldsWrapperStyles={{ ...fieldsWrapperStyles, mt: 0 }}
    />
  </FormSectionHeader>
);

const CommodityDetailsSection = (props: Object) => (
  <FormSectionHeader
    expanded={true}
    title={G.getWindowLocale('titles:commodity-details', 'Commodity Details')}
  >
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={commodityDetailsSectionFields}
      fieldsWrapperStyles={fieldsWrapperStyles}
    />
  </FormSectionHeader>
);

const HazmatSection = (props: Object) => (
  <FormSectionHeader
    expanded={true}
    title={G.getWindowLocale('titles:hazmat-fields', 'Hazmat Fields')}
  >
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={hazmatSectionFields}
      fieldsWrapperStyles={fieldsWrapperStyles}
    />
  </FormSectionHeader>
);

const CarHaulerSection = (props: Object) => {
  const { values, setValues } = props;

  const updateItemDetails = (newValues: Object) => setValues(R.mergeRight(values, newValues));

  return (
    <FormSectionHeader
      expanded={true}
      title={G.getWindowLocale('titles:car-hauler', 'Car Hauler')}
    >
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={fieldsWrapperStyles}
        fields={getCarHaulerSectionFields(values, updateItemDetails)}
      />
    </FormSectionHeader>
  );
};

const ItemForm = (props: Object) => {
  const { guid, handleSubmit, withContainer, handleSearchItem, withItemTemplateSearch } = props;

  const bgColor = G.getTheme('colors.whiteGrey');
  const isEditMode = G.isNotNilAndNotEmpty(guid);
  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  return (
    <Box
      width={910}
      overflow='auto'
      maxHeight='87vh'
    >
      {
        G.isTrue(withItemTemplateSearch) &&
        <TemplateSearchSection bgColor={bgColor} handleSearchItem={handleSearchItem} />
      }
      <Form onSubmit={handleSubmit}>
        {
          G.isTrue(isEditMode) && <StatusSection {...props} />
        }
        {
          R.and(G.isTrue(useContainers), G.isTrue(withContainer)) &&
          <ContainerSection {...props} />
        }
        <BaseSection {...props} />
        <CommodityDetailsSection {...props} />
        <HazmatSection {...props} />
        <CarHaulerSection {...props} />
        {
          G.isTrue(isEditMode) && <ReferenceSection {...props} scope={GC.REF_SCOPE_NAME_ITEM} />
        }
        <StickedBox p={15} zIndex={12} bottom='0px' bg={bgColor}>
          <FormFooter2 />
        </StickedBox>
      </Form>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openLoader,
  closeLoader,
  createReferenceRequest,
  getItemReferenceRequest,
  deleteItemReferenceRequest,
  updateItemReferenceRequest,
  changeTemplateItemStatusRequest,
})(enhance(ItemForm));
