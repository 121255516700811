const FIELD_TEMPLATE = 'template';
const FIELD_ORIGIN_CITY = 'originCity';
const FIELD_ORIGIN_STATE = 'originState';
const FIELD_DESTINATIONS = 'destinations';
const FIELD_ORIGIN_COUNTRY = 'originCountry';
const FIELD_ORIGIN_LATITUDE = 'originLatitude';
const FIELD_ORIGIN_LONGITUDE = 'originLongitude';
const FIELD_DESTINATION_CITY = 'destinationCity';
const FIELD_DESTINATION_STATES = 'destinationStates';
const FIELD_DESTINATION_COUNTRY = 'destinationCountry';
const FIELD_DESTINATION_LATITUDE = 'destinationLatitude';
const FIELD_DESTINATION_LONGITUDE = 'destinationLongitude';

const CAPACITY_FULL = 'FULL';
const CAPACITY_PARTIAL = 'PARTIAL';

const FILTER_COLORS = [
  '#e6194b',
  '#000075',
  '#3cb44b',
  '#800000',
  '#4363d8',
  '#f58231',
  '#f032e6',
  '#46f0f0',
  '#ffe119',
  '#808000',
  '#911eb4',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#aaffc3',
  '#ffd8b1',
  '#808080',
  '#000000',
];

export {
  CAPACITY_FULL,
  FILTER_COLORS,
  FIELD_TEMPLATE,
  CAPACITY_PARTIAL,
  FIELD_ORIGIN_CITY,
  FIELD_ORIGIN_STATE,
  FIELD_DESTINATIONS,
  FIELD_ORIGIN_COUNTRY,
  FIELD_ORIGIN_LATITUDE,
  FIELD_ORIGIN_LONGITUDE,
  FIELD_DESTINATION_CITY,
  FIELD_DESTINATION_STATES,
  FIELD_DESTINATION_COUNTRY,
  FIELD_DESTINATION_LATITUDE,
  FIELD_DESTINATION_LONGITUDE,
};
