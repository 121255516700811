import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, SectionsDivider } from '../../../ui';
// feature master-invoice
import TotalRow from './total-row';
import { calculateChargesTotal } from '../helpers';
//////////////////////////////////////////////////

const DEFAULT_FONT_SIZE = 13;

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.light.blue');
const greenColor = G.getTheme('colors.light.green');
const redColor = G.getTheme('colors.light.mainRed');
const darkGreyColor = G.getTheme('colors.darkGrey');
const lightGreyColor = G.getTheme('colors.lightGrey');
const blueGreyColor = G.getTheme('colors.light.blueGrey');

const chargesArrayName = GC.FIELD_MASTER_INVOICE_CHARGES;

const MasterInvoiceTotals = (props: Object) => {
  const charges = R.pathOr([], ['values', chargesArrayName], props);

  const invoicesTotal = R.pathOr(0, ['values', GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL], props);

  const currency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['values', GC.FIELD_CURRENCY],
    props,
  ));

  const chargesTotal = calculateChargesTotal(charges);

  const discountTotal = G.calculateMasterInvoiceDiscount(charges);

  const additionalCharges = R.add(chargesTotal, discountTotal);

  const grandTotal = R.subtract(R.add(invoicesTotal, additionalCharges), discountTotal);

  const totalStyles = {
    my: '3px',
    width: '100%',
    borderRadius: '4px',
    fontSize: DEFAULT_FONT_SIZE,
  };

  return (
    <Flex
      mr={15}
      width={450}
      flexShrink={0}
      height={306.5}
      bg={whiteColor}
      borderRadius={6}
      flexDirection='column'
      alignItems='flex-start'
    >
      <Flex
        p={15}
        height={32}
        width='100%'
        fontSize={11}
        bg={lightGreyColor}
        color={darkGreyColor}
        borderTopLeftRadius='6px'
        borderTopRightRadius='6px'
      >
        <Box width={170} fontSize={DEFAULT_FONT_SIZE}>{G.getWindowLocale('titles:currency', 'Currency')}</Box>
        <Box ml='auto' width={100} fontSize={DEFAULT_FONT_SIZE}>
          {G.getWindowLocale('titles:totals', 'Totals')}
        </Box>
      </Flex>
      <Flex
        py={5}
        px={15}
        width='100%'
        flexDirection='column'
        alignItems='flex-start'
      >
        <TotalRow
          rowTotal={discountTotal}
          payrollCurrency={currency}
          wrapperStyles={{ ...totalStyles, color: redColor }}
          label={G.getWindowLocale('titles:discount', 'Discount')}
        />
        <TotalRow
          payrollCurrency={currency}
          rowTotal={additionalCharges}
          wrapperStyles={{ ...totalStyles, color: greenColor }}
          label={G.getWindowLocale('titles:additional-charges', 'Additional Charges')}
        />
        <TotalRow
          rowTotal={invoicesTotal}
          payrollCurrency={currency}
          wrapperStyles={{ ...totalStyles, color: blueGreyColor }}
          label={G.getWindowLocale('titles:total-clo-charges', 'Total Order Charges')}
        />
      </Flex>
      <SectionsDivider borderColor={lightGreyColor} />
      {
        G.isNotNaN(grandTotal) && G.isNotNilAndNotEmpty(grandTotal) &&
        <Box
          ml='auto'
          p='10px 15px'
          fontWeight={700}
          color={blueColor}
          fontSize={DEFAULT_FONT_SIZE + 1}
        >
          {
          `${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
            currency} ${G.toFixed(grandTotal)}`
        }
        </Box>
      }
    </Flex>
  );
};

export default MasterInvoiceTotals;
