import * as R from 'ramda';
import React from 'react';
// feature new-do
import { isPageCloTemplate } from '../../new-do/helpers';
import LoadsData from '../../new-do/components/loads-data';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, StickedBox, ActionButton, CancelButton } from '../../../ui';
//////////////////////////////////////////////////

const colorWhite = G.getTheme('colors.white');
const colorDarkBlue = G.getTheme('colors.dark.blue');
const colorGreyMatterhorn = G.getTheme('colors.greyMatterhorn');

const commonBtnStyles = {
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  textColor: colorGreyMatterhorn,
  borderColor: colorGreyMatterhorn,
};

const submitStyles = {
  ...commonBtnStyles,
  textColor: colorWhite,
  bgColor: colorDarkBlue,
};

const boxStyles = {
  width: '100%',
  p: '14px 20px',
  bg: colorWhite,
  justifyContent: 'space-between',
};

const saveStyles = {
  mr: 20,
  height: 32,
  p: '4px 8px',
  fontSize: 14,
  borderRadius: '5px',
  bgColor: colorWhite,
  textColor: colorDarkBlue,
  border: `1px solid ${colorDarkBlue}`,
};

const getSaveAndDuplicateQtyBtnText = (props: Object) => {
  const { saveAndDuplicateQty } = props;

  const txt = G.getWindowLocale('titles:save-and-duplicate', 'Save And Duplicate');

  if (R.gt(saveAndDuplicateQty, 0)) {
    return `${txt} (${saveAndDuplicateQty})`;
  }

  return txt;
};

const Footer = (props: Object) => {
  const {
    pageType,
    branchGuid,
    templateData,
    numberOfLoads,
    handleClickSave,
    handleClickCancel,
    sendDataToApiRequest,
    saveAsTemplateRequest,
    handleOpenNextStartDatesForm,
  } = props;

  const templateName = R.path([GC.FIELD_TEMPLATE_NAME], templateData);
  const disabled = G.ifElse(G.isNilOrEmpty(branchGuid), true, false);
  const cursor = G.ifElse(disabled, 'not-allowed', 'pointer');
  const disabledMultiple = G.ifElse(R.or(G.isNilOrEmpty(branchGuid), R.lt(numberOfLoads, 2)), true, false);
  const cursorMultiple = G.ifElse(disabledMultiple, 'not-allowed', 'pointer');

  return (
    <StickedBox bottom='0px' zIndex={13}>
      <Flex {...boxStyles} overflowX='auto'>
        <CancelButton {...cancelStyles} onClick={handleClickCancel}>
          {G.getWindowLocale('actions:cancel', 'Cancel')}
        </CancelButton>
        <LoadsData {...props} />
        <Flex>
          {
            R.not(isPageCloTemplate(pageType)) &&
            <ActionButton
              {...saveStyles}
              cursor={cursorMultiple}
              disabled={disabledMultiple}
              onClick={handleOpenNextStartDatesForm}
            >
              {G.getWindowLocale('actions:save-multiple', 'Save Multiple')}
            </ActionButton>
          }
          {
            R.not(isPageCloTemplate(pageType)) &&
            <ActionButton
              {...saveStyles}
              cursor={cursor}
              disabled={disabled}
              onClick={() => sendDataToApiRequest({ duplicate: true })}
            >
              {getSaveAndDuplicateQtyBtnText(props)}
            </ActionButton>
          }
          {
            isPageCloTemplate(pageType) &&
            <ActionButton
              {...submitStyles}
              cursor={cursor}
              disabled={disabled}
              onClick={() => saveAsTemplateRequest({
                mode: 'updateTemplate',
                [GC.FIELD_TEMPLATE_NAME]: templateName,
              })}
            >
              {G.getWindowLocale('actions:update', 'Update')}
            </ActionButton>
          }
          {
            R.not(isPageCloTemplate(pageType)) &&
            <ActionButton
              {...submitStyles}
              cursor={cursor}
              disabled={disabled}
              onClick={handleClickSave}
            >
              {G.getWindowLocale('actions:submit', 'Submit')}
            </ActionButton>
          }
        </Flex>
      </Flex>
    </StickedBox>
  );
};

export default Footer;
