import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../../common/actions';
// helpers/constants
import * as G from '../../../helpers';
// report-common
import { getReportReducers } from '../../../report-common';
// feature invoice
import * as H from '../helpers';
// feature invoice/customer
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  additionalFilters: {},
};

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setAdditionalFilters = (state: Object, filters: Object) => (
  P.$set('additionalFilters', filters, state)
);

const getConfigsSuccess = (state: Object, configs: Object) => (
  P.$set('configs', configs, state)
);

const changeInvoicesSuccess = (state: Object, data: Object) => H.changeInvoicesSuccess(state, data);

const readyForBillingSuccess = (state: Object, data: Object) => H.readyForBillingSuccess(state, data);

const updateCloInvoiceSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const invoice = P.$get(`itemList.${guid}`, state);
  const payments = G.transformPaymentsFromArrayToObjectWithReportKeys(data);

  const mappedInvoice = R.mapObjIndexed(
    (item: string, key: string) => R.pathOr(item, R.split('.', key), data),
    invoice,
  );

  const updatedInvoice = R.mergeRight(mappedInvoice, payments);

  return P.$set(`itemList.${guid}`, updatedInvoice, state);
};

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.getConfigsSuccess]: getConfigsSuccess,
  [A.setAdditionalFilters]: setAdditionalFilters,
  [A.changeInvoicesSuccess]: changeInvoicesSuccess,
  [A.readyForBillingSuccess]: readyForBillingSuccess,
  [A.updateCloInvoiceSuccess]: updateCloInvoiceSuccess,
}, initialState);
