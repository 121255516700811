import * as R from 'ramda';
import { connect } from 'react-redux';
import { OuterClick } from 'react-outer-click';
import { createStructuredSelector } from 'reselect';
import React, { useState, useCallback } from 'react';
// components
import CreateCarrier from '../../components/create-carrier';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { sendLogOutRequest } from '../auth/actions';
import { makeSelectCurrentBranch } from '../branch/selectors';
import NotificationModal from '../notification/components/popup';
import { makeSelectUnreadCount } from '../notification/selectors';
import BranchTreeComponent from '../branch/components/branch-tree';
import ShareWithDivisions from '../branch/components/share-with-divisions';
import { makeSelectCurrentUserSettingsFields } from '../profile/selectors';
import { setExpandedContainerOptions } from '../expanded-container/actions';
import { makeSelectStyling, makeSelectMainStyling } from '../styling/selectors';
import { switchBranchRequest, openAddBranchModalRequest } from '../branch/actions';
import { makeSelectShipmentList, makeSelectAuthorizedLoadBoards } from '../load-board/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { getCurrentMenuItem } from '../../helpers/menu';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, FixedBox, AbsoluteBox, RelativeBox, RelativeFlex } from '../../ui';
// utilities
import routesMap, { getOrderEntryRoute } from '../../utilities/routes';
// feature menus
import { makeSelectNavItems } from './selectors';
import SidebarComponent from './components/sidebar';
import { clickOnItemAction, deleteNavItemRequest } from './actions';
import {
  Logo,
  PinedL,
  NavItem,
  HrDivider,
  MenuWrapper,
  IconWrapper,
  LogoMenuBox,
  EditNavPanel,
  NavItemPinned,
} from './ui';
//////////////////////////////////////////////////

const mockItems = [
  {
    action: 'routeBeta',
    iconName: 'routesLoads',
    title: 'Dispatch Board',
    key: 'menu:dispatch-board',
  },
  {
    title: 'Trip',
    key: 'menu:tel',
    action: 'telList',
    iconName: 'codeTableTel',
  },
  {
    iconName: 'average',
    action: 'averageFuelPrice',
    title: 'Average Fuel Prices',
    key: 'menu:average-fuel-price',
  },
];

const PinedItems = (props: Object) => {
  const {
    styling,
    navItems,
    iconsColor,
    setNavState,
    isEditableNav,
    handleClickNavItem,
    handleRemoveNavItem,
  } = props;

  const carrier = G.isCurrentUserTypeCarrier();
  const minusHeight = G.ifElse(carrier, '150px', '450px');
  const pinedHeight = G.ifElse(carrier, '100%', 'calc(100% - 50px)');

  return (
    <RelativeBox height={`calc(100vh - ${minusHeight})`} bg='rgba(255, 255, 255, 0.3)'>
      <PinedL height={pinedHeight}>
        {
          navItems.map((item: Object, i: number) => (
            <NavItemPinned
              p={13}
              key={i}
              isSideBar={true}
              action={item.action}
              afterColor={iconsColor}
              title={G.getWindowLocale(item.key, '')}
              onClick={() => handleClickNavItem(item.action)}
              active={R.equals(getCurrentMenuItem(), item.action)}
            >
              <span>{R.pathOr(() => {}, [item.iconName], I)(iconsColor, 24, 24)}</span>
              {
                isEditableNav &&
                <Box onClick={(e: Object) => handleRemoveNavItem(e, item)}>
                  {I.trash(G.getTheme('colors.light.darkRed'))}
                </Box>
              }
            </NavItemPinned>
          ))
        }
      </PinedL>
      {
        R.not(carrier) &&
        <EditNavPanel
          p='13px 16px 0'
          navState={isEditableNav}
          bg={styling.backgroundColor}
          onClick={() => setNavState(R.not(isEditableNav))}
          title={G.getWindowLocale('action:edit', 'Edit Panel')}
        >
          {
            I.pencil(
              G.ifElse(
                isEditableNav,
                G.getTheme('icons.iconColor'),
                iconsColor,
              ),
              20,
              20,
            )
          }
          <HrDivider m='8px 0 0' width='100%' />
        </EditNavPanel>
      }
    </RelativeBox>
  );
};

const UserBox = ({ iconsColor, currentUserSettings }: Object) => {
  let fullText = R.pathOr('', ['loginId'], currentUserSettings);
  const lastName = R.pathOr('', ['lastName'], currentUserSettings);
  const firstName = R.pathOr('', ['firstName'], currentUserSettings);
  let text = R.toUpper(R.pathOr('', [0], fullText));

  if (R.or(G.isNotEmpty(firstName), G.isNotEmpty(lastName))) {
    fullText = `${firstName} ${lastName}(${fullText})`;
    text = `${R.toUpper(R.pathOr('', [0], firstName))}${R.toUpper(R.pathOr('', [0], lastName))}`;
  }

  return (
    <Flex
      width={30}
      height={30}
      m='10px 16px'
      cursor='pointer'
      title={fullText}
      border='1px solid'
      borderRadius='50%'
      alignItems='center'
      justifyContent='center'
      borderColor={iconsColor}
      background='rgba(255, 255, 255, 0.5)'
      onClick={() => G.goToRoute(routesMap.profile)}
    >
      {text}
    </Flex>
  );
};

const Notification = (props: Object) => {
  const { styling, iconsColor, showPopup, handleShowPopup, unreadNotificationsCount } = props;

  return (
    <RelativeFlex
      p={13}
      width={60}
      cursor='pointer'
      height='max-content'
      flexDirection='column'
      title={G.getWindowLocale('titles:notifications', 'Notifications')}
    >
      <IconWrapper onClick={handleShowPopup}>
        {I.bellIcon(iconsColor, 24, 24)}
        <AbsoluteBox
          p='2px'
          top='6px'
          left='30px'
          height='18px'
          fontSize='9px'
          minWidth='18px'
          border='1px solid'
          color={iconsColor}
          borderRadius='50px'
          width='max-content'
          justifyContent='center'
          borderColor={iconsColor}
          bg={styling.backgroundColor}
        >
          {unreadNotificationsCount}
        </AbsoluteBox>
      </IconWrapper>
      {
        showPopup &&
        <OuterClick onOuterClick={handleShowPopup}>
          <NotificationModal {...props} onHidePopup={handleShowPopup} />
        </OuterClick>
      }
    </RelativeFlex>
  );
};

const Logout = ({ iconsColor, sendLogOutRequest }: Object) => (
  <NavItem
    p={13}
    onClick={sendLogOutRequest}
    title={G.getWindowLocale('actions:logout', 'Logout')}
  >
    <IconWrapper w='24px' h='24px'>
      {I.goOut(iconsColor, 24, 24)}
    </IconWrapper>
  </NavItem>
);

const LoadBoardIcon = ({ count, styling, iconsColor, authorizedLoadBoards, handleOpenLoadBoardsSideBar }: Object) => {
  if (G.isNilOrEmpty(authorizedLoadBoards)) return <Box height={50} width={60} />;

  return (
    <RelativeFlex
      p={15}
      cursor='pointer'
      flexDirection='column'
      onClick={handleOpenLoadBoardsSideBar}
    >
      {I.loadSearch(iconsColor, 24, 24)}
      <AbsoluteBox
        p='2px'
        top='6px'
        left='24px'
        height='18px'
        fontSize='9px'
        minWidth='18px'
        border='1px solid'
        color={iconsColor}
        borderRadius='50px'
        width='max-content'
        justifyContent='center'
        borderColor={iconsColor}
        bg={styling.backgroundColor}
      >
        {count}
      </AbsoluteBox>
    </RelativeFlex>
  );
};

const MenuComponent = (props: Object) => {
  const {
    styling,
    navItems,
    openModal,
    closeModal,
    rootBranch,
    mainStyling,
    currentBranch,
    clickOnItemAction,
    sendLogOutRequest,
    loadBoardShipments,
    switchBranchRequest,
    currentUserSettings,
    deleteNavItemRequest,
    authorizedLoadBoards,
    unreadNotificationsCount,
    openAddBranchModalRequest,
    setExpandedContainerOptions,
  } = props;

  const [isMenuOpen, setMenuStatus] = useState(false);
  const [showPopup, toggleShowPopup] = useState(false);
  const [isEditableNav, setNavState] = useState(false);

  const handleShowPopup = useCallback(() => toggleShowPopup(R.not(showPopup)));
  const handleSwitchBranch = useCallback((guid: string) => switchBranchRequest(guid));
  const handleOpenLoadBoardsSideBar = useCallback(() => {
    if (R.equals(routesMap.externalloadboard, G.getCurrentPathname())) return;

    setExpandedContainerOptions({ opened: true, componentType: GC.PAGE_LOAD_BOARDS });
  }, []);

  const handleRemoveNavItem = useCallback((event: Event, item: Object) => {
    G.stopPropagation(event);

    deleteNavItemRequest(item);
  }, [deleteNavItemRequest]);

  const handleClickNavItem = useCallback((action: string) => {
    setMenuStatus(false);
    clickOnItemAction(action);
  }, [setMenuStatus, clickOnItemAction]);

  const handleCreateCarrier = useCallback(() => {
    const component = <CreateCarrier />;
    const modal = {
      p: 15,
      component,
      options: {
        width: 340,
        version: 2,
        height: 'auto',
        overflow: 'auto',
        maxHeight: '90vh',
        title: G.getWindowLocale('titles:add-new-carrier', 'Create Carrier'),
      },
    };

    openModal(modal);
  }, [openModal]);

  const handleShareWithDivisions = useCallback((guid: string) => {
    const { openModal } = props;

    const modal = {
      p: '0px',
      component: (
        <ShareWithDivisions branchGuid={guid} />
      ),
      options: {
        width: 500,
        height: 'auto',
        minWidth: 'fit-content',
        title: G.getWindowLocale('actions:share-with-divisions', 'Share With Divisions'),
      },
    };

    openModal(modal);
  }, [openModal]);

  const handleOpenEntTree = useCallback(() => {
    const modal = {
      p: '0px',
      component: (
        <BranchTreeComponent
          width={500}
          allowPin={true}
          cursor='pointer'
          allowActions={true}
          closeAction={closeModal}
          handleShareWithDivisions={handleShareWithDivisions}
          onClickBranch={(guid: string) => {
            closeModal();
            switchBranchRequest(guid);
          }}
        />
      ),
      options: {
        width: 500,
        height: 'auto',
        minWidth: 'fit-content',
      },
    };

    openModal(modal);
  }, [openModal]);

  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  const sidebarProps = {
    styling,
    openModal,
    isMenuOpen,
    iconsColor,
    mainStyling,
    currentBranch,
    handleOpenEntTree,
    handleClickNavItem,
    handleSwitchBranch,
    branches: rootBranch,
  };

  const pinedItemsProps = {
    styling,
    navItems,
    iconsColor,
    setNavState,
    isEditableNav,
    handleClickNavItem,
    handleRemoveNavItem,
  };

  if (G.isCurrentUserTypeCarrier()) {
    return (
      <Box>
        <MenuWrapper bg={styling.backgroundColor}>
          <PinedItems {...pinedItemsProps} navItems={mockItems} />
          <Notification
            styling={styling}
            showPopup={showPopup}
            iconsColor={iconsColor}
            handleShowPopup={handleShowPopup}
            unreadNotificationsCount={unreadNotificationsCount}
          />
          <UserBox iconsColor={iconsColor} currentUserSettings={currentUserSettings} />
          <Logout iconsColor={iconsColor} sendLogOutRequest={sendLogOutRequest} />
        </MenuWrapper>
      </Box>
    );
  }

  return (
    <OuterClick onOuterClick={() => setMenuStatus(false)}>
      <Box>
        <MenuWrapper bg={styling.backgroundColor}>
          <Box height={250}>
            <LogoMenuBox
              width={60}
              height={60}
              cursor='pointer'
              overflow='hidden'
              onClick={() => setMenuStatus((prev: boolean) => R.not(prev))}
            >
              <Flex height={60} width={60} alignItems='center' justifyContent='center'>
                {I.menu(iconsColor)}
              </Flex>
              <Flex
                p='5px'
                width={60}
                height={60}
                alignItems='center'
                justifyContent='center'
                bg={styling.logoBgColor}
              >
                <Logo
                  alt='logo'
                  maxHeight={60}
                  src={R.or(styling.logoUrl, G.getNewLogoLink())}
                  title={G.getWindowLocale('menu:menu', 'Control Tower')}
                />
              </Flex>
            </LogoMenuBox>
            <AuthWrapper has={[PC.BRANCH_WRITE]}>
              <NavItem
                p={13}
                title={G.getWindowLocale('actions:customer', 'Add Customer')}
                onClick={() => openAddBranchModalRequest({ type: GC.BRANCH_TYPE_CUSTOMER_STORED_VALUE })}
              >
                <IconWrapper w='24px' h='24px'>
                  {I.addChild(iconsColor, 24, 24)}
                </IconWrapper>
              </NavItem>
            </AuthWrapper>
            <AuthWrapper has={[PC.CLO_WRITE]}>
              <NavItem
                p={13}
                onClick={() => G.goToRoute(getOrderEntryRoute())}
                title={G.getWindowLocale('actions:new-clo', 'New CLO')}
              >
                <IconWrapper w='24px' h='24px'>
                  {I.newDO(iconsColor)}
                </IconWrapper>
              </NavItem>
            </AuthWrapper>
            <AuthWrapper has={[PC.CREATE_CARRIER_EXECUTE]}>
              <NavItem
                p={13}
                onClick={handleCreateCarrier}
                title={G.getWindowLocale('actions:create-carrier', 'Create Carrier')}
              >
                <IconWrapper w='24px' h='24px'>
                  {I.createCarrier(iconsColor)}
                </IconWrapper>
              </NavItem>
            </AuthWrapper>
            <AuthWrapper has={[PC.CLO_READ, PC.CLO_WRITE, PC.TEL_READ, PC.TEL_WRITE]}>
              <NavItem
                p={11}
                onClick={() => {
                  const amousDefaultRouteTab = G.getItemFromWindow('amousDefaultRouteTab');
                  const route = G.ifElse(
                    R.equals(amousDefaultRouteTab, GC.FIELD_CLO),
                    routesMap.routeListOrder,
                    routesMap.routeListLoad,
                  );

                  G.goToRoute(route);
                }}
                title={G.getWindowLocale('menu:dispatch-board', 'Dispatch Board')}
              >
                <IconWrapper w='24px' h='24px'>
                  {I.routesLoads(iconsColor, 24, 24)}
                </IconWrapper>
              </NavItem>
            </AuthWrapper>
          </Box>
          <PinedItems {...pinedItemsProps} />
          <LoadBoardIcon
            styling={styling}
            iconsColor={iconsColor}
            count={R.length(loadBoardShipments)}
            authorizedLoadBoards={authorizedLoadBoards}
            handleOpenLoadBoardsSideBar={handleOpenLoadBoardsSideBar}
          />
          <Notification
            styling={styling}
            showPopup={showPopup}
            iconsColor={iconsColor}
            handleShowPopup={handleShowPopup}
            toggleShowPopup={toggleShowPopup}
            unreadNotificationsCount={unreadNotificationsCount}
          />
          <UserBox iconsColor={iconsColor} currentUserSettings={currentUserSettings} />
          <Logout iconsColor={iconsColor} sendLogOutRequest={sendLogOutRequest} />
        </MenuWrapper>
        <AuthWrapper has={[PC.BRANCH_READ, PC.BRANCH_WRITE]}>
          <FixedBox
            top='0'
            left='50%'
            p='2px 15px'
            fontSize={12}
            cursor='pointer'
            fontWeight='bold'
            color={iconsColor}
            width='max-content'
            borderBottomLeftRadius={20}
            onClick={handleOpenEntTree}
            transform='translateX(-50%)'
            borderBottomRightRadius={20}
            boxShadow='0 0 5px 0 rgba(0, 0, 0, 0.3)'
            bg={R.or(styling.backgroundColor, G.getTheme('colors.light.darkRed'))}
          >
            {R.prop(GC.FIELD_BRANCH_NAME, currentBranch)}
          </FixedBox>
        </AuthWrapper>
        <SidebarComponent {...sidebarProps} currentMenuItem={getCurrentMenuItem()} />
      </Box>
    </OuterClick>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  styling: makeSelectStyling(state),
  navItems: makeSelectNavItems(state),
  mainStyling: makeSelectMainStyling(state),
  currentBranch: makeSelectCurrentBranch(state),
  loadBoardShipments: makeSelectShipmentList(state),
  unreadNotificationsCount: makeSelectUnreadCount(state),
  authorizedLoadBoards: makeSelectAuthorizedLoadBoards(state),
  currentUserSettings: makeSelectCurrentUserSettingsFields(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  sendLogOutRequest,
  clickOnItemAction,
  switchBranchRequest,
  deleteNavItemRequest,
  openAddBranchModalRequest,
  setExpandedContainerOptions,
})(MenuComponent);
