import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState } from 'react-recompose';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, RelativeBox } from '../../../../ui';
// feature invoice
import { getArrowIcon } from '../../helpers';
// feature invoice/carrier
import RowContent from './row-content';
import { getRelatedRows } from '../settings/detail-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('expanded', 'toggle', false),
  pure,
);

const Fields = (props: Object) => (
  <Flex width='100%' p={10}>
    <Flex width='50%' flexDirection='column'>
      <RowContent options={R.prop(GC.FIELD_TEL, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_INVOICE_DATE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_GL_CODE, props.rowOptions)} />
    </Flex>
    <Flex width='50%' flexDirection='column' name='COLUMN'>
      <RowContent options={R.prop(GC.FIELD_MODE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, props.rowOptions)} />
      <RowContent options={R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, props.rowOptions)} />
      <Flex width='100%' fontSize={11}>
        <TextComponent
          mr={10}
          withEllipsis={true}
          width='calc(45% - 10px)'
          title={`${G.getWindowLocale('titles:total', 'Total')}:`}
        >
          {G.getWindowLocale('titles:total', 'Total')}:
        </TextComponent>
        {
          G.isNotNil(props.total)
          && (
            <TextComponent
              p='4px'
              fontWeight={700}
              borderRadius={3}
              withEllipsis={true}
              display='inline-block'
              maxWidth='calc(55% - 10px)'
              bg={G.getTheme('colors.light.blue')}
              color={G.getTheme('colors.light.mainLight')}
              title={`${props.currencySymbol} ${props.total}`}
            >
              {props.currencySymbol} {G.toFixed(props.total)}
            </TextComponent>
          )
        }
      </Flex>
    </Flex>
  </Flex>
);

const Charges = (props: Object) => (
  <Box
    fontSize={11}
    overflow='hidden'
    transition='height 0.1s linear'
    minHeight={R.and(props.expanded, 60)}
    borderTop={G.ifElse(props.expanded, '2px solid', 'none')}
    height={G.ifElse(
      props.expanded,
      R.add(R.multiply(props.charges.length, 22), 20),
      0,
    )}
  >
    <Flex
      p='4px'
      width='100%'
      background={G.getTheme('tables.rows.borderColor')}
    >
      <Flex width='70%' />
      <Flex width='30%'>{G.getWindowLocale('titles:invoice', 'Invoice')}</Flex>
    </Flex>
    {
      props.charges.map((charge: Object, i: number) => (
        <Flex
          key={i}
          p='4px'
          width='100%'
        >
          <Flex width='70%'>
            <TextComponent
              withEllipsis={true}
              width='calc(100% - 10px)'
              title={R.prop(GC.FIELD_DISPLAYED_VALUE, charge)}
            >
              {R.prop(GC.FIELD_DISPLAYED_VALUE, charge)}:
            </TextComponent>
          </Flex>
          <Flex width='30%'>{props.currencySymbol} {G.toFixed(R.prop(GC.FIELD_CHARGE_TOTAL, charge))}</Flex>
        </Flex>
      ))
    }
  </Box>
);

const RelatedDetails = (props: Object) => {
  const { expanded } = props;
  const rowOptions = getRelatedRows(props);
  const currencySymbol = G.getCurrencySymbol(props.currency);
  return (
    <RelativeBox
      mr={15}
      mb={15}
      width={350}
      borderRadius={5}
      overflow='hidden'
      verticalAlign='top'
      display='inline-block'
      bg={G.getTheme('colors.light.lightGrey')}
    >
      <Flex
        width='100%'
        p='3px 10px'
        bg={G.getTheme('colors.light.darkRed')}
        color={G.getTheme('colors.light.mainLight')}
      >
        <Box width='100%'><RowContent mb='0px' options={R.prop(GC.FIELD_INVOICE_NUMBER, rowOptions)} /></Box>
      </Flex>
      <Fields {...props} rowOptions={rowOptions} currencySymbol={currencySymbol} />
      <Charges {...props} expanded={expanded} currencySymbol={currencySymbol} />
      <Flex
        height={10}
        width='100%'
        justifyContent='center'
        bg={G.getTheme('colors.light.darkRed')}
        onClick={() => props.toggle(R.not(expanded))}
      >
        {getArrowIcon(expanded)}
      </Flex>
    </RelativeBox>
  );
};

export default enhance(RelatedDetails);
