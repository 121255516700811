import React from 'react';
// helpers/constants
import * as GC from '../../../constants';
// feature
import AcceptDeclineLosSubscriptionComponent from '../components/accept-reject';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_ACCEPT_REJECT]: {
    width: 100,
    name: 'titles:accept-reject',
    customComponent: ({ data }: Object) => <AcceptDeclineLosSubscriptionComponent entity={data} />,
  },
  [GC.FIELD_SUBSCRIPTION_ID]: {
    width: 200,
    name: 'titles:subscription-id',
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:status',
  },
  [GC.FIELD_ACCEPT_OR_REJECT_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:accept-reject-date',
  },
  [GC.FIELD_ACCEPTED_OR_REJECTED_BY]: {
    width: 200,
    name: 'titles:accepted-rejected-by',
  },
  [GC.FIELD_REJECT_REASON]: {
    width: 200,
    name: 'titles:reject-reason',
  },
  [GC.FIELD_UNSUBSCRIPTION_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:unsubscription-date',
  },
  [GC.FIELD_RECEIVED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:received-date',
  },
  [GC.FIELD_CONFIRMATION_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:confirmation-date',
  },
  [GC.FIELD_MASTER_ENTERPRISE_NAME]: {
    width: 200,
    name: 'titles:master-enterprise-name',
  },
  [GC.FIELD_LINKED]: {
    width: 150,
    type: 'boolean',
    name: 'titles:linked',
  },
  [GC.FIELD_LINKED_BY]: {
    width: 200,
    name: 'titles:linked-by',
  },
  [GC.FIELD_BRANCH_DELETED]: {
    width: 150,
    type: 'boolean',
    name: 'titles:branch-deleted',
  },
  [GC.GRC.CUSTOMER_DETAILS_NAME]: {
    width: 200,
    name: ['titles:customer-details', 'titles:name'],
  },
  [GC.GRC.CUSTOMER_DETAILS_FEDERAL_EIN]: {
    width: 200,
    name: ['titles:customer-details', 'titles:federal-ein'],
  },
  [GC.GRC.CUSTOMER_DETAILS_CONTACT_NUMBER]: {
    width: 200,
    name: ['titles:customer-details', 'titles:contact-number'],
  },
  [GC.GRC.CREATED_BY_USER_FIRST_NAME]: {
    width: 200,
    name: ['titles:created-by-user', 'titles:first-name'],
  },
  [GC.GRC.CREATED_BY_USER_LAST_NAME]: {
    width: 200,
    name: ['titles:created-by-user', 'titles:last-name'],
  },
  [GC.GRC.CREATED_BY_USER_EMAIL_ID]: {
    width: 200,
    name: ['titles:created-by-user', 'titles:email-id'],
  },
  [GC.GRC.CREATED_BY_USER_CONTACT_NUMBER]: {
    width: 200,
    name: ['titles:created-by-user', 'titles:contact-number'],
  },
  [GC.GRC.CONFIRMED_BY_USER_FIRST_NAME]: {
    width: 200,
    name: ['titles:confirmed-by-user', 'titles:first-name'],
  },
  [GC.GRC.CONFIRMED_BY_USER_LAST_NAME]: {
    width: 200,
    name: ['titles:confirmed-by-user', 'titles:last-name'],
  },
  [GC.GRC.CONFIRMED_BY_USER_EMAIL_ID]: {
    width: 200,
    name: ['titles:confirmed-by-user', 'titles:email-id'],
  },
  [GC.GRC.CONFIRMED_BY_USER_CONTACT_NUMBER]: {
    width: 200,
    name: ['titles:confirmed-by-user', 'titles:contact-number'],
  },
  [GC.GRC.LOS_SUBSCRIPTION_ORDER_PLAN_NAME]: {
    width: 200,
    name: ['titles:order', 'titles:plan-name'],
  },
  [GC.FIELD_LOS_PRICING_CONFIG_NAME]: {
    width: 200,
    name: 'titles:los-pricing-config-name',
  },
};
