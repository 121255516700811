// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature master-invoice
import { FIELD_GROUP_BY_REFERENCE_VALUE, masterInvoiceConfigDefaultGroupByOptions } from '../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    type: 'string',
    value: GC.FIELD_MASTER_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:master-invoice-number', 'Master Invoice #'),
  },
  {
    type: 'number',
    value: GC.FIELD_MASTER_INVOICE_TOTAL_DISCOUNT,
    name: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    type: 'date',
    value: GC.FIELD_MASTER_INVOICE_DATE,
    name: G.getWindowLocale('titles:date', 'Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_MASTER_INVOICE_DATE_FROM,
    name: G.getWindowLocale('titles:date-from', 'Date From'),
  },
  {
    type: 'date',
    value: GC.FIELD_MASTER_INVOICE_DATE_TO,
    name: G.getWindowLocale('titles:date-to', 'Date To'),
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_LOCATION_NAME,
    name: G.getWindowLocale('titles:bill-to', 'Bill To'),
  },
  {
    type: 'string',
    value: GC.GRC.STATUS_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    value: GC.FIELD_MASTER_INVOICE_CHECK_NUMBER,
    name: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  {
    type: 'number',
    value: GC.FIELD_MASTER_INVOICE_INVOICES_COUNT,
    name: G.getWindowLocale('titles:invoices-count', 'Invoices Count'),
  },
  {
    type: 'number',
    value: GC.FIELD_MASTER_INVOICE_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:additional-charges', 'Additional Charges'),
  },
  {
    type: 'number',
    value: GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL,
    name: G.getWindowLocale('titles:invoices-total', 'Invoices Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_MASTER_INVOICE_GRAND_TOTAL,
    name: G.getWindowLocale('titles:gross-total', 'Gross Total'),
  },
  {
    type: 'string',
    value: GC.FIELD_MASTER_INVOICE_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'date',
    value: GC.FIELD_MASTER_INVOICE_INTEGRATED_DATE,
    name: G.getWindowLocale('titles:integrated-date', 'Integrated Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:updated-by', 'Updated By'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
    name: G.getWindowLocale('titles:integration-status', 'Integration Status'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
    name: G.getWindowLocale('titles:integration-type', 'Integration Type'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:integrated-by', 'Integrated By'),
  },
  {
    type: 'date',
    value: GC.FIELD_MASTER_INVOICE_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INVOICES_CLO_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:clo-reference', 'CLO Reference'),
  },
  {
    type: 'string',
    value: FIELD_GROUP_BY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:reference', 'Reference'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_MASTER_INVOICE_GROUP_BY,
    options: masterInvoiceConfigDefaultGroupByOptions,
    name: G.getWindowLocale('titles:group-by', 'Group By'),
    placeholder: {
      text: 'Choose a group',
      key: 'titles:choose-a-group',
    },
  },
];
