import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleEditInvoice: ({ edit, closeFixedPopup }: Object) => () => {
      edit();
      closeFixedPopup();
    },
    handleRemoveInvoice: ({ data, remove, closeFixedPopup }: Object) => () => {
      remove(data);
      closeFixedPopup();
    },
  }),
  pure,
);

const getOptions = ({ completed, handleEditInvoice, handleRemoveInvoice }: Object) => {
  const iconColor = G.getTheme('colors.dark.blue');
  const permissions = G.ifElse(
    G.isTrue(completed),
    [PC.CLO_INVOICE_OVERWRITE_EXECUTE],
    [PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE],
  );

  return [
    {
      permissions,
      frontIcon: I.pencil(iconColor),
      action: handleEditInvoice,
      text: G.getWindowLocale('actions:edit', 'Edit'),
    },
    {
      permissions,
      frontIcon: I.trash(iconColor),
      action: handleRemoveInvoice,
      text: G.getWindowLocale('actions:remove', 'Remove'),
    },
  ];
};

export const InvoiceDetailsActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(InvoiceDetailsActions);
