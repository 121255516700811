import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
// features
import {
  MailSendingWithAsyncData,
  EmailSendingCarrierDispatch,
  MailSendingCarrierDispatchWithAsyncInitialData,
} from './';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const namesArray = [
  GC.COMMUNICATION_MAILING_DEFAULT_REPLY_TO,
  GC.COMMUNICATION_MAILING_LOCK_DEFAULT_REPLY_TO,
];

export const withMultipleMailSending = compose(
  withHandlers({
    handleSendMultiMail: ({ openModal }: Object) => (
      load: Object,
      addressKey: string,
      branchGuid: string,
      initialValues: Object,
    ) => {
      const { guid, loadType } = load;

      const asyncOptions = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuid,
          [GC.FIELD_PRIMARY_OBJECT_GUID]: guid,
        },
      };

      const values = {
        loadType,
        loadGuid: guid,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      };

      const modal = {
        component: (
          <MailSendingWithAsyncData
            isMulti={true}
            branchGuid={branchGuid}
            asyncOptions={asyncOptions}
            configsNamesArray={namesArray}
            asyncEndpoint={endpointsMap[addressKey]}
            initialValues={R.mergeRight(values, initialValues)}
          />
        ),
        options: {
          height: 'auto',
          boxShadow: 'none',
          width: 'max-content',
          title: G.getWindowLocale('titles:new-message', 'New Message'),
        },
        p: '0px',
      };

      openModal(modal);
    },
  }),
);

export const withDispatchCarrierRate = compose(
  withHandlers({
    handleDispatchCarrierRate: (props: Object) => async (options: Object) => {
      const { openModal, openLoader, closeLoader } = props;

      const { branchGuid, selectedRate, handleDispatchCarrierRateWithTerminalData } = options;

      let dispatchData = R.mergeRight(options, { terminal: null });

      const terminalGuid = R.path([GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_CARRIER_TERMINAL_GUID], selectedRate);

      const internalDivision = R.path([
        GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT,
        GC.SYSTEM_OBJECT_CARRIER_SNAPSHOT,
        GC.FIELD_INTERNAL_DIVISION,
      ], selectedRate);

      if (G.isNotNilAndNotEmpty(terminalGuid)) {
        G.callFunction(openLoader);

        const endpoint = endpointsMap.getCarrierTerminalDispatchProcessEndpoint(terminalGuid);

        const res = await sendRequest('get', endpoint);

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          const terminalAutoAcceptCondition = R.or(
            R.pathEq(true, [GC.FIELD_TERMINAL_PROCESS_AUTO_ACCEPT], data),
            R.includes(
              G.getPropFromObject(GC.FIELD_TERMINAL_PROCESS_TYPE, data),
              [GC.TERMINAL_PROCESS_TYPE_API, GC.TERMINAL_PROCESS_TYPE_EDI, GC.TERMINAL_PROCESS_TYPE_INTERNAL],
            ),
          );

          if (terminalAutoAcceptCondition) {
            G.callFunction(closeLoader);

            return G.callFunction(handleDispatchCarrierRateWithTerminalData);
          }

          G.callFunction(closeLoader);

          dispatchData = R.assoc('terminal', data, dispatchData);
        } else {
          G.callFunction(closeLoader);
          G.handleFailResponseSimple(res, false, 'withDispatchCarrierRate');
        }
      } else if (G.isTrue(internalDivision)) {
        return G.callFunction(handleDispatchCarrierRateWithTerminalData);
      } else {
        G.callFunction(openLoader);

        const configsNamesArray = [
          GC.CARRIER_DISPATCH_PROCESS_MESSAGE,
          GC.CARRIER_DISPATCH_PROCESS_SUBJECT,
          GC.CARRIER_DISPATCH_PROCESS_REPLY_TO,
          GC.CARRIER_DISPATCH_PROCESS_AUTO_ACCEPT,
          GC.CARRIER_DISPATCH_PROCESS_DEFAULT_DOCUMENT_TEMPLATE,
        ];

        const configOptions = {
          params: {
            [GC.FIELD_BRANCH_GUID]: branchGuid,
            names: R.join(',', [configsNamesArray]),
          },
        };

        const res = await sendRequest('get', endpointsMap.branchConfigsEndpoint, configOptions);

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          if (G.isNotEmpty(data)) {
            const asyncConfigs = G.mapConfigValuesByName(data);

            const autoAccept = G.getConfigValueFromState(
              GC.CARRIER_DISPATCH_PROCESS_AUTO_ACCEPT,
              asyncConfigs.configs,
            );

            if (G.isTrue(autoAccept)) {
              G.callFunction(closeLoader);

              return G.callFunction(handleDispatchCarrierRateWithTerminalData);
            }

            G.callFunction(closeLoader);

            dispatchData = R.assoc('asyncConfigs', asyncConfigs, dispatchData);
          }
        } else {
          G.callFunction(closeLoader);
          G.handleFailResponseSimple(
            res,
            false,
            'withDispatchCarrierRate',
          );
        }
      }

      let component = <EmailSendingCarrierDispatch {...dispatchData} isCarrier={true} />;

      if (G.isNotNilAndNotEmpty(G.getPropFromObject('asyncEndpoint', options))) {
        component = <MailSendingCarrierDispatchWithAsyncInitialData {...dispatchData} isCarrier={true} />;
      }

      const modal = {
        p: '0px',
        component,
        options: {
          height: 'auto',
          boxShadow: 'none',
          width: 'max-content',
          title: G.getWindowLocale('titles:new-message', 'New Message'),
        },
      };

      openModal(modal);
    },
  }),
);
