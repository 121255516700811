import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { FormGroupTitleMultiple } from '../../../components';
import { DateRangeMui } from '../../../components/date-range';
import { LocalLoader } from '../../../components/local-loader';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { PAYMENT_TERM_OPTIONS } from '../../../helpers/options';
// hocs
import { withAsyncEditCloInvoice } from '../../../hocs/with-async-edit-clo-invoice';
import { withAsyncConfigsByBranches } from '../../../hocs/with-async-configs-by-branches';
import {
  withFixedPopover,
  withDataFilterHoc,
  withAsyncGetBranchListByType,
  withAsyncInvoiceStatusConfigs,
} from '../../../hocs';
// ui
import { Box, Flex, ReactSelect } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature master-invoice
import * as H from '../helpers';
import * as C from '../constants';
import { withAddCloDocumentAndPrintCloInvoice } from '../hocs';
import GroupsWithInvoicesTable from './groups-with-invoices-table';
//////////////////////////////////////////////////

const groupOptions = [
  {
    value: C.GROUP_BY_NAME,
    label: G.getWindowLocale('titles:bill-to-location-name', 'Bill To Location Name'),
  },
  {
    value: C.GROUP_BY_BRANCH,
    label: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: C.GROUP_BY_NO_GROUP,
    label: G.getWindowLocale('titles:no-group', 'No Group'),
  },
];

const getGroupOptions = (reference: Object) => {
  if (G.isNilOrEmpty(reference)) return groupOptions;

  return R.insert(2, {
    value: C.GROUP_BY_REFERENCE,
    label: `Ref: ${G.getPropFromObject(GC.FIELD_NAME, reference)}`,
  }, groupOptions);
};

const enhance = compose(
  withFixedPopover,
  withAddCloDocumentAndPrintCloInvoice,
  withAsyncConfigsByBranches,
  withState('invoiceStatusOptions', 'setInvoiceStatusOptions', []),
  withState('statuses', 'setStatuses', []),
  withAsyncInvoiceStatusConfigs,
  withAsyncGetBranchListByType(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  withAsyncEditCloInvoice({ updateFrom: C.ADD_MASTER_INVOICE }),
  withState('asyncEndpoint', 'setAsyncEndpoint', ({ asyncEndpoint }: Object) => asyncEndpoint),
  withDataFilterHoc,
  withState('groupBy', 'setGroupBy', ({ reference, defaultGroupBy }: Object) => {
    if (R.and(R.equals(defaultGroupBy, C.GROUP_BY_REFERENCE), G.isNilOrEmpty(reference))) {
      return C.GROUP_BY_NO_GROUP;
    }

    return R.or(defaultGroupBy, C.GROUP_BY_NO_GROUP);
  }),
  withState('excludeCustomers', 'setExcludeCustomers', []),
  withState('invoicePaymentTerms', 'setInvoicePaymentTerms', (props: Object) => R.map(
    (value: string) => R.find(R.propEq(value, GC.FIELD_VALUE), PAYMENT_TERM_OPTIONS),
    R.pathOr([], ['configs', 'defaultInvoicePaymentTerms'], props),
  )),
  withState('filterDates', 'setFilterDates', (props: Object) => {
    const dateTo = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

    return {
      dateTo,
      quickDaysValue: props.initialFilterDays,
      dateFrom: G.subtractMomentTimeWithFormat(
        dateTo,
        props.initialFilterDays,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      ),
    };
  }),
  withState('masterInvoiceDates', 'setMasterInvoiceDates', (props: Object) => {
    const dateTo = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_FORMAT);

    return {
      dateTo,
      quickDaysValue: props.initialMasterInvoiceDays,
      dateFrom: G.subtractMomentTimeWithFormat(
        dateTo,
        props.initialMasterInvoiceDays,
        'days',
        GC.DEFAULT_DATE_FORMAT,
      ),
    };
  }),
  withState('initialData', 'setInitialData', null),
  withState('loading', 'setDataLoading', false),
  withHandlers({
    handleSetFilterDates: (props: Object) => (dates: Object) => {
      const {
        groupBy,
        statuses,
        branchGuid,
        documentTypes,
        setFilterDates,
        invoicePaymentTerms,
        getFilteredDataRequest,
      } = props;

      setFilterDates(dates);
      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const paymentTerms = R.map(({ value }: Object) => value, invoicePaymentTerms);
      const options = {
        data: R.mergeRight(
          dates,
          {
            groupBy,
            paymentTerms,
            statusConfigGuids,
            documentTypeGuids,
            [GC.FIELD_BRANCH_GUID]: branchGuid,
          },
        ),
      };
      getFilteredDataRequest(options);
    },
    handleSetDates: (props: Object) => (dates: Object) => props.setMasterInvoiceDates(dates),
    handleSetFilterQuickDays: (props: Object) => (value: number) => {
      const {
        groupBy,
        statuses,
        branchGuid,
        filterDates,
        documentTypes,
        setFilterDates,
        invoicePaymentTerms,
        getFilteredDataRequest,
      } = props;

      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const paymentTerms = R.map(({ value }: Object) => value, invoicePaymentTerms);
      const dates = {
        dateTo: filterDates.dateTo,
        dateFrom: G.subtractMomentTimeWithFormat(
          filterDates.dateTo,
          R.dec(value),
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
      };
      setFilterDates(R.assoc('quickDaysValue', value, dates));
      const options = {
        data: R.mergeRight(
          dates,
          {
            groupBy,
            paymentTerms,
            statusConfigGuids,
            documentTypeGuids,
            [GC.FIELD_BRANCH_GUID]: branchGuid,
          },
        ),
      };
      getFilteredDataRequest(options);
    },
    handleSetQuickDays: (props: Object) => (value: number) => (
      props.setMasterInvoiceDates({
        quickDaysValue: value,
        dateTo: props.masterInvoiceDates.dateTo,
        dateFrom: G.subtractMomentTimeWithFormat(
          props.masterInvoiceDates.dateTo,
          R.dec(value),
          'days',
          GC.DEFAULT_DATE_FORMAT,
        ),
      })
    ),
    handleChangeGroupBy: (props: Object) => ({ value }: Object) => {
      const {
        statuses,
        setGroupBy,
        branchGuid,
        filterDates,
        documentTypes,
        setAsyncEndpoint,
        invoicePaymentTerms,
        getFilteredDataRequest,
      } = props;

      const { dateTo, dateFrom } = filterDates;
      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const paymentTerms = R.map(({ value }: Object) => value, invoicePaymentTerms);
      const options = {
        data: {
          dateTo,
          dateFrom,
          paymentTerms,
          groupBy: value,
          statusConfigGuids,
          documentTypeGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      setGroupBy(value);

      if (R.equals(value, C.GROUP_BY_NO_GROUP)) {
        return setAsyncEndpoint(
          endpointsMap.customerMasterInvoiceNoGroupInvoices,
          () => getFilteredDataRequest(options),
        );
      }

      setAsyncEndpoint(
        endpointsMap.customerMasterInvoiceGroupedInvoices,
        () => getFilteredDataRequest(options),
      );
    },
    handleChangeStatusFilter: (props: Object) => (statuses: Array) => {
      const {
        groupBy,
        branchGuid,
        filterDates,
        setStatuses,
        documentTypes,
        excludeCustomers,
        invoicePaymentTerms,
        getFilteredDataRequest,
      } = props;

      setStatuses(statuses);
      const { dateTo, dateFrom } = filterDates;
      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const paymentTerms = R.map(({ value }: Object) => value, invoicePaymentTerms);
      const excludeEnterpriseGuids = R.map(({ value }: Object) => value, excludeCustomers);
      const options = {
        data: {
          dateTo,
          groupBy,
          dateFrom,
          paymentTerms,
          statusConfigGuids,
          documentTypeGuids,
          excludeEnterpriseGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      getFilteredDataRequest(options);
    },
    handleChangeDocumentTypesFilter: (props: Object) => (documentTypes: Array) => {
      const {
        groupBy,
        statuses,
        branchGuid,
        filterDates,
        excludeCustomers,
        setDocumentTypes,
        invoicePaymentTerms,
        getFilteredDataRequest,
      } = props;

      setDocumentTypes(documentTypes);
      const { dateTo, dateFrom } = filterDates;
      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const paymentTerms = R.map(({ value }: Object) => value, invoicePaymentTerms);
      const excludeEnterpriseGuids = R.map(({ value }: Object) => value, excludeCustomers);
      const options = {
        data: {
          dateTo,
          groupBy,
          dateFrom,
          paymentTerms,
          statusConfigGuids,
          documentTypeGuids,
          excludeEnterpriseGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      getFilteredDataRequest(options);
    },
    handleChangePaymentTermsFilter: (props: Object) => (paymentTermsList: Array) => {
      const {
        groupBy,
        statuses,
        branchGuid,
        filterDates,
        documentTypes,
        excludeCustomers,
        getFilteredDataRequest,
        setInvoicePaymentTerms,
      } = props;

      setInvoicePaymentTerms(paymentTermsList);

      const { dateTo, dateFrom } = filterDates;

      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const paymentTerms = R.map(({ value }: Object) => value, paymentTermsList);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const excludeEnterpriseGuids = R.map(({ value }: Object) => value, excludeCustomers);
      const options = {
        data: {
          dateTo,
          groupBy,
          dateFrom,
          paymentTerms,
          statusConfigGuids,
          documentTypeGuids,
          excludeEnterpriseGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      getFilteredDataRequest(options);
    },
    handleChangeExcludeCustomers: (props: Object) => (excludeCustomers: Array) => {
      const {
        groupBy,
        statuses,
        branchGuid,
        filterDates,
        paymentTerms,
        documentTypes,
        setExcludeCustomers,
        getFilteredDataRequest,
      } = props;

      setExcludeCustomers(excludeCustomers);

      const { dateTo, dateFrom } = filterDates;

      const statusConfigGuids = R.map(({ value }: Object) => value, statuses);
      const documentTypeGuids = R.map(({ value }: Object) => value, documentTypes);
      const excludeEnterpriseGuids = R.map(({ value }: Object) => value, excludeCustomers);
      const options = {
        data: {
          dateTo,
          groupBy,
          dateFrom,
          paymentTerms,
          statusConfigGuids,
          documentTypeGuids,
          excludeEnterpriseGuids,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };
      getFilteredDataRequest(options);
    },
  }),
  withPropsOnChange(['CIStatusConfig'], (props: Object) => {
    if (G.isNotNilAndNotEmpty(props.CIStatusConfig)) {
      const invoiceStatusOptions = props.CIStatusConfig.map(({ guid, parentGuid, displayedValue }: Object) => ({
        label: displayedValue,
        value: R.or(parentGuid, guid),
      }));
      const setStatuses = () => (
        props.handleChangeStatusFilter(R.filter(
          ({ value }: Object) => R.includes(value, R.pathOr([], ['configs', 'defaultInvoiceStatusGuids'], props)),
          invoiceStatusOptions,
        ))
      );
      props.setInvoiceStatusOptions(invoiceStatusOptions, setStatuses);
    }
  }),
  withPropsOnChange(
    (props: Object, nextProps: Object) => {
      const path = ['asyncData', 'data'];

      if (R.equals(R.path(path, props), R.path(path, nextProps))) return false;

      return true;
    },
    (props: Object) => {
      const { setDataLoading, asyncData: { data } } = props;

      if (R.isNil(data)) return;

      setDataLoading(true);

      async function getConfigs() {
        const {
          groupBy,
          autoNumber,
          branchGuid,
          setInitialData,
          masterInvoiceDates,
          getConfigsByBranchesRequest,
        } = props;

        const branchGuids = R.and(G.isNotNilAndNotEmpty(branchGuid), R.equals(groupBy, C.GROUP_BY_NO_GROUP))
          ? [branchGuid]
          : R.uniq(R.map(R.prop(GC.BRANCH_GUID), data));

        const configs = await getConfigsByBranchesRequest({
          names: [GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED],
        }, branchGuids);

        const initialData = H.createInitialData(
          data,
          groupBy,
          masterInvoiceDates,
          branchGuid,
          configs,
          autoNumber,
        );

        setInitialData(initialData, () => setDataLoading(false));
      }

      getConfigs();
    },
  ),
  pure,
);

const AddMasterInvoice = enhance((props: Object) => {
  const {
    groupBy,
    statuses,
    openModal,
    reference,
    closeModal,
    branchGuid,
    autoNumber,
    initialData,
    filterDates,
    documentTypes,
    handleSetDates,
    updatedInvoice,
    openFixedPopup,
    closeFixedPopup,
    branchListByType,
    excludeCustomers,
    addMasterInvoices,
    masterInvoiceDates,
    handleSetQuickDays,
    handleChangeGroupBy,
    documentTypeOptions,
    invoicePaymentTerms,
    handleSetFilterDates,
    invoiceStatusOptions,
    handleAddCloDocument,
    handleEditCloInvoice,
    handleChangeStatusFilter,
    handleSetFilterQuickDays,
    handleChangeExcludeCustomers,
    handleChangePaymentTermsFilter,
    handleChangeDocumentTypesFilter,
  } = props;

  const reactSelectProps = {
    height: 30,
    isMulti: true,
    isClearable: false,
    openMenuOnFocus: true,
    shouldNotGetValueFromOptions: true,
    additionalStyles: {
      container: (baseStyles: Object) => ({
        ...baseStyles,
        width: 180,
        marginRight: 12,
      }),
    },
  };

  return (
    <Box
      width='95vw'
      zIndex='unset'
      maxWidth={1200}
      bg={G.getTheme('colors.white')}
    >
      <FormGroupTitleMultiple
        mb='0'
        showArrowToggle={false}
        title={G.getWindowLocale('titles:add-master-invoice', 'Add Master Invoice')}
      />
      <Flex p={15} alignItems='center'>
        <DateRangeMui
          {...filterDates}
          width={80}
          useNewMuiInputField={true}
          popperPlacement='bottom-end'
          quickDays={GC.QUICK_DAYS_FILTER}
          setQuickDays={handleSetFilterQuickDays}
          onSelectDateRange={handleSetFilterDates}
          maxDate={G.momentAddYearsFromCurrent(100)}
          labelTo={G.getWindowLocale('titles:to', 'To')}
          minDate={G.momentSubtractYearsFromCurrent(100)}
          labelFrom={G.getWindowLocale('titles:from', 'From')}
          label={G.getWindowLocale('titles:invoices-date-range', 'Invoices Date Range')}
        />
        <Box mr='8px' fontSize={12}>
          {G.getWindowLocale('titles:group-by', 'Group By')}
        </Box>
        <ReactSelect
          {...R.omit(['isMulti', 'shouldNotGetValueFromOptions'], reactSelectProps)}
          value={groupBy}
          onChange={handleChangeGroupBy}
          options={getGroupOptions(reference)}
        />
      </Flex>
      <Flex ml={10} my={12} alignItems='flex-start'>
        <Box mt='6px' mr='8px'>
          <Box fontSize={12} m='0 0 5px 10px'>
            {G.getWindowLocale('titles:invoice-status-filter', 'Invoice Status Filter')}
          </Box>
          <ReactSelect
            {...reactSelectProps}
            value={statuses}
            options={invoiceStatusOptions}
            onChange={handleChangeStatusFilter}
          />
        </Box>
        <Box mt='6px' mr='8px'>
          <Box fontSize={12} m='0 0 5px 10px'>
            {G.getWindowLocale('titles:document-type-filter', 'Document Type Filter')}
          </Box>
          <ReactSelect
            {...reactSelectProps}
            mr={12}
            value={documentTypes}
            useMenuPortalTarget={true}
            options={documentTypeOptions}
            onChange={handleChangeDocumentTypesFilter}
          />
        </Box>
        <Box mt='6px' mr='8px'>
          <Box fontSize={12} m='0 0 5px 10px'>
            {G.getWindowLocale('titles:document-type-filter', 'Invoice Payment Terms Filter')}
          </Box>
          <ReactSelect
            {...reactSelectProps}
            useMenuPortalTarget={true}
            value={invoicePaymentTerms}
            options={PAYMENT_TERM_OPTIONS}
            onChange={handleChangePaymentTermsFilter}
          />
        </Box>
        <Box mt='6px'>
          <Box fontSize={12} m='0 0 5px 10px'>
            {G.getWindowLocale('titles:exclude-customers', 'Exclude Customers')}
          </Box>
          <ReactSelect
            {...reactSelectProps}
            useMaxHeight={true}
            value={excludeCustomers}
            options={branchListByType}
            valueContainerMaxHeight={200}
            onChange={handleChangeExcludeCustomers}
          />
        </Box>
      </Flex>
      {
        G.isNotNilAndNotEmpty(props.initialData) &&
        <Box p={15} borderTop='1px solid' borderColor={G.getTheme('colors.lightGrey')}>
          <DateRangeMui
            {...masterInvoiceDates}
            width={80}
            useNewMuiInputField={true}
            popperPlacement='bottom-end'
            quickDays={GC.QUICK_DAYS_RANGE}
            setQuickDays={handleSetQuickDays}
            onSelectDateRange={handleSetDates}
            maxDate={G.momentAddYearsFromCurrent(100)}
            labelTo={G.getWindowLocale('titles:to', 'To')}
            minDate={G.momentSubtractYearsFromCurrent(100)}
            labelFrom={G.getWindowLocale('titles:from', 'From')}
            label={G.getWindowLocale('titles:set-master-invoices-date-range', 'Set Master Invoices Date Range')}
          />
        </Box>
      }
      <LocalLoader width='100%' localLoaderOpen={props.loading}>
        <GroupsWithInvoicesTable
          groupBy={groupBy}
          openModal={openModal}
          reference={reference}
          closeModal={closeModal}
          branchGuid={branchGuid}
          autoNumber={autoNumber}
          initialData={initialData}
          dates={masterInvoiceDates}
          documentTypes={documentTypes}
          updatedInvoice={updatedInvoice}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          addDocument={handleAddCloDocument}
          addMasterInvoices={addMasterInvoices}
          handleEditInvoice={handleEditCloInvoice}
        />
      </LocalLoader>
    </Box>
  );
});

export default AddMasterInvoice;
