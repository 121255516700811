import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectMailSendingStore = (state: Object) => state.mailSending;

const makeSelectMailSending = () => createSelector(
  selectMailSendingStore,
  ({ mailSend }: Object) => mailSend,
);

const makeSelectContactList = () => createSelector(
  selectMailSendingStore,
  ({ contactList }: Object) => contactList,
);

export { makeSelectMailSending, makeSelectContactList };
