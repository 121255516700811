import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers} from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
// features
import { withApplyOrderRateUpliftForm } from '../../../features/rate/components/apply-order-rate-uplift-form';
// features
// feature new-do
import withTripRates from '../../new-do/hocs/with-trip-rates';
import { FORM_BOX_SHADOW, TAB_NAME_PRICING } from '../../new-do/constants';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, ActionButton } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature lite-new-do
import FormTab from './form-tab';
import PrevNextAction from './validate-action';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.dark.blue');

const actionStyles = {
  ml: 20,
  height: 32,
  p: '4px 8px',
  fontSize: 14,
  borderRadius: '5px',
  bgColor: whiteColor,
  textColor: blueColor,
  border: `1px solid ${blueColor}`,
};

const DriverInfo = (props: Object) => {
  const { driverRate } = props;

  const driver = R.path(['frontData', 'driver'], driverRate);

  const driverGuid = G.getGuidFromObject(driver);
  const { fullText } = G.getUserInfo(driver);

  const clickHandler = () => window.open(
    `${window.location.origin}${routesMap[G.getFleetProfileRoutePathNameByFleetType()](driverGuid)}`,
    G.getWindowLocale('titles:driver', 'Driver'),
    'width:200,height:200',
  );

  return (
    <Box mr={20}>
      <InfoPair
        text={fullText}
        clickHandler={clickHandler}
        label={G.getWindowLocale('titles:driver', 'Driver')}
      />
    </Box>
  );
};

const RateInfo = (props: Object) => {
  const { rateName } = props;

  const rate = R.path([rateName], props);

  if (G.isNilOrEmpty(rate)) return null;

  return (
    <Box>
      <InfoPair
        text={G.getTelMainTotalInfo(rate)}
        label={G.getWindowLocale('titles:main', 'Main')}
      />
      <InfoPair
        text={G.getTelDiscountTotalInfo(rate)}
        label={G.getWindowLocale('titles:discount', 'Discount')}
      />
      <InfoPair
        text={G.getTelFuelTotalInfo(rate)}
        label={G.getWindowLocale('titles:fuel', 'Fuel')}
      />
      <InfoPair
        text={G.getTelAdditionalChargesTotalInfo(rate)}
        label={G.getWindowLocale('titles:other', 'Other')}
      />
      <InfoPair
        text={G.getTelChargesTotalInfo(rate)}
        label={G.getWindowLocale('titles:total', 'Total')}
      />
    </Box>
  );
};

const TripPricing = (props: Object) => {
  const {
    driverRate,
    handleClickPrev,
    setValueToStore,
    handleClickSave,
    handleAddDriverRate,
    handleEditDriverRate,
    handleApplyOrderRateUpliftForm,
  } = props;

  return (
    <Box>
      <FormTab {...props} />
      <Box boxShadow={FORM_BOX_SHADOW}>
        <Box pt={20}>
          <Box mb={20}>
            <ActionButton
              {...actionStyles}
              cursor='pointer'
              onClick={handleAddDriverRate}
            >
              {G.getWindowLocale('titles:add-driver-rate', 'Add Driver Rate')}
            </ActionButton>
            { driverRate &&
              <ActionButton
                {...actionStyles}
                cursor='pointer'
                onClick={handleEditDriverRate}
              >
                {G.getWindowLocale('titles:edit-driver-rate', 'Edit Driver Rate')}
              </ActionButton>
            }
            {
              driverRate &&
              <ActionButton
                {...actionStyles}
                cursor='pointer'
                onClick={() => setValueToStore({ value: null, path: 'telDriverRate' })}
              >
                {G.getWindowLocale('titles:remove-driver-rate', 'Remove Driver Rate')}
              </ActionButton>
            }
            {
              driverRate &&
              <ActionButton
                {...actionStyles}
                cursor='pointer'
                onClick={handleApplyOrderRateUpliftForm}
              >
                {G.getWindowLocale('titles:apply-order-rate-uplift', 'Apply Order Rate Uplift')}
              </ActionButton>
            }
          </Box>
          {
            driverRate &&
            <Flex mx={20} mb={20}>
              <DriverInfo {...props} />
              <RateInfo {...props} rateName='driverRate' />
            </Flex>
        }
          <PrevNextAction
            showNext={false}
            submitForm={handleClickSave}
            handleClickPrev={handleClickPrev}
          />
        </Box>
      </Box>
    </Box>
  );
};

const enhance = compose(
  withTripRates,
  withApplyOrderRateUpliftForm({ submitActionName: 'applyOrderRateUpliftRequest' }),
  withHandlers({
    onClickTabCallback: (props: Object) => (activeName: Object) => {
      const { setActiveLeftTab } = props;

      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { setActiveLeftTab } = props;

      setActiveLeftTab(TAB_NAME_PRICING);
    },
  }),
  pure,
);

export default enhance(TripPricing);
