import * as R from 'ramda';
// helpers/constants
import * as GC from '../../../constants';
import {
  PAYMENT_TERM_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const defaultBillToFields = {
  [GC.FIELD_PAYMENT_TERM]: '',
  // location
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_ADDRESS_2]: '',
  [GC.FIELD_TEMPLATE_ID]: '',
  [GC.FIELD_LOCATION_TYPE]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  // contact
  [GC.FIELD_FAX]: '',
  [GC.FIELD_PHONE]: '',
  [GC.FIELD_EMAILS]: [],
  [GC.FIELD_CONTACT_NAME]: '',
  [GC.FIELD_PHONE_EXTENSION]: '',
};

const defaultCustomerRateFields = {
  [GC.FIELD_MODE]: '',
  [GC.FIELD_CHARGES]: [],
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_CURRENCY]: '',
  [GC.FIELD_SERVICE_TYPE]: '',
  [GC.FIELD_SERVICE_DAYS]: '',
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: '',
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: '',
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: '',
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: '',
};

const defaultCustomerFields = {
  ...defaultBillToFields,
  ...defaultCustomerRateFields,
};

const mainBillToFields = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    name: 'titles:payment-terms',
    fieldName: GC.FIELD_PAYMENT_TERM,
    label: ['titles:payment-term', 'Payment Term'],
    options: R.prepend(GC.EMPTY_OPTION_OBJECT, PAYMENT_TERM_OPTIONS),
  },
  {
    type: 'select',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    label: ['titles:type', 'Type'],
    fieldName: GC.FIELD_LOCATION_TYPE,
    options: {
      configName: GC.TEMPLATES_LOCATION_TYPE,
    },
  },
  {
    type: 'asyncSearch',
    inputWrapperStyles: {
      zIndex: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: ['titles:search-by-template-id-name', 'Search by Template Id/Name'],
  },
];

const addressBillToFields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    inputWrapperStyles: {
      mr: 15,
      zIndex: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: ['titles:address1', 'Address1'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 170,
    },
    fieldName: GC.FIELD_ADDRESS_2,
    label: ['titles:address2', 'Address2'],
  },
];

const addressBillToFields2 = [
  {
    isRequired: true,
    omitAddress1: true,
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_ZIP,
    label: ['titles:postal-code', 'Postal Code'],
  },
  {
    isRequired: true,
    type: 'countrySelect',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
  },
];

const contactFields = [
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    fieldName: GC.FIELD_CONTACT_NAME,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 170,
    },
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
  },
  {
    type: 'number',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 170,
    },
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
];

const contactFields2 = [
  {
    type: 'multiEmail',
    inputWrapperStyles: {
      mr: 15,
      width: 235,
    },
    shouldOverrideEmails: true,
    fieldName: GC.FIELD_EMAILS,
    label: ['titles:emails', 'Emails'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      width: 235,
    },
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax-number', 'Fax Number]'],
  },
];

const contactFields3 = [
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_CONTACT_NAME,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 150,
    },
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
  },
  {
    type: 'text',
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax-number', 'Fax Number]'],
  },
];

const commentFields = [
  {
    type: 'textarea',
    inputStyles: {
      p: 15,
      height: 60,
    },
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 500,
    },
    fieldName: GC.FIELD_COMMENTS,
  },
];

const totalCustomerRateFields = [
  {
    type: 'text',
    isRequired: true,
    inputStyles: {
      width: 83,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    label: ['titles:total-distance', 'Total Distance'],
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    inputStyles: {
      width: 95,
      borderRadius: '0px 4px 4px 0px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  },
  {
    type: 'text',
    isRequired: true,
    inputStyles: {
      width: 83,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    label: ['titles:total-weight', 'Total Weight'],
  },
  {
    type: 'select',
    inputStyles: {
      width: 95,
      borderRadius: '0px 4px 4px 0px',
    },
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
  },
];

const customerRateFields = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles: {
      mr: 15,
      flexGrow: 1,
      flexBasis: 100,
    },
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
    label: ['titles:mode', 'Mode'],
  },
  ...totalCustomerRateFields,
];

const allCustomerFormFields = [
  ...mainBillToFields,
  ...addressBillToFields,
  ...addressBillToFields2,
  ...contactFields,
  ...contactFields2,
  ...commentFields,
  ...customerRateFields,
];

export {
  commentFields,
  contactFields,
  contactFields2,
  contactFields3,
  mainBillToFields,
  customerRateFields,
  addressBillToFields,
  defaultBillToFields,
  addressBillToFields2,
  allCustomerFormFields,
  defaultCustomerFields,
};
