import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../../common/actions';
// report-common
import { getReportReducers } from '../../../report-common';
// feature invoice
import * as H from '../helpers';
// feature invoice/vendor
import * as A from './actions';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers();

const getConfigsSuccess = (state: Object, configs: Object) => (
  P.$set('configs', configs, state)
);

const changeInvoicesSuccess = (state: Object, data: Object) => H.changeInvoicesSuccess(state, data);

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.getConfigsSuccess]: getConfigsSuccess,
  [A.changeInvoicesSuccess]: changeInvoicesSuccess,
}, initialState);
