import * as R from 'ramda';
import React from 'react';
// components
import { LocalLoader } from '../../../components/local-loader';
// features
import DocumentForm from '../../dispatch-details-new/forms/document-form';
// helpers/constants
import * as GC from '../../../constants';
// hocs
import { withAsyncCloEvents, withAsyncAvailableDocumentTypes } from '../../../hocs';
//////////////////////////////////////////////////

const DocumentFormWithAsyncCloEvents = withAsyncAvailableDocumentTypes(withAsyncCloEvents((props: Object) => (
  <LocalLoader localLoaderOpen={R.isNil(props.asyncEvents)}>
    <DocumentForm
      {...props}
      load={R.assoc(GC.FIELD_LOAD_STOPS, props.asyncEvents, props.load)}
    />
  </LocalLoader>
)));

export default DocumentFormWithAsyncCloEvents;
