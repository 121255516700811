import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature master-invoice
import { ChargesSectionFooter } from './charges';
//////////////////////////////////////////////////

const getTotalByPropName = (list: Array, propName: string) => R.compose(
  R.sum,
  R.map(R.prop(propName)),
)(list);

const SelectedListTotals = (props: Object) => {
  const { list } = props;

  let currency = R.or(
    G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
    GC.DEFAULT_UI_CURRENCY,
  );

  if (R.equals(R.length(list), 1)) {
    currency = R.path([0, GC.FIELD_CURRENCY], list);
  }

  const currencySymbol = G.getCurrencySymbol(currency);

  const discountTotal = getTotalByPropName(list, GC.FIELD_MASTER_INVOICE_TOTAL_DISCOUNT);
  const invoicesTotal = getTotalByPropName(list, GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL);
  const additionalCharges = getTotalByPropName(list, GC.FIELD_MASTER_INVOICE_CHARGES_TOTAL);
  const grandTotal = getTotalByPropName(list, GC.FIELD_MASTER_INVOICE_GRAND_TOTAL);
  const invoicesCount = getTotalByPropName(list, 'invoicesCount');

  return (
    <Flex>
      <Box p='10px 0' bg={G.getTheme('modal.bgColor')}>
        <TextComponent
          mr={15}
          p='2px 10px'
          fontSize={14}
          display='block'
          fontWeight={700}
          borderRadius='3px'
          color={G.getTheme('colors.white')}
          bg={G.getTheme('colors.light.blueGrey')}
        >
          {
            `${G.getWindowLocale('titles:invoices-count', 'Invoices Count')}: ${invoicesCount}`
          }
        </TextComponent>
      </Box>
      <ChargesSectionFooter
        grandTotal={grandTotal}
        currency={currencySymbol}
        discountTotal={discountTotal}
        invoicesTotal={invoicesTotal}
        additionalCharges={additionalCharges}
      />
    </Flex>
  );
};

export default SelectedListTotals;
