import { put, call } from 'redux-saga/effects';
// components
import { closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

export function* getInvoiceConfigByGroupSaga(params: Object) {
  try {
    return yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      { params },
    );
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getInvoiceConfigByGroupSaga exception');
  }
}
