import * as R from 'ramda';
import { delay } from 'redux-saga';
import {
  all,
  put,
  call,
  take,
  select,
  takeLatest,
} from 'redux-saga/effects';
// common
import { initialDataLoadSuccess } from '../../common/actions';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
import { closeModal, closeAllModals } from '../../components/modal/actions';
import { checkReportFunction, transformSearchCriteriaBeforeReportPost } from '../../components/edit-report/helpers';
// features
import PC from '../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectCurrentUserSettingsFields } from '../profile/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { getLoadBoardIntegrationConfigListRequest } from '../configurations/config-pages/integration/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { sequenceAll, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature load board
import * as A from './actions';
import {
  makeSelectSearchFilters,
  makeSelectSearchFilterStates,
  makeSelectSearchFiltersCalled,
  makeSelectAuthorizedLoadBoards,
  makeSelectIndexedSearchFilterList,
} from './selectors';
import * as H from './helpers';
//////////////////////////////////////////////////

// load board
function* handleLoginLoadBoardSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpointsMap.loadBoardLoginNew, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.loginLoadBoardSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleLoginLoadBoardSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleLoginLoadBoardSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleLogoutLoadBoardSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const loadBoards = yield select(makeSelectAuthorizedLoadBoards());

    const loadboard = R.find(R.propEq(
      payload,
      GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE,
    ), loadBoards);

    const res = yield call(sendRequest, 'delete', endpointsMap.loadBoardLoginById(R.prop(GC.FIELD_GUID, loadboard)));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.logoutLoadBoardSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleLogoutLoadBoardSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleLogoutLoadBoardSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const CONFIGS_ARR = [
  GC.UI_LOADBOARD_AUTODIALMODAL,
  GC.UI_LOADBOARD_AUTODIALACTION,
  GC.UI_LOADBOARD_AUTODIALAPPNAME,
];

function* getConfigsByNamesSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const configs = G.mapConfigValuesByName(data);

      yield put(A.getConfigsByNamesSuccess(configs));
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* getLoggedLoadBoardsRequest() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.loadBoardLoginNew);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoggedLoadBoardsSuccess(data));
      yield put(A.getConfigsByNamesRequest());
    } else {
      yield call(G.handleFailResponse, res, 'getLoggedLoadBoardsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoggedLoadBoardsRequest exception');
  }
}
// end load board

function* handleGetSearchFilterStatesSaga({ payload }: Object) {
  try {
    const params = { searchFilterGuid: payload };

    const res = yield call(sendRequest, 'get', endpointsMap.searchFilterState, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSearchFilterStatesSuccess({ data: data.elements, searchFilterGuid: payload }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSearchFilterStatesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetSearchFilterStatesSaga exception');
  }
}

function* handleCreateFilterStateSaga(payload: Object) {
  try {
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.searchFilterState, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createSearchFilterStateSuccess({ payload, data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateFilterStateSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleCreateFilterStateSaga exception');
  }
}

function* handleDeleteFilterStateSaga({ guid, searchFilterGuid }: Object) {
  try {
    const res = yield call(sendRequest, 'delete', endpointsMap.searchFilterStateById(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteSearchFilterStateSuccess({ guid, searchFilterGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteFilterStateSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleDeleteFilterStateSaga exception');
  }
}

function* handleActivateFilterStateSaga({ payload }: Object) {
  try {
    const options = { data: { guid: payload, status: GC.LB_STATUS_ACTIVE }};

    const res = yield call(sendRequest, 'put', endpointsMap.searchFilterStateStatus, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.activateSearchFilterStateSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleActivateFilterStateSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleActivateFilterStateSaga exception');
  }
}

function* handleGetSearchFiltersSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.searchFilter);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSearchFiltersSuccess(data));

      const filters = yield select(makeSelectSearchFilters());

      if (G.isNotEmpty(filters)) {
        yield sequenceAll(filters.map(({ guid }: Object) => call(
          handleGetSearchFilterStatesSaga,
          { payload: guid },
        )));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSearchFiltersSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetSearchFiltersSaga exception');
  }
}

function* handleCreateSearchFilterSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload) };

    const res = yield call(sendRequest, 'post', endpointsMap.searchFilter, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.createSearchFilterSuccess(data));
      yield put(A.getSearchFilterStatesRequest(G.getGuidFromObject(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateSearchFilterSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateSearchFilterSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteSearchFilterSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.searchFilterById(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.deleteSearchFilterSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteSearchFilterSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteSearchFilterSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* updateFilterLBTypes(payload: Object) {
  const types = R.prop('types', payload);
  const searchFilterGuid = G.getGuidFromObject(payload);
  const states = yield select(makeSelectSearchFilterStates());
  const lbStates = R.propOr([], searchFilterGuid, states);

  const statusesToCreate = [];
  const statusesToDelete = [];

  R.forEach((type: string) => {
    if (R.not(R.any(R.propEq(type, GC.FIELD_TYPE), lbStates))) {
      statusesToCreate.push({ type, searchFilterGuid });
    }
  }, types);

  yield all(R.map((data: Object) => call(handleCreateFilterStateSaga, data), statusesToCreate));

  R.forEach((status: string) => {
    const { type, guid} = status;

    if (R.not(R.includes(type, types))) {
      statusesToDelete.push({ guid, searchFilterGuid });
    }
  }, lbStates);

  yield all(R.map((data: Object) => call(handleDeleteFilterStateSaga, data), statusesToDelete));
}
function* handleUpdateSearchFilterSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    yield call(updateFilterLBTypes, payload);

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, R.omit(['types'], payload)) };

    const res = yield call(sendRequest, 'put', endpointsMap.searchFilter, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.updateSearchFilterSuccess(data));
      yield put(A.getSearchFilterStatesRequest(G.getGuidFromObject(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateSearchFilterSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateSearchFilterSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleToggleSelectSearchFilterSaga({ payload }: Object) {
  try {
    const states = yield select(makeSelectSearchFilterStates());

    const data = {
      [GC.FIELD_GUID]: payload,
      [GC.FIELD_STATUS]: G.ifElse(
        R.any(R.propEq(GC.LB_STATUS_ACTIVE, GC.FIELD_STATUS), R.propOr([], payload, states)),
        GC.LB_STATUS_DISABLED,
        GC.LB_STATUS_ACTIVE,
      ),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.searchFilterStatus, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSearchFilterStatesRequest(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleToggleSelectSearchFilterSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleToggleSelectSearchFilterSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

// reports
function* handleGetAvailableReportListSaga() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return;

    const params = {
      reportType: GC.EXTERNAL_LOAD_BOARD_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checked = checkReportFunction(reports);

      yield put(A.setReports(checked));

      if (G.isNilOrEmpty(reports)) return;

      const defaultReport = G.findDefaultReport(checked);

      yield put(A.setUsedReport(defaultReport));
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(payload));

      yield call(handleGetAvailableReportListSaga);
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleGetAvailableReportListSaga);
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));

    const options = {
      data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.report, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(payload));

      yield call(handleGetAvailableReportListSaga);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}
// end reports

// denied company
function* handleGetCompanyFiltersSaga() {
  try {
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.deniedCompanyPage,
      {
        data: {
          page: 0,
          limit: 1000,
        },
      },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const deniedCompanies = R.prop('elements', data);

      yield put(A.getCompanyFiltersSuccess(deniedCompanies));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCompanyFiltersSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCompanyFiltersSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAddCompanyFilterSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpointsMap.deniedCompany, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.addCompanyFilterSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleAddCompanyFilterSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleAddCompanyFilterSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteCompanyFilterSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.deniedCompanyById(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteCompanyFilterSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCompanyFilterSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCompanyFilterSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}
// end denied company

// shipment
function* handleGetSavedShipmentsRequest() {
  try {
    const requestData = {
      data: {
        page: 0,
        limit: 1000,
        filter: {
          [GC.FIELD_STATUS]: GC.LB_SHIPMENT_STATUS_SAVED,
        },
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.committedShipmentPage, requestData);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSavedShipmentsSuccess(data.elements));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSavedShipmentsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetSavedShipmentsRequest exception');
  }
}
function* handleSaveShipmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const filters = yield select(makeSelectIndexedSearchFilterList());

    const searchFilterGuid = R.prop('searchFilterGuid', payload);
    const prepared = R.omit(['highlighted', 'cancelled'], payload);

    const bookmarkData = H.remapToCommit(prepared, filters, GC.LB_SHIPMENT_STATUS_SAVED);

    const res = yield call(sendRequest, 'post', endpointsMap.committedShipment, { data: bookmarkData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.saveShipmentSuccess({
        shipment: data,
        searchFilterGuid,
      }));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveShipmentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleSaveShipmentSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateShipmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const bookmarkData = H.remapToUpdateShipment(payload);

    const res = yield call(sendRequest, 'put', endpointsMap.committedShipment, { data: bookmarkData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.updateShipmentSuccess(data));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateShipmentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateShipmentSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteSavedShipmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const guid = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_GUID], payload);

    const res = yield call(sendRequest, 'delete', endpointsMap.committedShipmentById(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteSavedShipmentSuccess(payload));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteSavedShipmentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteSavedShipmentSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}
// end shipment

// booking
function* handleGetBookedShipmentsRequest() {
  try {
    const requestData = {
      data: {
        page: 0,
        limit: 1000,
        filter: {
          [GC.FIELD_STATUS]: GC.LB_SHIPMENT_STATUS_COMMITTED,
        },
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.committedShipmentPage, requestData);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBookedShipmentsSuccess(data.elements));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetBookedShipmentsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBookedShipmentsRequest exception');
  }
}

function* handleBookSavedShipmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentUserData = yield select(makeSelectCurrentUserSettingsFields());

    const bookingData = H.getBookingRequestData(payload, currentUserData);

    const res = yield call(sendRequest, 'post', endpointsMap.committedShipmentBooking, { data: bookingData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteSavedShipmentSuccess(payload));
      yield put(A.bookSavedShipmentSuccess({ shipment: payload, booking: data }));
    } else {
      yield call(G.handleFailResponse, res, 'handleBookSavedShipmentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleBookSavedShipmentSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteBookedShipmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const guid = R.path([GC.FIELD_LOAD_BOARD_SHIPMENT, GC.FIELD_LOAD_BOARD_BOOKING, GC.FIELD_GUID], payload);

    const res = yield call(sendRequest, 'delete', endpointsMap.committedShipmentBookingById(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteBookedShipmentSuccess(payload));

      yield delay(10);

      yield put(A.getSavedShipmentsRequest());

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteBookedShipmentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteBookedShipmentSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}
// end booking

// templates
function* handleGetTemplatesSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.searchFilterTemplate);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTemplatesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTemplatesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetTemplatesSaga exception');
  }
}

function* handleSaveTemplateSaga({ payload }: Object) {
  try {
    const method = G.ifElse(G.isNotNil(G.getGuidFromObject(payload)), 'put', 'post');

    const res = yield call(sendRequest, method, endpointsMap.searchFilterTemplate, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.saveTemplateSuccess(data));
      yield put(closeAllModals());
    } else {
      yield call(G.handleFailResponse, res, 'handleSaveTemplateSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleSaveTemplateSaga exception');
  }
}

function* handleDeleteTemplateSaga({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'delete', endpointsMap.searchFilterTemplateById(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteTemplateSuccess(payload));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTemplateSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleDeleteTemplateSaga exception');
  }
}
// templates

function* handleStartLoadSearchSaga() {
  const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());

  if (G.isAnyTrue(
    R.not(isInitialDataLoaded),
    G.isCurrentBranchTypeCustomer(),
    G.hasNotAmousCurrentUserPermissions([PC.EXTERNAL_LOAD_BOARD_EXECUTE]),
  )) return;

  yield put(getLoadBoardIntegrationConfigListRequest());

  yield call(getLoggedLoadBoardsRequest);

  yield put(A.getSearchFiltersRequest());
  yield put(A.getTemplatesRequest());
}

function* handleVisitLoadBoardPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_LOAD_BOARD_PAGE);

    if (G.isCurrentBranchTypeCustomer()) return;

    yield put(openLoader({ showDimmer: true }));

    const searchFiltersCalled = yield select(makeSelectSearchFiltersCalled());

    if (R.not(searchFiltersCalled)) {
      yield take(A.getSearchFiltersSuccess);
    }

    yield put(A.getSavedShipmentsRequest());
    yield put(A.getBookedShipmentsRequest());

    yield call(handleGetCompanyFiltersSaga);
    yield call(handleGetAvailableReportListSaga);

    yield put(closeLoader());
    break;
  }
}

function* loadBoardWatcherSaga() {
  yield takeLatest(A.startLoadSearchPage, handleStartLoadSearchSaga);
  yield takeLatest(GC.VISIT_LOAD_BOARD_PAGE, handleVisitLoadBoardPageSaga);
  // configs
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  // report
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // companies
  yield takeLatest(A.addCompanyFilterRequest, handleAddCompanyFilterSaga);
  yield takeLatest(A.deleteCompanyFilterRequest, handleDeleteCompanyFilterSaga);
  // shipment
  yield takeLatest(A.saveShipmentRequest, handleSaveShipmentSaga);
  yield takeLatest(A.updateShipmentRequest, handleUpdateShipmentSaga);
  yield takeLatest(A.getSavedShipmentsRequest, handleGetSavedShipmentsRequest);
  yield takeLatest(A.deleteSavedShipmentRequest, handleDeleteSavedShipmentSaga);
  // booking
  yield takeLatest(A.bookSavedShipmentRequest, handleBookSavedShipmentSaga);
  yield takeLatest(A.getBookedShipmentsRequest, handleGetBookedShipmentsRequest);
  yield takeLatest(A.deleteBookedShipmentRequest, handleDeleteBookedShipmentSaga);
  // load boards
  yield takeLatest(A.loginLoadBoardRequest, handleLoginLoadBoardSaga);
  yield takeLatest(A.logoutLoadBoardRequest, handleLogoutLoadBoardSaga);
  yield takeLatest(A.getSearchFiltersRequest, handleGetSearchFiltersSaga);
  yield takeLatest(A.getLoggedLoadBoardsRequest, getLoggedLoadBoardsRequest);
  // filters
  yield takeLatest(A.createSearchFilterRequest, handleCreateSearchFilterSaga);
  yield takeLatest(A.deleteSearchFilterRequest, handleDeleteSearchFilterSaga);
  yield takeLatest(A.updateSearchFilterRequest, handleUpdateSearchFilterSaga);
  yield takeLatest(A.activateSearchFilterState, handleActivateFilterStateSaga);
  yield takeLatest(A.toggleSelectSearchFilter, handleToggleSelectSearchFilterSaga);
  yield takeLatest(A.getSearchFilterStatesRequest, handleGetSearchFilterStatesSaga);
  // templates
  yield takeLatest(A.getTemplatesRequest, handleGetTemplatesSaga);
  yield takeLatest(A.saveTemplateRequest, handleSaveTemplateSaga);
  yield takeLatest(A.deleteTemplateRequest, handleDeleteTemplateSaga);

  // start load board
  yield take(initialDataLoadSuccess);

  yield put(A.startLoadSearchPage());
}

export {
  getConfigsByNamesSaga,
  handleSaveShipmentSaga,
  handleGetTemplatesSaga,
  handleSaveTemplateSaga,
  handleUpdateShipmentSaga,
  handleLoginLoadBoardSaga,
  handleDeleteTemplateSaga,
  handleLogoutLoadBoardSaga,
  handleStartLoadSearchSaga,
  handleAddCompanyFilterSaga,
  handleGetSearchFiltersSaga,
  getLoggedLoadBoardsRequest,
  handleBookSavedShipmentSaga,
  handleGetCompanyFiltersSaga,
  handleVisitLoadBoardPageSaga,
  handleCreateSearchFilterSaga,
  handleDeleteSearchFilterSaga,
  handleUpdateSearchFilterSaga,
  handleChangeDefaultReportSaga,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleDeleteCompanyFilterSaga,
  handleDeleteSavedShipmentSaga,
  handleActivateFilterStateSaga,
  handleDeleteBookedShipmentSaga,
  handleGetSavedShipmentsRequest,
  handleGetBookedShipmentsRequest,
  handleGetSearchFilterStatesSaga,
  handleGetAvailableReportListSaga,
  handleToggleSelectSearchFilterSaga,
};

export default loadBoardWatcherSaga;
