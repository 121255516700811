import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const store = ({ serviceVendorInvoice }: Object) => serviceVendorInvoice;

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectConfigs = () => createSelector(
  store,
  ({ configs }: Object) => ({
    carrierInvoicesStatuses: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configs,
      GC.INVOICE_CI_STATUS,
      true,
    ),
  }),
);

const makeSelectReconciliationConfigs = () => createSelector(
  store,
  ({ currentBranchConfigs }: Object) => {
    const rejectStatusReason = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      currentBranchConfigs,
      GC.INVOICE_CARRIER_REJECT_REASON,
    );
    const approveStatusReason = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      currentBranchConfigs,
      GC.INVOICE_CARRIER_APPROVE_REASON,
    );
    return { rejectStatusReason, approveStatusReason };
  },
);

const makeSelectInvoiceStatusOptions = () => createSelector(
  store,
  ({ invoiceStatusOptions }: Object) => invoiceStatusOptions,
);

export {
  makeSelectConfigs,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectInvoiceStatusOptions,
  makeSelectReconciliationConfigs,
};
