import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// feature menus
import {
  SidebarItem,
  IconWrapper,
  SidebarGroupWrapper,
  SidebarItemRightPlusIcon,
  SidebarItemRightArrowIcon } from '../ui';
//////////////////////////////////////////////////

const renderArrowIcon = (opened: boolean, iconColor: string) => (
  <SidebarItemRightArrowIcon>
    {
      G.ifElse(
        G.isTrue(opened),
        I.arrowUpSimple(iconColor),
        I.arrowDownSimple(iconColor),
      )
    }
  </SidebarItemRightArrowIcon>
);

const renderPlusIcon = (handleClickPlusIcon: Object, item: Object, iconColor: string) => (
  <SidebarItemRightPlusIcon
    color={iconColor}
    onClick={(event: Event) => {
      G.stopPropagation(event);
      handleClickPlusIcon(item);
    }}
  >
    +
  </SidebarItemRightPlusIcon>
);

const renderSubMenu = (submenu: Object, subMenuProps: Object) => submenu.map((item: Object, index: number) => (
  <SidebarItemComponent {...subMenuProps} key={index} item={item} />
));

export const SidebarItemComponent = (props: Object) => {
  const {
    item,
    styling,
    navItems,
    iconsColor,
    currentItem,
    subMenusStatus,
    toggleSubmenus,
    handleClickNavItem,
    handleClickPlusIcon,
  } = props;

  const [opened, toggleItem] = useState(false);
  const [isHovered, setHoverStatus] = useState(false);

  const handleToggleItem = useCallback(() => {
    if (R.isNil(item.ownerItemGuid)) {
      const newStatus = R.mapObjIndexed(
        (value: any, key: string) => R.and(R.equals(key, item.key), R.not(value)),
        subMenusStatus,
      );

      return toggleSubmenus(newStatus);
    }

    toggleItem(R.not(opened));
  }, [item, subMenusStatus]);

  const subMenuProps = {
    styling,
    navItems,
    iconsColor,
    currentItem,
    subMenusStatus,
    toggleSubmenus,
    handleClickNavItem,
    handleClickPlusIcon,
  };

  if (G.isNotEmpty(item.submenu)) {
    return (
      <SidebarGroupWrapper opened={R.or(subMenusStatus[item.key], opened)}>
        <SidebarItem
          color={iconsColor}
          onClick={handleToggleItem}
          opened={R.or(subMenusStatus[item.key], opened)}
        >
          <IconWrapper>
            {
              R.pathOr(
                () => {},
                [item.iconName],
                I,
              )(R.or(iconsColor, G.getTheme('menus.sidebar.iconColor')), null, null, styling.backgroundColor)}
          </IconWrapper>
          {item.title}
          {renderArrowIcon(R.or(subMenusStatus[item.key], props.opened), iconsColor)}
        </SidebarItem>
        {renderSubMenu(item.submenu, subMenuProps)}
      </SidebarGroupWrapper>
    );
  }

  return (
    <SidebarItem
      color={iconsColor}
      onMouseEnter={() => setHoverStatus(true)}
      onMouseLeave={() => setHoverStatus(false)}
      active={R.equals(currentItem, item.action)}
      onClick={() => handleClickNavItem(item.action)}
    >
      <IconWrapper>
        {
          R.pathOr(
            () => {},
            [item.iconName],
            I,
          )(R.or(iconsColor, G.getTheme('menus.sidebar.iconColor')), null, null, styling.backgroundColor)}
      </IconWrapper>
      {item.title}
      {
        R.and(isHovered, R.isNil(R.find(R.propEq(item.guid, 'guid'), navItems)))
        && renderPlusIcon(handleClickPlusIcon, item, iconsColor)
      }
    </SidebarItem>
  );
};

export default SidebarItemComponent;
