import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
// feature invoice
import { generalFields } from './common-fields';
//////////////////////////////////////////////////

export const totalFields = [
  {
    type: 'text',
    isRequired: true,
    inputStyles: {
      width: 113,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    label: G.getWindowLocale('titles:total-distance', 'Total Distance'),
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    inputStyles: {
      width: 90,
      borderRadius: '0px 4px 4px 0px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  },
  {
    type: 'text',
    isRequired: true,
    inputStyles: {
      width: 113,
      borderRadius: '4px 0 0 4px',
    },
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    label: G.getWindowLocale('titles:total-weight', 'Total Weight'),
  },
  {
    type: 'select',
    inputWrapperStyles: { mr: 20 },
    inputStyles: {
      width: 90,
      borderRadius: '0px 4px 4px 0px',
    },
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    label: ['titles:total-pickup-quantity', 'Total Pickup Quantity'],
    inputStyles: { ml: 'auto', width: 113, borderRadius: '4px 0 0 4px' },
  },
  {
    type: 'select',
    fieldName: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    inputStyles: { width: 90, borderRadius: '0 4px 4px 0' },
  },
];

export const customerFields = R.map(
  (item: Object) => R.assoc(
    'inputWrapperStyles',
    {
      flexGrow: 1,
      flexBasis: 150,
      mr: G.ifElse(
        R.equals(item, R.last(generalFields)),
        '0px',
        20,
      ),
    },
    item,
  ),
  generalFields,
);
