import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
// schemes
import * as VS from '../../schemes';
//////////////////////////////////////////////////

export const validationSchemaBillToCustomerRate = Yup.object()
  .shape(VS.validationSchemaPayTermObject)
  .shape(VS.validationSchemaLocationTypeObject)
  .shape(VS.validationSchemaLocationObject)
  .shape(VS.validationSchemaFaxObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.validationSchemaTripTotalObject)
  .shape(VS.validationSchemaContactObject);

export const isValidBillToCustomerRateForm = (formValues: Object) => validationSchemaBillToCustomerRate.isValidSync(
  G.mapObjectEmptyStringFieldsToNull(formValues),
);
