import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { useRef, useState, useEffect } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TitlePanel } from '../../../components/title-panel';
// forms
import { Toggle } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withWindowSize } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, RelativeBox } from '../../../ui';
// feature load-board
import LBResultsList from './lb-results-list';
import CompanyFilters from './company-filters';
import { resultsTableSettings } from '../settings/table-settings';
import { setTableTitleSort, setTableTitleFilter } from '../actions';
import { searchResultsFilterParams } from '../settings/field-settings';
import { searchResultsColumnSettings } from '../settings/result-settings';
import {
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
  makeSelectPinnedReportsArePresent,
} from '../selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withWindowSize,
  withHandlers({
    handleTableTitleFilter: ({
      setTableTitleSort,
      getItemListRequest,
      setTableTitleFilter,
      resetListAndPagination,
    }: Object) => (data: Object) => {
      const { isSorting } = data;

      if (isSorting) {
        setTableTitleSort(data.sortData);
      } else {
        const filter = G.createReportFilterFromTitleSearch(data);

        setTableTitleFilter(filter);
      }
    },
    handleSelectItem: (props: Object) => (guid: string) => (
      props.setItemList(R.map(
        (item: Object) => {
          if (R.equals(item.guid, guid)) return R.assoc('selected', R.not(item.selected), item);

          return item;
        },
        props.itemList,
      ))
    ),
    handleOpenCompanyFilterList: (props: Object) => () => {
      const { openModal } = props;

      const modal = {
        p: '0px',
        component: <CompanyFilters />,
        options: {
          width: 500,
          height: 'auto',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:company-filters', 'Company Filters'),
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const LoadBoardPanel = ({
  cancelledShown,
  companyFilters,
  toggleCancelledShown,
  handleOpenCompanyFilterList,
}: Object) => {
  const blueColor = G.getTheme('colors.dark.blue');
  const mainLightColor = G.getTheme('colors.light.mainLight');

  return (
    <Flex ml='auto' pl='16px' pr='16px' height='100%'>
      <Box mr='8px'>
        {G.getWindowLocale('actions:show', 'Show')} {G.getWindowLocale('titles:covered', 'Covered')}
      </Box>
      <Toggle
        icons={false}
        checked={cancelledShown}
        onChange={toggleCancelledShown}
      />
      <Flex ml={16} cursor='pointer' onClick={handleOpenCompanyFilterList}>
        <Box color={blueColor}>
          {G.getWindowLocale('titles:company-filters', 'Company Filters')}
        </Box>
        <RelativeBox ml='8px'>
          {I.simpleFilter(blueColor, 20, 20)}
          <AbsoluteBox
            p='3px'
            top={-10}
            fontSize={9}
            minWidth={18}
            display='flex'
            bg={blueColor}
            borderRadius='8px'
            border='1px solid'
            color={mainLightColor}
            left='calc(100% - 8px)'
            justifyContent='center'
            borderColor={mainLightColor}
          >
            {R.length(companyFilters)}
          </AbsoluteBox>
        </RelativeBox>
      </Flex>
    </Flex>
  );
}
const ResultList = enhance((props: Object) => {
  const {
    report,
    loading,
    itemList,
    cancelledShown,
    companyFilters,
    titleSortValues,
    handleSelectItem,
    handleEditReport,
    titleFilterValues,
    handleSelectReport,
    toggleCancelledShown,
    handleTableTitleFilter,
    pinnedReportsArePresent,
    changeDefaultReportRequest,
    handleOpenCompanyFilterList,
  } = props;

  const renderReport = R.assoc('fields', R.prepend({ name: 'highlighted', sequence: 0 }, report.fields), report);

  // Adjust table height after filter bookmarks expanded
  const resultsRef = useRef();
  const [resultHeight, setResultHeight] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (resultsRef.current) {
        const height = resultsRef.current?.getBoundingClientRect().height;

        setResultHeight(height);
      }
    }, 10);
  }, [new Date()]);

  return (
    <Flex
      ref={resultsRef}
      flexGrow={1}
      alignItems='normal'
      flexDirection='column'
      height='calc(100vh - 425px)'
    >
      <TitlePanel
        {...props}
        zIndex={2}
        withCount={true}
        noExportable={true}
        popperWithCount={true}
        selectedReport={report}
        hideReportFilters={true}
        withoutQuickFilter={true}
        hiddenAddReportFilter={true}
        hiddenRightFilterInfo={false}
        totalCount={R.length(itemList)}
        handleEditReport={handleEditReport}
        type={GC.EXTERNAL_LOAD_BOARD_REPORT}
        filterProps={searchResultsFilterParams}
        handleSelectReport={handleSelectReport}
        changeDefaultReportRequest={changeDefaultReportRequest}
        title={G.getWindowLocale('titles:load-board-results', 'Load Board Results')}
        additionalComponent={
          <LoadBoardPanel
            cancelledShown={cancelledShown}
            companyFilters={companyFilters}
            toggleCancelledShown={toggleCancelledShown}
            handleOpenCompanyFilterList={handleOpenCompanyFilterList}
          />
        }
      />
      <Box zIndex={1} maxHeight='calc(100% - 60px)'>
        <LBResultsList
          loading={loading}
          itemList={itemList}
          hasSelectable={true}
          report={renderReport}
          resultHeight={resultHeight}
          totalCount={R.length(itemList)}
          onOptionClick={handleSelectItem}
          titleSortValues={titleSortValues}
          tableSettings={resultsTableSettings}
          titleFilterValues={titleFilterValues}
          columnSettings={searchResultsColumnSettings}
          handleTableTitleFilter={handleTableTitleFilter}
          pinnedReportsArePresent={pinnedReportsArePresent}
        />
      </Box>
    </Flex>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  titleSortValues: makeSelectTitleSortValues(state),
  titleFilterValues: makeSelectTableTitleFilters(state),
  pinnedReportsArePresent: makeSelectPinnedReportsArePresent(state),
});

export default connect(mapStateToProps, {
  setTableTitleSort,
  setTableTitleFilter,
})(ResultList);
