import * as R from 'ramda';
import React from 'react';
// forms
import { PayToInfo, BillToInfo, RemitToInfo } from '../../../forms/forms/bill-remit-to/info';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

export const HeaderSection = (props: Object) => (
  <Flex p='10px' alignItems='center' justifyContent='center'>
    <BillToInfo {...props} />
    <RemitToInfo {...props} />
  </Flex>
);

export const RemitToSection = (props: Object) => (
  <Flex p='10px' alignItems='center' justifyContent='center'>
    <RemitToInfo {...props} />
  </Flex>
);

export const PayRemitToSection = (props: Object) => {
  const { values, serviceVendorListAvailabe } = props;

  const serviceVendorGuid = G.getPropFromObject(GC.FIELD_SERVICE_VENDOR_GUID, values);
  const serviceVendor = R.find(R.propEq(serviceVendorGuid, GC.FIELD_GUID), serviceVendorListAvailabe);
  const payTo = R.or(
    G.getPropFromObject(GC.SYSTEM_OBJECT_PAY_TO_LOCATION, serviceVendor),
    G.getPropFromObject(GC.SYSTEM_OBJECT_PRIMARY_LOCATION, serviceVendor),
  );

  if (G.isNilOrEmpty(serviceVendor)) return null;

  return (
    <Flex p='10px' alignItems='center' justifyContent='center'>
      {G.isNotNilAndNotEmpty(payTo) && <PayToInfo payTo={payTo} />}
      <RemitToInfo {...props} />
    </Flex>
  );
};
