import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature load-planner-template
import * as A from './actions';
//////////////////////////////////////////////////

function* getLoadPlannerTemplateListRequestSaga() {
  try {
    yield put(A.setListLoading(true));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'post',
      successAction: A.getItemListSuccess,
      parentSagaName: 'getLoadPlannerTemplateListRequestSaga',
      endpoint: endpointsMap.loadPlannerTemplateListAvailable,
    });

    yield put(A.setListLoading(false));
  } catch (error) {
    yield call(G.handleException, error, 'getLoadPlannerTemplateListRequestSaga exception');
  }
}

function* removeLoadPlannerTemplateRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    yield call(crudSaga, {
      payload,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeLoadPlannerTemplateSuccess,
      parentSagaName: 'removeLoadPlannerTemplateRequestSaga',
      endpoint: endpointsMap.getRemoveLoadPlannerTemplateEndpoint(payload),
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeLoadPlannerTemplateRequestSaga exception');
  }
}
function* getCrossDockLocationsSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      parentSagaName: 'getCrossDockLocationsSaga',
      endpoint: endpointsMap.crossDockLocationList,
      successAction: A.getCrossDockLocationsSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCrossDockLocationsSaga exception');
  }
}

function* handleVisitLoadPlannerTemplateListSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());

    yield call(getLoadPlannerTemplateListRequestSaga);
    yield call(getCrossDockLocationsSaga);

    yield put(closeLoader());

    break;
  }
}

function* loadPlannerTemplatesWatcherSaga() {
  yield takeLatest(A.removeLoadPlannerTemplateRequest, removeLoadPlannerTemplateRequestSaga);
  yield takeLatest(GC.VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE, handleVisitLoadPlannerTemplateListSaga);
}

export default loadPlannerTemplatesWatcherSaga;
