import { createReducer } from 'redux-act';
import * as P from 'plow-js';
// feature mail-sending
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  contactList: null,
  mailSend: {
    to: [''],
    subject: '',
  },
};

const getMailSendingSuccess = (state: Object, data: Object) => (
  P.$set('mailSend', data, state)
);

const createMailSendingSuccess = (state: Object, data: Object) => (
  P.$set('mailSend', data, state)
);

const updateMailSendingSuccess = (state: Object, data: Object) => (
  P.$set('mailSend', data, state)
);

const receivedContactListSuccess = (state: Object, data: Object) => (
  P.$set('contactList', data, state)
);

export default createReducer({
  [A.getMailSendingSuccess]: getMailSendingSuccess,
  [A.createMailSendingSuccess]: createMailSendingSuccess,
  [A.updateMailSendingSuccess]: updateMailSendingSuccess,
  [A.receivedContactListSuccess]: receivedContactListSuccess,
}, initialState);
