import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// features
import { FormSection } from '../../rate/ui';
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
// forms
import { Fieldset2 } from '../../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { SectionsDivider } from '../../../ui';
// hocs
import {
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
} from '../../../hocs';
// feature master-invoice
import { ChargesSection } from './charges';
import {
  masterInvoiceInitFields,
  getMasterInvoiceSectionSettings,
} from '../settings/master-invoice-with-charges-settings';
import {
  validationSchemaMasterInvoiceObject,
  validationSchemaMasterInvoiceObjectNumber,
} from '../validators';
//////////////////////////////////////////////////

const MasterInvoiceSection = pure((props: Object) => {
  const { values, glDisabled } = props;

  return (
    <FormSection justifyContent='space-around'>
      {
        getMasterInvoiceSectionSettings(values, glDisabled).map(({ fields }: Object, i: number) => (
          <Fieldset2
            {...props}
            key={i}
            fields={fields}
            fieldsWrapperStyles={{ mt: 25, flexDirection: 'column' }}
          />
      ))}
    </FormSection>
  );
});

const getCharges = R.compose(
  R.map((item: Object) => R.assoc(
    GC.FIELD_GL_CODE,
    R.path([GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID], item),
    item,
  )),
  R.pathOr([], ['data', GC.FIELD_CHARGES]),
);

export const setDefaultValues = ({ asyncInitialData }: Object) => R.mergeAll([
  masterInvoiceInitFields,
  R.pathOr({}, ['data'], asyncInitialData),
  {
    [GC.FIELD_CHARGES]: getCharges(asyncInitialData),
    [GC.FIELD_GL_CODE]: R.path(['data', GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID], asyncInitialData),
    [GC.FIELD_MASTER_INVOICE_STATUS]: R.path(['data', GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], asyncInitialData),
  },
]);

const validationSchemaObject = Yup.object()
  .shape(validationSchemaMasterInvoiceObjectNumber)
  .shape(validationSchemaMasterInvoiceObject);

export const setOptionsForSelect = (props: Object) => ({
  [GC.FIELD_MASTER_INVOICE_STATUS]: G.mapCustomConfigOptionsFromProps('CIStatusConfig', props, true),
  [GC.FIELD_GL_CODE]: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    G.getPropFromObject('asyncConfigs', props),
    GC.INVOICE_GL_CODE,
    true,
  ),
});

export const MasterInvoiceWithCharges = (props: Object) => {
  const { titleText, authorities, handleSubmit } = props;

  return (
    <section>
      <FormGroupTitleMultiple
        mb='0px'
        title={titleText}
        showArrowToggle={false}
      />
      <SectionsDivider />
      <form onSubmit={handleSubmit}>
        <MasterInvoiceSection
          {...G.getFormikProps(props)}
          {...setOptionsForSelect(props)}
          glDisabled={G.notContain(PC.GL_CODE_WRITE, authorities)}
        />
        <ChargesSection {...props} />
        <FormFooter2 boxStyles={{ p: 15 }} />
      </form>
    </section>
  );
};

const mapChargesWithSequenceNum = (masterInvoice: Object) => R.assoc(
  GC.FIELD_MASTER_INVOICE_CHARGES,
  G.mapIndexed((charge: Object, index: number) => R.assoc(
    GC.FIELD_CHARGE_SEQUENCE_NUM,
    R.inc(index),
    charge,
  ), R.or(G.getPropFromObject(GC.FIELD_MASTER_INVOICE_CHARGES, masterInvoice), [])),
  masterInvoice,
);

const configsNamesArray = [GC.INVOICE_GL_CODE];

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withAsyncInitialDataOnDidMount,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendMasterInvoice } = props;

      const data = R.compose(
        mapChargesWithSequenceNum,
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
      )(values);

      handleSendMasterInvoice(data);
    },
    displayName: 'MasterInvoiceForm',
  }),
  withProps(() => ({ configsNamesArray })),
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncInvoiceStatusConfigs,
  pure,
);

export default enhance(MasterInvoiceWithCharges);
