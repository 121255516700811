import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  withProps,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { LocalLoader } from '../../../components/local-loader';
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { OrderChargesTotal } from '../../../components/charge/components';
import { withRecalculateAllChargesId } from '../../../components/charge/hocs';
import { DOChargeComponent } from '../../../components/charge/formik/charges-array';
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
import { getIsPriceSheetItemFieldsEditable } from '../../../components/price-sheet-items/settings';
import { withAsyncChargeAccessorials } from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import SectionDivider from '../../new-do/components/section-divider';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
// forms
import { Form } from '../../../forms';
import { Fieldset2 } from '../../../forms/formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncSequence,
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
  withConnectModalAndLoaderActions,
  withAsyncGetGLCodeMappingsByScope,
  withComponentDidUpdatePropCallback,
} from '../../../hocs';
// schemes
import * as VS from '../../../schemes';
// ui
import { StickedBox, SectionsDivider } from '../../../ui';
// feature invoice
import PaymentsArray from './payments-array';
import ReferencesArray from './references-array';
import { HeaderSection } from './header-section';
import { getOrderBillToRequest } from '../customer/actions';
import { totalFields, customerFields } from '../settings/customer-fields';
import { setOptionsForSelect, setDefaultInvoiceValues, getDataWithTransformedReferences } from '../helpers';
import {
  commonFields1,
  commonFields2,
  commentFields,
  contactFields,
  externalInvoiceNumberField,
  validationSchemaReferences,
} from '../settings/common-fields';
//////////////////////////////////////////////////

const configsNamesArrayForCreateInvoice = [
  GC.INVOICE_GL_CODE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.INVOICE_CLO_INVOICE_NUMBER_SEQUENCE,
  GC.INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED,
];

const configsNamesArrayForUpdateInvoice = [
  GC.INVOICE_GL_CODE,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
];

const validationSchemaObject = Yup.lazy((values: Object) => Yup.object()
  .shape(validationSchemaReferences)
  .shape(VS.validationSchemaContactObject)
  .shape(VS.validationSchemaInvoiceObject)
  .shape(VS.validationSchemaTripTotalObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(G.ifElse(getIsPriceSheetItemFieldsEditable(values), VS.validationSchemaPriceSheetItems, {})));

const getBranchGuidFromProps = (props: Object) => R.pathOr(
  R.pathOr(R.pathOr('', ['branchGuid'], props), [GC.FIELD_CLO, GC.FIELD_BRANCH_GUID], props),
  [GC.FIELD_CLO, GC.FIELD_BRANCH, GC.FIELD_GUID],
  props,
);

const getFormGroupTitles = ({ isEditMode }: Object) => G.ifElse(
  isEditMode,
  G.getWindowLocale('titles:edit-customer-invoice', 'Edit Customer Invoice'),
  G.getWindowLocale('titles:add-customer-invoice', 'Add Customer Invoice'),
);

const CustomerCharges = (props: Object) => {
  const {
    isEditMode,
    authorities,
    isCreateMode,
    asyncConfigs,
    glCodeMappings,
    glCodeMappingList,
    recalculateAllChargesId,
  } = props;

  const branchGuid = getBranchGuidFromProps(props);
  const commonTotalProps = G.getTotalsFromValues(props);
  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const totalTripVolume = R.pathOr(0, ['itemsVolume', GC.ITEMS_VOLUME], props);
  const totalTripVolumeUom = R.pathOr('', ['itemsVolume', GC.ITEMS_VOLUME_UOM], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  const totalQuantities = {
    [R.path(['values', GC.FIELD_PACKAGE_TYPE], props)]: R.path(['values', GC.FIELD_TOTAL_PICKUP_QUANTITY], props),
  };

  const stopsQuantity = R.pathOr(
    R.pathOr(0, ['stopsQuantity'], props),
    [GC.FIELD_CLO, GC.FIELD_LOAD_STOPS, 'length'],
    props,
  );

  const glCodeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  );

  return (
    <div>
      <DOChargeComponent
        {...commonTotalProps}
        rateProps={props}
        isInvoice={true}
        isCustomer={true}
        currency={currency}
        branchGuid={branchGuid}
        loadType={GC.FIELD_CLO}
        isEditMode={isEditMode}
        authorities={authorities}
        serviceDays={serviceDays}
        isCreateMode={isCreateMode}
        glCodeOptions={glCodeOptions}
        stopsQuantity={stopsQuantity}
        glCodeMappings={glCodeMappings}
        normalizedTotal={normalizedTotal}
        totalQuantities={totalQuantities}
        totalTripVolume={totalTripVolume}
        chargesArrayName={GC.FIELD_CHARGES}
        glCodeMappingList={glCodeMappingList}
        totalTripVolumeUom={totalTripVolumeUom}
        recalculateAllChargesId={recalculateAllChargesId}
      />
    </div>
  );
};

const CustomerInvoiceForm = (props: Object) => {
  const {
    values,
    isEditMode,
    authorities,
    handleSubmit,
  } = props;

  const charges = G.getChargesFromObject(values);
  const optionsForSelect = setOptionsForSelect('CIStatusConfig', props);

  const fields2 = R.compose(
    R.map(
      R.when(
        R.propSatisfies(R.equals(R.__, GC.FIELD_INTEGRATED_DATE), 'fieldName'),
        R.assocPath(['inputWrapperStyles', 'mr'], 20),
      ),
    ),
    R.append(externalInvoiceNumberField),
  )(commonFields2);

  return (
    <Form width='100%' onSubmit={handleSubmit}>
      <StickedBox top='0px' zIndex={1001}>
        <FormGroupTitleMultiple
          mb='0'
          showArrowToggle={false}
          title={getFormGroupTitles(props)}
        />
      </StickedBox>
      {isEditMode && <HeaderSection {...props} />}
      {isEditMode && <SectionsDivider />}
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={commonFields1}
        fieldsWrapperStyles={{ px: 10, mt: 20, mb: 30 }}
        handlers={{
          handleDisableIntegratedDate: () => isEditMode,
          handleDisableGlCode: () => G.notContain(PC.GL_CODE_WRITE, authorities),
        }}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields2}
        fieldsWrapperStyles={{ px: 10, flexWrap: 'nowrap' }}
      />
      <PaymentsArray {...props} />
      <ReferencesArray {...props} scope={GC.REF_SCOPE_NAME_CUSTOMER_INVOICE} />
      <PriceSheetItems
        {...G.getFormikProps(props)}
        tableMarginBottom={20}
        editable={getIsPriceSheetItemFieldsEditable(values)}
        additionalFormHeaderSectionStyles={{ my: 0, mb: 20 }}
      />
      <SectionDivider
        mt='0px'
        text={G.getWindowLocale('titles:contact-info', 'Contact Info', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={contactFields}
        fieldsWrapperStyles={{ px: 10, flexWrap: 'nowrap' }}
      />
      <SectionDivider
        text={G.getWindowLocale('titles:comments', 'Comments', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={commentFields}
        fieldsWrapperStyles={{ px: 10 }}
      />
      <SectionDivider
        text={G.getWindowLocale('titles:rate', 'Rate', { caseAction: 'upperCase' })}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        {...optionsForSelect}
        fields={customerFields}
        fieldsWrapperStyles={{ mb: 30, px: 10, flexWrap: 'nowrap' }}
      />
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={totalFields}
        fieldsWrapperStyles={{ mb: 10, px: 10, flexWrap: 'nowrap' }}
      />
      <CustomerCharges {...props} />
      <StickedBox bottom='0px' zIndex={102}>
        <OrderChargesTotal {...props} charges={charges} />
        <ChargeFormFooter />
      </StickedBox>
    </Form>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
  availableTypes: makeSelectAvailableReferenceTypesByScope(state),
});

const enhanceCreate = compose(
  connect(mapStateToProps),
  withConnectModalAndLoaderActions,
  withRecalculateAllChargesId,
  withProps(() => ({
    isInvoice: true,
    isCustomer: true,
    isEditMode: false,
    configsNamesArray: configsNamesArrayForCreateInvoice,
  })),
  withAsyncChargeAccessorials(),
  withState('origSequence', 'setOrigSequence', null),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultInvoiceValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendCloInvoice } = props;

      const references = R.map(
        R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, G.getPropFromObject(GC.FIELD_CLO_GUID, values)),
        R.pathOr([], [GC.FIELD_REFERENCES], values),
      );

      const formattedIntegratedDate = G.checkAndConvertMomentInstanceToFormattedDate(
        G.getPropFromObject(GC.FIELD_INTEGRATED_DATE, values),
        GC.DEFAULT_DATE_TIME_FORMAT,
      );

      const items = R.map(G.omitObjectSystemFields, R.propOr([], 'items', values));

      const invoice = R.compose(
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.mergeLeft({
          items,
          references,
          [GC.FIELD_INTEGRATED_DATE]: formattedIntegratedDate,
        }),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        R.omit([GC.FIELD_GUID, GC.FIELD_VERSION]),
        G.omitObjectChildArrayItemSystemFieldsCurried(GC.FIELD_CHARGES),
        G.omitEmptyPaymentsFromData,
      )(G.omitEmptyChargesFromData(values, GC.FIELD_CHARGES));

      if (R.equals(R.prop(GC.FIELD_INVOICE_NUMBER, invoice), props.origSequence)) {
        handleSendCloInvoice(R.assoc(GC.FIELD_INVOICE_NUMBER, null, invoice));
      } else {
        handleSendCloInvoice(invoice);
      }
    },
    displayName: 'CREATE_CUSTOMER_INVOICE_FORM',
  }),
  withAsyncSequence,
  withAsyncInvoicesCount,
  withAsyncInvoiceStatusConfigs,
  withPropsOnChange(
    ['asyncSequence'],
    (props: Object) => {
      const { asyncSequence, setFieldValue, setOrigSequence, defaultInvoiceName } = props;

      const value = G.ifElse(
        G.isNotNil(asyncSequence),
        asyncSequence,
        defaultInvoiceName,
      );

      if (G.isNotNil(asyncSequence)) setOrigSequence(asyncSequence);

      setFieldValue(GC.FIELD_INVOICE_NUMBER, value);
    },
  ),
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_CUSTOMER),
  pure,
);

const asyncSetDefaultValues = (props: Object) => {
  const { availableTypes, asyncInitialData: { data } } = props;

  if (G.isNilOrEmpty(data)) return {};

  const dataToUse = getDataWithTransformedReferences(data, availableTypes);

  return setDefaultInvoiceValues(R.assoc('initialValues', dataToUse, props));
};

const enhanceAsyncUpdate = compose(
  connect(mapStateToProps, { getOrderBillToRequest }),
  withState('orderBillTo', 'setOrderBillTo', {}),
  withConnectModalAndLoaderActions,
  withRecalculateAllChargesId,
  withProps(() => ({
    isAsync: true,
    isInvoice: true,
    isCustomer: true,
    isEditMode: true,
    configsNamesArray: configsNamesArrayForUpdateInvoice,
  })),
  withAsyncChargeAccessorials(),
  withAsyncInitialDataOnDidMount,
  withAsyncConfigs,
  withAsyncInvoiceStatusConfigs,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => asyncSetDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendCloInvoice, asyncInitialData: { data } } = props;

      const { billTo, cloGuid, references, integratedDate } = values;

      const referencesWithCloGuid = R.map(R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, cloGuid), R.or(references, []));

      const formattedIntegratedDate = G.checkAndConvertMomentInstanceToFormattedDate(
        integratedDate,
        GC.DEFAULT_DATE_TIME_FORMAT,
      );

      const invoice = R.compose(
        R.mergeLeft({
          [GC.FIELD_REFERENCES]: referencesWithCloGuid,
          [GC.FIELD_INTEGRATED_DATE]: formattedIntegratedDate,
        }),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        G.omitEmptyPaymentsFromData,
      )(G.omitEmptyChargesFromData(values, GC.FIELD_CHARGES));

      const shouldCreateSeparateBillTo = R.and(
        G.isTrue(R.pathOr(false, [GC.FIELD_SEPARATE_INVOICE_BILL_TO], billTo)),
        G.isFalse(R.pathOr(false, [GC.FIELD_BILL_TO, GC.FIELD_SEPARATE_INVOICE_BILL_TO], data)),
      );

      if (G.isTrue(shouldCreateSeparateBillTo)) {
        return handleSendCloInvoice(R.assocPath([GC.FIELD_BILL_TO], G.omitObjectSystemFields(billTo), invoice));
      }

      handleSendCloInvoice(invoice);
    },
    displayName: 'ASYNC_EDIT_CUSTOMER_INVOICE_FORM',
  }),
  withHandlers({
    updateBillToRequest: (props: Object) => (values: Object) => {
      const { setFieldValue, initialValues } = props;

      const initial = R.pathOr({}, [GC.SYSTEM_OBJECT_BILL_TO], initialValues);

      setFieldValue(GC.SYSTEM_OBJECT_BILL_TO, R.mergeRight(initial, values));
    },
    updateRemitToRequest: (props: Object) => (values: Object) => {
      const { setFieldValue, initialValues } = props;

      const initial = R.pathOr({}, [GC.SYSTEM_OBJECT_REMIT_TO], initialValues);

      setFieldValue(GC.SYSTEM_OBJECT_REMIT_TO, R.mergeRight(initial, values));
    },
    handleGetOrderBillToRequest: (props: Object) => () => {
      const { cloGuid, setOrderBillTo, getOrderBillToRequest, asyncInitialData: { data } } = props;

      if (G.isTrue(R.pathOr(false, [GC.FIELD_BILL_TO, GC.FIELD_SEPARATE_INVOICE_BILL_TO], data))) {
        getOrderBillToRequest({ cloGuid, callback: setOrderBillTo });
      }
    },
    handleChangeSeparateBillTo: (props: Object) => (event: Object) => {
      const { orderBillTo, handleChange, setFieldValue, asyncInitialData: { data: { billTo } } } = props;

      handleChange(event);

      if (G.isTrue(R.pathOr(false, [GC.FIELD_SEPARATE_INVOICE_BILL_TO], billTo))) {
        const newBillTo = G.ifElse(
          R.pathOr(false, ['currentTarget', 'checked'], event),
          billTo,
          orderBillTo,
        );

        setFieldValue(GC.SYSTEM_OBJECT_BILL_TO, newBillTo);
      }
    },
  }),
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_CUSTOMER),
  withComponentDidUpdatePropCallback({
    propName: 'asyncInitialData',
    callbackName: 'handleGetOrderBillToRequest',
  }),
  pure,
);

export default enhanceCreate(CustomerInvoiceForm);

export const AsyncEditCustomerInvoiceForm = enhanceAsyncUpdate((props: Object) => (
  <LocalLoader localLoaderOpen={R.pathOr(true, ['asyncInitialData', 'loading'], props)}>
    <CustomerInvoiceForm {...props} />
  </LocalLoader>
));
