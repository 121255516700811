import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withAsyncInvoiceStatusConfigs } from '../../../hocs';
// forms
import { Fieldset2 } from '../../../forms';
// feature invoice
import {
  actionFormFields,
  getDefaultActionFormFields,
  getActionFormValidationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    validationSchema: ({ statusConfigName }: Object) => getActionFormValidationSchema(statusConfigName),
    handleSubmit: (values: Object, { props }: Object) => {
      props.closeModal();
      props.submitAction(R.assoc('guids', props.invoiceGuids, values));
    },
    mapPropsToValues: ({ initialValues, statusConfigName }: Object) => G.setInitialFormikValues(
      getDefaultActionFormFields(statusConfigName),
      initialValues,
    ),
  }),
  withAsyncInvoiceStatusConfigs,
  pure,
);

const ActionForm = (props: Object) => {
  const { fields, options, handleSubmit, statusConfigName } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 15 }}
        fields={R.or(fields, actionFormFields)}
        options={R.or(options, G.mapCustomConfigOptionsFromProps(statusConfigName, props))}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(ActionForm);
