import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const changeLngFail = createAction('changeLngFail');
export const changeLngRequest = createAction('changeLngRequest');
export const initialLoadLngFail = createAction('initialLoadLngFail');
export const initialLoadLngRequest = createAction('initialLoadLngRequest');
export const changeLngSuccess = createAction('changeLngSuccess', (lng: string, data: Object) => ({ lng, data }));
export const initialLoadLngSuccess = createAction(
  'initialLoadLngSuccess',
  (lng: string, data: Object) => ({ lng, data }),
);
