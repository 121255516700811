import React from 'react';
import * as R from 'ramda';
// feature master-invoice
import PaymentInfo from './payment-info';
import MasterInvoiceCharges from './master-invoice-charges';
//////////////////////////////////////////////////

const ActiveTab = (props: Object) => {
  const {
    activeTab,
    widthToUse,
  } = props;

  if (R.equals(activeTab, 0)) {
    return <MasterInvoiceCharges {...props} width={widthToUse} minWidth={widthToUse} />;
  }

  return <PaymentInfo {...props} />;
};

export default ActiveTab;
